<ion-item
  class="checklist-folder"
  [ngClass]="{ 'checklist-folder--with-progress-bars': showProgressBars }"
  lines="none"
  [button]="!forceOpen"
  (click)="onFolderClick()"
  [detail]="false"
  appHaptics
  [disabled]="forceOpen"
  [disableHaptics]="forceOpen"
>
  <ion-icon
    slot="start"
    class="checklist-folder__icon"
    name="folder-open-outline"
  />
  <div *ngIf="!showProgressBars" slot="end">
    <div class="checklist-folder__progress-value">
      Critical: {{ criticalTasksCount - criticalDoneTasksCount }} left
    </div>
    <div class="checklist-folder__progress-value">
      Total: {{ allTasksCount - allDoneTasksCount }} left
    </div>
  </div>
  <div class="checklist-folder__item-body">
    <div class="checklist-folder__title ion-text-capitalize">
      {{ taskFolderTitle.toLowerCase() }}
    </div>
    <div *ngIf="showProgressBars" class="progress-line">
      <div class="checklist-folder__progress-label">Critical</div>
      <ion-progress-bar
        [value]="criticalDoneTasksCount / criticalTasksCount"
        [color]="
          criticalTasksCount - criticalDoneTasksCount === 0
            ? 'success'
            : 'warning'
        "
      />
      <div class="checklist-folder__progress-value">
        {{ criticalTasksCount - criticalDoneTasksCount }} left
      </div>
    </div>
    <div *ngIf="showProgressBars" class="progress-line">
      <div class="checklist-folder__progress-label">Total</div>
      <ion-progress-bar
        [value]="allDoneTasksCount / allTasksCount"
        [color]="
          allTasksCount - allDoneTasksCount === 0 ? 'success' : 'primary'
        "
      />
      <div class="checklist-folder__progress-value">
        {{ allTasksCount - allDoneTasksCount }} left
      </div>
    </div>
  </div>
</ion-item>

<div *ngIf="isOpen || forceOpen" class="group-tasks">
  <app-checklist-item
    *ngFor="let item of items; let i = index; trackBy: listItemTrackBy"
    [item]="item"
    [isOverdue]="item | isOverdue : time"
    (click)="itemClick.emit(item)"
    appHaptics
    [animationOrder]="i"
  ></app-checklist-item>
</div>
