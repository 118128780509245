<ion-header class="ion-no-border" *ngIf="isSuccess || isLoading">
  <ion-toolbar class="app-common-background">
    <ion-buttons slot="start" class="ion-hide-lg-up">
      <app-slim-menu-button></app-slim-menu-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div *ngIf="!isSuccess; else successStub" class="layout-container">
    <ion-grid *ngIf="isLoading">
      <ion-row class="ion-justify-content-center">
        <ion-spinner name="lines-sharp-small"></ion-spinner>
      </ion-row>
    </ion-grid>

    <ng-container *ngIf="!isLoading && survey && currentQuestion.question">
      <app-positive-feedback-question
        *ngIf="
          currentQuestion.question.type ===
          psychologicalSurveyQuestionType.POSITIVE_FEEDBACK
        "
        class="question-animation"
        [survey]="survey"
        [question]="currentQuestion.question"
        [previousAnswer]="currentQuestion.answer"
        (completed)="onQuestionComplete($event)"
        (goBack)="onStepBack()"
      />
      <app-negative-feedback-question
        *ngIf="
          currentQuestion.question.type ===
          psychologicalSurveyQuestionType.NEGATIVE_FEEDBACK
        "
        class="question-animation"
        [survey]="survey"
        [question]="currentQuestion.question"
        [previousAnswer]="currentQuestion.answer"
        (completed)="onQuestionComplete($event)"
        (goBack)="onStepBack()"
      />
      <app-mood-check-question
        *ngIf="
          currentQuestion.question.type ===
          psychologicalSurveyQuestionType.MOOD_CHECK
        "
        class="question-animation"
        [survey]="survey"
        [question]="currentQuestion.question"
        [previousAnswer]="currentQuestion.answer"
        (completed)="onQuestionComplete($event)"
        (goBack)="onStepBack()"
      />
      <app-team-evaluation-question
        *ngIf="
          currentQuestion.question.type ===
          psychologicalSurveyQuestionType.TEAM_EVALUATION
        "
        class="question-animation"
        [survey]="survey"
        [question]="currentQuestion.question"
        [previousAnswer]="currentQuestion.answer"
        (completed)="onQuestionComplete($event)"
        (goBack)="onStepBack()"
      />
    </ng-container>
  </div>
</ion-content>

<ng-template #successStub>
  <app-empty-stub
    class="question-animation"
    icon="checkmark-circle-outline"
    text="You're done. Thank you!"
  ></app-empty-stub>
</ng-template>
