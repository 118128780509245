import { NgModule } from '@angular/core';
import { EditTaskPerformanceModalComponent } from './edit-task-performance-modal.component';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DateSelectorModule } from '@app/modules/date-selector/date-selector.module';
import { UserSelectorComponent } from '../user-selector/user-selector.component';
import { WideButtonComponent } from '../wide-button/wide-button.component';
import { DirectivesModule } from '@app/modules/directives.module';
import { DigitOnlyModule } from '@uiowa/digit-only';
import { TimeFormControlModule } from '../time-form-control/time-form-control.module';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    ReactiveFormsModule,
    DateSelectorModule,
    UserSelectorComponent,
    WideButtonComponent,
    DirectivesModule,
    DigitOnlyModule,
    TimeFormControlModule
  ],
  exports: [EditTaskPerformanceModalComponent],
  declarations: [EditTaskPerformanceModalComponent]
})
export class EditTaskPerformanceModalModule {}
