import { Component } from '@angular/core';
import { TenantService } from '@app/core/service/tenant.service';
import { IonNav } from '@ionic/angular';
import { ITenantFeatures } from '@backend/models/types/tenant';
import { BehaviorsEditComponent } from './components/behaviors-edit/behaviors-edit.component';
import { GeneralSettingsComponent } from './components/general-settings/general-settings.component';
import { SecuritySettingsComponent } from './components/security-settings/security-settings.component';
import { ChecklistSettingsComponent } from './components/checklist-settings/checklist-settings.component';
import { TagsSettingsComponent } from './components/tags-settings/tags-settings.component';
import { AnnouncementsSettingsComponent } from './components/announcements-settings/announcements-settings.component';
import { SuggestionsSettingsComponent } from './components/suggestions-settings/suggestions-settings.component';
import { DailyReportSettingsComponent } from './components/daily-report-settings/daily-report-settings.component';
import { PulseSettingsComponent } from './components/pulse-settings/pulse-settings.component';
import { CompensationSettingsComponent } from './components/compensation-settings/compensation-settings.component';
import { RecognitionSettingsComponent } from './components/recognition-settings/recognition-settings.component';

@Component({
  selector: 'app-team-settings',
  templateUrl: './team-settings.component.html',
  styleUrls: ['./team-settings.component.scss']
})
export class TeamSettingsComponent {
  protected tenantFeatures: Pick<
    ITenantFeatures,
    | 'checklist'
    | 'announcements'
    | 'suggestions'
    | 'multiUserMode'
    | 'survey'
    | 'dailyReport'
    | 'calculateRewards'
    | 'tags'
    | 'auditTasks'
    | 'insights'
  > = {
    checklist: false,
    announcements: false,
    suggestions: false,
    survey: false,
    dailyReport: false,
    multiUserMode: false,
    calculateRewards: false,
    tags: false,
    auditTasks: false,
    insights: false
  };

  public constructor(
    private readonly _tenantService: TenantService,
    private readonly _ionNav: IonNav
  ) {
    this._tenantService.tenant$.subscribe((tenant) => {
      this.tenantFeatures = tenant.features;
    });
  }

  protected goToGeneral() {
    this._ionNav.push(GeneralSettingsComponent);
  }

  protected goToSecurity() {
    this._ionNav.push(SecuritySettingsComponent);
  }

  protected goToCompensation() {
    this._ionNav.push(CompensationSettingsComponent);
  }

  protected goToBehavior() {
    this._ionNav.push(BehaviorsEditComponent);
  }

  protected goToDailyReport() {
    this._ionNav.push(DailyReportSettingsComponent);
  }

  protected goToRecognition() {
    this._ionNav.push(RecognitionSettingsComponent);
  }

  protected goToChecklist() {
    this._ionNav.push(ChecklistSettingsComponent);
  }

  protected goToTags() {
    this._ionNav.push(TagsSettingsComponent);
  }

  protected goToAnnouncements() {
    this._ionNav.push(AnnouncementsSettingsComponent);
  }

  protected goToSuggestions() {
    this._ionNav.push(SuggestionsSettingsComponent);
  }

  protected goToPulse() {
    this._ionNav.push(PulseSettingsComponent);
  }
}
