import { isMobile } from '@app/utils/is-mobile';

export class ModalClass {
  public _modalName: string;
  public _isMobile: boolean;

  public constructor() {
    const className: string = this.constructor.toString().match(/\w+/g)[1];

    this._modalName = className;
    this._isMobile = isMobile();
  }
}
