<ion-header class="ion-no-border">
  <ion-toolbar class="app-common-background" *ngIf="cancelButton === 'menu'">
    <ion-buttons
      slot="start"
      class="ion-hide-lg-up"
      *ngIf="question.orderIndex === 0 && !selectedUser; else goBackBtn"
    >
      <app-slim-menu-button></app-slim-menu-button>
    </ion-buttons>
  </ion-toolbar>

  <ion-toolbar
    class="app-common-background survey-preview-toolbar"
    *ngIf="cancelButton === 'cancel'"
  >
    <ion-buttons
      slot="start"
      *ngIf="question.orderIndex === 0 && !selectedUser; else goBackBtn"
    >
      <ion-button (click)="onCancelClick()" color="primary" appHaptics>
        Cancel
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<app-width-restricted-container class="question-holder" *ngIf="!selectedUser">
  <div class="top-content ion-padding-top">
    <ion-text>
      <h2
        class="ion-text-center heading"
        [innerHTML]="
          questionTexting === 'LAST_SHIFT'
            ? question.params.questionTitleLastShift
            : question.params.questionTitle
        "
      ></h2>
    </ion-text>
    <ion-text>
      <h3 class="ion-text-center emoji">{{ question.params.emoji }}</h3>
    </ion-text>
    <div class="users-list users-list--same-width">
      <ion-chip
        *ngIf="result.usersFeedback.length"
        outline
        (click)="selectIndividuals()"
        appHaptics
      >
        <ion-icon name="add" class="add-icon"></ion-icon>
        <ion-label class="user-name">Select Individuals</ion-label>
      </ion-chip>
    </div>
    <div class="users-divider">
      <div class="app-divider-line"></div>
    </div>
    <div class="users-list">
      <ion-chip
        *ngFor="let user of result.usersFeedback"
        outline
        (click)="editUser(user.userId)"
        class="user-chip"
        appHaptics
      >
        <div class="avatar">
          <div>{{ getUserInitials(user.userId) }}</div>
        </div>
        <ion-label class="user-name">{{ getUserName(user.userId) }}</ion-label>
        <ion-button
          (click)="removeUser($event, user.userId)"
          class="remove-btn ion-no-margin"
          fill="clear"
          appHaptics
        >
          <ion-icon
            slot="icon-only"
            name="close"
            class="remove-icon"
          ></ion-icon>
        </ion-button>
      </ion-chip>
    </div>
    <ion-grid *ngIf="result.usersFeedback.length === 0">
      <ion-row class="ion-justify-content-center">
        <ion-col size-xs="12" size-sm="6">
          <app-emoji-button
            label="Yes"
            emoji="🙂"
            appHaptics
            (click)="selectIndividuals()"
          />
        </ion-col>
      </ion-row>
      <ion-row class="ion-justify-content-center">
        <ion-col size-xs="12" size-sm="6">
          <app-emoji-button
            label="No"
            emoji="🙁"
            appHaptics
            (click)="skipQuestion()"
          />
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
  <div class="bottom-content">
    <ion-grid>
      <ion-row class="ion-justify-content-center ion-padding">
        <ion-col size="6">
          <ion-button
            *ngIf="result.usersFeedback.length > 0"
            color="dark"
            (click)="completeQuestion()"
            appHaptics
            expand="block"
          >
            {{ question.params.submitButtonText }}
          </ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
</app-width-restricted-container>

<app-width-restricted-container
  class="question-holder"
  *ngIf="selectedUser && !isRecognitionScreen"
>
  <div class="top-content ion-padding-top">
    <ion-text>
      <h2
        class="ion-text-center heading"
        [innerHtml]="behaviorsSelectorTitle"
      ></h2>
    </ion-text>
    <div class="behaviors-list">
      <ion-list>
        <ion-item
          button
          [detail]="false"
          *ngFor="let behavior of selectedBehaviors; trackBy: trackBehaviors"
          (click)="checkboxClick($event, behavior.id)"
          appHaptics
        >
          <ion-checkbox [value]="behavior.id" [checked]="behavior.checked">
            <span class="behavior-name">
              {{ behavior.name }}
            </span>
          </ion-checkbox>
        </ion-item>
        <ion-item
          button
          [detail]="false"
          (click)="suggestedOptionCheckboxClick($event)"
          appHaptics
        >
          <ion-checkbox
            value="suggested-option"
            [checked]="isSuggestedOptionChecked"
          >
            <span class="behavior-name"> Suggest a new option </span>
          </ion-checkbox>
        </ion-item>
      </ion-list>
      <ion-grid [attr.data-hidden]="!isSuggestedOptionChecked">
        <ion-row>
          <ion-col size="12">
            <ion-textarea
              #suggestionTextarea
              class="app-w100 input-with-background fix-textarea"
              fill="solid"
              [autoGrow]="true"
              placeholder="Your suggestion will only be shared with the app developer and will not be visible to anyone else in your organization."
              [rows]="3"
              [value]="suggestedOption"
              (ionInput)="onSuggestedOptionChange($event)"
              enterkeyhint="done"
              (keypress)="onSuggestionTextareaKeyPress($event)"
              autocapitalize="on"
            ></ion-textarea>
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>
  </div>
  <div class="bottom-content">
    <ion-grid>
      <ion-row class="ion-justify-content-center ion-padding">
        <ion-col size="6">
          <ion-button
            color="dark"
            (click)="showRecognition()"
            appHaptics
            expand="block"
            [disabled]="!canGoToRecognitionScreen"
            [disableHaptics]="!canGoToRecognitionScreen"
          >
            Continue
          </ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
</app-width-restricted-container>

<app-width-restricted-container
  class="question-holder"
  *ngIf="selectedUser && isRecognitionScreen"
>
  <div class="top-content ion-padding-top">
    <ion-text>
      <h2
        class="ion-text-center heading"
        [innerHtml]="recognitionTitleHtml"
      ></h2>
    </ion-text>
    <ion-grid>
      <ion-row class="comment-holder">
        <ion-col size="12">
          <ion-textarea
            #commentTextarea
            class="app-w100 input-with-background fix-textarea"
            fill="solid"
            [rows]="10"
            [value]="comment"
            (ionInput)="onCommentChange($event)"
            enterkeyhint="done"
            (keypress)="onCommentTextareaKeyPress($event)"
            [placeholder]="''"
            autocapitalize="on"
          ></ion-textarea>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
  <div class="bottom-content">
    <ion-grid>
      <ion-row class="ion-justify-content-center">
        <ion-col>
          <div class="ion-text-center">
            <div class="private-info-label">Private</div>
            <ion-toggle
              [(ngModel)]="isPrivateComment"
              class="ion-no-padding"
              appHaptics
              aria-label="Private"
            />
            <div class="private-info-description">
              {{ privateInfo.description }}
            </div>
          </div>
        </ion-col>
      </ion-row>
      <ion-row class="ion-justify-content-center ion-padding">
        <ion-col size="6">
          <ion-button
            color="dark"
            (click)="submitUser()"
            appHaptics
            [disabled]="!comment"
            [disableHaptics]="!comment"
            expand="block"
          >
            Continue
          </ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
</app-width-restricted-container>

<!-- <ng-template #skipTemplate>
  <ion-button color="dark" (click)="skipQuestion()" appHaptics expand="block">
    {{
      questionTexting === 'LAST_SHIFT'
        ? question.params.skipButtonTextLastShift
        : question.params.skipButtonText
    }}
  </ion-button>
</ng-template> -->

<ion-modal
  #selectIndividualsModal
  [handle]="false"
  [breakpoints]="[0, 1]"
  [initialBreakpoint]="1"
>
  <ng-template>
    <app-user-selector
      class="ion-page"
      title=""
      [users]="allUsers"
      (selectionChange)="onUserSelect($event)"
      (selectionCancel)="selectIndividualsModal.dismiss()"
      [focusSearchbar]="true"
      [hiddenUsers]="hiddenUsers"
    ></app-user-selector>
  </ng-template>
</ion-modal>

<ng-template #goBackBtn>
  <ion-buttons slot="start">
    <ion-button (click)="onBackButtonClick()" appHaptics>
      <ion-icon slot="icon-only" name="chevron-back-outline"></ion-icon>
    </ion-button>
  </ion-buttons>
</ng-template>
