import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { BackdoorInfoModalComponent } from './backdoor-info-modal.component';
import { BackdoorApiService } from '@app/core/service/api/backdoor.api.service';
import { DirectivesModule } from '../directives.module';

@NgModule({
  imports: [CommonModule, IonicModule, DirectivesModule],
  declarations: [BackdoorInfoModalComponent],
  providers: [BackdoorApiService]
})
export class BackdoorInfoModalModule {}
