import { Pipe, PipeTransform } from '@angular/core';
import { nextDailyPeriodStart } from '@app/utils/next-daily-period-start';
import { nextMonthlyPeriodStart } from '@app/utils/next-monthly-period-start';
import { nextTwoWeekPeriodStart } from '@app/utils/next-two-week-period-start';
import { nextWeeklyPeriodStart } from '@app/utils/next-weekly-period-start';
import { EDailySurveyFrequency } from '@backend/types/daily-survey-definition';

@Pipe({
  name: 'transformDailySurveyFrequency'
})
export class TransformDailySurveyFrequencyPipe implements PipeTransform {
  public transform(frequency: EDailySurveyFrequency): (value: Date) => Date {
    switch (frequency) {
      case EDailySurveyFrequency.EVERY_DAY:
        return nextDailyPeriodStart;
      case EDailySurveyFrequency.EVERY_WEEK:
        return nextWeeklyPeriodStart;
      case EDailySurveyFrequency.EVERY_TWO_WEEKS:
        return nextTwoWeekPeriodStart;
      case EDailySurveyFrequency.ONCE_PER_MONTH:
        return nextMonthlyPeriodStart;
    }
  }
}
