import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MongoStoredObject } from '../../../types/mongo-stored-object';
import { ObjectId } from '../../../types/object-id';
import { Observable } from 'rxjs';
import { TaskDto } from '@app/types/task-dto';
import { Duration } from 'date-fns';
import { UserModel } from '../../model/user.model';
import { ITaskFeedback } from '@backend/models/types/task-feedback';
import { IAttachment } from '@backend/models/types/attachment';
import { ITaskPerformance } from '@backend/models/types/task-performance';
import { TSpecRoute_getTasks } from '@backend/types/generated/Tasks/getTasks';
import {
  TSpecBodyCreateTask,
  TSpecRoute_createTask
} from '@backend/types/generated/Tasks/createTask';
import {
  TSpecBodyPatchTask,
  TSpecPathPatchTask,
  TSpecRoute_patchTask
} from '@backend/types/generated/Tasks/patchTask';
import {
  TSpecPathGetTaskById,
  TSpecRoute_getTaskById
} from '@backend/types/generated/Tasks/getTaskById';
import {
  TSpecPathDeleteTask,
  TSpecRoute_deleteTask
} from '@backend/types/generated/Tasks/deleteTask';
import {
  TSpecPathRestoreTask,
  TSpecRoute_restoreTask
} from '@backend/types/generated/Tasks/restoreTask';
import {
  TSpecBodySetTaskPerformance,
  TSpecPathSetTaskPerformance,
  TSpecRoute_setTaskPerformance
} from '@backend/types/generated/Tasks/setTaskPerformance';
import {
  TSpecPathGetLastTaskPerformance,
  TSpecRoute_getLastTaskPerformance
} from '@backend/types/generated/Tasks/getLastTaskPerformance';
import {
  TSpecPathGetTaskPerformances,
  TSpecQueryGetTaskPerformances,
  TSpecRoute_getTaskPerformances
} from '@backend/types/generated/Users/getTaskPerformances';
import {
  TSpecBodyReorderTasksMultiple,
  TSpecRoute_reorderTasksMultiple
} from '@backend/types/generated/Tasks/reorderTasksMultiple';
import {
  TSpecBodyRefoldTasksMultiple,
  TSpecRoute_refoldTasksMultiple
} from '@backend/types/generated/Tasks/refoldTasksMultiple';
import {
  TSpecBodyPatchTasks,
  TSpecRoute_patchTasks
} from '@backend/types/generated/Tasks/patchTasks';
import {
  TSpecPathGetTimeAttackStats,
  TSpecRoute_getTimeAttackStats
} from '@backend/types/generated/Tasks/getTimeAttackStats';
import {
  TSpecPathGetTaskFeedbackStats,
  TSpecRoute_getTaskFeedbackStats
} from '@backend/types/generated/Tasks/getTaskFeedbackStats';
import {
  TSpecBodyPostTaskFeedback,
  TSpecPathPostTaskFeedback,
  TSpecRoute_postTaskFeedback
} from '@backend/types/generated/Tasks/postTaskFeedback';

@Injectable({ providedIn: 'root' })
export class TasksApiService {
  public constructor(private readonly _httpClient: HttpClient) {}

  public getTasks() {
    return this._httpClient.get<MongoStoredObject<TaskDto>[]>(
      TSpecRoute_getTasks()
    );
  }

  public createTask(params: { body: TSpecBodyCreateTask }) {
    return this._httpClient.post<MongoStoredObject<TaskDto>>(
      TSpecRoute_createTask(),
      params.body
    );
  }

  public patchTask(params: {
    path: TSpecPathPatchTask;
    body: TSpecBodyPatchTask;
  }) {
    return this._httpClient.patch<MongoStoredObject<TaskDto>>(
      TSpecRoute_patchTask(params.path),
      params.body
    );
  }

  public getTaskById(params: { path: TSpecPathGetTaskById }) {
    return this._httpClient.get<MongoStoredObject<TaskDto>>(
      TSpecRoute_getTaskById(params.path)
    );
  }

  public deleteTask(params: { path: TSpecPathDeleteTask }) {
    return this._httpClient.delete<MongoStoredObject<TaskDto>>(
      TSpecRoute_deleteTask(params.path)
    );
  }

  public restoreTask(params: { path: TSpecPathRestoreTask }) {
    return this._httpClient.post<MongoStoredObject<TaskDto>>(
      TSpecRoute_restoreTask(params.path),
      {}
    );
  }

  public setTaskPerformance(params: {
    path: TSpecPathSetTaskPerformance;
    body: TSpecBodySetTaskPerformance;
  }) {
    return this._httpClient.put<unknown>(
      TSpecRoute_setTaskPerformance(params.path),
      params.body
    );
  }

  public getLastTaskPerformance(params: {
    path: TSpecPathGetLastTaskPerformance;
  }): Observable<{
    lastPerformance:
      | (MongoStoredObject<ITaskPerformance> & {
          user: Pick<UserModel, 'firstName' | 'lastName'>;
          note?: {
            attachments: MongoStoredObject<IAttachment>[];
          };
        })
      | null;
    lastPerformanceWithNote:
      | (MongoStoredObject<ITaskPerformance> & {
          user: Pick<UserModel, 'firstName' | 'lastName'>;
          note?: {
            attachments: MongoStoredObject<IAttachment>[];
          };
        })
      | null;
    tasksForAudit: {
      task: MongoStoredObject<TaskDto>;
      lastPerformance:
        | (MongoStoredObject<Omit<ITaskPerformance, 'user'>> & {
            user: Pick<UserModel, 'firstName' | 'lastName'> & { _id: ObjectId };
            note?: {
              attachments: MongoStoredObject<IAttachment>[];
            };
          })
        | null;
    }[];
  }> {
    return this._httpClient.get<{
      lastPerformance:
        | (MongoStoredObject<ITaskPerformance> & {
            user: Pick<UserModel, 'firstName' | 'lastName'>;
            note?: {
              attachments: MongoStoredObject<IAttachment>[];
            };
          })
        | null;
      lastPerformanceWithNote:
        | (MongoStoredObject<ITaskPerformance> & {
            user: Pick<UserModel, 'firstName' | 'lastName'>;
            note?: {
              attachments: MongoStoredObject<IAttachment>[];
            };
          })
        | null;
      tasksForAudit: {
        task: MongoStoredObject<TaskDto>;
        lastPerformance:
          | (MongoStoredObject<Omit<ITaskPerformance, 'user'>> & {
              user: Pick<UserModel, 'firstName' | 'lastName'> & {
                _id: ObjectId;
              };
              note?: {
                attachments: MongoStoredObject<IAttachment>[];
              };
            })
          | null;
      }[];
    }>(TSpecRoute_getLastTaskPerformance(params.path));
  }

  public getTaskPerformances(params: {
    path: TSpecPathGetTaskPerformances;
    query: TSpecQueryGetTaskPerformances;
  }): Observable<
    (MongoStoredObject<ITaskPerformance> & {
      user: Pick<UserModel, 'firstName' | 'lastName'>;
      note?: {
        attachments: MongoStoredObject<IAttachment>[];
      };
    })[]
  > {
    return this._httpClient.get<
      (MongoStoredObject<ITaskPerformance> & {
        user: Pick<UserModel, 'firstName' | 'lastName'>;
        note?: {
          attachments: MongoStoredObject<IAttachment>[];
        };
      })[]
    >(TSpecRoute_getTaskPerformances(params.path), {
      params: { ...params.query }
    });
  }

  public reorderTasksMultiple(params: {
    body: TSpecBodyReorderTasksMultiple;
  }): Observable<MongoStoredObject<TaskDto>[]> {
    return this._httpClient.post<MongoStoredObject<TaskDto>[]>(
      TSpecRoute_reorderTasksMultiple(),
      params.body
    );
  }

  public refoldTasksMultiple(params: {
    body: TSpecBodyRefoldTasksMultiple;
  }): Observable<MongoStoredObject<TaskDto>[]> {
    return this._httpClient.post<MongoStoredObject<TaskDto>[]>(
      TSpecRoute_refoldTasksMultiple(),
      params.body
    );
  }

  public patchTasks(params: {
    body: TSpecBodyPatchTasks;
  }): Observable<MongoStoredObject<TaskDto>[]> {
    return this._httpClient.patch<MongoStoredObject<TaskDto>[]>(
      TSpecRoute_patchTasks(),
      params.body
    );
  }

  public getTimeAttackStats(params: {
    path: TSpecPathGetTimeAttackStats;
  }): Observable<{
    averageDuration: Duration;
    currentRecord: {
      user: UserModel;
      duration: Duration;
    };
    rewardForBeatingTheAverage: number;
    rewardForAttempt: number;
  }> {
    return this._httpClient.get<{
      averageDuration: Duration;
      currentRecord: {
        user: UserModel;
        duration: Duration;
      };
      rewardForBeatingTheAverage: number;
      rewardForAttempt: number;
    }>(TSpecRoute_getTimeAttackStats(params.path));
  }

  public getTaskFeedbackStats(params: {
    path: TSpecPathGetTaskFeedbackStats;
  }): Observable<{
    daysSinceLastFeedback: number;
    daysSinceLastFeedbackNeeded: number;
    performancesSinceLastFeedback: number;
    performancesSinceLastFeedbackNeeded: number;
    isFeedbackNeeded: boolean;
    mandatory: boolean;
  }> {
    return this._httpClient.get<{
      daysSinceLastFeedback: number;
      daysSinceLastFeedbackNeeded: number;
      performancesSinceLastFeedback: number;
      performancesSinceLastFeedbackNeeded: number;
      isFeedbackNeeded: boolean;
      mandatory: boolean;
    }>(TSpecRoute_getTaskFeedbackStats(params.path));
  }

  public postTaskFeedback(params: {
    path: TSpecPathPostTaskFeedback;
    body: TSpecBodyPostTaskFeedback;
  }): Observable<MongoStoredObject<ITaskFeedback>> {
    return this._httpClient.post<MongoStoredObject<ITaskFeedback>>(
      TSpecRoute_postTaskFeedback(params.path),
      params.body
    );
  }
}
