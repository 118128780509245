import { Types } from 'mongoose';

export enum EAnnouncementAcknowledgeButtonText {
  ACKNOWLEDGE = 'ACKNOWLEDGE',
  UNDERSTAND_AND_AGREE = 'UNDERSTAND_AND_AGREE'
}

export enum EAnnouncementRespondButtonText {
  RESPOND = 'RESPOND',
  REQUEST_CLARIFICATION = 'REQUEST_CLARIFICATION',
  REPORT_ISSUE = 'REPORT_ISSUE'
}

export enum EAnnouncementActions {
  ONLY_ACKNOWLEDGE = 'ONLY_ACKNOWLEDGE',
  ONLY_RESPOND = 'ONLY_RESPOND',
  ACKNOWLEDGE_AND_RESPOND = 'ACKNOWLEDGE_AND_RESPOND'
}

export interface IRecipient {
  id: Types.ObjectId;
  read?: Date;
  acknowledged?: Date;
  recipientName?: string;
}

export interface IAnnouncement {
  title: string;
  body: string;
  recipients: IRecipient[];
  date: Date;
  author: Types.ObjectId;
  authorName: string;
  edited?: Date;
  tenant: Types.ObjectId;
  isCritical: boolean;
  unacknowledged?: boolean;
  attachments?: Types.ObjectId[];
  isSystemGenerated: boolean;
  actions: EAnnouncementActions;
  acknowledgeButtonText: EAnnouncementAcknowledgeButtonText;
  respondButtonText: EAnnouncementRespondButtonText;
  threadOf: Types.ObjectId | null;
  responses: Types.ObjectId[];
  libraryDoc: Types.ObjectId | null;
  taskAudit: Types.ObjectId | null;
}
