import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  HostListener,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter,
  HostBinding
} from '@angular/core';
import {
  OpportunityModel,
  SolutionModel
} from '@app/core/model/suggestion.model';
import { SuggestionApiService } from '@app/core/service/api/suggestion.api.service';
import { LoadingController } from '@ionic/angular';
import { formatUploadedBody } from '@app/utils/wysiwyg-editor';
import { EnvironmentService } from '@app/core/service/environment.service';
import { IonPopover } from '@ionic/angular';
import { SafeStyle, DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-solution-item',
  templateUrl: './solution-item.component.html',
  styleUrls: ['./solution-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SolutionItemComponent implements OnInit {
  @Input()
  public opportunity!: OpportunityModel;

  @Input()
  public solution!: SolutionModel;

  @Input()
  public showOptions = false;

  @Input()
  public animationOrder?: number = -1;

  @ViewChild('popover')
  public popover!: IonPopover;

  @HostListener('click', ['$event'])
  protected onClick(event: any): void {
    if (this.showOptions) {
      this.presentPopover({ ...event, target: this.nativeElement });
    }
  }

  @HostBinding('class.clickable')
  get isClickable() {
    return this.showOptions;
  }

  @HostBinding('style')
  get myStyle(): SafeStyle {
    return this._sanitizer.bypassSecurityTrustStyle(
      `--animation-order: ${this.animationOrder};`
    );
  }

  @HostBinding('class.animated')
  public get isAnimated() {
    return this.animationOrder !== -1;
  }

  @Output()
  public solutionDeleted = new EventEmitter<void>();

  protected body = '';
  protected isPopoverOpen = false;
  protected nativeElement: any;

  public constructor(
    private readonly _loadingCtrl: LoadingController,
    private readonly _suggestionApiService: SuggestionApiService,
    private readonly _environmentService: EnvironmentService,
    private readonly _elementRef: ElementRef,
    private readonly _sanitizer: DomSanitizer
  ) {
    this.nativeElement = this._elementRef.nativeElement;
  }

  public ngOnInit(): void {
    this.body = formatUploadedBody(
      this.solution.body,
      this.solution.attachments,
      this._environmentService.serverUrl
    );
  }

  protected presentPopover(e: Event) {
    this.popover.event = e;
    this.isPopoverOpen = true;
  }

  protected async onIsPublicChange(event: any) {
    if (event && event.detail) {
      this.solution.isPublic = event.detail.checked;

      const loading = await this._loadingCtrl.create({
        message: 'Loading...'
      });
      loading.present();

      this._suggestionApiService
        .updateSolution({
          path: {
            opportunityId: this.opportunity._id,
            solutionId: this.solution._id
          },
          body: { isPublic: event.detail.checked }
        })
        .subscribe(() => {
          loading.dismiss();
        });
    }
  }

  protected async onDeleteClick() {
    const loading = await this._loadingCtrl.create({
      message: 'Loading...'
    });
    loading.present();

    this._suggestionApiService
      .deleteSolution({
        path: {
          opportunityId: this.opportunity._id,
          solutionId: this.solution._id
        }
      })
      .subscribe(() => {
        loading.dismiss();
        this.popover.dismiss();
        this.solutionDeleted.emit();
      });
  }
}
