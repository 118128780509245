<ion-header class="ion-no-border">
  <ion-toolbar class="app-toolbar">
    <ion-buttons slot="start">
      <ion-button (click)="onBackButtonClick()" appHaptics>
        <ion-icon slot="icon-only" name="chevron-back-outline"></ion-icon>
        <ion-label>Back</ion-label>
      </ion-button>
    </ion-buttons>
    <ion-title class="ion-text-capitalize"> Tags </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content color="light">
  <app-width-restricted-container class="container-with-fab">
    <ion-list [inset]="true">
      <ion-item>
        <ion-input
          label="Date Field Name"
          type="text"
          [ngModel]="tagsSettings.pickupDateFieldName"
          [ngModelOptions]="{ standalone: true }"
          [debounce]="700"
          (ionInput)="onPickupDateFieldNameInput($any($event))"
          labelPlacement="stacked"
          required
          autocapitalize="on"
          [spellcheck]="true"
        />
      </ion-item>
      <ion-item>
        <ion-toggle
          appHaptics
          [(ngModel)]="tagsSettings.customerNameEnabled"
          (ionChange)="onCustomerNameEnabledChange($event)"
        >
          <ion-label> Customer Name Field </ion-label>
        </ion-toggle>
      </ion-item>
    </ion-list>
  </app-width-restricted-container>
</ion-content>

<ion-loading #loading message="Loading..."></ion-loading>
