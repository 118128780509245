import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  TSpecQueryGetSuggestionCategories,
  TSpecRoute_getSuggestionCategories
} from '@backend/types/generated/SuggestionCategories/getSuggestionCategories';
import {
  TSpecBodyAddSuggestionCategory,
  TSpecRoute_addSuggestionCategory
} from '@backend/types/generated/SuggestionCategories/addSuggestionCategory';
import {
  TSpecBodyUpdateSuggestionCategory,
  TSpecPathUpdateSuggestionCategory,
  TSpecRoute_updateSuggestionCategory
} from '@backend/types/generated/SuggestionCategories/updateSuggestionCategory';
import {
  TSpecPathDeleteSuggestionCategory,
  TSpecRoute_deleteSuggestionCategory
} from '@backend/types/generated/SuggestionCategories/deleteSuggestionCategory';
import { SuggestionCategoryModel } from '@app/core/model/suggestion-category.model';

@Injectable()
export class SuggestionCategoryApiService {
  public constructor(private readonly _httpClient: HttpClient) {}

  public getSuggestionCategories(params: {
    query: TSpecQueryGetSuggestionCategories;
  }): Observable<SuggestionCategoryModel[]> {
    return this._httpClient.get<SuggestionCategoryModel[]>(
      TSpecRoute_getSuggestionCategories(),
      { params: { ...params.query } }
    );
  }

  public addSuggestionCategory(params: {
    body: TSpecBodyAddSuggestionCategory;
  }): Observable<SuggestionCategoryModel> {
    return this._httpClient.post<SuggestionCategoryModel>(
      TSpecRoute_addSuggestionCategory(),
      params.body
    );
  }

  public updateSuggestionCategory(params: {
    path: TSpecPathUpdateSuggestionCategory;
    body: TSpecBodyUpdateSuggestionCategory;
  }): Observable<SuggestionCategoryModel> {
    return this._httpClient.put<SuggestionCategoryModel>(
      TSpecRoute_updateSuggestionCategory(params.path),
      params.body
    );
  }

  public deleteSuggestionCategory(params: {
    path: TSpecPathDeleteSuggestionCategory;
  }): Observable<null> {
    return this._httpClient.delete<null>(
      TSpecRoute_deleteSuggestionCategory(params.path)
    );
  }
}
