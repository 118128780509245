import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component
} from '@angular/core';
import { BehaviorApiService } from '@app/core/service/api/behavior.api.service';
import { BehaviorModel } from '@app/core/model/behavior.model';
import { TenantService } from '@app/core/service/tenant.service';
import { take } from 'rxjs';

@Component({
  selector: 'app-values-accordion',
  templateUrl: './values-accordion.component.html',
  styleUrls: ['./values-accordion.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ValuesAccordionComponent {
  protected valueBehaviors: BehaviorModel[] = [];
  protected isLoading = true;

  public constructor(
    private readonly _behaviorApiService: BehaviorApiService,
    private readonly _cdr: ChangeDetectorRef,
    private readonly _tenantService: TenantService
  ) {
    this._tenantService.tenant$.pipe(take(1)).subscribe((tenant) => {
      this._behaviorApiService
        .getValueBehaviors({ path: { tenantId: tenant._id } })
        .subscribe((valueBehaviors) => {
          this.valueBehaviors = valueBehaviors;
          this.isLoading = false;
          this._cdr.markForCheck();
        });
    });
  }
}
