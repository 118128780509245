import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MongoStoredObject } from '@app/types/mongo-stored-object';
import { TSpecRoute_getDepartments } from '@backend/types/generated/Departments/getDepartments';
import { IDepartment } from '@backend/models/types/department';
import {
  TSpecBodyAddDepartment,
  TSpecRoute_addDepartment
} from '@backend/types/generated/Departments/addDepartment';
import {
  TSpecBodyUpdateDepartment,
  TSpecPathUpdateDepartment,
  TSpecRoute_updateDepartment
} from '@backend/types/generated/Departments/updateDepartment';
import {
  TSpecPathDeleteDepartment,
  TSpecRoute_deleteDepartment
} from '@backend/types/generated/Departments/deleteDepartment';

@Injectable()
export class DepartmentApiService {
  public constructor(private readonly _httpClient: HttpClient) {}

  public getDepartments(): Observable<MongoStoredObject<IDepartment>[]> {
    return this._httpClient.get<MongoStoredObject<IDepartment>[]>(
      TSpecRoute_getDepartments()
    );
  }

  public addDepartment(params: {
    body: TSpecBodyAddDepartment;
  }): Observable<MongoStoredObject<IDepartment>> {
    return this._httpClient.post<MongoStoredObject<IDepartment>>(
      TSpecRoute_addDepartment(),
      params.body
    );
  }

  public updateDepartment(params: {
    path: TSpecPathUpdateDepartment;
    body: TSpecBodyUpdateDepartment;
  }): Observable<MongoStoredObject<IDepartment>> {
    return this._httpClient.put<MongoStoredObject<IDepartment>>(
      TSpecRoute_updateDepartment(params.path),
      params.body
    );
  }

  public deleteDepartment(params: {
    path: TSpecPathDeleteDepartment;
  }): Observable<null> {
    return this._httpClient.delete<null>(
      TSpecRoute_deleteDepartment(params.path)
    );
  }
}
