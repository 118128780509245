<ion-header
  collapse="condense"
  class="content-title content-title--{{ titleSize }}"
>
  <ion-toolbar class="app-toolbar">
    <ion-title #title class="ion-text-capitalize" size="large">
      <ng-content />
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-header> </ion-header>

<div *ngIf="subtitle" class="content-subtitle">
  <ion-text>{{ subtitle }}</ion-text>
</div>
