// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_saveAsNewVersion = (path: TSpecPathSaveAsNewVersion) => {
  let res = "/library/docs/:libraryDocId/save-as-new-version";
  Object.entries(path).map(([key, value]) => {
    res = res.replace(':' + key, value);
  });
  return res;
};

export type TSpecHandler_saveAsNewVersion = RequestHandler<TSpecPathSaveAsNewVersion, any, TSpecBodySaveAsNewVersion, TSpecQuerySaveAsNewVersion, {}>;

export interface TSpecPathSaveAsNewVersion { libraryDocId: string; }

export interface TSpecBodySaveAsNewVersion {
  originalDoc: string;
  version: number;
  tenant: string;
  userCreated: string | null;
  dateCreated: string;
  isDeleted: boolean;
  signed: {
    user: string;
    date: string;
  }[];
}

export interface TSpecQuerySaveAsNewVersion {}
