// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_copyTasksFrom = (path: TSpecPathCopyTasksFrom) => {
  let res = "/tenants/:id/tasks/copy-from";
  Object.entries(path).map(([key, value]) => {
    res = res.replace(':' + key, value);
  });
  return res;
};

export type TSpecHandler_copyTasksFrom = RequestHandler<TSpecPathCopyTasksFrom, any, TSpecBodyCopyTasksFrom, TSpecQueryCopyTasksFrom, {}>;

export interface TSpecPathCopyTasksFrom { id: string; }

export interface TSpecBodyCopyTasksFrom {
  skipSameNameTasks: boolean;
  tasks: string[];
}

export interface TSpecQueryCopyTasksFrom {}
