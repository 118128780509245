// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_removeMyConnectedAccount = () => "/connected-accounts";

export type TSpecHandler_removeMyConnectedAccount = RequestHandler<TSpecPathRemoveMyConnectedAccount, any, TSpecBodyRemoveMyConnectedAccount, TSpecQueryRemoveMyConnectedAccount, {}>;

export interface TSpecPathRemoveMyConnectedAccount {  }

export interface TSpecBodyRemoveMyConnectedAccount {}

export interface TSpecQueryRemoveMyConnectedAccount {
  userId: string;
}
