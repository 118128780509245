// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_deleteTaskFolder = (path: TSpecPathDeleteTaskFolder) => {
  let res = "/task-folders/:taskFolderId";
  Object.entries(path).map(([key, value]) => {
    res = res.replace(':' + key, value);
  });
  return res;
};

export type TSpecHandler_deleteTaskFolder = RequestHandler<TSpecPathDeleteTaskFolder, any, TSpecBodyDeleteTaskFolder, TSpecQueryDeleteTaskFolder, {}>;

export interface TSpecPathDeleteTaskFolder { taskFolderId: string; }

export interface TSpecBodyDeleteTaskFolder {}

export interface TSpecQueryDeleteTaskFolder {}
