import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'wysiwygPreview',
  standalone: true
})
export class WysiwygPreviewPipe implements PipeTransform {
  /**
   * Takes a value and makes it lowercase.
   */
  transform(value: string) {
    if (value) {
      return value
        .replace('&#160;', '') // replace escaped spaces
        .trim()
        .replace(/\$\[ATTACHMENT_ID=[^\]]+\]/g, '') // remove attachments
        .replace(/<\/?[^>]+>/gi, ' '); // remove html tags
    }
    return '';
  }
}
