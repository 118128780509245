<ion-header class="ion-no-border">
  <ion-toolbar class="app-common-background" *ngIf="cancelButton === 'menu'">
    <ion-buttons
      slot="start"
      class="ion-hide-lg-up"
      *ngIf="question.orderIndex === 0; else goBackBtn"
    >
      <app-slim-menu-button></app-slim-menu-button>
    </ion-buttons>
  </ion-toolbar>

  <ion-toolbar
    class="app-common-background survey-preview-toolbar"
    *ngIf="cancelButton === 'cancel'"
  >
    <ion-buttons slot="start" *ngIf="question.orderIndex === 0; else goBackBtn">
      <ion-button (click)="onCancelClick()" color="primary" appHaptics>
        Cancel
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<app-width-restricted-container class="question-holder">
  <div class="top-content ion-padding-top">
    <ion-text>
      <h2 class="ion-text-center heading" [innerHTML]="questionTitle"></h2>
      <h5
        class="ion-text-center subheading"
        [innerHTML]="question.params.questionSubtitle"
      ></h5>
    </ion-text>
    <ion-grid class="ion-padding">
      <ion-row
        *ngIf="isLoading; else options"
        class="ion-justify-content-center"
      >
        <ion-spinner name="lines-sharp-small"></ion-spinner>
      </ion-row>
    </ion-grid>
  </div>
  <div class="bottom-content">
    <ion-grid>
      <ion-row *ngIf="usersWhoAreWorkingToday.length > 0">
        <ion-col size="12" class="current-user">
          {{ currentUser + 1 }} / {{ usersWhoAreWorkingToday.length }}
        </ion-col>
      </ion-row>
      <ion-row class="ion-justify-content-center ion-padding">
        <ion-col size="6" *ngIf="currentUser > 0">
          <ion-button
            fill="outline"
            color="dark"
            (click)="prevUser()"
            appHaptics
            expand="block"
            [disabled]="isLoading"
            [disableHaptics]="isLoading"
          >
            <ion-icon slot="start" name="chevron-back-outline"></ion-icon>
            {{ question.params.prevUserButtonText }}
          </ion-button>
        </ion-col>
        <ion-col size="6">
          <ion-button
            color="dark"
            (click)="
              currentUser >= usersWhoAreWorkingToday.length - 1
                ? completeQuestion()
                : nextUser()
            "
            appHaptics
            expand="block"
            [disabled]="isLoading"
            [disableHaptics]="isLoading"
          >
            {{ buttonText }}
            <ion-icon slot="end" name="chevron-forward-outline"></ion-icon>
          </ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
</app-width-restricted-container>

<ng-template #goBackBtn>
  <ion-buttons slot="start">
    <ion-button (click)="onBackButtonClick()" appHaptics>
      <ion-icon slot="icon-only" name="chevron-back-outline"></ion-icon>
    </ion-button>
  </ion-buttons>
</ng-template>

<ng-template #options>
  <ng-container *ngIf="usersWhoAreWorkingToday.length > 0; else emptyList">
    <ion-row
      class="ion-justify-content-center"
      *ngFor="let option of question.params.options"
    >
      <ion-col size-xs="12" size-sm="6">
        <app-emoji-button
          [label]="option.text"
          [emoji]="option.emoji"
          appHaptics
          (click)="toggleEvaluation(option.text)"
          [selected]="optionIsSelected(option.text)"
        />
      </ion-col>
    </ion-row>
  </ng-container>
</ng-template>

<ng-template #emptyList>
  <app-empty-stub
    icon="calendar-outline"
    text="It seems that no one has worked with you recently"
  ></app-empty-stub>
</ng-template>
