// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_getPublicOpportunities = () => "/suggestions/opportunities/public";

export type TSpecHandler_getPublicOpportunities = RequestHandler<TSpecPathGetPublicOpportunities, any, TSpecBodyGetPublicOpportunities, TSpecQueryGetPublicOpportunities, {}>;

export interface TSpecPathGetPublicOpportunities {  }

export interface TSpecBodyGetPublicOpportunities {}

export interface TSpecQueryGetPublicOpportunities {
  page?: string;
  limit?: string;
  searchTerm?: string;
}
