import { Component, Input, OnInit } from '@angular/core';
import { MongoStoredObject } from '@app/types/mongo-stored-object';
import { ModalController } from '@ionic/angular';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { IDepartment } from '@backend/models/types/department';
import { ModalClass } from '@app/core/class/modal.class';
import fastDeepEqual from 'fast-deep-equal';

@Component({
  selector: 'app-department-modal',
  templateUrl: './department-modal.component.html'
})
export class DepartmentModalComponent extends ModalClass implements OnInit {
  @Input()
  public readonly department: MongoStoredObject<IDepartment> = null;

  protected form = new FormGroup({
    name: new FormControl<string>('', Validators.required)
  });

  public constructor(private readonly _modalCtrl: ModalController) {
    super();
  }

  public ngOnInit(): void {
    if (this.department) {
      this.form.reset({
        name: this.department.name
      });
    }
  }

  protected get canSave() {
    if (this.form.invalid) return false;

    if (this.department) {
      return !fastDeepEqual(
        { name: this.department.name },
        { name: this.form.get('name').value }
      );
    }

    return true;
  }

  protected onCloseClick(): void {
    this._modalCtrl.dismiss(null, 'cancel', this._modalName);
  }

  protected save(): void {
    this._modalCtrl.dismiss(this.form.value, 'confirm', this._modalName);
  }

  protected delete(): void {
    this._modalCtrl.dismiss(this.form.value, 'delete', this._modalName);
  }
}
