// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_getLedger = (path: TSpecPathGetLedger) => {
  let res = "/tenant/:tenantId/ledger";
  Object.entries(path).map(([key, value]) => {
    res = res.replace(':' + key, value);
  });
  return res;
};

export type TSpecHandler_getLedger = RequestHandler<TSpecPathGetLedger, any, TSpecBodyGetLedger, TSpecQueryGetLedger, {}>;

export interface TSpecPathGetLedger { tenantId: string; }

export interface TSpecBodyGetLedger {}

export interface TSpecQueryGetLedger {
  page?: string;
  limit?: string;
  searchTerm?: string;
}
