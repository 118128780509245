// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_getTimezones = () => "/timezones";

export type TSpecHandler_getTimezones = RequestHandler<TSpecPathGetTimezones, any, TSpecBodyGetTimezones, TSpecQueryGetTimezones, {}>;

export interface TSpecPathGetTimezones {  }

export interface TSpecBodyGetTimezones {}

export interface TSpecQueryGetTimezones {}
