// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_createTenant = () => "/tenants";

export type TSpecHandler_createTenant = RequestHandler<TSpecPathCreateTenant, any, TSpecBodyCreateTenant, TSpecQueryCreateTenant, {}>;

export interface TSpecPathCreateTenant {  }

export interface TSpecBodyCreateTenant {
  tenantName: string;
  adminEmail: string;
  timezone: string;
}

export interface TSpecQueryCreateTenant {}
