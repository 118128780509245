import { Component, OnInit, ViewChild } from '@angular/core';
import { BehaviorApiService } from '@app/core/service/api/behavior.api.service';
import { DataManager } from '@syncfusion/ej2-data';
import {
  EditSettingsModel,
  ToolbarItems,
  IEditCell,
  ToolbarService,
  EditService,
  PageService,
  RowDDService,
  SelectionService,
  GridComponent,
  RowDragEventArgs
} from '@syncfusion/ej2-angular-grids';
import { TenantService } from '@app/core/service/tenant.service';
import { IonNav, LoadingController, ModalController } from '@ionic/angular';
import { ITenant } from '@backend/models/types/tenant';
import { BehaviorModel } from '@app/core/model/behavior.model';
import { ToastService } from '@app/core/service/toast.service';
import { TenantApiService } from '@app/core/service/api/tenant.api.service';
import { MongoStoredObject } from '@app/types/mongo-stored-object';
import { TaskDto } from '@app/types/task-dto';
import { combineLatest } from 'rxjs';
import {
  EditSystemEntityModalComponent,
  ISystemEntity
} from '@app/modals/edit-system-entity-modal/edit-system-entity-modal.component';
import { TaskListService } from '@app/core/service/task-list.service';
import { UserRoles } from '@backend/models/types/user';
import { showModal } from '@app/utils/modal';

@Component({
  selector: 'app-behaviors-edit',
  templateUrl: './behaviors-edit.component.html',
  styleUrls: ['./behaviors-edit.component.scss'],
  providers: [
    ToolbarService,
    EditService,
    PageService,
    RowDDService,
    SelectionService
  ]
})
export class BehaviorsEditComponent implements OnInit {
  @ViewChild('normalgrid', { static: true })
  public behaviourTable: GridComponent;

  protected currentTab = 'behaviors';
  protected editSettings: EditSettingsModel = {
    showDeleteConfirmDialog: true,
    allowEditing: true,
    allowAdding: true,
    allowDeleting: true,
    mode: 'Normal'
  };
  protected toolbar: ToolbarItems[] = [
    'Add',
    'Edit',
    'Delete',
    'Update',
    'Cancel'
  ];
  protected numericParams: IEditCell = { params: { decimals: 0 } };
  protected requiredValidation: object = { required: true };
  protected isLoading = false;
  protected behaviorSource: DataManager;
  protected tenant: MongoStoredObject<ITenant>;
  protected eligibleToChange: UserRoles[] = [];
  protected sysGen: {
    behaviors: (BehaviorModel & {
      module?: { chipText: string; chipColor: string };
    })[];
    tasks: (MongoStoredObject<TaskDto> & {
      module?: { chipText: string; chipColor: string };
    })[];
  } = {
    behaviors: [],
    tasks: []
  };

  public constructor(
    private readonly _behaviorApiService: BehaviorApiService,
    private readonly _taskListService: TaskListService,
    private readonly _tenantService: TenantService,
    private readonly _tenantApiService: TenantApiService,
    private readonly _ionNav: IonNav,
    private readonly _toastService: ToastService,
    private readonly _modalCtrl: ModalController,
    private readonly _loadingCtrl: LoadingController
  ) {}

  public ngOnInit() {
    this._tenantService.tenant$.subscribe((tenant) => {
      this.tenant = tenant;
      this._getBehaviors();
    });
    this.behaviorSource = new DataManager([]);
  }

  protected get rowInEdit(): number {
    return this.behaviourTable.isEdit
      ? (this.behaviourTable.editModule as any).editModule.editRowIndex
      : -1;
  }

  protected actionBegin(args: any): void {
    if (args.requestType === 'beginEdit') {
      if (args.rowData.isSystemGenerated) {
        (this.behaviourTable.columns[0] as any).allowEditing = false;
      } else {
        (this.behaviourTable.columns[0] as any).allowEditing = true;
      }
    }

    if (args.requestType === 'save') {
      if (args.action === 'add') {
        const data = {
          name: args.data.name,
          points: args.data.points,
          isValue: args.data.isValue,
          description: args.data.description,
          eligible: this.eligibleToChange,
          tenantId: this.tenant._id
        };

        this._behaviorApiService
          .createBehavior({ body: data })
          .subscribe(() => {
            this._getBehaviors();
          });
      } else {
        const id = args.data._id;

        const data = {
          name: args.data.name,
          points: args.data.points,
          isValue: args.data.isValue,
          description: args.data.description,
          eligible: this.eligibleToChange
        };

        this._behaviorApiService
          .updateBehavior({
            path: { id, tenantId: this.tenant._id },
            body: data
          })
          .subscribe(() => {
            this._getBehaviors();
          });
      }
    } else if (args.requestType === 'delete') {
      const id = args.data[0]._id;

      this._behaviorApiService.deleteBehavior(id).subscribe({
        next: () => {
          this._getBehaviors();
        },
        error: () => {
          this._toastService.presentToast('This behavior cannot be deleted');
          this._getBehaviors();
        }
      });
    }
  }

  protected rowDrop(args: RowDragEventArgs) {
    if (args.dropIndex !== args.fromIndex) {
      this._behaviorApiService
        .moveBehavior({
          path: {
            from: (args.fromIndex || 0).toString(),
            to: (args.dropIndex || 0).toString(),
            tenantId: this.tenant._id
          }
        })
        .subscribe(() => {
          this._getBehaviors();
        });
    }
  }

  private _getBehaviors() {
    const findBehaviorModule = (
      item: BehaviorModel
    ): BehaviorModel & {
      module?: { chipText: string; chipColor: string };
    } => {
      const behaviorId = item._id.toString();
      switch (behaviorId) {
        case this.tenant.behaviors.checklist?.toString():
          return {
            ...item,
            module: { chipColor: 'success', chipText: 'Checklist' }
          };
        case this.tenant.behaviors.positiveFeedback?.toString():
          return {
            ...item,
            module: { chipColor: 'warning', chipText: 'Pulse' }
          };
        case this.tenant.behaviors.reinforce?.toString():
          return {
            ...item,
            module: { chipColor: 'danger', chipText: 'Recognition' }
          };
      }
      return item;
    };

    const findTaskModule = (
      item: MongoStoredObject<TaskDto>
    ): MongoStoredObject<TaskDto> & {
      module?: { chipText: string; chipColor: string };
    } => {
      const taskId = item._id.toString();
      switch (taskId) {
        case this.tenant.tasks.uploadTag?.toString():
        case this.tenant.tasks.servingTable?.toString():
        case this.tenant.tasks.readyForPickup?.toString():
        case this.tenant.tasks.completeTag?.toString():
        case this.tenant.tasks.reminder1st?.toString():
        case this.tenant.tasks.reminder2nd?.toString():
          return {
            ...item,
            module: { chipColor: 'tertiary', chipText: 'Tags' }
          };
        case this.tenant.tasks.dailySurvey?.toString():
          return {
            ...item,
            module: { chipColor: 'warning', chipText: 'Pulse' }
          };
        case this.tenant.tasks.suggestionSubmit?.toString():
          return {
            ...item,
            module: { chipColor: 'success', chipText: 'Suggestions' }
          };
      }
      return item;
    };

    this.isLoading = true;

    combineLatest([
      this._tenantApiService.getSysGenBehaviorsAndTasks(),
      this._behaviorApiService.getNonSysGenBehaviors({
        path: { tenantId: this.tenant._id }
      })
    ]).subscribe(([sysGen, recognitionBehaviors]) => {
      if (sysGen) {
        this.sysGen = {
          behaviors: sysGen.behaviors
            .map(findBehaviorModule)
            .filter(
              (m) =>
                (m.module?.chipText === 'Checklist' &&
                  this.tenant.features.checklist) ||
                (m.module?.chipText === 'Pulse' &&
                  this.tenant.features.survey) ||
                m.module?.chipText === 'Recognition'
            ),
          tasks: sysGen.tasks
            .map(findTaskModule)
            .filter(
              (m) =>
                (m.module?.chipText === 'Tags' && this.tenant.features.tags) ||
                (m.module?.chipText === 'Pulse' &&
                  this.tenant.features.survey) ||
                (m.module?.chipText === 'Suggestions' &&
                  this.tenant.features.suggestions)
            )
        };
      }

      if (recognitionBehaviors) {
        this.behaviorSource = new DataManager(
          recognitionBehaviors.map((item, rowIndex) => {
            return {
              ...item,
              whoEligible: item.eligible.length
                ? item.eligible.length > 1
                  ? 'Only Manager and Admin'
                  : 'Only Admin'
                : 'Everyone',
              rowIndex
            };
          })
        );
      }

      this.isLoading = false;
    });
  }

  protected onBackButtonClick() {
    this._ionNav.pop();
  }

  protected onEligibleChange(event: string) {
    switch (event) {
      case 'Everyone':
        this.eligibleToChange = [];
        break;
      case 'Only Manager and Admin':
        this.eligibleToChange = [UserRoles.Manager, UserRoles.Admin];
        break;
      case 'Only Admin':
        this.eligibleToChange = [UserRoles.Admin];
        break;
    }
  }

  protected onCurrentTabChange(event: any): void {
    this.currentTab = event.detail.value;
  }

  protected async onSystemBehaviorClick(behavior: BehaviorModel) {
    const { role, data } = await showModal<ISystemEntity>(
      {
        component: EditSystemEntityModalComponent,
        props: {
          entity: {
            name: behavior.name,
            points: behavior.points
          }
        }
      },
      this._modalCtrl
    );

    if (role === 'confirm') {
      const loading = await this._loadingCtrl.create({
        message: 'Saving...'
      });
      loading.present();

      const entity: ISystemEntity = data;

      this._behaviorApiService
        .updateBehavior({
          path: { id: behavior._id, tenantId: this.tenant._id },
          body: {
            ...behavior,
            name: entity.name,
            points: entity.points
          }
        })
        .subscribe(() => {
          loading.dismiss();
          this._getBehaviors();
        });
    }
  }

  protected async onSystemTaskClick(task: MongoStoredObject<TaskDto>) {
    const { role, data } = await showModal<ISystemEntity>(
      {
        component: EditSystemEntityModalComponent,
        props: {
          entity: {
            name: task.title,
            points: task.points
          }
        }
      },
      this._modalCtrl
    );

    if (role === 'confirm') {
      const loading = await this._loadingCtrl.create({
        message: 'Saving...'
      });
      loading.present();

      const entity: ISystemEntity = data;

      this._taskListService
        .patchTask(task._id, {
          ...task,
          details: {
            ...task.details,
            attachments: task.details.attachments.map((a) => a._id.toString())
          },
          title: entity.name,
          points: entity.points
        })
        .subscribe(() => {
          loading.dismiss();
          this._getBehaviors();
        });
    }
  }
}
