// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_restoreTask = (path: TSpecPathRestoreTask) => {
  let res = "/tasks/:id/restore";
  Object.entries(path).map(([key, value]) => {
    res = res.replace(':' + key, value);
  });
  return res;
};

export type TSpecHandler_restoreTask = RequestHandler<TSpecPathRestoreTask, any, TSpecBodyRestoreTask, TSpecQueryRestoreTask, {}>;

export interface TSpecPathRestoreTask { id: string; }

export interface TSpecBodyRestoreTask {}

export interface TSpecQueryRestoreTask {}
