// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_deleteTask = (path: TSpecPathDeleteTask) => {
  let res = "/tasks/:id";
  Object.entries(path).map(([key, value]) => {
    res = res.replace(':' + key, value);
  });
  return res;
};

export type TSpecHandler_deleteTask = RequestHandler<TSpecPathDeleteTask, any, TSpecBodyDeleteTask, TSpecQueryDeleteTask, {}>;

export interface TSpecPathDeleteTask { id: string; }

export interface TSpecBodyDeleteTask {}

export interface TSpecQueryDeleteTask {}
