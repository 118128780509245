// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_submitCompensation = () => "/compensation";

export type TSpecHandler_submitCompensation = RequestHandler<TSpecPathSubmitCompensation, any, TSpecBodySubmitCompensation, TSpecQuerySubmitCompensation, {}>;

export interface TSpecPathSubmitCompensation {  }

export interface TSpecBodySubmitCompensation {
  includePointsFrom: EIncludePointsFrom[];
  range: {
    start: string;
    end: string;
  };
  fields: string[];
  pools: {
    pool: string;
    value: number;
  }[];
  usersData: {
    firstName: string;
    lastName: string;
    compensationEmployeeId: string;
    totalPointsEarned: number;
    totalHoursWorked: number;
    notify: boolean;
    user: string;
    pools: {
      pool: string;
      value: number;
      isEligible: boolean;
    }[];
  }[];
}

export enum EIncludePointsFrom {
  tasksCompleted = "tasksCompleted",
  pointsSent = "pointsSent",
  pointsReceived = "pointsReceived"
}

export interface TSpecQuerySubmitCompensation {}
