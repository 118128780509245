export interface IFeedbackStatsItem {
  behaviorId: string;
  name: string;
  orderIndex: number;
  positiveCount: number;
  negativeCount: number;
}

export interface IFeedbackStats {
  feedbackByBehaviors: IFeedbackStatsItem[];
  moodCheck: {
    avgValue: number;
  };
  feedbackByEvaluationOptions: IFeedbackStatsItem[];
}

export interface IFeedbackStatsResult {
  personal: IFeedbackStats;
  team: IFeedbackStats;
}

export enum EPsychologicalSurveyShowFeedbackBy {
  feedbackByBehaviors = 'feedbackByBehaviors',
  feedbackByEvaluationOptions = 'feedbackByEvaluationOptions'
}
