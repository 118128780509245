import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AuthenticationService } from '../../authentication';
import { isSameDay, subMinutes, isWithinInterval } from 'date-fns';
import { TodaysShiftModalComponent } from '@app/modules/todays-shift-modal/todays-shift-modal.component';
import { EUserScheduleType } from '@backend/models/types/user';
import { Observable, take } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { MongoStoredObject } from '@app/types/mongo-stored-object';
import { IUserShiftTime } from '@backend/models/types/user-shift-time';
import {
  TSpecQueryGetUserLatestShiftTime,
  TSpecRoute_getUserLatestShiftTime
} from '@backend/types/generated/Users/getUserLatestShiftTime';
import {
  TSpecBodySetShiftTime,
  TSpecRoute_setShiftTime
} from '@backend/types/generated/Users/setShiftTime';
import { Router } from '@angular/router';
import { PsychologicalSurveyApiService } from './psychologicalSurvey.api.service';

@Injectable()
export class TodaysShiftApiService {
  public constructor(
    private readonly _modalCtrl: ModalController,
    private readonly _authenticationService: AuthenticationService,
    private readonly _httpClient: HttpClient,
    private readonly _router: Router,
    private readonly _psychologicalSurveyApiService: PsychologicalSurveyApiService
  ) {}

  public async askTodaysShift(dailySurveyPNTime = 0) {
    const user = this._authenticationService.user;

    if (user && user.schedule) {
      this.getUserLatestShiftTime({ query: {} })
        .pipe(take(1))
        .subscribe(async (lastShift) => {
          if (
            user.schedule.scheduleType === EUserScheduleType.VARIABLE &&
            (!lastShift || !isSameDay(new Date(lastShift.date), new Date()))
          ) {
            const todaysShiftModal =
              document.getElementById('todays-shift-modal');
            if (!todaysShiftModal) {
              const modal = await this._modalCtrl.create({
                id: 'todays-shift-modal',
                component: TodaysShiftModalComponent,
                cssClass: 'modal-auto-height todays-shift-modal',
                breakpoints: [0, 1],
                initialBreakpoint: 1,
                handle: false,
                backdropDismiss: false,
                canDismiss: async (_, role) => role === 'confirm'
              });

              modal.present();
              await modal.onWillDismiss();
            }
          }

          if (dailySurveyPNTime) {
            let todaysShiftEnd: string | null = null;

            if (user.schedule.scheduleType === EUserScheduleType.FIXED) {
              const weekDayIndex = new Date().getDay();

              if (user.schedule.weekDays[weekDayIndex]) {
                todaysShiftEnd = user.schedule.weekTime[weekDayIndex].to;
              }
            } else if (
              user.schedule.scheduleType === EUserScheduleType.VARIABLE
            ) {
              if (
                lastShift &&
                isSameDay(new Date(lastShift.date), new Date()) &&
                lastShift.end
              ) {
                todaysShiftEnd = lastShift.end;
              }
            }

            if (todaysShiftEnd) {
              const splitted = todaysShiftEnd.split(':');

              const dateTo = new Date();
              const toHour = Number(splitted[0]);
              const toMinute = Number(splitted[1]);
              dateTo.setHours(toHour);
              dateTo.setMinutes(toMinute);

              const dateFrom = subMinutes(dateTo, dailySurveyPNTime);

              const showSurvey = isWithinInterval(new Date(), {
                start: dateFrom,
                end: dateTo
              });

              if (showSurvey) {
                this._psychologicalSurveyApiService
                  .getUnansweredPsychologicalSurveys({
                    query: { skipSurveyMode: true }
                  })
                  .pipe(take(1))
                  .subscribe(({ items }) => {
                    if (items.length) {
                      this._router.navigate(['/i/psychological-survey']);
                    }
                  });
              }
            }
          }
        });
    }
  }

  public getUserLatestShiftTime(params: {
    query: TSpecQueryGetUserLatestShiftTime;
  }): Observable<MongoStoredObject<IUserShiftTime> | null> {
    return this._httpClient.get<MongoStoredObject<IUserShiftTime> | null>(
      TSpecRoute_getUserLatestShiftTime(),
      { params: { ...params.query } }
    );
  }

  public setShiftTime(params: {
    body: TSpecBodySetShiftTime;
  }): Observable<MongoStoredObject<IUserShiftTime>> {
    return this._httpClient.post<MongoStoredObject<IUserShiftTime>>(
      TSpecRoute_setShiftTime(),
      params.body
    );
  }
}
