// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_getAllSolutionsOfOpportunity = (path: TSpecPathGetAllSolutionsOfOpportunity) => {
  let res = "/suggestions/opportunities/:opportunityId/solutions";
  Object.entries(path).map(([key, value]) => {
    res = res.replace(':' + key, value);
  });
  return res;
};

export type TSpecHandler_getAllSolutionsOfOpportunity = RequestHandler<TSpecPathGetAllSolutionsOfOpportunity, any, TSpecBodyGetAllSolutionsOfOpportunity, TSpecQueryGetAllSolutionsOfOpportunity, {}>;

export interface TSpecPathGetAllSolutionsOfOpportunity { opportunityId: string; }

export interface TSpecBodyGetAllSolutionsOfOpportunity {}

export interface TSpecQueryGetAllSolutionsOfOpportunity {}
