// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_patchTaskPerformances = () => "/task-performance";

export type TSpecHandler_patchTaskPerformances = RequestHandler<TSpecPathPatchTaskPerformances, any, TSpecBodyPatchTaskPerformances, TSpecQueryPatchTaskPerformances, {}>;

export interface TSpecPathPatchTaskPerformances {  }

export interface TSpecBodyPatchTaskPerformances {
  taskId: string;
  performances: {
    _id: string;
    user: string;
    body: {
      isDeleted?: boolean;
      startTime?: string;
      completeTime?: string;
      quantity?: number;
      timeAttackBonusPoints?: number;
    };
    isNew: boolean;
  }[];
}

export interface TSpecQueryPatchTaskPerformances {}
