// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_updateBackdoorInfo = (path: TSpecPathUpdateBackdoorInfo) => {
  let res = "/backdoor/info/:id";
  Object.entries(path).map(([key, value]) => {
    res = res.replace(':' + key, value);
  });
  return res;
};

export type TSpecHandler_updateBackdoorInfo = RequestHandler<TSpecPathUpdateBackdoorInfo, any, TSpecBodyUpdateBackdoorInfo, TSpecQueryUpdateBackdoorInfo, {}>;

export interface TSpecPathUpdateBackdoorInfo { id: string; }

export interface TSpecBodyUpdateBackdoorInfo {
  versionRange: string;
  title: string;
  message: string;
  links: {
    text?: string;
    url?: string;
  }[];
  canDismiss: boolean;
  tenantsToShow: string[];
  showToAllTenants: boolean;
  platformsToShow: EBackdoorInfoPlatform[];
}

export enum EBackdoorInfoPlatform {
  web = "web",
  ios = "ios",
  android = "android"
}

export interface TSpecQueryUpdateBackdoorInfo {}
