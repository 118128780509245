// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_updateOpportunity = (path: TSpecPathUpdateOpportunity) => {
  let res = "/suggestions/opportunities/:opportunityId";
  Object.entries(path).map(([key, value]) => {
    res = res.replace(':' + key, value);
  });
  return res;
};

export type TSpecHandler_updateOpportunity = RequestHandler<TSpecPathUpdateOpportunity, any, TSpecBodyUpdateOpportunity, TSpecQueryUpdateOpportunity, {}>;

export interface TSpecPathUpdateOpportunity { opportunityId: string; }

export interface TSpecBodyUpdateOpportunity {
  category?: string;
  body: string;
  attachments: string[];
  isPublic: boolean;
  status: ESuggestionStatus;
  author?: string;
}

export enum ESuggestionStatus {
  AWAITING_REVIEW = "AWAITING_REVIEW",
  IN_QUEUE = "IN_QUEUE",
  IN_PROGRESS = "IN_PROGRESS",
  RESOLVED = "RESOLVED",
  ARCHIVED = "ARCHIVED"
}

export interface TSpecQueryUpdateOpportunity {}
