import { Injectable } from '@angular/core';
import { UserApiService } from './api/user.api.service';
import { UserModel } from '../model/user.model';
import {
  Observable,
  Subject,
  repeat,
  shareReplay,
  switchMap,
  take,
  tap
} from 'rxjs';
import { TenantApiService } from './api/tenant.api.service';
import { UserEntityService } from './user-entity.service';
import { TSpecBodyPatchUsers } from '@backend/types/generated/Tenants/patchUsers';

@Injectable({ providedIn: 'root' })
export class UserListService {
  private readonly _update$ = new Subject<void>();
  public users$: Observable<UserModel[]> = this._api
    .getAllUsersNames()
    .pipe(repeat({ delay: () => this._update$ }), shareReplay(1));

  public constructor(
    private readonly _api: UserApiService,
    private readonly _tenantApi: TenantApiService,
    private readonly _userService: UserEntityService
  ) {}

  public update(): void {
    this._update$.next();
  }

  public patchUsers(body: TSpecBodyPatchUsers): Observable<unknown> {
    return this._userService.user$.pipe(
      take(1),
      switchMap(({ tenant }) =>
        this._tenantApi.patchUsers({ path: { id: tenant }, body })
      ),
      tap(() => {
        this._update$.next();
      })
    );
  }
}
