import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthenticationService, LocalStorageService } from '@app/core';
import { ToastService } from '@app/core/service/toast.service';
import { Router } from '@angular/router';
import {
  LoadingController,
  IonModal,
  ActionSheetController
} from '@ionic/angular';
import { UserModel } from '@app/core/model/user.model';
import { PasscodeApiService } from '@app/core/service/passcode-api.service';
import { ObjectId } from '@app/types/object-id';
import { PasscodeTimeoutService } from '@app/core/service/passcode-timeout.service';

const PIN_CODE_LENGTH = 4;
const DEFAULT_PASSCODE_REDIRECTS_TO = '/i/recognition';

@Component({
  selector: 'app-pin-code',
  templateUrl: './pin-code.component.html',
  styleUrls: ['./pin-code.component.scss']
})
export class PinCodeComponent implements OnInit {
  @ViewChild('userSelectorModal', { static: true })
  public userSelectorModal!: IonModal;

  protected pinCode = '';
  protected users: UserModel[] = [];

  public constructor(
    private readonly _authenticationService: AuthenticationService,
    private readonly _toastService: ToastService,
    private readonly _router: Router,
    private readonly _loadingCtrl: LoadingController,
    private readonly _localStorageService: LocalStorageService,
    private readonly _passcodeApiService: PasscodeApiService,
    private readonly _actionSheetCtrl: ActionSheetController,
    private readonly _passcodeTimeoutService: PasscodeTimeoutService
  ) {}

  public ngOnInit(): void {
    const multiUserMode = !!this._localStorageService.getItem('multiUserMode');

    if (!multiUserMode) {
      this._router.navigate(['/login']);
    }
  }

  protected onNumberPress(number: string) {
    if (this.pinCode.length < PIN_CODE_LENGTH) {
      this.pinCode += number;

      if (this.pinCode.length === PIN_CODE_LENGTH) {
        this._loginWithPinCode();
      }
    }
  }

  protected onBackspacePress() {
    if (this.pinCode.length > 0) {
      this.pinCode = this.pinCode.slice(0, -1);
    }
  }

  protected async goToLogin() {
    const actionSheet = await this._actionSheetCtrl.create({
      header: 'Are you sure?',
      subHeader:
        'Logging out will deactivate Multi-User Mode. Only an Admin can reactivate the passcode mode.',
      buttons: [
        {
          role: 'destructive',
          text: 'Deactivate Multi-User Mode and Logout'
        },
        {
          role: 'cancel',
          text: 'Cancel'
        }
      ]
    });
    actionSheet.present();

    const { role } = await actionSheet.onWillDismiss();

    if (role === 'destructive') {
      this._passcodeTimeoutService.deactivateMultiUserMode();

      this._router.navigate(['/login']);
    }
  }

  protected async forgotPin() {
    const loading = await this._loadingCtrl.create({
      message: 'Loading...'
    });
    loading.present();

    this._passcodeApiService.getForgotPasscodeUsers().subscribe((users) => {
      this.users = users;
      loading.dismiss();
      this.userSelectorModal.present();
    });
  }

  protected async onUserSelect(event: ObjectId[]) {
    if (event.length) {
      const loading = await this._loadingCtrl.create({
        message: 'Loading...'
      });
      loading.present();

      this.userSelectorModal.dismiss();
      this._passcodeApiService.sendPincodeToEmail(event[0]).subscribe(() => {
        loading.dismiss();
        this._toastService.presentToast('Passcode sent! Check your email');
      });
    }
  }

  private async _loginWithPinCode() {
    const loading = await this._loadingCtrl.create({
      message: 'Loading...'
    });
    loading.present();

    this._authenticationService.loginWithPinCode(this.pinCode).subscribe({
      next: () => {
        this.pinCode = '';
        loading.dismiss();

        let passcodeRedirectsTo = this._localStorageService.getItem(
          'passcodeRedirectsTo'
        );

        if (passcodeRedirectsTo && typeof passcodeRedirectsTo === 'string') {
          if (!passcodeRedirectsTo.startsWith('/')) {
            passcodeRedirectsTo = DEFAULT_PASSCODE_REDIRECTS_TO;
          }
        } else {
          passcodeRedirectsTo = DEFAULT_PASSCODE_REDIRECTS_TO;
        }

        this._router.navigate([passcodeRedirectsTo]);
      },
      error: () => {
        this.pinCode = '';
        loading.dismiss();
        this._toastService.presentToast('Wrong Passcode');
      }
    });
  }
}
