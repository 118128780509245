// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_getAverageTeamPointsForPeriod = () => "/user/average-team-points-for-period";

export type TSpecHandler_getAverageTeamPointsForPeriod = RequestHandler<TSpecPathGetAverageTeamPointsForPeriod, any, TSpecBodyGetAverageTeamPointsForPeriod, TSpecQueryGetAverageTeamPointsForPeriod, {}>;

export interface TSpecPathGetAverageTeamPointsForPeriod {  }

export interface TSpecBodyGetAverageTeamPointsForPeriod {}

export interface TSpecQueryGetAverageTeamPointsForPeriod {
  direction: EAggregationDirection;
  from: string;
  to: string;
}

export enum EAggregationDirection {
  sender = "sender",
  recipient = "recipient"
}
