// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_updateLedger = (path: TSpecPathUpdateLedger) => {
  let res = "/tenant/:tenantId/ledger/:id";
  Object.entries(path).map(([key, value]) => {
    res = res.replace(':' + key, value);
  });
  return res;
};

export type TSpecHandler_updateLedger = RequestHandler<TSpecPathUpdateLedger, any, TSpecBodyUpdateLedger, TSpecQueryUpdateLedger, {}>;

export interface TSpecPathUpdateLedger { tenantId: string; id: string; }

export interface TSpecBodyUpdateLedger {
  description: string;
  recipientId: string;
  behaviorId: string;
  isPrivate: boolean;
}

export interface TSpecQueryUpdateLedger {}
