import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { ListPostLineComponent } from './list-post-line.component';

@NgModule({
  imports: [CommonModule, IonicModule],
  exports: [ListPostLineComponent],
  declarations: [ListPostLineComponent]
})
export class ListPostLineModule {}
