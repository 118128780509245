<ion-header class="ion-no-border">
  <ion-toolbar class="app-toolbar">
    <ion-buttons slot="start" class="ion-hide-lg-up">
      <app-slim-menu-button></app-slim-menu-button>
    </ion-buttons>
    <ion-title>Users</ion-title>
    <ion-buttons slot="end" [attr.data-hidden]="!hasEditRights">
      <ion-button id="popover-button" appHaptics>
        <ion-icon slot="icon-only" name="options-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
  <ion-toolbar class="app-toolbar ion-no-padding">
    <app-width-restricted-container>
      <ion-searchbar (ionInput)="handleSearchInput($event)" />
    </app-width-restricted-container>
  </ion-toolbar>
</ion-header>

<ion-content color="light">
  <app-width-restricted-container class="users-container">
    <ion-grid *ngIf="isLoading">
      <ion-row class="ion-justify-content-center">
        <ion-spinner name="lines-sharp-small"></ion-spinner>
      </ion-row>
    </ion-grid>

    <div class="users-table" *ngIf="users.length > 0">
      <cdk-virtual-scroll-viewport
        class="ion-hide-lg-up"
        [itemSize]="cdkItemSize"
        [minBufferPx]="cdkItemSize * 12"
        [maxBufferPx]="cdkItemSize * 12"
      >
        <ion-list>
          <ion-item
            *cdkVirtualFor="
              let user of users | filterUsers : searchTerm : showDisabledUsers;
              trackBy: trackItems;
              let i = index
            "
            [detail]="hasEditRights"
            [button]="hasEditRights"
            (click)="editUser(user._id)"
            appHaptics
            [disableHaptics]="!hasEditRights"
            class="user-item"
          >
            <ion-label>
              <h2 [ngClass]="{ 'deleted-user': user.isDeleted }">
                {{ user.firstName }} {{ user.lastName }}
              </h2>
              <p *ngIf="user.email" class="subtext">
                {{ user.email }}
              </p>
              <p *ngIf="user.supervisor" class="subtext">
                Supervisor:
                {{ user.supervisor.firstName }}
                {{ user.supervisor.lastName }}
              </p>
              <p class="subtext" *ngIf="user.roles.length > 0">
                Role:
                {{ user.roles | formatRoles }}
              </p>
            </ion-label>
          </ion-item>
        </ion-list>
      </cdk-virtual-scroll-viewport>

      <table
        mat-table
        [dataSource]="dataSource"
        matSort
        class="ion-hide-lg-down"
        matSortStart="desc"
      >
        <ng-container matColumnDef="firstName">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            class="header-cell"
            appHaptics
          >
            First Name
          </th>
          <td
            mat-cell
            *matCellDef="let row"
            [ngClass]="{ 'deleted-user': row.isDeleted }"
          >
            {{ row.firstName }}
          </td>
        </ng-container>
        <ng-container matColumnDef="lastName">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            class="header-cell"
            appHaptics
          >
            Last Name
          </th>
          <td
            mat-cell
            *matCellDef="let row"
            [ngClass]="{ 'deleted-user': row.isDeleted }"
          >
            {{ row.lastName }}
          </td>
        </ng-container>
        <ng-container matColumnDef="email">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            class="header-cell"
            appHaptics
          >
            Email
          </th>
          <td
            mat-cell
            *matCellDef="let row"
            [ngClass]="{ 'deleted-user': row.isDeleted }"
          >
            {{ row.email }}
          </td>
        </ng-container>
        <ng-container matColumnDef="supervisor">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            class="header-cell"
            appHaptics
          >
            Supervisor
          </th>
          <td
            mat-cell
            *matCellDef="let row"
            [ngClass]="{ 'deleted-user': row.isDeleted }"
          >
            <span *ngIf="row.supervisor">
              {{ row.supervisor.firstName }} {{ row.supervisor.lastName }}
            </span>
          </td>
        </ng-container>
        <ng-container matColumnDef="roles">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            class="header-cell"
            appHaptics
          >
            Role
          </th>
          <td
            mat-cell
            *matCellDef="let row"
            [ngClass]="{ 'deleted-user': row.isDeleted }"
          >
            <span *ngIf="row.roles.length > 0">
              {{ row.roles | formatRoles }}
            </span>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr
          [ngClass]="{ 'row-clickable': hasEditRights }"
          mat-row
          *matRowDef="let row; columns: displayedColumns"
          (click)="hasEditRights && editUser(row._id)"
          appHaptics
          [disableHaptics]="!hasEditRights"
        ></tr>
      </table>
    </div>
  </app-width-restricted-container>

  <ion-fab
    slot="fixed"
    vertical="bottom"
    horizontal="end"
    class="main-fab"
    *ngIf="hasEditRights"
  >
    <ion-fab-button color="dark" (click)="inviteUser()" appHaptics>
      <ion-icon name="add"></ion-icon>
    </ion-fab-button>
  </ion-fab>
</ion-content>

<ion-popover trigger="popover-button">
  <ng-template>
    <ion-list lines="none">
      <ion-item>
        <ion-toggle
          [checked]="showDisabledUsers"
          (ionChange)="handleDisabledUsersToggle($event)"
          appHaptics
        >
          Show Disabled Users
        </ion-toggle>
      </ion-item>
    </ion-list>
  </ng-template>
</ion-popover>
