// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_deleteBehavior = (path: TSpecPathDeleteBehavior) => {
  let res = "/behavior/:id";
  Object.entries(path).map(([key, value]) => {
    res = res.replace(':' + key, value);
  });
  return res;
};

export type TSpecHandler_deleteBehavior = RequestHandler<TSpecPathDeleteBehavior, any, TSpecBodyDeleteBehavior, TSpecQueryDeleteBehavior, {}>;

export interface TSpecPathDeleteBehavior { id: string; }

export interface TSpecBodyDeleteBehavior {}

export interface TSpecQueryDeleteBehavior {}
