import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { UserScheduleEditorModalComponent } from './user-schedule-editor-modal.component';
import { UserScheduleEditorModule } from '../user-schedule-editor/user-schedule-editor.module';

@NgModule({
  imports: [CommonModule, IonicModule, UserScheduleEditorModule],
  exports: [UserScheduleEditorModalComponent],
  declarations: [UserScheduleEditorModalComponent]
})
export class UserScheduleEditorModalModule {}
