// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_getTaskPerformances = (path: TSpecPathGetTaskPerformances) => {
  let res = "/users/:id/task-performances";
  Object.entries(path).map(([key, value]) => {
    res = res.replace(':' + key, value);
  });
  return res;
};

export type TSpecHandler_getTaskPerformances = RequestHandler<TSpecPathGetTaskPerformances, any, TSpecBodyGetTaskPerformances, TSpecQueryGetTaskPerformances, {}>;

export interface TSpecPathGetTaskPerformances { id: string; }

export interface TSpecBodyGetTaskPerformances {}

export interface TSpecQueryGetTaskPerformances {
  from?: string;
  to?: string;
  page?: string;
  limit?: string;
}
