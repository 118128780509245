import {
  Component,
  ChangeDetectionStrategy,
  Input,
  OnInit
} from '@angular/core';
import {
  AlertController,
  LoadingController,
  ModalController
} from '@ionic/angular';
import { ToastService } from '@app/core/service/toast.service';
import { AnnouncementModel } from '@app/core/model/announcement.model';
import {
  EAnnouncementActions,
  EAnnouncementRespondButtonText
} from '@backend/models/types/announcement';
import { AnnouncementListService } from '@app/core/service/announcement-list.service';

@Component({
  selector: 'app-announcement-respond-modal',
  templateUrl: './announcement-respond-modal.component.html',
  styleUrls: ['./announcement-respond-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AnnouncementRespondModalComponent implements OnInit {
  @Input()
  public announcement: AnnouncementModel;

  @Input()
  public buttonText = '';

  protected readonly announcementActions = EAnnouncementActions;
  protected readonly announcementRespondButtonText =
    EAnnouncementRespondButtonText;

  protected body = '';

  public constructor(
    private readonly _modalCtrl: ModalController,
    private readonly _loadingCtrl: LoadingController,
    private readonly _toastService: ToastService,
    private readonly _announcementListService: AnnouncementListService,
    private readonly _alertCtrl: AlertController
  ) {}

  public async ngOnInit() {
    if (this.announcement.taskAudit) {
      const alert = await this._alertCtrl.create({
        cssClass: 'wide-alert',
        header: 'Task Audit Feedback',
        subHeader: 'Your feedback will be shared with audit reviewers',
        buttons: [
          {
            role: 'confirm',
            text: 'OK'
          }
        ]
      });
      alert.present();
    }
  }

  protected onCloseClick(role = 'cancel') {
    this._modalCtrl.dismiss(null, role, 'announcement-respond-modal');
  }

  protected async onRespondClick() {
    const loading = await this._loadingCtrl.create({
      message: 'Sending...'
    });
    loading.present();

    this._announcementListService
      .respondToAnnouncement(this.announcement._id, {
        body: this.body
      })
      .subscribe({
        next: () => {
          loading.dismiss();
          this._toastService.presentToast('Message Sent Successfully!');
          this.onCloseClick('confirm');
        },
        error: () => {
          loading.dismiss();
          this._toastService.presentToast('Unable to Send Message');
        }
      });
  }

  protected get respondButtonText() {
    switch (this.announcement.respondButtonText) {
      case EAnnouncementRespondButtonText.RESPOND:
        return 'Respond';
      case EAnnouncementRespondButtonText.REPORT_ISSUE:
        return 'Report Issue';
      default:
        return 'Share Feedback';
    }
  }
}
