<app-login-page-wrapper>
  <form [formGroup]="mainFormGroup" (ngSubmit)="submit()" novalidate>
    <div class="error-place error-place-persistent">
      Enter and verify your new password
    </div>

    <ion-input
      type="email"
      [value]="email"
      placeholder="Email"
      email
      [readonly]="true"
      [disabled]="true"
      class="hidden-control"
    />

    <ion-row>
      <ion-col size="12">
        <app-form-error-wrapper
          [control]="mainFormGroup.controls['password']"
          [controlName]="'Password'"
        >
          <ion-input
            type="password"
            class="login-form-control"
            formControlName="password"
            placeholder="New password"
            name="password"
          />
        </app-form-error-wrapper>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col size="12">
        <app-form-error-wrapper
          [control]="mainFormGroup.controls['confirmPassword']"
          [controlName]="'Password Confirmation'"
        >
          <ion-input
            type="password"
            class="login-form-control"
            formControlName="confirmPassword"
            placeholder="Re-enter new password"
            name="confirmPassword"
          />
        </app-form-error-wrapper>
        <div
          class="input-error"
          *ngIf="mainFormGroup.errors?.['PasswordNoMatch']"
        >
          Passwords do not match
        </div>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col size="12">
        <ion-button
          type="submit"
          color="primary"
          expand="block"
          [disabled]="mainFormGroup.invalid"
          appHaptics
          [disableHaptics]="mainFormGroup.invalid"
          class="ion-no-margin"
        >
          Confirm
        </ion-button>
      </ion-col>
    </ion-row>

    <div class="controls">
      <ion-button
        type="button"
        color="medium"
        fill="clear"
        size="small"
        appHaptics
        (click)="goToLogin()"
      >
        Cancel and return to login
      </ion-button>
      <input type="submit" class="hidden-control" />
    </div>
  </form>
</app-login-page-wrapper>
