// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_getAllTenants = () => "/tenants";

export type TSpecHandler_getAllTenants = RequestHandler<TSpecPathGetAllTenants, any, TSpecBodyGetAllTenants, TSpecQueryGetAllTenants, {}>;

export interface TSpecPathGetAllTenants {  }

export interface TSpecBodyGetAllTenants {}

export interface TSpecQueryGetAllTenants {}
