// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_getCompensationFields = () => "/compensation/fields";

export type TSpecHandler_getCompensationFields = RequestHandler<TSpecPathGetCompensationFields, any, TSpecBodyGetCompensationFields, TSpecQueryGetCompensationFields, {}>;

export interface TSpecPathGetCompensationFields {  }

export interface TSpecBodyGetCompensationFields {}

export interface TSpecQueryGetCompensationFields {}
