// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_getAllUsersNames = () => "/users";

export type TSpecHandler_getAllUsersNames = RequestHandler<TSpecPathGetAllUsersNames, any, TSpecBodyGetAllUsersNames, TSpecQueryGetAllUsersNames, {}>;

export interface TSpecPathGetAllUsersNames {  }

export interface TSpecBodyGetAllUsersNames {}

export interface TSpecQueryGetAllUsersNames {}
