// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_loginToConnectedAccount = () => "/connected-accounts/login";

export type TSpecHandler_loginToConnectedAccount = RequestHandler<TSpecPathLoginToConnectedAccount, any, TSpecBodyLoginToConnectedAccount, TSpecQueryLoginToConnectedAccount, {}>;

export interface TSpecPathLoginToConnectedAccount {  }

export interface TSpecBodyLoginToConnectedAccount {
  userId: string;
}

export interface TSpecQueryLoginToConnectedAccount {}
