// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_addCompensationField = () => "/compensation/fields";

export type TSpecHandler_addCompensationField = RequestHandler<TSpecPathAddCompensationField, any, TSpecBodyAddCompensationField, TSpecQueryAddCompensationField, {}>;

export interface TSpecPathAddCompensationField {  }

export interface TSpecBodyAddCompensationField {
  name: string;
  type: ECompensationFieldType;
}

export enum ECompensationFieldType {
  EMPLOYEE_ID = "EMPLOYEE_ID",
  HOURS = "HOURS"
}

export interface TSpecQueryAddCompensationField {}
