<div [formGroup]="scheduleFormGroup">
  <ion-list [inset]="true">
    <ion-item>
      <ion-select
        labelPlacement="stacked"
        aria-label="Schedule"
        interface="popover"
        label="Schedule"
        appHaptics
        formControlName="scheduleType"
      >
        <ion-select-option [value]="userScheduleType.FIXED">
          Fixed
        </ion-select-option>
        <ion-select-option [value]="userScheduleType.VARIABLE">
          Variable
        </ion-select-option>
      </ion-select>
    </ion-item>
  </ion-list>

  <div *ngIf="isFixedScheduleType; else variableScheduleInfo">
    <div [formGroup]="weekTimeFormGroup">
      <app-list-pre-line title="Weekdays" />
      <ion-list
        [inset]="true"
        *ngFor="let day of dayArray; let i = index; trackBy: trackByDay"
        formGroupName="{{ i }}"
      >
        <div [formGroup]="scheduleFormGroup">
          <ion-item
            formGroupName="weekDays"
            [lines]="scheduleFormGroup.value.weekDays[i] ? 'full' : 'none'"
          >
            <ion-checkbox formControlName="{{ i }}">
              {{ weekDays[i] }}
            </ion-checkbox>
          </ion-item>
        </div>
        <app-time-form-control
          [attr.data-hidden]="!scheduleFormGroup.value.weekDays[i]"
          [isItem]="true"
          [itemLines]="true"
          formControlName="from"
          id="from{{ i }}"
          label="From"
        />
        <app-time-form-control
          [attr.data-hidden]="!scheduleFormGroup.value.weekDays[i]"
          [isItem]="true"
          formControlName="to"
          id="to{{ i }}"
          label="To"
        />
      </ion-list>
    </div>
  </div>
</div>

<ng-template #variableScheduleInfo>
  <app-list-post-line
    *ngIf="lastVariableShiftTime && !lastVariableShiftTime.isNotWorkingToday"
    text="The last shift time was set to
  {{ lastVariableShiftTime.start | formatTime : '' }} -
  {{ lastVariableShiftTime.end | formatTime : '' }}
  on {{ lastVariableShiftTime.date | date }}"
  />
  <app-list-post-line
    *ngIf="lastVariableShiftTime && lastVariableShiftTime.isNotWorkingToday"
    text="{{ userId ? 'User' : 'You' }} answered as not working on
  {{ lastVariableShiftTime.date | date }}"
  />
</ng-template>
