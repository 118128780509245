import { ModalController } from '@ionic/angular';
import { ComponentProps, ComponentRef } from '@ionic/core';
import { isMobile } from './is-mobile';
import { ObjectId } from '@app/types/object-id';
import { UserSelectorModalComponent } from '@app/modals/user-selector-modal/user-selector-modal.component';
import { UserModel, UserNameModel } from '@app/core/model/user.model';
import { BehaviorSelectorModalComponent } from '@app/modals/behavior-selector-modal/behavior-selector-modal.component';
import { BehaviorModel } from '@app/core/model/behavior.model';

export const showModal = async <T>(
  params: {
    component: ComponentRef;
    props?: ComponentProps<ComponentRef>;
  },
  modalCtrl: ModalController,
  parentModalName?: string
): Promise<{ role: string; data: T }> => {
  const isM = isMobile();
  const modalName = (params.component as any).name;

  const modal = await modalCtrl.create({
    id: modalName,
    cssClass: isM ? undefined : 'modal-auto-height',
    component: params.component,
    componentProps: params.props,
    handle: false,
    presentingElement: isM
      ? parentModalName
        ? document.getElementById(parentModalName)
        : document.querySelector('ion-split-pane')
      : undefined
  });
  modal.present();

  const { role, data } = await modal.onWillDismiss();

  return { role, data };
};

export const showUserSelectorModal = async (
  modalProps: {
    recipients?: ObjectId[];
    hideSelectAllButton?: boolean;
    title?: string;
    users: (UserModel | UserNameModel)[];
    hiddenUsers?: ObjectId[];
    multiple?: boolean;
    focusSearchbar?: boolean;
    doneBtnText?: string;
  },
  modalCtrl: ModalController,
  parentModalName?: string
): Promise<{
  success: boolean;
  selectedUsers: ObjectId[];
}> => {
  const { role, data } = await showModal<ObjectId[]>(
    {
      component: UserSelectorModalComponent,
      props: modalProps
    },
    modalCtrl,
    parentModalName
  );

  if (role === 'confirm') {
    return { success: true, selectedUsers: data };
  }

  return { success: false, selectedUsers: [] };
};

export const showBehaviorSelectorModal = async (
  modalProps: {
    behaviors?: BehaviorModel[];
    focusSearchbar?: boolean;
  },
  modalCtrl: ModalController,
  parentModalName?: string
): Promise<{
  success: boolean;
  selectedBehavior: BehaviorModel;
}> => {
  const { role, data } = await showModal<BehaviorModel>(
    {
      component: BehaviorSelectorModalComponent,
      props: modalProps
    },
    modalCtrl,
    parentModalName
  );

  if (role === 'confirm') {
    return { success: true, selectedBehavior: data };
  }

  return { success: false, selectedBehavior: null };
};
