// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_getSuggestionCategories = () => "/suggestion-categories";

export type TSpecHandler_getSuggestionCategories = RequestHandler<TSpecPathGetSuggestionCategories, any, TSpecBodyGetSuggestionCategories, TSpecQueryGetSuggestionCategories, {}>;

export interface TSpecPathGetSuggestionCategories {  }

export interface TSpecBodyGetSuggestionCategories {}

export interface TSpecQueryGetSuggestionCategories {
  tenantId: string;
}
