<ion-header class="ion-no-border">
  <ion-toolbar class="app-toolbar">
    <ion-buttons slot="end">
      <ion-button (click)="onCloseClick()" appHaptics>
        <ion-icon slot="icon-only" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>
      {{ compensationPool ? 'Update Pool' : 'Create Pool' }}
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content *ngIf="_isMobile; else content" color="light">
  <ng-container *ngTemplateOutlet="content" />
</ion-content>

<ng-template #content>
  <app-full-height-container [formGroup]="form" [isModal]="true">
    <ion-list [inset]="true">
      <ion-item>
        <ion-input
          label="Name"
          formControlName="name"
          labelPlacement="stacked"
          autocapitalize="on"
          [spellcheck]="true"
        />
      </ion-item>
      <ion-item>
        <ion-select
          formControlName="type"
          labelPlacement="stacked"
          aria-label="Pool Type"
          interface="popover"
          label="Pool Type"
          appHaptics
        >
          <ion-select-option [value]="compensationPoolType.CURRENCY">
            Currency
          </ion-select-option>
          <ion-select-option [value]="compensationPoolType.HOURS">
            Hours
          </ion-select-option>
          <ion-select-option [value]="compensationPoolType.OTHER">
            Other
          </ion-select-option>
        </ion-select>
      </ion-item>
    </ion-list>

    <ion-list [inset]="true">
      <ion-item button appHaptics id="selectRecipients">
        {{ formattedRecipients }}
      </ion-item>
    </ion-list>

    <ion-button
      bottom
      *ngIf="compensationPool"
      color="danger"
      expand="block"
      fill="outline"
      appHaptics
      (click)="delete()"
      class="ion-no-margin"
    >
      Delete
    </ion-button>
    <ion-button
      bottom
      color="dark"
      expand="block"
      [disabled]="!canSave"
      appHaptics
      [disableHaptics]="!canSave"
      (click)="save()"
      class="ion-no-margin"
    >
      {{ compensationPool ? 'Update' : 'Create' }}
    </ion-button>
  </app-full-height-container>
</ng-template>

<ion-modal
  trigger="selectRecipients"
  #userSelectorModal
  [handle]="false"
  [breakpoints]="[0, 1]"
  [initialBreakpoint]="1"
>
  <ng-template>
    <app-user-selector
      class="ion-page"
      [users]="allUsers"
      [recipients]="selectedUserIds"
      (selectionChange)="selectedIdChanged($event)"
      (selectionCancel)="userSelectorModal.dismiss()"
      [multiple]="true"
    ></app-user-selector>
  </ng-template>
</ion-modal>
