import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component
} from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { GlobalSettingsService } from '@app/core/service/global-settings.service';
import { UserModel } from '@app/core/model/user.model';
import { MongoStoredObject } from '@app/types/mongo-stored-object';
import { IPsychologicalSurvey } from '@backend/models/types/psychological-survey';
import { PsychologicalSurveyApiService } from '@app/core/service/api/psychologicalSurvey.api.service';
import { IPsychologicalSurveyQuestion } from '@backend/models/types/psychological-survey-question';
import { EPsychologicalSurveyShowFeedbackBy } from '@backend/types/psychological-survey-feedback-stats';

@Component({
  selector: 'app-global-settings',
  templateUrl: './global-settings.component.html',
  styleUrls: ['./global-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GlobalSettingsComponent {
  protected readonly psychologicalSurveyShowFeedbackBy =
    EPsychologicalSurveyShowFeedbackBy;

  protected isLoading = true;
  protected mainFormGroup: FormGroup = new FormGroup({
    instantFeedback: new FormGroup({
      email: new FormControl<string>('', Validators.required),
      inactivityTime: new FormControl<string>('0', Validators.required)
    }),
    psychologicalSurvey: new FormGroup({
      currentSurvey: new FormControl<string>('', Validators.required),
      emailForSuggestedOptions: new FormControl<string>(
        '',
        Validators.required
      ),
      showFeedbackBy: new FormControl<EPsychologicalSurveyShowFeedbackBy>(
        EPsychologicalSurveyShowFeedbackBy.feedbackByBehaviors,
        Validators.required
      )
    }),
    aiPrompts: new FormGroup({
      suggestionTitle: new FormControl<string>('', Validators.required)
    })
  });
  protected superAdmins: Pick<UserModel, 'firstName' | 'lastName'>[] = [];
  protected psychologicalSurveys: {
    survey: MongoStoredObject<IPsychologicalSurvey>;
    questions: MongoStoredObject<IPsychologicalSurveyQuestion>[];
  }[] = [];

  public constructor(
    private readonly _loadingCtrl: LoadingController,
    private readonly _globalSettingsService: GlobalSettingsService,
    private readonly _psychologicaSurveyApiService: PsychologicalSurveyApiService,
    private readonly _cdr: ChangeDetectorRef
  ) {
    this._globalSettingsService.globalSettings$.subscribe((globalSettings) => {
      this.superAdmins = globalSettings.superAdmins;
      this.mainFormGroup.reset({
        ...globalSettings,
        instantFeedback: {
          ...globalSettings.instantFeedback,
          inactivityTime:
            globalSettings.instantFeedback.inactivityTime.toString()
        }
      });
    });

    this._psychologicaSurveyApiService
      .getAllPsychologicalSurveys()
      .subscribe((surveys) => {
        this.psychologicalSurveys = surveys;
        this._cdr.markForCheck();
      });
  }

  protected get currentSurveyQuestions() {
    const currentSurvey = this.mainFormGroup.get(
      'psychologicalSurvey.currentSurvey'
    ).value;
    return (
      this.psychologicalSurveys.find((s) => s.survey._id === currentSurvey)
        ?.questions || []
    );
  }

  protected get canSave() {
    return !this.mainFormGroup.invalid;
  }

  protected async save() {
    const loading = await this._loadingCtrl.create({
      message: 'Saving...'
    });
    loading.present();

    const globalSettings = this.mainFormGroup.value;

    this._globalSettingsService
      .updateGlobalSettings({
        ...globalSettings,
        instantFeedback: {
          ...globalSettings.instantFeedback,
          inactivityTime:
            Number(globalSettings.instantFeedback.inactivityTime) || 0
        }
      })
      .subscribe(() => {
        loading.dismiss();
      });
  }
}
