// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_deleteSolution = (path: TSpecPathDeleteSolution) => {
  let res = "/suggestions/opportunities/:opportunityId/solutions/:solutionId";
  Object.entries(path).map(([key, value]) => {
    res = res.replace(':' + key, value);
  });
  return res;
};

export type TSpecHandler_deleteSolution = RequestHandler<TSpecPathDeleteSolution, any, TSpecBodyDeleteSolution, TSpecQueryDeleteSolution, {}>;

export interface TSpecPathDeleteSolution { opportunityId: string; solutionId: string; }

export interface TSpecBodyDeleteSolution {}

export interface TSpecQueryDeleteSolution {}
