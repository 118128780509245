import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  Output,
  OnInit,
  EventEmitter,
  ViewChild,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import {
  AngularEditorComponent,
  AngularEditorConfig,
  AngularEditorModule
} from '@kolkov/angular-editor';
import { AfterContentInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { removeInlineStyles } from '@app/utils/wysiwyg-editor';

@Component({
  selector: 'app-wysiwyg-editor',
  templateUrl: './wysiwyg-editor.component.html',
  styleUrls: ['./wysiwyg-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, AngularEditorModule, FormsModule]
})
export class WysiwygEditorComponent
  implements OnInit, OnChanges, AfterContentInit
{
  @Input()
  public readonly mode?: 'view' | 'edit' = 'edit';

  @Input()
  public readonly value?: string = '';

  @Input()
  public readonly bodyPlaceholder?: string = 'Body';

  @Input()
  public readonly minHeight?: string = '100px';

  @Input()
  public readonly disableAttachments?: boolean = false;

  @Input()
  public readonly toolbarMode?: 'full' | 'image-only' = 'full';

  @Input()
  public readonly dark?: boolean = false;

  @Input()
  public readonly isItem?: boolean = false;

  @Output()
  public valueChange = new EventEmitter<string>();

  @ViewChild('wysiwygEditor', { static: true })
  public wysiwygEditor!: AngularEditorComponent;

  protected body = '';
  protected editorConfig: AngularEditorConfig = {
    editable: false,
    sanitize: true,
    // rawPaste: true,
    showToolbar: false,
    spellcheck: true,
    height: 'auto',
    maxHeight: 'auto',
    toolbarHiddenButtons: [
      [
        'subscript',
        'superscript',
        'fontName',
        'toggleEditorMode',
        'backgroundColor',
        'customClasses',
        'insertVideo',
        'justifyRight',
        'justifyFull',
        'justifyLeft',
        'justifyCenter',
        'textColor',
        'strikeThrough',
        'insertHorizontalRule',
        'undo',
        'redo',
        'heading'
      ]
    ]
  };

  public constructor(private readonly _sanitizer: DomSanitizer) {}

  public ngAfterContentInit(): void {
    setTimeout(() => {
      const contenteditableDiv = document.querySelector(
        `#wysiwyg-editor .angular-editor-textarea`
      );

      const isSafari =
        navigator.userAgent.includes('Safari/') &&
        navigator.userAgent.includes('Version/');

      if (isSafari) {
        contenteditableDiv.addEventListener(
          'keydown',
          (e: any) => {
            // bold
            if (e.metaKey) {
              if (e.keyCode === 66 || e.keyCode === 73 || e.keyCode === 85) {
                e.preventDefault();
              }

              switch (e.keyCode) {
                // bold
                case 66:
                  document.execCommand('Bold', false, '');
                  break;
                // italic
                case 73:
                  document.execCommand('Italic', false, '');
                  break;
                // underline
                case 85:
                  document.execCommand('Underline', false, '');
                  break;
              }
            }
          },
          true
        );
      }
    });
  }

  public ngOnInit(): void {
    this.editorConfig.placeholder = this.bodyPlaceholder;
    this.editorConfig.minHeight = this.minHeight;

    if (this.mode === 'edit') {
      this.editorConfig.editable = true;
      this.editorConfig.showToolbar = true;
    }

    if (this.disableAttachments) {
      this.editorConfig.toolbarHiddenButtons[0].push('insertImage');
    }

    this.wysiwygEditor.onPaste = (e) => {
      // cancel paste
      e.preventDefault();

      // get text representation of clipboard
      let text = '';
      if (this.toolbarMode === 'image-only') {
        text = e.clipboardData.getData('text/plain');
      } else {
        text = e.clipboardData.getData('text/html');
      }

      // sanitize html
      const safeHtml = this._sanitizer.bypassSecurityTrustHtml(text);
      //@ts-ignore
      const html: string = safeHtml['changingThisBreaksApplicationSecurity'];
      const clearedHtml = removeInlineStyles(html);

      // insert html
      document.execCommand('insertHTML', false, clearedHtml);
      return '';
    };
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      if (changes.value && changes.value.currentValue) {
        this.body = this.value;
      }

      if (changes.disableAttachments) {
        if (changes.disableAttachments.currentValue) {
          if (
            !this.editorConfig.toolbarHiddenButtons[0].includes('insertImage')
          ) {
            this.editorConfig.toolbarHiddenButtons[0].push('insertImage');
          }
        } else {
          this.editorConfig.toolbarHiddenButtons[0] =
            this.editorConfig.toolbarHiddenButtons[0].filter(
              (btn) => btn !== 'insertImage'
            );
        }
      }
    }
  }

  protected onChange(e: string) {
    this.body = e;
    this.valueChange.emit(e);
  }

  protected setFocus() {
    this.wysiwygEditor.focus();
  }

  protected get currentFontSize() {
    return this.wysiwygEditor && this.wysiwygEditor.editorToolbar
      ? this.wysiwygEditor.editorToolbar.fontSize
      : '';
  }
}
