import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  TSpecBodyPostRecognition,
  TSpecRoute_postRecognition
} from '@backend/types/generated/Recognition/postRecognition';
import { TSpecRoute_getRecognitionReminders } from '@backend/types/generated/Recognition/getRecognitionReminders';
import { TSpecRoute_dismissRecognitionReminder } from '@backend/types/generated/Recognition/dismissRecognitionReminder';

@Injectable({ providedIn: 'root' })
export class RecognitionApiService {
  public constructor(private _httpClient: HttpClient) {}

  public postRecognition(params: {
    body: TSpecBodyPostRecognition;
  }): Observable<unknown> {
    return this._httpClient.post<unknown>(
      TSpecRoute_postRecognition(),
      params.body
    );
  }

  public getRecognitionReminders(): Observable<{
    items: { title: string }[];
    count: number;
    totalCount: number;
  }> {
    return this._httpClient.get<{
      items: { title: string }[];
      count: number;
      totalCount: number;
    }>(TSpecRoute_getRecognitionReminders());
  }

  public dismissRecognitionReminder(): Observable<null> {
    return this._httpClient.post<null>(
      TSpecRoute_dismissRecognitionReminder(),
      {}
    );
  }
}
