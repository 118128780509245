import { Injectable } from '@angular/core';
import { Observable, Subject, map, repeat, shareReplay, tap } from 'rxjs';
import { InstantFeedbackApiService } from './api/instantFeedback.api.service';

@Injectable({ providedIn: 'root' })
export class InstantFeedbackService {
  public readonly pageSize = 20;

  private readonly _update$ = new Subject<void>();

  public unreadCount$: Observable<number> = this._api.getUnreadCount().pipe(
    map(({ unreadCount }) => unreadCount),
    repeat({ delay: () => this._update$ }),
    shareReplay({ refCount: true, bufferSize: 1 })
  );

  public constructor(private readonly _api: InstantFeedbackApiService) {}

  public updateUnreadCount() {
    this._update$.next();
  }

  public readInstantFeedback(instantFeedbackId: string) {
    return this._api
      .readInstantFeedback({ path: { id: instantFeedbackId } })
      .pipe(tap(() => this.updateUnreadCount()));
  }
}
