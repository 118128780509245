import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit
} from '@angular/core';
import { IonNav } from '@ionic/angular';
import { ICompensationUserValue } from '../compensation-hours/compensation-hours.component';
import { MongoStoredObject } from '@app/types/mongo-stored-object';
import { ICompensationPool } from '@backend/models/types/compensation-pool';
import { CompensationPreviewComponent } from '../compensation-preview/compensation-preview.component';
import { CompensationApiService } from '@app/core/service/api/compensation.api.service';
import { ICompensationField } from '@backend/models/types/compensation-field';
import { TenantService } from '@app/core/service/tenant.service';
import { EIncludePointsFrom } from '@backend/types/compensation-definition';

export type ICompensationPoolValue = MongoStoredObject<ICompensationPool> & {
  value: string;
};

@Component({
  selector: 'app-compensation-pools',
  templateUrl: './compensation-pools.component.html',
  styleUrls: ['./compensation-pools.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CompensationPoolsComponent implements OnInit {
  @Input()
  public fields: MongoStoredObject<ICompensationField>[] = [];

  @Input()
  public users: ICompensationUserValue[] = [];

  protected isLoading = true;
  protected pools: ICompensationPoolValue[] = [];
  protected includePointsFrom: EIncludePointsFrom[] = [];

  public constructor(
    private readonly _ionNav: IonNav,
    private readonly _compensationApiService: CompensationApiService,
    private readonly _cdr: ChangeDetectorRef,
    private readonly _tenantService: TenantService
  ) {}

  public ngOnInit(): void {
    this._tenantService.tenant$.subscribe((tenant) => {
      this.includePointsFrom = tenant.settings.compensation.includePointsFrom;
    });

    this._compensationApiService
      .getCompensationPools()
      .subscribe((compensationPools) => {
        this.pools = compensationPools.map((p) => ({ ...p, value: '' }));

        this.isLoading = false;
        this._cdr.markForCheck();
      });
  }

  protected onBackButtonClick(): void {
    this._ionNav.pop();
  }

  protected onInputChange(event: any, pool: ICompensationPoolValue): void {
    let v: string = event.detail.value.replace(/[^0-9.]/g, '');

    if (v.endsWith('.')) {
      v = v + '00';
    }

    if (v.startsWith('.')) {
      v = '0' + v;
    }

    pool.value = v;
  }

  protected itemTrackBy(_index: number, item: ICompensationPoolValue) {
    return item._id;
  }

  protected next() {
    this._ionNav.push(CompensationPreviewComponent, {
      users: this.users,
      pools: this.pools,
      fields: this.fields,
      includePointsFrom: this.includePointsFrom
    });
  }
}
