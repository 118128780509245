// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_getLastTaskPerformance = (path: TSpecPathGetLastTaskPerformance) => {
  let res = "/tasks/:id/last-performance";
  Object.entries(path).map(([key, value]) => {
    res = res.replace(':' + key, value);
  });
  return res;
};

export type TSpecHandler_getLastTaskPerformance = RequestHandler<TSpecPathGetLastTaskPerformance, any, TSpecBodyGetLastTaskPerformance, TSpecQueryGetLastTaskPerformance, {}>;

export interface TSpecPathGetLastTaskPerformance { id: string; }

export interface TSpecBodyGetLastTaskPerformance {}

export interface TSpecQueryGetLastTaskPerformance {}
