import { Component, ViewChild, AfterViewInit } from '@angular/core';
import { IonNav, IonModal, IonLoading } from '@ionic/angular';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UserModel } from '@app/core/model/user.model';
import { UserListService } from '@app/core/service/user-list.service';
import { ObjectId } from '@app/types/object-id';
import { ToastService } from '@app/core/service/toast.service';
import { UserApiService } from '@app/core/service/api/user.api.service';
import { CompensationApiService } from '@app/core/service/api/compensation.api.service';
import { MongoStoredObject } from '@app/types/mongo-stored-object';
import { ICompensationPool } from '@backend/models/types/compensation-pool';
import { TenantService } from '@app/core/service/tenant.service';
import { ITenantFeatures } from '@backend/models/types/tenant';
import { combineLatest, take } from 'rxjs';
import { EMAIL_REGEX } from '@app/utils/email';
import { UserRoles } from '@backend/models/types/user';
import { DepartmentApiService } from '@app/core/service/api/department.api.servic';
import { IDepartment } from '@backend/models/types/department';

@Component({
  selector: 'app-invite-user',
  templateUrl: './invite-user.component.html',
  styleUrls: ['./invite-user.component.scss']
})
export class InviteUserComponent implements AfterViewInit {
  @ViewChild('loading', { static: true })
  public loading!: IonLoading;

  @ViewChild('supervisorSelectorModal', { static: true })
  public supervisorSelectorModal!: IonModal;

  protected mainFormGroup: FormGroup = new FormGroup({
    email: new FormControl<string>('', [
      Validators.required,
      Validators.pattern(EMAIL_REGEX)
    ]),
    supervisorId: new FormControl<string | null>(null),
    department: new FormControl<ObjectId>(null),
    role: new FormControl<UserRoles | 'None'>('None'),
    compensationEmployeeId: new FormControl<string>(''),
    compensationPools: new FormControl<string[]>([])
  });
  protected allUsers: UserModel[] = [];
  protected roleOptions: (UserRoles | 'None')[] = [
    'None',
    ...Object.values(UserRoles)
  ];
  protected compensationPools: MongoStoredObject<ICompensationPool>[] = [];
  protected tenantFeatures: Pick<ITenantFeatures, 'calculateRewards'> = {
    calculateRewards: false
  };
  protected departments: MongoStoredObject<IDepartment>[] = [];

  public constructor(
    private readonly _ionNav: IonNav,
    private readonly _userListService: UserListService,
    private readonly _userApiService: UserApiService,
    private readonly _toastService: ToastService,
    private readonly _compensationApiService: CompensationApiService,
    private readonly _tenantService: TenantService,
    private readonly _departmentApiService: DepartmentApiService
  ) {}

  public ngAfterViewInit(): void {
    this.loading.present();
    combineLatest([
      this._tenantService.tenant$,
      this._userListService.users$,
      this._compensationApiService.getCompensationPools(),
      this._departmentApiService.getDepartments()
    ])
      .pipe(take(1))
      .subscribe({
        next: ([tenant, users, compensationPools, departments]) => {
          this.allUsers = users;
          this.compensationPools = compensationPools;
          this.tenantFeatures = tenant.features;
          this.departments = departments;
          this.loading.dismiss();
        },
        error: (e) => {
          this.loading.dismiss();
          this._toastService.presentToast(
            e.error?.message || 'Unable to load data'
          );
        }
      });
  }

  protected get emailError() {
    if (
      this.mainFormGroup.get('email').dirty &&
      this.mainFormGroup.get('email').errors
    ) {
      if (this.mainFormGroup.get('email').errors.required) {
        return 'Email is required';
      } else if (this.mainFormGroup.get('email').errors.pattern) {
        return 'Wrong email format';
      }
    }
    return '';
  }

  protected get selectedUserIds(): ObjectId[] {
    return [this.mainFormGroup.get('supervisorId').value];
  }

  protected get formattedSupervisor(): string {
    const user = this.allUsers.find(
      (user) => user._id === this.mainFormGroup.get('supervisorId').value
    );
    return user ? `${user.firstName} ${user.lastName}` : '';
  }

  protected onBackButtonClick(): void {
    this._ionNav.pop();
  }

  protected onSupervisorChange(event: ObjectId[]): void {
    this.mainFormGroup.controls.supervisorId.setValue(event[0].toString());
    this.supervisorSelectorModal.dismiss();
  }

  protected handleSupervisorClearClick(): void {
    this.mainFormGroup.controls.supervisorId.setValue(null);
  }

  protected async inviteUser() {
    this.loading.present();

    const supervisor = this.allUsers.find(
      (user) => user._id === this.mainFormGroup.get('supervisorId').value
    );

    const user: any = {
      email: this.mainFormGroup.get('email').value.toLowerCase(),
      department: this.mainFormGroup.get('department').value,
      roles:
        this.mainFormGroup.get('role').value === 'None'
          ? []
          : [this.mainFormGroup.get('role').value],
      compensationEmployeeId: this.mainFormGroup.get('compensationEmployeeId')
        .value,
      compensationPools: this.mainFormGroup.get('compensationPools').value
    };

    if (supervisor) {
      user.supervisorId = supervisor._id;
    }

    this._userApiService.inviteUser({ body: user }).subscribe({
      next: () => {
        this.loading.dismiss();
        this._userListService.update();
        this._toastService.presentToast('The invitation has been emailed!');
        this._ionNav.pop();
      },
      error: (e) => {
        this.loading.dismiss();
        this._toastService.presentToast(
          e.error?.message || 'Unable to invite user'
        );
      }
    });
  }
}
