// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_postTaskFeedback = (path: TSpecPathPostTaskFeedback) => {
  let res = "/tasks/:id/feedback";
  Object.entries(path).map(([key, value]) => {
    res = res.replace(':' + key, value);
  });
  return res;
};

export type TSpecHandler_postTaskFeedback = RequestHandler<TSpecPathPostTaskFeedback, any, TSpecBodyPostTaskFeedback, TSpecQueryPostTaskFeedback, {}>;

export interface TSpecPathPostTaskFeedback { id: string; }

export interface TSpecBodyPostTaskFeedback {
  body: string;
  attachments: string[];
}

export interface TSpecQueryPostTaskFeedback {}
