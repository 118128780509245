import {
  AfterViewInit,
  Component,
  Input,
  OnInit,
  ViewChild
} from '@angular/core';
import { ToastService } from '@app/core/service/toast.service';
import { IonLoading, IonModal, IonNav } from '@ionic/angular';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TenantService } from '@app/core/service/tenant.service';
import { UploadFileApiService } from '@app/core/service/api/uploadFile.api.service';
import { formatUploadedBody } from '@app/utils/wysiwyg-editor';
import { EnvironmentService } from '@app/core/service/environment.service';
import {
  ESuggestionStatus,
  ESuggestionType
} from '@backend/models/types/suggestion';
import { SuggestionApiService } from '@app/core/service/api/suggestion.api.service';
import { AuthenticationService } from '@app/core';
import { OpportunityModel } from '@app/core/model/suggestion.model';
import { UserEntityService } from '@app/core/service/user-entity.service';
import { ObjectId } from '@app/types/object-id';
import { UserModel } from '@app/core/model/user.model';
import { UserListService } from '@app/core/service/user-list.service';
import { TasksApiService } from '@app/core/service/api/tasks.api.service';
import { UserRoles } from '@backend/models/types/user';
import { SuggestionCategoryApiService } from '@app/core/service/api/suggestionCategory.api.service';
import { take } from 'rxjs';
import { SuggestionCategoryModel } from '@app/core/model/suggestion-category.model';

@Component({
  selector: 'app-suggestion-add',
  templateUrl: './suggestion-add.component.html',
  styleUrls: ['./suggestion-add.component.scss']
})
export class SuggestionAddComponent implements OnInit, AfterViewInit {
  @Input()
  public readonly suggestion: OpportunityModel = null;

  @Input()
  public readonly parentId: string = '';

  @ViewChild('loading', { static: true })
  public loading!: IonLoading;

  @ViewChild('userSelectorModal')
  public userSelectorModal!: IonModal;

  protected suggestionType = ESuggestionType;

  protected mainFormGroup = new FormGroup({
    status: new FormControl<ESuggestionStatus>(
      ESuggestionStatus.AWAITING_REVIEW,
      Validators.required
    ),
    author: new FormControl<ObjectId>(null),
    category: new FormControl<ObjectId>(null),
    isPublic: new FormControl<boolean>(false)
  });
  protected body = '';
  protected disableAttachments = false;
  protected statuses = [
    {
      title: 'Awaiting Review',
      value: ESuggestionStatus.AWAITING_REVIEW
    },
    {
      title: 'In Queue',
      value: ESuggestionStatus.IN_QUEUE
    },
    {
      title: 'In Progress',
      value: ESuggestionStatus.IN_PROGRESS
    },
    {
      title: 'Resolved',
      value: ESuggestionStatus.RESOLVED
    },
    {
      title: 'Archived',
      value: ESuggestionStatus.ARCHIVED
    }
  ];
  protected isAdmin = false;
  protected allUsers: UserModel[] = [];
  protected postOpportunityPoints = 0;
  protected categories: SuggestionCategoryModel[] = [];

  public constructor(
    private readonly _suggestionApiService: SuggestionApiService,
    private readonly _suggestionCategoryApiService: SuggestionCategoryApiService,
    private readonly _ionNav: IonNav,
    private readonly _toastService: ToastService,
    private readonly _tenantService: TenantService,
    private readonly _uploadFileApiService: UploadFileApiService,
    private readonly _environmentService: EnvironmentService,
    private readonly _authenticationService: AuthenticationService,
    private readonly _userEntityService: UserEntityService,
    private readonly _userListService: UserListService,
    private readonly _tasksApiService: TasksApiService
  ) {
    this.isAdmin = this._authenticationService.user.roles.includes(
      UserRoles.Admin
    );

    this._userListService.users$.subscribe((users) => {
      this.allUsers = users;
    });
  }

  public ngAfterViewInit(): void {
    this.loading.present();
    this._tenantService.tenant$.pipe(take(1)).subscribe((tenant) => {
      this.disableAttachments = !tenant.features.suggestionAttachments;

      if (tenant.tasks.suggestionSubmit) {
        this._tasksApiService
          .getTaskById({
            path: { id: tenant.tasks.suggestionSubmit.toString() }
          })
          .subscribe((suggestionSubmitTask) => {
            this.postOpportunityPoints = suggestionSubmitTask.points;
          });
      }

      this._suggestionCategoryApiService
        .getSuggestionCategories({
          query: { tenantId: tenant._id }
        })
        .subscribe({
          next: (categories) => {
            this.categories = categories;
            this.loading.dismiss();
          },
          error: (e) => {
            this.loading.dismiss();
            this._toastService.presentToast(
              e.error?.message || 'Unable to fetch suggestion categories'
            );
          }
        });
    });
  }

  public ngOnInit(): void {
    if (this.suggestion) {
      this.mainFormGroup.reset({
        ...this.suggestion,
        author: this.suggestion.author?._id || null,
        category: this.suggestion.category || null
      });
      this.body = formatUploadedBody(
        this.suggestion.body,
        this.suggestion.attachments,
        this._environmentService.serverUrl
      );
    } else {
      this.mainFormGroup.patchValue({
        author: this._authenticationService.user._id
      });
    }
  }

  protected get selectedUserIds(): ObjectId[] {
    const author = this.mainFormGroup.get('author').value;
    return author ? [author] : [];
  }

  protected get formattedAuthor(): string {
    const user = this.allUsers.find(
      (user) => user._id === this.mainFormGroup.get('author').value
    );
    return user ? `${user.firstName} ${user.lastName}` : '';
  }

  protected onUserChange(event: ObjectId[]): void {
    this.mainFormGroup.controls.author.setValue(event[0]);
    this.userSelectorModal.dismiss();
  }

  protected onBackButtonClick() {
    this._ionNav.pop();
  }

  protected async onFormSubmit() {
    this.loading.present();

    this._uploadFileApiService
      .extractAttachmentsFromBody(
        this.body || '',
        this.suggestion?.attachments || []
      )
      .subscribe({
        next: ({ body, newAttachments, keptAttachments }) => {
          if (this.suggestion) {
            this._suggestionApiService
              .updateOpportunity({
                path: { opportunityId: this.suggestion._id.toString() },
                body: {
                  status: this.mainFormGroup.get('status').value,
                  body,
                  isPublic: this.mainFormGroup.get('isPublic').value,
                  author: this.mainFormGroup.get('author').value,
                  category: this.mainFormGroup.get('category').value,
                  attachments: [
                    ...keptAttachments,
                    ...newAttachments.map((a) => a._id.toString())
                  ]
                }
              })
              .subscribe({
                next: () => {
                  this._toastService.presentToast(
                    'Suggestion Successfully Updated!'
                  );
                  this._ionNav.pop().then(() => this._ionNav.pop());
                },
                error: (e) => {
                  this.loading.dismiss();
                  this._toastService.presentToast(
                    e.error?.message || 'Unable to update suggestion'
                  );
                }
              });
          } else {
            if (!this.parentId) {
              this._suggestionApiService
                .postOpportunity({
                  body: {
                    isPublic: this.mainFormGroup.get('isPublic').value,
                    body,
                    attachments: [
                      ...keptAttachments,
                      ...newAttachments.map((a) => a._id.toString())
                    ],
                    status: this.mainFormGroup.get('status').value,
                    author: this.mainFormGroup.get('author').value,
                    category: this.mainFormGroup.get('category').value,
                    tenant: this._authenticationService.user.tenant
                  }
                })
                .subscribe({
                  next: () => {
                    this._userEntityService.update();
                    this._toastService.presentToast('Suggestion Submitted!', {
                      okText: this.postOpportunityPoints
                        ? `+${this.postOpportunityPoints} points`
                        : ''
                    });
                    this._ionNav.pop();
                  },
                  error: (e) => {
                    this.loading.dismiss();
                    this._toastService.presentToast(
                      e.error?.message || 'Unable to submit suggestion'
                    );
                  }
                });
            } else {
              this._suggestionApiService
                .postSolution({
                  path: { opportunityId: this.parentId },
                  body: {
                    isPublic: this.mainFormGroup.get('isPublic').value,
                    body,
                    attachments: [
                      ...keptAttachments,
                      ...newAttachments.map((a) => a._id.toString())
                    ]
                  }
                })
                .subscribe({
                  next: () => {
                    this._toastService.presentToast(
                      'Comment Successfully Added!'
                    );
                    this._ionNav.pop();
                  },
                  error: (e) => {
                    this.loading.dismiss();
                    this._toastService.presentToast(
                      e.error?.message || 'Unable to submit suggestion'
                    );
                  }
                });
            }
          }
        },
        error: (e) => {
          console.log(e);
          this.loading.dismiss();
          this._toastService.presentToast('Unable to upload attachments');
        }
      });
  }

  protected get publicInfo() {
    return this.mainFormGroup.get('isPublic').value
      ? 'Everybody on the team can view'
      : 'Only visible to Managers & Admins';
  }

  protected get canSave() {
    if (!this.parentId) {
      return this.body && !this.mainFormGroup.invalid;
    } else {
      return this.body;
    }
  }

  protected async onDeleteClick() {
    this.loading.present();

    this._suggestionApiService
      .deleteOpportunity({ path: { opportunityId: this.suggestion._id } })
      .subscribe(() => {
        this._toastService.presentToast('Suggestion Successfully Deleted');
        this._ionNav.pop().then(() => this._ionNav.pop());
      });
  }
}
