import { NgModule } from '@angular/core';
import { BehaviorSelectorModalComponent } from './behavior-selector-modal.component';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DirectivesModule } from '@app/modules/directives.module';
import { FullHeightContainerModule } from '@app/modules/full-height-container/full-height-container.module';
import { FilterBehaviorsPipe } from './filter-behaviors.pipe';
import { ValuesModalModule } from '../values-modal/values-modal.module';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    ReactiveFormsModule,
    DirectivesModule,
    FullHeightContainerModule,
    FilterBehaviorsPipe,
    ValuesModalModule
  ],
  exports: [BehaviorSelectorModalComponent],
  declarations: [BehaviorSelectorModalComponent]
})
export class BehaviorSelectorModalModule {}
