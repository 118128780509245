// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_deleteLedger = (path: TSpecPathDeleteLedger) => {
  let res = "/ledger/:id";
  Object.entries(path).map(([key, value]) => {
    res = res.replace(':' + key, value);
  });
  return res;
};

export type TSpecHandler_deleteLedger = RequestHandler<TSpecPathDeleteLedger, any, TSpecBodyDeleteLedger, TSpecQueryDeleteLedger, {}>;

export interface TSpecPathDeleteLedger { id: string; }

export interface TSpecBodyDeleteLedger {}

export interface TSpecQueryDeleteLedger {}
