import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MongoStoredObject } from '@app/types/mongo-stored-object';
import {
  IPsychologicalSurveyQuestion,
  ITeamEvaluationParams
} from '@backend/models/types/psychological-survey-question';
import { ITeamEvaluationResult } from '@backend/models/types/psychological-survey-answer';
import { IPsychologicalSurvey } from '@backend/models/types/psychological-survey';
import { UserModel } from '@app/core/model/user.model';
import { PsychologicalSurveyApiService } from '@app/core/service/api/psychologicalSurvey.api.service';

@Component({
  selector: 'app-team-evaluation-question',
  templateUrl: './team-evaluation-question.component.html',
  styleUrls: ['./team-evaluation-question.component.scss']
})
export class TeamEvaluationQuestionComponent implements OnInit {
  @Input()
  public readonly cancelButton: 'menu' | 'cancel' = 'menu';

  @Input()
  public readonly survey: MongoStoredObject<IPsychologicalSurvey>;

  @Input()
  public readonly question: MongoStoredObject<
    IPsychologicalSurveyQuestion & { params: ITeamEvaluationParams }
  >;

  @Input()
  public readonly previousAnswer?: ITeamEvaluationResult = null;

  @Output()
  public completed = new EventEmitter<{
    questionId: string;
    result: ITeamEvaluationResult;
  }>();

  @Output()
  public goBack = new EventEmitter<void>();

  @Output()
  public cancelClicked = new EventEmitter<void>();

  protected result: ITeamEvaluationResult = {
    users: []
  };
  protected currentUser: number = 0;
  protected usersWhoAreWorkingToday: UserModel[] = [];
  protected isLoading = true;

  public constructor(
    private readonly _psychologicalSurveyApiService: PsychologicalSurveyApiService
  ) {
    this._psychologicalSurveyApiService
      .getUsersWhoWereWorkingLastPeriod()
      .subscribe((users) => {
        this.usersWhoAreWorkingToday = users;
        this.result.users = this.usersWhoAreWorkingToday.map((u) => ({
          userId: u._id,
          selectedEvaluationOptions: []
        }));
        this.isLoading = false;
      });
  }

  protected get questionTitle() {
    return this.question && this.usersWhoAreWorkingToday.length
      ? this.question.params.questionTitle.replace(
          '${firstName}',
          `<b>${this.usersWhoAreWorkingToday[this.currentUser].firstName}</b>`
        )
      : '';
  }

  public ngOnInit(): void {
    if (this.previousAnswer) {
      this.result = this.previousAnswer;
    }
  }

  protected completeQuestion() {
    this.completed.emit({
      questionId: this.question._id,
      result: this.result
    });
  }

  protected onBackButtonClick() {
    this.goBack.emit();
  }

  protected onCancelClick() {
    this.cancelClicked.emit();
  }

  protected toggleEvaluation(evaluationOption: string) {
    const user = this.result.users.find(
      (u) => u.userId === this.usersWhoAreWorkingToday[this.currentUser]._id
    );

    const exists = user.selectedEvaluationOptions.find(
      (option) => option === evaluationOption
    );

    if (exists) {
      user.selectedEvaluationOptions = user.selectedEvaluationOptions.filter(
        (option) => option !== evaluationOption
      );
    } else {
      user.selectedEvaluationOptions.push(evaluationOption);
    }
  }

  protected optionIsSelected(evaluationOption: string) {
    if (this.usersWhoAreWorkingToday.length) {
      const user = this.result.users.find(
        (u) => u.userId === this.usersWhoAreWorkingToday[this.currentUser]._id
      );

      return user.selectedEvaluationOptions.includes(evaluationOption);
    }

    return false;
  }

  protected prevUser() {
    this.currentUser--;
  }

  protected nextUser() {
    this.currentUser++;
  }

  protected get buttonText() {
    if (!this.usersWhoAreWorkingToday.length)
      return this.question.params.submitButtonText;

    const user = this.result.users.find(
      (u) => u.userId === this.usersWhoAreWorkingToday[this.currentUser]._id
    );

    return this.currentUser >= this.usersWhoAreWorkingToday.length - 1
      ? this.question.params.submitButtonText
      : user.selectedEvaluationOptions.length
      ? this.question.params.nextUserButtonText
      : this.question.params.skipUserButtonText;
  }
}
