import { Input, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CameraService } from '@app/core/service/camera.service';
import {
  IonModal,
  IonNav,
  IonPopover,
  LoadingController
} from '@ionic/angular';
import { format, startOfDay } from 'date-fns';
import { TagApiService } from '@app/core/service/api/tag.api.service';
import { MongoStoredObject } from '@app/types/mongo-stored-object';
import { ETagStatus, ITag } from '@backend/models/types/tag';
import { UploadFileApiService } from '@app/core/service/api/uploadFile.api.service';
import { dataURLtoFile, base64Resize } from '@app/utils/file';
import { IUploadedFile } from '@backend/types/uploaded-file';
import { UserListService } from '@app/core/service/user-list.service';
import { ObjectId } from '@app/types/object-id';
import { UserModel } from '@app/core/model/user.model';
import { ToastService } from '@app/core/service/toast.service';
import { TagsComponent } from './components/tags/tags.components';
import { UserEntityService } from '@app/core/service/user-entity.service';
import { isNumeric } from '@app/utils/is-numeric';
import { TenantService } from '@app/core/service/tenant.service';
import { ImageViewerService } from '@app/core/service/image-viewer.service';

const TAG_IMAGE_SIZE = 1280;
const ITEM_IMAGE_SIZE = 640;

@Component({
  selector: 'app-upload-tag',
  templateUrl: './upload-tag.component.html',
  styleUrls: ['./upload-tag.component.scss']
})
export class UploadTagComponent implements OnInit, OnDestroy {
  protected tagStatus = ETagStatus;

  @Input()
  public input: {
    tag: MongoStoredObject<
      ITag & { user: Pick<UserModel, 'firstName' | 'lastName'> }
    >;
  };

  @ViewChild('popover')
  protected popover: IonPopover;

  @ViewChild('userSelectorModal')
  public userSelectorModal!: IonModal;

  protected tag: MongoStoredObject<ITag>;
  protected mainFormGroup: FormGroup = new FormGroup({
    pickupDate: new FormControl<string>('', Validators.required),
    serverId: new FormControl<string>('', Validators.required),
    customerCell: new FormControl<string>('', [
      Validators.required,
      Validators.minLength(10)
    ]),
    customerName: new FormControl<string>(''),
    notes: new FormControl<string>(''),
    count: new FormControl<string>('', Validators.required)
  });
  protected isOpen = false;
  protected tagImage: IUploadedFile = null;
  protected productImages: IUploadedFile[] = [];
  protected allUsers: UserModel[] = [];
  protected pickupDateFieldName = '';
  protected customerNameEnabled = false;

  public constructor(
    private readonly _cameraService: CameraService,
    private readonly _tagApiService: TagApiService,
    private readonly _uploadFileApiService: UploadFileApiService,
    private readonly _toastService: ToastService,
    private readonly _loadingCtrl: LoadingController,
    private readonly _userListService: UserListService,
    private readonly _ionNav: IonNav,
    private readonly _userEntity: UserEntityService,
    private readonly _tenantService: TenantService,
    private readonly _imageViewerService: ImageViewerService
  ) {}

  public ngOnInit(): void {
    if (this.input) {
      this.mainFormGroup.reset({
        pickupDate: this.input.tag.pickupDate,
        serverId: this.input.tag.serverId,
        customerCell: this.input.tag.customerCell.replace(/\D/g, ''),
        customerName: this.input.tag.customerName,
        notes: this.input.tag.notes,
        count: this.input.tag.count
      });

      this.tagImage = this.input.tag.images.length
        ? this.input.tag.images[0]
        : null;

      this.productImages = this.input.tag.images.slice(1);
    } else {
      this._tagApiService
        .prepareTag({ body: { clientDate: new Date().toISOString() } })
        .subscribe((response) => {
          if (
            response.pickupDate &&
            !this.mainFormGroup.controls.pickupDate.value
          ) {
            this.mainFormGroup.controls.pickupDate.setValue(
              response.pickupDate
            );
          }
          if (
            response.serverId &&
            !this.mainFormGroup.controls.serverId.value
          ) {
            this.mainFormGroup.controls.serverId.setValue(response.serverId);
          }
        });
    }

    this._userListService.users$.subscribe((users) => {
      this.allUsers = users;
    });
    this._tenantService.tenant$.subscribe((tenant) => {
      this.pickupDateFieldName = tenant.settings.tags.pickupDateFieldName;
      this.customerNameEnabled = tenant.settings.tags.customerNameEnabled;

      if (tenant.settings.tags.customerNameEnabled) {
        this.mainFormGroup.controls.customerName.setValidators([
          Validators.required
        ]);
      }
    });
  }

  public ngOnDestroy(): void {
    // delete unused tag
    if (this.tag) {
      this._tagApiService
        .deleteMyReservedTag({ path: { tagId: this.tag._id } })
        .subscribe();
    }
  }

  protected async reserveTag() {
    const loading = await this._loadingCtrl.create({
      message: 'Loading...'
    });
    loading.present();

    this._tagApiService
      .reserveTag({ body: { clientDate: new Date().toISOString() } })
      .subscribe((response) => {
        this.tag = response.tag;
        loading.dismiss();
      });
  }

  protected get canSave() {
    return (
      this.mainFormGroup.valid && this.tagImage && this.productImages.length > 0
    );
  }

  protected get selectedUserIds(): ObjectId[] {
    return [this.mainFormGroup.get('serverId').value];
  }

  protected get formattedServer(): string {
    const user = this.allUsers.find(
      (user) => user._id === this.mainFormGroup.get('serverId').value
    );
    return user ? `${user.firstName} ${user.lastName}` : '';
  }

  protected onPickupDateInputClick(e: Event) {
    this.popover.event = e;
    this.isOpen = true;
  }

  protected onCalendarChange(event: any) {
    if (event.detail.value && typeof event.detail.value === 'string') {
      this.mainFormGroup.controls.pickupDate.setValue(
        format(startOfDay(new Date(event.detail.value)), 'M/d/yy')
      );
      this.isOpen = false;
    }
  }

  protected async takePicture(target: 'TAG' | 'PRODUCT') {
    this._cameraService.takePicture().subscribe(async (photo) => {
      if (photo) {
        const loading = await this._loadingCtrl.create({
          message: 'Processing...'
        });
        loading.present();

        base64Resize(
          photo.dataUrl,
          target === 'TAG' ? TAG_IMAGE_SIZE : ITEM_IMAGE_SIZE,
          (base64) => {
            const file = dataURLtoFile(base64, target);

            this._uploadFileApiService.uploadFile(file).subscribe((file) => {
              loading.dismiss();

              if (file.originalname === 'TAG') {
                this.tagImage = file;

                if (this.productImages.length === 0) {
                  this.takePicture('PRODUCT');
                }
              } else if (file.originalname === 'PRODUCT') {
                this.productImages.unshift(file);

                this.takePicture('PRODUCT');
              }
            });
          }
        );
      }
    });
  }

  protected async uploadTag() {
    const loading = await this._loadingCtrl.create({
      message: 'Uploading...'
    });
    loading.present();

    this._tagApiService
      .uploadTag({
        path: { tagId: this.tag._id },
        body: {
          pickupDate: this.mainFormGroup.get('pickupDate').value,
          serverId: this.mainFormGroup.get('serverId').value,
          customerCell: this._phoneFormat(
            this.mainFormGroup.get('customerCell').value
          ),
          customerName: this.mainFormGroup.get('customerName').value,
          notes: this.mainFormGroup.get('notes').value,
          count: Number(this.mainFormGroup.get('count').value),
          images: [this.tagImage, ...this.productImages]
        }
      })
      .subscribe((res) => {
        loading.dismiss();
        this._toastService.presentToast(res.message);
        this._userEntity.update();

        if (res.success) {
          this.tag = null;
          this.mainFormGroup.controls.customerCell.setValue('');
          this.mainFormGroup.controls.customerName.setValue('');
          this.mainFormGroup.controls.notes.setValue('');
          this.mainFormGroup.controls.count.setValue('');
          this.tagImage = null;
          this.productImages = [];
        }
      });
  }

  protected async updateTag() {
    const loading = await this._loadingCtrl.create({
      message: 'Updating...'
    });
    loading.present();

    this._tagApiService
      .updateTag({
        path: { tagId: this.input.tag._id },
        body: {
          pickupDate: this.mainFormGroup.get('pickupDate').value,
          customerCell: this._phoneFormat(
            this.mainFormGroup.get('customerCell').value
          ),
          customerName: this.mainFormGroup.get('customerName').value,
          notes: this.mainFormGroup.get('notes').value,
          serverId: this.mainFormGroup.get('serverId').value,
          count: Number(this.mainFormGroup.get('count').value),
          images: [this.tagImage, ...this.productImages]
        }
      })
      .subscribe((res) => {
        this._toastService.presentToast(res.message);
        this._userEntity.update();

        if (res.success) {
          this._ionNav.pop().then(() => this._ionNav.pop());
        } else {
          loading.dismiss();
        }
      });
  }

  protected async deletePhoto(blobName: string) {
    if (this.tagImage.blobName === blobName) {
      this.tagImage = null;
    } else {
      this.productImages = this.productImages.filter(
        (productImage) => productImage.blobName !== blobName
      );
    }
  }

  protected viewPhoto(index: number) {
    if (this.tagImage) {
      this._imageViewerService.viewImage(
        [
          this.tagImage.url,
          ...this.productImages.map((productImage) =>
            productImage.url.toString()
          )
        ],
        index + 1
      );
    } else {
      this._imageViewerService.viewImage(
        this.productImages.map((productImage) => productImage.url.toString()),
        index
      );
    }
  }

  protected productImageTrackBy(_index: number, productImage: IUploadedFile) {
    return productImage.blobName;
  }

  protected onUserChange(event: ObjectId[]): void {
    this.mainFormGroup.controls.serverId.setValue(event[0].toString());
    this.userSelectorModal.dismiss();
  }

  private _phoneFormat = (input: string) => {
    if (input.length === 10) {
      return input
        .replace(/\D/g, '')
        .replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
    } else {
      return input;
    }
  };

  protected onCustomerCellKeyDown(event: any) {
    event.preventDefault();

    if (
      event.key === 'Backspace' &&
      this.mainFormGroup.get('customerCell').value.length > 0
    ) {
      this.mainFormGroup.controls.customerCell.setValue(
        this.mainFormGroup.get('customerCell').value.slice(0, -1)
      );
    } else if (
      isNumeric(event.key) &&
      this.mainFormGroup.get('customerCell').value.length < 10
    ) {
      this.mainFormGroup.controls.customerCell.setValue(
        this.mainFormGroup.get('customerCell').value + event.key
      );
    }
  }

  protected viewTags() {
    this._ionNav.push(TagsComponent);
  }

  protected onBackButtonClick() {
    this._ionNav.pop();
  }
}
