export enum PayFrequency {
  EVERY_WEEK = 'EVERY_WEEK',
  EVERY_TWO_WEEKS = 'EVERY_TWO_WEEKS',
  ONCE_PER_MONTH = 'ONCE_PER_MONTH',
  TWICE_PER_MONTH = 'TWICE_PER_MONTH'
}

export interface PayrollDefinitionWeek {
  frequency: PayFrequency.EVERY_WEEK;
  date1: string;
}

export interface PayrollDefinitionTwoWeek {
  frequency: PayFrequency.EVERY_TWO_WEEKS;
  date1: string;
}

export interface PayrollDefinitionMonth {
  frequency: PayFrequency.ONCE_PER_MONTH;
  date1: string;
}

export interface PayrollDefinitionTwicePerMonth {
  frequency: PayFrequency.TWICE_PER_MONTH;
  date1: string;
  date2: string;
}

export type PayrollDefinition =
  | PayrollDefinitionWeek
  | PayrollDefinitionTwoWeek
  | PayrollDefinitionMonth
  | PayrollDefinitionTwicePerMonth;
