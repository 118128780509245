// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_getThreadOfAnnouncement = (path: TSpecPathGetThreadOfAnnouncement) => {
  let res = "/announcements/:id/thread";
  Object.entries(path).map(([key, value]) => {
    res = res.replace(':' + key, value);
  });
  return res;
};

export type TSpecHandler_getThreadOfAnnouncement = RequestHandler<TSpecPathGetThreadOfAnnouncement, any, TSpecBodyGetThreadOfAnnouncement, TSpecQueryGetThreadOfAnnouncement, {}>;

export interface TSpecPathGetThreadOfAnnouncement { id: string; }

export interface TSpecBodyGetThreadOfAnnouncement {}

export interface TSpecQueryGetThreadOfAnnouncement {}
