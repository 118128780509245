import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserModel } from '../model/user.model';
import { LocalStorageService } from '../local-storage.service';

@Injectable()
export class PasscodeApiService {
  public constructor(
    private readonly _httpClient: HttpClient,
    private readonly _localStorageService: LocalStorageService
  ) {}

  public getForgotPasscodeUsers(): Observable<UserModel[]> {
    const multiUserModeAuthToken = this._localStorageService.getItem(
      'multiUserModeAuthToken'
    );

    return this._httpClient.get<UserModel[]>('/users', {
      headers: { Authorization: `Bearer ${multiUserModeAuthToken || ''}` }
    });
  }

  public sendPincodeToEmail(
    userId: string
  ): Observable<{ result: { statusCode: number } }> {
    const multiUserModeAuthToken = this._localStorageService.getItem(
      'multiUserModeAuthToken'
    );

    return this._httpClient.post<{ result: { statusCode: number } }>(
      `/users/${userId}/send-pincode-to-email`,
      {},
      { headers: { Authorization: `Bearer ${multiUserModeAuthToken || ''}` } }
    );
  }
}
