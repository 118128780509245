// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_updateBehavior = (path: TSpecPathUpdateBehavior) => {
  let res = "/tenants/:tenantId/behavior/:id";
  Object.entries(path).map(([key, value]) => {
    res = res.replace(':' + key, value);
  });
  return res;
};

export type TSpecHandler_updateBehavior = RequestHandler<TSpecPathUpdateBehavior, any, TSpecBodyUpdateBehavior, TSpecQueryUpdateBehavior, {}>;

export interface TSpecPathUpdateBehavior { tenantId: string; id: string; }

export interface TSpecBodyUpdateBehavior {
  name: string;
  points: number;
  isValue: boolean;
  description: string;
  eligible: UserRoles[];
}

export enum UserRoles {
  Admin = "Admin",
  Manager = "Manager"
}

export interface TSpecQueryUpdateBehavior {}
