import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { map } from 'rxjs';
import { UserEntityService } from '../service/user-entity.service';
import { UserRoles } from '@backend/models/types/user';

export const adminGuard = () => {
  const router = inject(Router);
  const userEntity = inject(UserEntityService);
  return userEntity.user$.pipe(
    map((user) => {
      if (user.roles.includes(UserRoles.Admin)) {
        return true;
      }
      return router.createUrlTree(['/']);
    })
  );
};
