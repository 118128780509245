// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_getCompensationAnnouncementPreview = () => "/compensation/announcement-preview";

export type TSpecHandler_getCompensationAnnouncementPreview = RequestHandler<TSpecPathGetCompensationAnnouncementPreview, any, TSpecBodyGetCompensationAnnouncementPreview, TSpecQueryGetCompensationAnnouncementPreview, {}>;

export interface TSpecPathGetCompensationAnnouncementPreview {  }

export interface TSpecBodyGetCompensationAnnouncementPreview {
  userId: string;
  compensation: {
    includePointsFrom: EIncludePointsFrom[];
    range: {
      start: string;
      end: string;
    };
    fields: string[];
    pools: {
      pool: string;
      value: number;
    }[];
    usersData: {
      firstName: string;
      lastName: string;
      compensationEmployeeId: string;
      totalPointsEarned: number;
      totalHoursWorked: number;
      notify: boolean;
      user: string;
      pools: {
        pool: string;
        value: number;
        isEligible: boolean;
      }[];
    }[];
  };
}

export enum EIncludePointsFrom {
  tasksCompleted = "tasksCompleted",
  pointsSent = "pointsSent",
  pointsReceived = "pointsReceived"
}

export interface TSpecQueryGetCompensationAnnouncementPreview {}
