<ion-header class="ion-no-border">
  <ion-toolbar class="app-toolbar">
    <ion-buttons slot="start">
      <ion-button (click)="onBackButtonClick()" appHaptics>
        <ion-icon slot="icon-only" name="chevron-back-outline"></ion-icon>
        <ion-label>Back</ion-label>
      </ion-button>
    </ion-buttons>
    <ion-title>Invite User</ion-title>
    <ion-buttons slot="end">
      <ion-button
        color="primary"
        [disabled]="mainFormGroup.invalid"
        appHaptics
        [disableHaptics]="mainFormGroup.invalid"
        (click)="inviteUser()"
      >
        Invite
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content color="light">
  <app-width-restricted-container
    class="container-with-fab"
    [formGroup]="mainFormGroup"
  >
    <ion-list [inset]="true">
      <ion-item>
        <ion-input
          label="Email"
          formControlName="email"
          labelPlacement="stacked"
          type="email"
        />
      </ion-item>
      <ion-item>
        <ion-select
          label="Role"
          labelPlacement="stacked"
          formControlName="role"
          appHaptics
          interface="popover"
        >
          <ion-select-option [value]="role" *ngFor="let role of roleOptions">
            {{ role }}
          </ion-select-option>
        </ion-select>
      </ion-item>
    </ion-list>
    <app-list-post-line *ngIf="emailError" [text]="emailError" color="danger" />

    <ion-list [inset]="true">
      <ion-item>
        <ion-input
          label="Supervisor (optional)"
          labelPlacement="stacked"
          [value]="formattedSupervisor"
          [readonly]="true"
          id="selectSupervisor"
          appHaptics
          [disableHaptics]="!!formattedSupervisor"
        />
        <ion-button
          *ngIf="formattedSupervisor"
          fill="clear"
          (click)="handleSupervisorClearClick()"
          appHaptics
          class="ion-no-margin"
        >
          <ion-icon slot="icon-only" name="close-circle" class="input-icon" />
        </ion-button>
      </ion-item>
      <ion-item *ngIf="departments.length > 0">
        <ion-select
          label="Department"
          interface="popover"
          placeholder="Select Department"
          aria-label="Department"
          formControlName="department"
          labelPlacement="stacked"
          appHaptics
        >
          <ion-select-option [value]="null">No Department</ion-select-option>
          <ion-select-option
            *ngFor="let department of departments"
            [value]="department._id"
          >
            {{ department.name }}
          </ion-select-option>
        </ion-select>
      </ion-item>
    </ion-list>

    <div *ngIf="tenantFeatures.calculateRewards">
      <app-list-pre-line title="Compensation" />
      <ion-list [inset]="true">
        <ion-item>
          <ion-input
            label="Employee ID from Payroll Provider (optional)"
            labelPlacement="stacked"
            formControlName="compensationEmployeeId"
            autocapitalize="on"
          />
        </ion-item>
        <ion-item *ngIf="compensationPools.length > 0">
          <ion-select
            label="Compensation Pools (optional)"
            labelPlacement="stacked"
            formControlName="compensationPools"
            appHaptics
            [multiple]="true"
          >
            <ion-select-option
              [value]="compensationPool._id"
              *ngFor="let compensationPool of compensationPools"
            >
              {{ compensationPool.name }}
            </ion-select-option>
          </ion-select>
        </ion-item>
      </ion-list>
    </div>
  </app-width-restricted-container>
</ion-content>

<ion-loading #loading message="Loading..." />

<ion-modal
  trigger="selectSupervisor"
  #supervisorSelectorModal
  [handle]="false"
  [breakpoints]="[0, 1]"
  [initialBreakpoint]="1"
>
  <ng-template>
    <app-user-selector
      title="Supervisor"
      class="ion-page"
      [users]="allUsers"
      [recipients]="selectedUserIds"
      (selectionChange)="onSupervisorChange($event)"
      (selectionCancel)="supervisorSelectorModal.dismiss()"
    ></app-user-selector>
  </ng-template>
</ion-modal>
