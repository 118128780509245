import { Types } from 'mongoose';

export enum EPsychologicalSurveyQuestionType {
  POSITIVE_FEEDBACK = 'POSITIVE_FEEDBACK',
  NEGATIVE_FEEDBACK = 'NEGATIVE_FEEDBACK',
  MOOD_CHECK = 'MOOD_CHECK',
  TEAM_EVALUATION = 'TEAM_EVALUATION'
}

export interface IPositiveFeedbackParams {
  questionTitle: string;
  questionTitleLastShift: string;
  emoji: string;
  // skipButtonText: string;
  // skipButtonTextLastShift: string;
  submitButtonText: string;
  behaviorsSelectorTitle: string;
  recognitionTitleHtml: string;
}

export interface INegativeFeedbackParams {
  questionTitle: string;
  questionTitleLastShift: string;
  emoji: string;
  // skipButtonText: string;
  // skipButtonTextLastShift: string;
  skipConfirmationTitle: string;
  submitButtonText: string;
  confidentialTitleHtml: string;
  confidentialBodyHtml: string;
  generalFeedbackTitle: string;
  generalFeedbackNotice: string;
  behaviorsSelectorTitle: string;
  behaviorsSelectorNotice: string;
  noticeHtml: string;
}

export interface IMoodCheckParams {
  questionTitle: string;
  options: {
    text: string;
    emoji: string;
    value: number;
  }[];
}

export interface ITeamEvaluationParams {
  questionTitle: string;
  questionSubtitle: string;
  options: {
    text: string;
    orderIndex: number;
    emoji: string;
  }[];
  submitButtonText: string;
  nextUserButtonText: string;
  prevUserButtonText: string;
  skipUserButtonText: string;
}

export type IPsychologicalSurveyQuestionParams =
  | IPositiveFeedbackParams
  | INegativeFeedbackParams
  | IMoodCheckParams
  | ITeamEvaluationParams;

export interface IPsychologicalSurveyQuestion {
  survey: Types.ObjectId;
  orderIndex: number;
  type: EPsychologicalSurveyQuestionType;
  params: IPsychologicalSurveyQuestionParams;
  isHidden: boolean;
}
