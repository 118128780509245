// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_acknowledgeTaskPerformanceNote = (path: TSpecPathAcknowledgeTaskPerformanceNote) => {
  let res = "/task-performance/:id/acknowledge-note";
  Object.entries(path).map(([key, value]) => {
    res = res.replace(':' + key, value);
  });
  return res;
};

export type TSpecHandler_acknowledgeTaskPerformanceNote = RequestHandler<TSpecPathAcknowledgeTaskPerformanceNote, any, TSpecBodyAcknowledgeTaskPerformanceNote, TSpecQueryAcknowledgeTaskPerformanceNote, {}>;

export interface TSpecPathAcknowledgeTaskPerformanceNote { id: string; }

export interface TSpecBodyAcknowledgeTaskPerformanceNote {
  forAllUsers: boolean;
}

export interface TSpecQueryAcknowledgeTaskPerformanceNote {}
