import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MongoStoredObject } from '@app/types/mongo-stored-object';
import { IPsychologicalSurvey } from '@backend/models/types/psychological-survey';
import { IPsychologicalSurveyQuestion } from '@backend/models/types/psychological-survey-question';
import { IPsychologicalSurveyBehavior } from '@backend/models/types/psychological-survey-behavior';
import { IFeedbackStatsResult } from '@backend/types/psychological-survey-feedback-stats';
import { TSpecRoute_getAllPsychologicalSurveys } from '@backend/types/generated/PsychologicalSurvey/getAllPsychologicalSurveys';
import { TSpecRoute_getCurrentPsychologicalSurvey } from '@backend/types/generated/PsychologicalSurvey/getCurrentPsychologicalSurvey';
import {
  TSpecQueryGetUnansweredPsychologicalSurveys,
  TSpecRoute_getUnansweredPsychologicalSurveys
} from '@backend/types/generated/PsychologicalSurvey/getUnansweredPsychologicalSurveys';
import {
  TSpecBodyAnswerToPsychologicalSurvey,
  TSpecPathAnswerToPsychologicalSurvey,
  TSpecRoute_answerToPsychologicalSurvey
} from '@backend/types/generated/PsychologicalSurvey/answerToPsychologicalSurvey';
import {
  TSpecPathGetPsychologicalSurveyBehaviors,
  TSpecRoute_getPsychologicalSurveyBehaviors
} from '@backend/types/generated/PsychologicalSurvey/getPsychologicalSurveyBehaviors';
import {
  TSpecQueryGetFeedbackStats,
  TSpecRoute_getFeedbackStats
} from '@backend/types/generated/PsychologicalSurvey/getFeedbackStats';
import { UserModel } from '@app/core/model/user.model';
import { TSpecRoute_getUsersWhoWereWorkingLastPeriod } from '@backend/types/generated/PsychologicalSurvey/getUsersWhoWereWorkingLastPeriod';

@Injectable()
export class PsychologicalSurveyApiService {
  public constructor(private readonly _httpClient: HttpClient) {}

  public getAllPsychologicalSurveys(): Observable<
    {
      survey: MongoStoredObject<IPsychologicalSurvey>;
      questions: MongoStoredObject<IPsychologicalSurveyQuestion>[];
    }[]
  > {
    return this._httpClient.get<
      {
        survey: MongoStoredObject<IPsychologicalSurvey>;
        questions: MongoStoredObject<IPsychologicalSurveyQuestion>[];
      }[]
    >(TSpecRoute_getAllPsychologicalSurveys());
  }

  public getCurrentPsychologicalSurvey(): Observable<{
    survey: MongoStoredObject<IPsychologicalSurvey>;
    questions: MongoStoredObject<IPsychologicalSurveyQuestion>[];
  }> {
    return this._httpClient.get<{
      survey: MongoStoredObject<IPsychologicalSurvey>;
      questions: MongoStoredObject<IPsychologicalSurveyQuestion>[];
    }>(TSpecRoute_getCurrentPsychologicalSurvey());
  }

  public getUnansweredPsychologicalSurveys(params: {
    query: TSpecQueryGetUnansweredPsychologicalSurveys;
  }): Observable<{
    items: {
      survey: MongoStoredObject<IPsychologicalSurvey>;
      questions: MongoStoredObject<IPsychologicalSurveyQuestion>[];
    }[];
    count: number;
    totalCount: number;
  }> {
    return this._httpClient.get<{
      items: {
        survey: MongoStoredObject<IPsychologicalSurvey>;
        questions: MongoStoredObject<IPsychologicalSurveyQuestion>[];
      }[];
      count: number;
      totalCount: number;
    }>(TSpecRoute_getUnansweredPsychologicalSurveys(), {
      params: { ...params.query }
    });
  }

  public answerToPsychologicalSurvey(params: {
    path: TSpecPathAnswerToPsychologicalSurvey;
    body: TSpecBodyAnswerToPsychologicalSurvey;
  }): Observable<MongoStoredObject<IPsychologicalSurvey>> {
    return this._httpClient.post<MongoStoredObject<IPsychologicalSurvey>>(
      TSpecRoute_answerToPsychologicalSurvey(params.path),
      params.body
    );
  }

  public getPsychologicalSurveyBehaviors(params: {
    path: TSpecPathGetPsychologicalSurveyBehaviors;
  }): Observable<MongoStoredObject<IPsychologicalSurveyBehavior>[]> {
    return this._httpClient.get<
      MongoStoredObject<IPsychologicalSurveyBehavior>[]
    >(TSpecRoute_getPsychologicalSurveyBehaviors(params.path));
  }

  public getFeedbackStats(params: {
    query: TSpecQueryGetFeedbackStats;
  }): Observable<IFeedbackStatsResult> {
    return this._httpClient.get<IFeedbackStatsResult>(
      TSpecRoute_getFeedbackStats(),
      { params: { ...params.query } }
    );
  }

  public getUsersWhoWereWorkingLastPeriod(): Observable<UserModel[]> {
    return this._httpClient.get<UserModel[]>(
      TSpecRoute_getUsersWhoWereWorkingLastPeriod()
    );
  }
}
