import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ChangeEmailComponent } from './change-email.component';
import { IonicModule } from '@ionic/angular';
import { NavBaseComponent } from '@app/modules/nav-base/nav-base.component';

const ROUTES: Routes = [
  {
    path: '',
    component: NavBaseComponent,
    data: {
      title: 'Change Email',
      rootComponent: ChangeEmailComponent
    }
  }
];

@NgModule({
  imports: [NavBaseComponent, RouterModule.forChild(ROUTES), IonicModule],
  declarations: [ChangeEmailComponent]
})
export class ChangeEmailModule {}
