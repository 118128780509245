// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_getCompensations = () => "/compensation";

export type TSpecHandler_getCompensations = RequestHandler<TSpecPathGetCompensations, any, TSpecBodyGetCompensations, TSpecQueryGetCompensations, {}>;

export interface TSpecPathGetCompensations {  }

export interface TSpecBodyGetCompensations {}

export interface TSpecQueryGetCompensations {}
