import { Component, Input, OnInit, ViewChild } from '@angular/core';
import {
  IonNav,
  ModalController,
  IonModal,
  IonInfiniteScroll,
  ViewWillLeave
} from '@ionic/angular';
import { InsightsRangeModalComponent } from '@app/modules/insights-range-modal/insights-range-modal.component';
import {
  EditTaskPerformanceModalComponent,
  IEditTaskPerformanceModalResult
} from '@app/modules/edit-task-performance-modal/edit-task-performance-modal.component';
import { DateInterval } from '@backend/types/date-interval';
import { AuthenticationService } from '@app/core';
import { UserApiService } from '@app/core/service/api/user.api.service';
import { MongoStoredObject } from '@app/types/mongo-stored-object';
import { ITaskPerformance } from '@backend/models/types/task-performance';
import { TaskPerformanceApiService } from '@app/core/service/api/taskPerformance.api.service';
import { ObjectId } from '@app/types/object-id';
import { ITask } from '@backend/models/types/task';
import { UserModel } from '@app/core/model/user.model';
import { TaskDto } from '@app/types/task-dto';
import { TaskListService } from '@app/core/service/task-list.service';
import { format, isSameDay } from 'date-fns';
import { ILedger } from '@backend/models/types/ledger';
import { UserEntityService } from '@app/core/service/user-entity.service';
import { UserRoles } from '@backend/models/types/user';
import { TenantService } from '@app/core/service/tenant.service';
import { take } from 'rxjs';

@Component({
  selector: 'app-tasks-completed',
  templateUrl: './tasks-completed.component.html',
  styleUrls: ['./tasks-completed.component.scss']
})
export class TasksCompletedComponent implements OnInit, ViewWillLeave {
  @Input()
  public userId: ObjectId;

  @Input()
  public fullName: string;

  @Input()
  public defaultRange: DateInterval;

  @ViewChild('taskSelectorModal', { static: true })
  public taskSelectorModal!: IonModal;

  @ViewChild('infiniteScroll')
  public infiniteScroll: IonInfiniteScroll;

  protected range: DateInterval;
  protected user: UserModel;
  protected taskPerformances: MongoStoredObject<
    ITaskPerformance & {
      task: MongoStoredObject<ITask>;
      ledgers: MongoStoredObject<ILedger>[];
    }
  >[] = [];
  protected tasks: MongoStoredObject<TaskDto>[] = [];
  protected page = 1;
  protected pageSize = 20;
  protected isLoading = true;
  protected hasNextPage = true;
  protected isAdminOrManager = false;
  protected tenantId: ObjectId = null;

  public constructor(
    private readonly _ionNav: IonNav,
    private readonly _modalCtrl: ModalController,
    private readonly _userApiService: UserApiService,
    private readonly _taskPerformanceApiService: TaskPerformanceApiService,
    private readonly _taskListService: TaskListService,
    private readonly _userEntityService: UserEntityService,
    private readonly _authenticationService: AuthenticationService,
    private readonly _tenantService: TenantService
  ) {
    this.isAdminOrManager =
      (this._authenticationService.user?.roles.includes(UserRoles.Admin) ||
        this._authenticationService.user?.roles.includes(UserRoles.Manager)) ??
      false;
    this._tenantService.tenant$
      .pipe(take(1))
      .subscribe((tenant) => (this.tenantId = tenant._id));
  }

  protected get isOneDayRange() {
    return isSameDay(this.range.start, this.range.end);
  }

  public ngOnInit(): void {
    this._userApiService
      .getAllUsersWithDeleted({ path: { tenantId: this.tenantId } })
      .subscribe((users) => {
        this.user = users.find((u) => u._id === this.userId);
        this.range = this.defaultRange;
        this._fetchTaskPerformances();
      });

    this._taskListService.list$.subscribe((tasks) => {
      this.tasks = tasks.filter((task) => !task.isDeleted);
    });
  }

  public async ionViewWillLeave() {
    const curPage = await this._ionNav.getActive();
    curPage.params = { range: this.range };
  }

  protected onIonInfinite() {
    this.page = this.page + 1;
    this._fetchTaskPerformances();
  }

  private _fetchTaskPerformances(reset = false): void {
    if (reset) {
      this.isLoading = true;
      this.page = 1;
      this.hasNextPage = true;
      this.taskPerformances = [];
    }

    this._userApiService
      .getTaskPerformances({
        path: { id: this.userId },
        query: {
          from: this.range.start.toISOString(),
          to: this.range.end.toISOString(),
          page: this.page.toString(),
          limit: this.pageSize.toString()
        }
      })
      .subscribe((taskPerformances) => {
        this.hasNextPage = taskPerformances.length === this.pageSize;
        this.taskPerformances = this.taskPerformances.concat(taskPerformances);
        this.isLoading = false;
        this.infiniteScroll?.complete();
      });
  }

  protected async onRangeClick() {
    const dateRangeModal = await this._modalCtrl.create({
      id: 'insights-range-modal',
      component: InsightsRangeModalComponent,
      cssClass: 'modal-auto-height',
      breakpoints: [0, 1],
      initialBreakpoint: 1,
      componentProps: {
        range: this.range
      },
      handle: false
    });
    dateRangeModal.present();

    const { data, role } = await dateRangeModal.onWillDismiss();

    if (role === 'confirm') {
      this.range = data;
      this._fetchTaskPerformances(true);
    }
  }

  protected listItemTrackBy(
    _index: number,
    item: MongoStoredObject<
      ITaskPerformance & {
        task: MongoStoredObject<ITask>;
        ledgers: MongoStoredObject<ILedger>[];
      }
    >
  ) {
    return item._id;
  }

  protected async onItemClick(
    item: MongoStoredObject<
      ITaskPerformance & {
        task: MongoStoredObject<ITask>;
        ledgers: MongoStoredObject<ILedger>[];
      }
    >
  ) {
    const bonusLedger = item.ledgers.find((l) =>
      l.description.endsWith(' (BONUS)')
    );

    const editTaskPerformanceModal = await this._modalCtrl.create({
      id: 'edit-task-performance-modal',
      component: EditTaskPerformanceModalComponent,
      cssClass: 'modal-auto-height',
      breakpoints: [0, 1],
      initialBreakpoint: 1,
      componentProps: {
        taskId: item.task._id,
        initialData: {
          userId: this.user._id,
          startDate: new Date(item.startTime || item.completeTime),
          startTime: item.startTime
            ? format(new Date(item.startTime), 'HH:mm')
            : '00:00',
          completeDate: new Date(item.completeTime),
          completeTime: format(new Date(item.completeTime), 'HH:mm'),
          quantity: item.quantity,
          timeAttackBonusPoints: bonusLedger?.points || 0,
          isTimeAttack: !!item.startTime,
          isDeleted: item.isDeleted
        },
        createMode: false
      },
      handle: false
    });
    editTaskPerformanceModal.present();

    const { data, role } = await editTaskPerformanceModal.onWillDismiss();

    if (role === 'confirm') {
      const editedPerformance: IEditTaskPerformanceModalResult = data;

      const startTime = editedPerformance.startDate;
      const [h1, m1] = editedPerformance.startTime.split(':');
      startTime.setHours(Number(h1));
      startTime.setMinutes(Number(m1));

      const completeTime = editedPerformance.completeDate;
      const [h2, m2] = editedPerformance.completeTime.split(':');
      completeTime.setHours(Number(h2));
      completeTime.setMinutes(Number(m2));

      this._taskPerformanceApiService
        .patchTaskPerformances({
          body: {
            taskId: item.task._id,
            performances: [
              {
                _id: item._id,
                user: editedPerformance.userId,
                body: {
                  isDeleted: editedPerformance.isDeleted,
                  startTime: editedPerformance.isTimeAttack
                    ? startTime.toISOString()
                    : null,
                  completeTime: completeTime.toISOString(),
                  quantity: editedPerformance.quantity,
                  timeAttackBonusPoints: editedPerformance.timeAttackBonusPoints
                },
                isNew: false
              }
            ]
          }
        })
        .subscribe(() => {
          this._fetchTaskPerformances(true);
          this._userEntityService.update();
        });
    }
  }

  protected async onTaskSelect(value: ObjectId[]) {
    if (value.length) {
      this.taskSelectorModal.dismiss();

      const createTaskPerformanceModal = await this._modalCtrl.create({
        id: 'edit-task-performance-modal',
        component: EditTaskPerformanceModalComponent,
        cssClass: 'modal-auto-height',
        breakpoints: [0, 1],
        initialBreakpoint: 1,
        componentProps: {
          taskId: value[0],
          initialData: {
            userId: this.user._id,
            startTime: '10:00',
            startDate: new Date(this.range.end),
            completeDate: new Date(this.range.end),
            completeTime: '12:00',
            quantity: 1,
            timeAttackBonusPoints: 0,
            isTimeAttack: false,
            isDeleted: false
          },
          createMode: true
        },
        handle: false
      });
      createTaskPerformanceModal.present();

      const { data, role } = await createTaskPerformanceModal.onWillDismiss();

      if (role === 'confirm') {
        const createdPerformance: IEditTaskPerformanceModalResult = data;

        const startTime = createdPerformance.startDate;
        const [h1, m1] = createdPerformance.startTime.split(':');
        startTime.setHours(Number(h1));
        startTime.setMinutes(Number(m1));

        const completeTime = createdPerformance.completeDate;
        const [h2, m2] = createdPerformance.completeTime.split(':');
        completeTime.setHours(Number(h2));
        completeTime.setMinutes(Number(m2));

        this._taskPerformanceApiService
          .patchTaskPerformances({
            body: {
              taskId: value[0],
              performances: [
                {
                  _id: 'NEW_TASK_PERFORMACE',
                  user: createdPerformance.userId,
                  body: {
                    startTime: createdPerformance.isTimeAttack
                      ? startTime.toISOString()
                      : null,
                    completeTime: completeTime.toISOString(),
                    quantity: createdPerformance.quantity,
                    timeAttackBonusPoints:
                      createdPerformance.timeAttackBonusPoints
                  },
                  isNew: true
                }
              ]
            }
          })
          .subscribe(() => {
            this._fetchTaskPerformances(true);
          });
      }
    }
  }

  protected async onBackButtonClick() {
    this._ionNav.pop();
  }
}
