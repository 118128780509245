// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_getAllOpportunities = () => "/suggestions/opportunities";

export type TSpecHandler_getAllOpportunities = RequestHandler<TSpecPathGetAllOpportunities, any, TSpecBodyGetAllOpportunities, TSpecQueryGetAllOpportunities, {}>;

export interface TSpecPathGetAllOpportunities {  }

export interface TSpecBodyGetAllOpportunities {}

export interface TSpecQueryGetAllOpportunities {
  page?: string;
  limit?: string;
  searchTerm?: string;
}
