export enum EPsychologicalFeedbackView {
  SINGLE_BAR_VIEW = 'SINGLE_BAR_VIEW',
  DOUBLE_BAR_VIEW = 'DOUBLE_BAR_VIEW'
}

export interface IPsychologicalSurvey {
  title: string;
  dateCreated: Date;
  forceFeedbackView?: EPsychologicalFeedbackView;
}
