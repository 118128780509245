import {
  ChangeDetectionStrategy,
  Component,
  Input,
  Output,
  EventEmitter
} from '@angular/core';

@Component({
  selector: 'app-list-pre-line',
  templateUrl: './list-pre-line.component.html',
  styleUrls: ['./list-pre-line.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ListPreLineComponent {
  @Input()
  public readonly title: string = '';

  @Input()
  public readonly showAddButton: boolean = false;

  @Output()
  public addClicked = new EventEmitter<void>();
}
