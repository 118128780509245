// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_getIntegration = (path: TSpecPathGetIntegration) => {
  let res = "/integrations/:provider";
  Object.entries(path).map(([key, value]) => {
    res = res.replace(':' + key, value);
  });
  return res;
};

export type TSpecHandler_getIntegration = RequestHandler<TSpecPathGetIntegration, any, TSpecBodyGetIntegration, TSpecQueryGetIntegration, {}>;

export interface TSpecPathGetIntegration { provider: string; }

export interface TSpecBodyGetIntegration {}

export interface TSpecQueryGetIntegration {
  tenantId: string;
}
