<div class="survey-item">
  <h2
    class="ion-text-center heading ion-no-margin"
    [innerHTML]="titleHtml"
  ></h2>
  <ion-button
    fill="outline"
    size="small"
    class="ion-no-margin"
    appHaptics
    (click)="buttonClicked.emit()"
  >
    Respond
  </ion-button>
</div>
