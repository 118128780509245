// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_updateTaskFolder = (path: TSpecPathUpdateTaskFolder) => {
  let res = "/task-folders/:taskFolderId";
  Object.entries(path).map(([key, value]) => {
    res = res.replace(':' + key, value);
  });
  return res;
};

export type TSpecHandler_updateTaskFolder = RequestHandler<TSpecPathUpdateTaskFolder, any, TSpecBodyUpdateTaskFolder, TSpecQueryUpdateTaskFolder, {}>;

export interface TSpecPathUpdateTaskFolder { taskFolderId: string; }

export interface TSpecBodyUpdateTaskFolder {
  title: string;
  notifyUsersOnAllCriticalTasksCompleted: string[];
  emailUsersOnAllCriticalTasksCompleted: boolean;
}

export interface TSpecQueryUpdateTaskFolder {}
