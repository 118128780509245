import { NgModule } from '@angular/core';
import { CompensationFieldModalComponent } from './compensation-field-modal.component';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DirectivesModule } from '@app/modules/directives.module';
import { FullHeightContainerModule } from '@app/modules/full-height-container/full-height-container.module';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    ReactiveFormsModule,
    DirectivesModule,
    FullHeightContainerModule
  ],
  exports: [CompensationFieldModalComponent],
  declarations: [CompensationFieldModalComponent]
})
export class CompensationFieldModalModule {}
