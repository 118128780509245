// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_postSolution = (path: TSpecPathPostSolution) => {
  let res = "/suggestions/opportunities/:opportunityId/solutions";
  Object.entries(path).map(([key, value]) => {
    res = res.replace(':' + key, value);
  });
  return res;
};

export type TSpecHandler_postSolution = RequestHandler<TSpecPathPostSolution, any, TSpecBodyPostSolution, TSpecQueryPostSolution, {}>;

export interface TSpecPathPostSolution { opportunityId: string; }

export interface TSpecBodyPostSolution {
  body: string;
  attachments: string[];
  isPublic: boolean;
}

export interface TSpecQueryPostSolution {}
