import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '@app/core';
import { ToastService } from '@app/core/service/toast.service';
import { confirmPasswordValidator } from '@app/utils/form-validators';
import { LoadingController } from '@ionic/angular';

@Component({
  selector: 'app-signup-user',
  templateUrl: './signup-user.component.html',
  styleUrls: ['../login/login.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SignupUserComponent {
  protected mainFormGroup: FormGroup = new FormGroup(
    {
      firstName: new FormControl<string>('', [
        Validators.required,
        Validators.pattern('[a-zA-Z -]*')
      ]),
      lastName: new FormControl<string>('', [
        Validators.required,
        Validators.pattern('[a-zA-Z -]*')
      ]),
      password: new FormControl<string>('', [
        Validators.required,
        Validators.minLength(6)
      ]),
      confirmPassword: new FormControl<string>('', [
        Validators.required,
        Validators.minLength(6)
      ])
    },
    {
      validators: [confirmPasswordValidator]
    }
  );
  protected signupToken = '';
  protected noPassword = false;

  public constructor(
    private readonly _router: Router,
    private readonly _loadingCtrl: LoadingController,
    private readonly _route: ActivatedRoute,
    private readonly _authenticationService: AuthenticationService,
    private readonly _toastService: ToastService
  ) {
    this._route.params.subscribe((params) => {
      if (params.signupToken && params.signupToken.length) {
        this.signupToken = params.signupToken;
      } else {
        this._router.navigate(['/login']);
      }
    });

    this._route.queryParams.subscribe((params) => {
      if (params.noPassword) {
        this.noPassword = true;
        this.mainFormGroup.patchValue({
          password: 'Valchemy',
          confirmPassword: 'Valchemy'
        });
      }
    });
  }

  protected async submit() {
    const formValue = this.mainFormGroup.value;

    const loading = await this._loadingCtrl.create({
      message: 'Loading...'
    });
    loading.present();

    this._authenticationService
      .acceptUserInvitation(this.signupToken, {
        firstName: formValue.firstName,
        lastName: formValue.lastName,
        password: formValue.password
      })
      .subscribe({
        next: () => {
          loading.dismiss();
          this._toastService.presentToast(
            'You have been successfully signed up, now try to log in'
          );
          this._router.navigate(['/login']);
        },
        error: ({ error }) => {
          loading.dismiss();
          this._toastService.presentToast(
            error.message || 'Something went wrong'
          );
        }
      });
  }
}
