<ion-header class="ion-no-border">
  <ion-toolbar class="app-common-background">
    <ion-buttons slot="start">
      <ion-button (click)="onBackButtonClick()" appHaptics>
        <ion-icon slot="icon-only" name="chevron-back-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title class="ion-text-capitalize">Users: {{ tenant.name }}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <app-width-restricted-container
    class="layout-container"
    *ngIf="userList$ | async as userList"
  >
    <ng-container *ngIf="userList.length === 0">
      <ng-container *ngTemplateOutlet="emptyList"></ng-container>
    </ng-container>

    <ion-list>
      <ion-item *ngFor="let user of userList; trackBy: trackUserBy">
        <ion-label>
          <h2>{{ user.firstName }} {{ user.lastName }}</h2>
          <h3>{{ user.email }}</h3>
          <h3 *ngIf="user.deviceInfos.length">
            Last sessions: {{ devicesStr(user.deviceInfos) }}
          </h3>
        </ion-label>
      </ion-item>
    </ion-list>
  </app-width-restricted-container>
</ion-content>

<ng-template #emptyList>
  <app-empty-stub text="No Users"></app-empty-stub>
</ng-template>
