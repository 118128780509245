<ion-header class="ion-no-border">
  <ion-toolbar class="app-toolbar">
    <ion-buttons slot="start">
      <ion-button (click)="cancelChanges()" color="primary" appHaptics>
        Cancel
      </ion-button>
    </ion-buttons>
    <ion-title>Select Timezone</ion-title>
    <ion-buttons slot="end">
      <ion-button
        (click)="confirmChanges()"
        color="primary"
        [disabled]="!selectedTimezoneId"
        appHaptics
        [disableHaptics]="!selectedTimezoneId"
      >
        Done
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
  <ion-toolbar class="app-toolbar">
    <ion-searchbar
      (ionInput)="searchbarInput($event)"
      (keyup.enter)="selectFirst()"
    />
  </ion-toolbar>
</ion-header>

<ion-content *ngIf="_isMobile; else content" color="light">
  <ng-container *ngTemplateOutlet="content" />
</ion-content>

<ng-template #content>
  <app-full-height-container [isModal]="true" [virtualScroll]="true">
    <cdk-virtual-scroll-viewport
      [itemSize]="cdkItemSize"
      [minBufferPx]="cdkItemSize * 15"
      [maxBufferPx]="cdkItemSize * 15"
    >
      <ion-list>
        <ion-item
          *cdkVirtualFor="
            let timezone of timezones | filterTimezones : filter;
            trackBy: trackItems
          "
          [ngClass]="{
            'highlighted-item': getFirst()?.tzCode === timezone.tzCode
          }"
        >
          <ion-checkbox
            [checked]="timezone.tzCode === selectedTimezoneId"
            (ionChange)="checkboxChange(timezone)"
            appHaptics
          >
            <ion-label>
              {{ timezone.tzCode }}
            </ion-label>
            <ion-note *ngIf="timezone.standard" class="timezone-extra-line">
              {{ timezone.standard.offsetStr }} {{ timezone.standard.name }} ({{
                timezone.standard.abbr
              }})
            </ion-note>
            <br *ngIf="timezone.standard || timezone.daylight" />
            <ion-note *ngIf="timezone.daylight" class="timezone-extra-line">
              {{ timezone.daylight.offsetStr }} {{ timezone.daylight.name }} ({{
                timezone.daylight.abbr
              }})
            </ion-note>
          </ion-checkbox>
        </ion-item>
      </ion-list>
    </cdk-virtual-scroll-viewport>
  </app-full-height-container>
</ng-template>
