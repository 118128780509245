import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ViewChild
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IonNav, IonicModule } from '@ionic/angular';

@Component({
  selector: 'app-nav-base',
  template: `<ion-nav #nav></ion-nav>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [IonicModule]
})
export class NavBaseComponent implements AfterViewInit {
  protected rootComponent: any;

  @ViewChild('nav')
  public nav: IonNav;

  public constructor(private readonly _route: ActivatedRoute) {
    this.rootComponent = this._route.snapshot.data.rootComponent;
  }

  ngAfterViewInit() {
    this.nav.setRoot(this.rootComponent);
  }
}
