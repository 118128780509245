import { Component } from '@angular/core';
import { PsychologicalSurveyApiService } from '@app/core/service/api/psychologicalSurvey.api.service';
import { ToastService } from '@app/core/service/toast.service';
import { UserEntityService } from '@app/core/service/user-entity.service';
import { MongoStoredObject } from '@app/types/mongo-stored-object';
import { IPsychologicalSurvey } from '@backend/models/types/psychological-survey';
import { IPsychologicalSurveyAnswerValue } from '@backend/models/types/psychological-survey-answer';
import {
  IPsychologicalSurveyQuestion,
  EPsychologicalSurveyQuestionType
} from '@backend/models/types/psychological-survey-question';
import { LoadingController } from '@ionic/angular';

@Component({
  selector: 'app-psychological-survey',
  templateUrl: './psychological-survey.component.html',
  styleUrls: ['./psychological-survey.component.scss']
})
export class PsychologicalSurveyComponent {
  protected psychologicalSurveyQuestionType = EPsychologicalSurveyQuestionType;

  protected survey: MongoStoredObject<IPsychologicalSurvey> = null;
  protected questions: MongoStoredObject<IPsychologicalSurveyQuestion>[] = [];
  protected isLoading = true;
  protected currentQuestionIndex = 0;
  protected isSuccess = false;
  protected answers: {
    question: string;
    result: IPsychologicalSurveyAnswerValue;
  }[] = [];

  public constructor(
    private readonly _psychologicalSurveyApiService: PsychologicalSurveyApiService,
    private readonly _toastSerivce: ToastService,
    private readonly _loadingCtrl: LoadingController,
    private readonly _userEntityService: UserEntityService
  ) {
    this._psychologicalSurveyApiService
      .getCurrentPsychologicalSurvey()
      .subscribe((res) => {
        this.survey = res.survey;
        this.questions = res.questions;
        this.isLoading = false;
      });
  }

  protected get currentQuestion() {
    if (this.questions.length === 0) {
      return null;
    }

    const question = this.questions[this.currentQuestionIndex];
    const answer = question
      ? this.answers.find((a) => a.question === question._id)?.result
      : null;

    return {
      question,
      answer
    } as any;
  }

  protected async onQuestionComplete(event: {
    questionId: string;
    result: IPsychologicalSurveyAnswerValue;
  }) {
    this.answers.push({
      question: event.questionId,
      result: event.result
    });

    if (this.currentQuestionIndex === this.questions.length - 1) {
      const loading = await this._loadingCtrl.create({
        message: 'Loading...'
      });
      loading.present();

      this._psychologicalSurveyApiService
        .answerToPsychologicalSurvey({
          path: { surveyId: this.survey._id },
          body: { questionAnswers: this.answers }
        })
        .subscribe({
          next: () => {
            this.isSuccess = true;
            loading.dismiss();
            this._userEntityService.update();
          },
          error: () => {
            loading.dismiss();
            this._toastSerivce.presentToast('Unable to complete the survey');
          }
        });
    } else {
      this.currentQuestionIndex++;
    }
  }

  protected onStepBack() {
    if (this.currentQuestionIndex > 0) {
      this.currentQuestionIndex--;
    }
  }
}
