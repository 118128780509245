// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_uploadFile = () => "/upload";

export type TSpecHandler_uploadFile = RequestHandler<TSpecPathUploadFile, any, TSpecBodyUploadFile, TSpecQueryUploadFile, {}>;

export interface TSpecPathUploadFile {  }

export interface TSpecBodyUploadFile {}

export interface TSpecQueryUploadFile {}
