import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  Input
} from '@angular/core';

@Component({
  selector: 'app-full-height-container',
  templateUrl: './full-height-container.component.html',
  styleUrls: ['./full-height-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FullHeightContainerComponent implements AfterViewInit {
  @Input()
  public readonly maxWidth: 'default' | 'modal' = 'default';

  @Input()
  public readonly isModal: boolean = false;

  @Input()
  public readonly virtualScroll: boolean = false;

  public ngAfterViewInit(): void {
    const scrollcontainer = document.querySelector<HTMLDivElement>(
      '.virtual-scroll-container'
    );

    if (scrollcontainer) {
      scrollcontainer.ontouchmove = function (e) {
        e.stopPropagation();
      };
    }
  }
}
