import { NgModule } from '@angular/core';
import { TenantsComponent } from './tenants.component';
import { RouterModule, Routes } from '@angular/router';
import { NavBaseComponent } from '@app/modules/nav-base/nav-base.component';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { WidthRestrictedContainerModule } from '@app/modules/width-restricted-container/width-restricted-container.module';
import { TenantEditComponent } from './components/tenant-edit/tenant-edit.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SlimMenuButtonComponent } from '@app/modules/slim-menu-button/slim-menu-button.component';
import { TenantTasksComponent } from './components/tenant-tasks/tenant-tasks.component';
import { TenantUsersComponent } from './components/tenant-users/tenant-users.component';
import { TenantIntegrationsComponent } from './components/tenant-integrations/tenant-integrations.component';
import { DirectivesModule } from '@app/modules/directives.module';
import { TenantFeaturesComponent } from './components/tenant-features/tenant-features.component';
import { EmptyStubModule } from '@app/modules/empty-stub/empty-stub.module';
import { GhlTagUploaderComponent } from './components/ghl-tag-uploader/ghl-tag-uploader.component';
import { TagApiService } from '@app/core/service/api/tag.api.service';
import { ListPreLineModule } from '@app/modules/list-pre-line/list-pre-line.module';
import { TimezoneSelectorModalModule } from '@app/modals/timezone-selector-modal/timezone-selector-modal.module';

const ROUTES: Routes = [
  {
    path: '',
    component: NavBaseComponent,
    data: {
      title: 'Tenants',
      rootComponent: TenantsComponent
    }
  }
];

@NgModule({
  imports: [
    NavBaseComponent,
    CommonModule,
    RouterModule.forChild(ROUTES),
    IonicModule,
    WidthRestrictedContainerModule,
    ReactiveFormsModule,
    FormsModule,
    SlimMenuButtonComponent,
    TimezoneSelectorModalModule,
    DirectivesModule,
    EmptyStubModule,
    ListPreLineModule
  ],
  declarations: [
    TenantsComponent,
    TenantEditComponent,
    TenantTasksComponent,
    TenantUsersComponent,
    TenantIntegrationsComponent,
    TenantFeaturesComponent,
    GhlTagUploaderComponent
  ],
  providers: [TagApiService]
})
export class TenantsModule {}
