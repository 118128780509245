import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserModel, UserPointsRecord } from '../../model/user.model';
import { InsightUserStats } from '@app/types/insight-user-stats';
import { ITaskPerformance } from '@backend/models/types/task-performance';
import { MongoStoredObject } from '@app/types/mongo-stored-object';
import { ITask } from '@backend/models/types/task';
import { IUserDeviceInfo } from '@backend/models/types/device-info';
import { ILedger } from '@backend/models/types/ledger';
import {
  TSpecPathGetAllUsers,
  TSpecRoute_getAllUsers
} from '@backend/types/generated/Users/getAllUsers';
import {
  TSpecPathGetAllUsersWithDeleted,
  TSpecRoute_getAllUsersWithDeleted
} from '@backend/types/generated/Users/getAllUsersWithDeleted';
import { TSpecRoute_getAllUsersNames } from '@backend/types/generated/Users/getAllUsersNames';
import {
  TSpecBodyInviteUser,
  TSpecRoute_inviteUser
} from '@backend/types/generated/Users/inviteUser';
import {
  TSpecBodyUpdateUser,
  TSpecPathUpdateUser,
  TSpecRoute_updateUser
} from '@backend/types/generated/Users/updateUser';
import { TSpecRoute_getUser } from '@backend/types/generated/Users/getUser';
import {
  TSpecPathGetUserById,
  TSpecRoute_getUserById
} from '@backend/types/generated/Users/getUserById';
import {
  TSpecPathGetTaskPerformances,
  TSpecQueryGetTaskPerformances,
  TSpecRoute_getTaskPerformances
} from '@backend/types/generated/Users/getTaskPerformances';
import {
  TSpecQueryGetPointsForPeriod,
  TSpecRoute_getPointsForPeriod
} from '@backend/types/generated/Users/getPointsForPeriod';
import {
  TSpecQueryGetAverageTeamPointsForPeriod,
  TSpecRoute_getAverageTeamPointsForPeriod
} from '@backend/types/generated/Users/getAverageTeamPointsForPeriod';
import {
  TSpecBodySoftDelete,
  TSpecPathSoftDelete,
  TSpecRoute_softDelete
} from '@backend/types/generated/Users/softDelete';
import {
  TSpecQueryGetInsights,
  TSpecRoute_getInsights
} from '@backend/types/generated/Users/getInsights';
import {
  TSpecPathGetAllUsersWithPinCodes,
  TSpecRoute_getAllUsersWithPinCodes
} from '@backend/types/generated/Users/getAllUsersWithPinCodes';

@Injectable()
export class UserApiService {
  public constructor(private readonly _httpClient: HttpClient) {}

  public getAllUsers(params: {
    path: TSpecPathGetAllUsers;
  }): Observable<
    (UserModel & { deviceInfos: MongoStoredObject<IUserDeviceInfo>[] })[]
  > {
    return this._httpClient.get<
      (UserModel & { deviceInfos: MongoStoredObject<IUserDeviceInfo>[] })[]
    >(TSpecRoute_getAllUsers(params.path));
  }

  public getAllUsersWithDeleted(params: {
    path: TSpecPathGetAllUsersWithDeleted;
  }): Observable<UserModel[]> {
    return this._httpClient.get<UserModel[]>(
      TSpecRoute_getAllUsersWithDeleted(params.path)
    );
  }

  public getAllUsersWithPinCodes(params: {
    path: TSpecPathGetAllUsersWithPinCodes;
  }): Observable<(UserModel & { pin: string })[]> {
    return this._httpClient.get<(UserModel & { pin: string })[]>(
      TSpecRoute_getAllUsersWithPinCodes(params.path)
    );
  }

  public getAllUsersNames(): Observable<UserModel[]> {
    return this._httpClient.get<UserModel[]>(TSpecRoute_getAllUsersNames());
  }

  public inviteUser(params: {
    body: TSpecBodyInviteUser;
  }): Observable<UserModel> {
    return this._httpClient.post<UserModel>(
      TSpecRoute_inviteUser(),
      params.body
    );
  }

  public updateUser(params: {
    path: TSpecPathUpdateUser;
    body: TSpecBodyUpdateUser;
  }): Observable<UserModel> {
    return this._httpClient.patch<UserModel>(
      TSpecRoute_updateUser(params.path),
      params.body
    );
  }

  public getUser(): Observable<UserModel> {
    return this._httpClient.get<UserModel>(TSpecRoute_getUser());
  }

  public getUserById(params: {
    path: TSpecPathGetUserById;
  }): Observable<UserModel> {
    return this._httpClient.get<UserModel>(TSpecRoute_getUserById(params.path));
  }

  public getTaskPerformances(params: {
    path: TSpecPathGetTaskPerformances;
    query: TSpecQueryGetTaskPerformances;
  }): Observable<
    MongoStoredObject<
      ITaskPerformance & {
        task: MongoStoredObject<ITask>;
        ledgers: MongoStoredObject<ILedger>[];
      }
    >[]
  > {
    return this._httpClient.get<
      MongoStoredObject<
        ITaskPerformance & {
          task: MongoStoredObject<ITask>;
          ledgers: MongoStoredObject<ILedger>[];
        }
      >[]
    >(TSpecRoute_getTaskPerformances(params.path), {
      params: { ...params.query }
    });
  }

  public getPointsForPeriod(params: {
    query: TSpecQueryGetPointsForPeriod;
  }): Observable<UserPointsRecord> {
    return this._httpClient.get<UserPointsRecord>(
      TSpecRoute_getPointsForPeriod(),
      { params: { ...params.query } }
    );
  }

  public getAverageTeamPointsForPeriod(params: {
    query: TSpecQueryGetAverageTeamPointsForPeriod;
  }): Observable<{ teamAveragePoints: number }> {
    return this._httpClient.get<{ teamAveragePoints: number }>(
      TSpecRoute_getAverageTeamPointsForPeriod(),
      { params: { ...params.query } }
    );
  }

  public softDelete(params: {
    path: TSpecPathSoftDelete;
    body: TSpecBodySoftDelete;
  }): Observable<UserModel> {
    return this._httpClient.put<UserModel>(
      TSpecRoute_softDelete(params.path),
      params.body
    );
  }

  public getInsights(params: {
    query: TSpecQueryGetInsights;
  }): Observable<InsightUserStats[]> {
    return this._httpClient.get<InsightUserStats[]>(TSpecRoute_getInsights(), {
      params: { ...params.query }
    });
  }
}
