<ion-text class="ion-text-uppercase">{{ title }}</ion-text>

<ion-fab-button
  *ngIf="showAddButton"
  size="small"
  color="primary"
  (click)="addClicked.emit()"
  appHaptics
>
  <ion-icon name="add"></ion-icon>
</ion-fab-button>
