import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AnnouncementModel } from '../../model/announcement.model';
import { TSpecRoute_getUnacknowledgedCount } from '@backend/types/generated/Announcements/getUnacknowledgedCount';
import { TSpecRoute_getCriticalUnacknowledgedAnnouncements } from '@backend/types/generated/Announcements/getCriticalUnacknowledgedAnnouncements';
import {
  TSpecQueryGetAllAnnouncements,
  TSpecRoute_getAllAnnouncements
} from '@backend/types/generated/Announcements/getAllAnnouncements';
import {
  TSpecPathGetAnnouncement,
  TSpecRoute_getAnnouncement
} from '@backend/types/generated/Announcements/getAnnouncement';
import {
  TSpecBodyPublishAnnouncement,
  TSpecRoute_publishAnnouncement
} from '@backend/types/generated/Announcements/publishAnnouncement';
import {
  TSpecBodyEditAnnouncement,
  TSpecPathEditAnnouncement,
  TSpecRoute_editAnnouncement
} from '@backend/types/generated/Announcements/editAnnouncement';
import {
  TSpecPathReadAnnouncement,
  TSpecRoute_readAnnouncement
} from '@backend/types/generated/Announcements/readAnnouncement';
import {
  TSpecPathAcknowledgeAnnouncement,
  TSpecRoute_acknowledgeAnnouncement
} from '@backend/types/generated/Announcements/acknowledgeAnnouncement';
import {
  TSpecPathDeleteAnnouncement,
  TSpecRoute_deleteAnnouncement
} from '@backend/types/generated/Announcements/deleteAnnouncement';
import {
  TSpecBodyRespondToAnnouncement,
  TSpecPathRespondToAnnouncement,
  TSpecRoute_respondToAnnouncement
} from '@backend/types/generated/Announcements/respondToAnnouncement';
import {
  TSpecPathGetThreadOfAnnouncement,
  TSpecRoute_getThreadOfAnnouncement
} from '@backend/types/generated/Announcements/getThreadOfAnnouncement';

@Injectable()
export class AnnouncementApiService {
  public constructor(private readonly _httpClient: HttpClient) {}

  public getUnacknowledgedCount(): Observable<{
    unacknowledgedCount: number;
  }> {
    return this._httpClient.get<{ unacknowledgedCount: number }>(
      TSpecRoute_getUnacknowledgedCount()
    );
  }

  public getCriticalUnacknowledgedAnnouncements(): Observable<{
    items: AnnouncementModel[];
    count: number;
    totalCount: number;
  }> {
    return this._httpClient.get<{
      items: AnnouncementModel[];
      count: number;
      totalCount: number;
    }>(TSpecRoute_getCriticalUnacknowledgedAnnouncements());
  }

  public getAllAnnouncements(params: {
    query: TSpecQueryGetAllAnnouncements;
  }): Observable<AnnouncementModel[]> {
    return this._httpClient.get<AnnouncementModel[]>(
      TSpecRoute_getAllAnnouncements(),
      { params: { ...params.query } }
    );
  }

  public getAnnouncement(params: {
    path: TSpecPathGetAnnouncement;
  }): Observable<AnnouncementModel> {
    return this._httpClient.get<AnnouncementModel>(
      TSpecRoute_getAnnouncement(params.path)
    );
  }

  public publishAnnouncement(params: { body: TSpecBodyPublishAnnouncement }) {
    return this._httpClient.post(TSpecRoute_publishAnnouncement(), params.body);
  }

  public editAnnouncement(params: {
    path: TSpecPathEditAnnouncement;
    body: TSpecBodyEditAnnouncement;
  }) {
    return this._httpClient.patch(
      TSpecRoute_editAnnouncement(params.path),
      params.body
    );
  }

  public readAnnouncement(params: { path: TSpecPathReadAnnouncement }) {
    return this._httpClient.post(TSpecRoute_readAnnouncement(params.path), {});
  }

  public acknowledgeAnnouncement(params: {
    path: TSpecPathAcknowledgeAnnouncement;
  }) {
    return this._httpClient.post(
      TSpecRoute_acknowledgeAnnouncement(params.path),
      {}
    );
  }

  public deleteAnnouncement(params: { path: TSpecPathDeleteAnnouncement }) {
    return this._httpClient.delete(TSpecRoute_deleteAnnouncement(params.path));
  }

  public respondToAnnouncement(params: {
    path: TSpecPathRespondToAnnouncement;
    body: TSpecBodyRespondToAnnouncement;
  }): Observable<AnnouncementModel> {
    return this._httpClient.post<AnnouncementModel>(
      TSpecRoute_respondToAnnouncement(params.path),
      params.body
    );
  }

  public getThreadOfAnnouncement(params: {
    path: TSpecPathGetThreadOfAnnouncement;
  }): Observable<AnnouncementModel[]> {
    return this._httpClient.get<AnnouncementModel[]>(
      TSpecRoute_getThreadOfAnnouncement(params.path)
    );
  }
}
