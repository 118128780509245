import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LogoutComponent } from './logout.component';
import { IonicModule } from '@ionic/angular';
import { NavBaseComponent } from '@app/modules/nav-base/nav-base.component';

const ROUTES: Routes = [
  {
    path: '',
    component: NavBaseComponent,
    data: {
      title: 'Logout',
      rootComponent: LogoutComponent
    }
  }
];

@NgModule({
  imports: [NavBaseComponent, RouterModule.forChild(ROUTES), IonicModule],
  declarations: [LogoutComponent]
})
export class LogoutModule {}
