// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_uploadAttachments = () => "/upload/attachments";

export type TSpecHandler_uploadAttachments = RequestHandler<TSpecPathUploadAttachments, any, TSpecBodyUploadAttachments, TSpecQueryUploadAttachments, {}>;

export interface TSpecPathUploadAttachments {  }

export interface TSpecBodyUploadAttachments {
  positionInBody: string | string[];
}

export interface TSpecQueryUploadAttachments {}
