import { Injectable } from '@angular/core';
import { TaskScheduleType } from '@backend/models/types/task';
import { MongoStoredObject } from '../../types/mongo-stored-object';
import { TaskDailyRendererClass } from '../class/task-daily-renderer.class';
import { TaskRendererClass } from '../class/task-renderer.class';
import { TaskNeverRendererClass } from '../class/task-never-renderer.class';
import { TaskWeeklyRendererClass } from '../class/task-weekly-renderer.class';
import { TaskSpecificDatesRendererClass } from '../class/task-specific-dates-renderer.class';
import { TaskDto } from '@app/types/task-dto';

@Injectable({ providedIn: 'root' })
export class TaskRendererFabricService {
  public createTaskRenderer(
    task: MongoStoredObject<TaskDto>,
    allowTasksToBeDisplayedOnlyDuringCertainHours: boolean
  ): TaskRendererClass {
    const schedule = task.schedule[0];
    switch (schedule.scheduleType) {
      case TaskScheduleType.DAILY:
        return new TaskDailyRendererClass(
          task,
          allowTasksToBeDisplayedOnlyDuringCertainHours
        );
      case TaskScheduleType.NEVER:
        return new TaskNeverRendererClass(
          task,
          allowTasksToBeDisplayedOnlyDuringCertainHours,
          schedule
        );
      case TaskScheduleType.WEEKLY:
        return new TaskWeeklyRendererClass(
          task,
          allowTasksToBeDisplayedOnlyDuringCertainHours,
          schedule
        );
      case TaskScheduleType.SPECIFIC_DATES:
        return new TaskSpecificDatesRendererClass(
          task,
          allowTasksToBeDisplayedOnlyDuringCertainHours,
          schedule
        );
      case TaskScheduleType.MONTHLY:
        throw new Error('Monthly checklist renderer not implemented');
    }
  }
}
