import { Component, Input } from '@angular/core';
import { IonicModule, ModalController } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { DirectivesModule } from '@app/modules/directives.module';
import { UserModel } from '@app/core/model/user.model';
import { LocalStorageService } from '@app/core';
import { UserApiService } from '@app/core/service/api/user.api.service';
import { ToastService } from '@app/core/service/toast.service';
import { Clipboard } from '@angular/cdk/clipboard';
import { PasscodeTimeoutService } from '@app/core/service/passcode-timeout.service';
import { FilterUsersPipe } from './filter-users.pipe';
import { TenantService } from '@app/core/service/tenant.service';
import { take } from 'rxjs';

@Component({
  selector: 'app-passcodes-list-modal',
  templateUrl: './passcodes-list-modal.component.html',
  styleUrls: ['./passcodes-list-modal.component.scss'],
  standalone: true,
  imports: [CommonModule, IonicModule, DirectivesModule, FilterUsersPipe]
})
export class PasscodesListModalComponent {
  @Input()
  public passcodeRedirectsTo = '/i/recognition';

  @Input()
  public passcodeTimeout = 60;

  protected filter = '';
  protected users: (UserModel & { pin: string })[] = [];
  protected isLoading = true;

  public constructor(
    private readonly _userApiService: UserApiService,
    private readonly _toastService: ToastService,
    private readonly _clipboard: Clipboard,
    private readonly _localStorageService: LocalStorageService,
    private readonly _modalCtrl: ModalController,
    private readonly _passcodeTimeoutService: PasscodeTimeoutService,
    private readonly _tenantService: TenantService
  ) {
    this._tenantService.tenant$.pipe(take(1)).subscribe((tenant) => {
      this._userApiService
        .getAllUsersWithPinCodes({ path: { tenantId: tenant._id } })
        .subscribe((users) => {
          this.users = users;
          this.isLoading = false;
        });
    });
  }

  protected get isMultiUserModeActive(): boolean {
    return !!this._localStorageService.getItem('multiUserMode');
  }

  protected trackItems(_: number, item: UserModel) {
    return item._id;
  }

  protected close() {
    this._modalCtrl.dismiss();
  }

  protected activate() {
    this._modalCtrl.dismiss();
    this._passcodeTimeoutService.activateMultiUserMode(
      this.passcodeRedirectsTo,
      this.passcodeTimeout
    );
  }

  protected deactivate() {
    this._modalCtrl.dismiss();
    this._passcodeTimeoutService.deactivateMultiUserMode();
  }

  protected searchbarInput(event: any): void {
    this.filter = event.detail.value;
  }

  protected copyPinCode(user: UserModel & { pin: string }) {
    this._clipboard.copy(user.pin);
    this._toastService.presentToast('Passcode copied');
  }
}
