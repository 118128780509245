// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_getPublicTenantDataWithUsers = (path: TSpecPathGetPublicTenantDataWithUsers) => {
  let res = "/tenants/:tenantId/public";
  Object.entries(path).map(([key, value]) => {
    res = res.replace(':' + key, value);
  });
  return res;
};

export type TSpecHandler_getPublicTenantDataWithUsers = RequestHandler<TSpecPathGetPublicTenantDataWithUsers, any, TSpecBodyGetPublicTenantDataWithUsers, TSpecQueryGetPublicTenantDataWithUsers, {}>;

export interface TSpecPathGetPublicTenantDataWithUsers { tenantId: string; }

export interface TSpecBodyGetPublicTenantDataWithUsers {}

export interface TSpecQueryGetPublicTenantDataWithUsers {}
