import { ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';
import { InsightsDataSourceService } from '@app/dashboard/dashboard/insights/insights-data-source.service';
import { DateInterval } from '@backend/types/date-interval';
import { RangePresets } from '@app/types/range-presets';
import { take } from 'rxjs';
import { InsightUserStatsWithShare } from '@app/dashboard/dashboard/insights/insight-user-stats-with-share';
import {
  ModalController,
  IonNav,
  ViewWillEnter,
  LoadingController,
  ViewDidEnter
} from '@ionic/angular';
import { InsightsRangeModalComponent } from '@app/modules/insights-range-modal/insights-range-modal.component';
import { InsightsDateRangeHelperService } from '@app/core/service/insights-date-range-helper.service';
import { Sort } from '@angular/material/sort';
import { TasksCompletedComponent } from '@app/dashboard/dashboard/insights/tasks-completed/tasks-completed.component';
import { AuthenticationService } from '@app/core';
import { PraiseReceivedComponent } from './praise-received/praise-received.component';
import { PraiseSentComponent } from './praise-sent/praise-sent.component';
import { ToastService } from '@app/core/service/toast.service';
import { format, isSameDay } from 'date-fns';
import { MatSort } from '@angular/material/sort';
import { TenantService } from '@app/core/service/tenant.service';
import { CompensationsComponent } from './compensations/compensations.component';
import { ITenantFeatures } from '@backend/models/types/tenant';
import { LotteryComponent } from './lottery/lottery.component';
import { UserRoles } from '@backend/models/types/user';

const FIELD_HEADERS: Omit<
  Record<keyof InsightUserStatsWithShare, string>,
  '_id' | 'firstName' | 'lastName' | 'isDeleted'
> = {
  fullName: 'Name',
  tasksCompleted: 'Task Points',
  pointsReceived: 'Praise Received',
  pointsSent: 'Praise Sent',
  pointsSum: 'Points',
  tasksCompletedShare: 'Task Points, %',
  tasksCompletedShareDecimal: 'Task Points, % (from 0 to 1)',
  pointsReceivedShare: 'Praise Received, %',
  pointsReceivedShareDecimal: 'Praise Received, % (from 0 to 1)',
  pointsSentShare: 'Praise Sent, %',
  pointsSentShareDecimal: 'Praise Sent, % (from 0 to 1)',
  pointsSumShare: 'Points, %',
  totalPoints: 'Total Points',
  totalPointsShare: 'Total Points, %',
  totalPointsShareDecimal: 'Total Points, % (from 0 to 1)'
};

type FieldName = keyof typeof FIELD_HEADERS;

const sortFields = (fields: FieldName[]) =>
  FIELDS_ORDER.filter((f) => fields.includes(f));

const sortPredicate = (a: [string, any], b: [string, any]) =>
  FIELDS_ORDER.indexOf(a[0] as any) - FIELDS_ORDER.indexOf(b[0] as any);

const FIELDS_ORDER: FieldName[] = [
  'fullName',
  'totalPoints',
  'totalPointsShare',
  'totalPointsShareDecimal',
  'tasksCompleted',
  'tasksCompletedShare',
  'tasksCompletedShareDecimal',
  'pointsReceived',
  'pointsReceivedShare',
  'pointsReceivedShareDecimal',
  'pointsSent',
  'pointsSentShare',
  'pointsSentShareDecimal',
  'pointsSum',
  'pointsSumShare'
];

function keyInFields([key]: [string, any], allowedFields: FieldName[]) {
  return allowedFields.includes(key as any);
}

@Component({
  selector: 'app-insights',
  templateUrl: './insights.component.html',
  styleUrls: ['./insights.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [InsightsDataSourceService]
})
export class InsightsComponent implements ViewWillEnter, ViewDidEnter {
  @ViewChild(MatSort, { static: false })
  public sort: MatSort;

  protected columnsToDisplay: FieldName[] = [
    'fullName',
    'tasksCompleted',
    'pointsReceived',
    'pointsSent'
  ];

  protected get fTasksCompleted(): boolean {
    return this.dataSource.options.fieldsToSum.includes('tasksCompleted');
  }

  protected get fPointsReceived(): boolean {
    return this.dataSource.options.fieldsToSum.includes('pointsReceived');
  }

  protected get fPointsSent(): boolean {
    return this.dataSource.options.fieldsToSum.includes('pointsSent');
  }

  protected get lastNameFirst(): boolean {
    return this.dataSource.options.lastNameFirst;
  }

  protected get splitCategories(): boolean {
    return !this.columnsToDisplay.includes('pointsSum');
  }

  protected showRange = false;
  protected range: DateInterval;
  protected isAdmin = false;
  protected isAdminOrManager = false;
  protected tenantFeatures: Pick<
    ITenantFeatures,
    'calculateRewards' | 'lottery'
  > = {
    calculateRewards: false,
    lottery: false
  };

  public constructor(
    protected readonly dataSource: InsightsDataSourceService,
    private readonly _toastService: ToastService,
    private readonly _clipboard: Clipboard,
    private readonly _modalCtrl: ModalController,
    private readonly _insightsDateRangeHelperService: InsightsDateRangeHelperService,
    private readonly _ionNav: IonNav,
    private readonly _authenticationService: AuthenticationService,
    private readonly _tenantService: TenantService,
    private readonly _loadingCtrl: LoadingController
  ) {
    this.isAdmin = this._authenticationService.user.roles.includes(
      UserRoles.Admin
    );
    this.isAdminOrManager =
      this._authenticationService.user.roles.includes(UserRoles.Admin) ||
      this._authenticationService.user.roles.includes(UserRoles.Manager);

    this._tenantService.tenant$.subscribe((tenant) => {
      this.tenantFeatures = tenant.features;
    });
  }

  protected get isOneDayRange() {
    return isSameDay(this.range.start, this.range.end);
  }

  public async ionViewDidEnter() {
    const topLoading = await this._loadingCtrl.getTop();
    if (topLoading) {
      topLoading.dismiss();
    }
  }

  public async ionViewWillEnter() {
    const prevPage = await this._ionNav.getActive();

    if (prevPage && prevPage.params && prevPage.params.range) {
      this.range = prevPage.params.range;
      this.dataSource.setDateRange(prevPage.params.range);
      this.showRange = true;
    } else {
      this._insightsDateRangeHelperService
        .getIntervalFromPreset(RangePresets.Today)
        .subscribe((range) => {
          this.range = range;
          this.dataSource.setDateRange(this.range);
          this.sort.sort({
            id: 'pointsSum',
            start: 'desc',
            disableClear: false
          });
          this.showRange = true;
        });
    }
  }

  private _objectToCsv(
    o: InsightUserStatsWithShare,
    allowedFields: FieldName[]
  ): string {
    const formatter = Intl.NumberFormat(navigator.language);
    return Object.entries(o)
      .filter((entry) => keyInFields(entry, allowedFields))
      .sort(sortPredicate)
      .map(([_, value]) => {
        if (typeof value === 'string') {
          return `"${value}"`;
        }
        if (typeof value === 'number') {
          return formatter.format(value);
        }
        if (typeof value === 'boolean') {
          return JSON.stringify(value);
        }
        throw new Error(`Can't serilize ${typeof value}: ${value}`);
      })
      .join('\t');
  }

  private _hideField(fieldName: FieldName): void {
    this.columnsToDisplay = sortFields(
      this.columnsToDisplay.filter((f) => f !== fieldName)
    );
  }

  private _showField(fieldName: FieldName): void {
    this.columnsToDisplay = sortFields([...this.columnsToDisplay, fieldName]);
  }

  protected onFilterChange(event: any, fieldName: FieldName): void {
    const {
      detail: { checked }
    } = event;
    if (checked) {
      this.dataSource.options = {
        ...this.dataSource.options,
        fieldsToSum: [...this.dataSource.options.fieldsToSum, fieldName as any]
      };
    } else {
      this.dataSource.options = {
        ...this.dataSource.options,
        fieldsToSum: this.dataSource.options.fieldsToSum.filter(
          (f) => f !== fieldName
        )
      };
    }

    if (this.splitCategories) {
      if (checked) {
        this._showField(fieldName);
      } else {
        this._hideField(fieldName);
      }
    }
  }

  protected onLastNameFirstChange(event: any): void {
    const {
      detail: { checked }
    } = event;
    this.dataSource.options = {
      ...this.dataSource.options,
      lastNameFirst: checked
    };
  }

  protected onSplitCategoriesChange(event: any): void {
    const {
      detail: { checked }
    } = event;
    if (!checked) {
      this.dataSource.options = {
        ...this.dataSource.options,
        fieldsToSum: ['tasksCompleted', 'pointsReceived', 'pointsSent']
      };
      this.columnsToDisplay = [
        'fullName',
        'tasksCompleted',
        'pointsReceived',
        'pointsSent'
      ];
      return;
    }
    this.dataSource.options = {
      ...this.dataSource.options,
      fieldsToSum: ['tasksCompleted', 'pointsReceived', 'pointsSent']
    };
    this.columnsToDisplay = ['fullName', 'pointsSum'];
  }

  protected onCopyDataClick(): void {
    const fieldsToRender: FieldName[] = [
      'fullName',
      'totalPoints',
      // ignore filtering
      'totalPointsShareDecimal',
      'tasksCompleted',
      'tasksCompletedShareDecimal',
      'pointsReceived',
      'pointsReceivedShareDecimal',
      'pointsSent',
      'pointsSentShareDecimal'
    ];

    // if (this.splitCategories) {
    //   if (this.fTasksCompleted) {
    //     fieldsToRender = [
    //       ...fieldsToRender,
    //       'tasksCompleted',
    //       // 'tasksCompletedShare',
    //       'tasksCompletedShareDecimal'
    //     ];
    //   }
    //   if (this.fPointsReceived) {
    //     fieldsToRender = [
    //       ...fieldsToRender,
    //       'pointsReceived',
    //       // 'pointsReceivedShare',
    //       'pointsReceivedShareDecimal'
    //     ];
    //   }
    //   if (this.fPointsSent) {
    //     fieldsToRender = [
    //       ...fieldsToRender,
    //       'pointsSent',
    //       // 'pointsSentShare',
    //       'pointsSentShareDecimal'
    //     ];
    //   }
    // } else {
    //   fieldsToRender = [...fieldsToRender, 'pointsSum', 'pointsSumShare'];
    // }

    const separator = '\t';
    this.dataSource
      .getCurrentData()
      .pipe(take(1))
      .subscribe((data) => {
        const header = Object.entries(FIELD_HEADERS)
          .filter((entry) => keyInFields(entry, fieldsToRender))
          .sort(sortPredicate)
          .map(([_, value]) => `"${value}"`)
          .join(separator);
        const csvData = data
          .map((o) => this._objectToCsv(o, fieldsToRender))
          .join('\n');
        this._clipboard.copy(`${header}\n${csvData}`);
        this._toastService.presentToast('Data copied');
      });
  }

  protected onCalculateRewardsClick() {
    this._ionNav.push(CompensationsComponent, {
      defaultRange: this.range
    });
  }

  protected onLotteryClick() {
    this.dataSource
      .getCurrentData()
      .pipe(take(1))
      .subscribe((data) => {
        this._ionNav.push(LotteryComponent, {
          users: data,
          rangeStr: this.isOneDayRange
            ? format(this.range.start, 'MMM d, y')
            : `${format(this.range.start, 'MMM d, y')} - ${format(
                this.range.end,
                'MMM d, y'
              )}`
        });
      });
  }

  protected async onRangeClick() {
    const modal = await this._modalCtrl.create({
      id: 'insights-range-modal',
      component: InsightsRangeModalComponent,
      cssClass: 'modal-auto-height',
      breakpoints: [0, 1],
      initialBreakpoint: 1,
      componentProps: {
        range: this.range
      },
      handle: false
    });
    modal.present();

    const { data, role } = await modal.onWillDismiss();

    if (role === 'confirm') {
      this.range = data;
      this.dataSource.setDateRange(this.range);
    }
  }

  protected onSortChange(sort: Sort): void {
    this.dataSource.setSort(sort);
  }

  protected onTaskCompletedClick(e: { _id: string; fullName: string }): void {
    this.showRange = false;
    this._ionNav.push(TasksCompletedComponent, {
      userId: e._id,
      fullName: e.fullName,
      defaultRange: this.range
    });
  }

  protected onPraiseReceivedClick(e: { _id: string; fullName: string }): void {
    this.showRange = false;
    this._ionNav.push(PraiseReceivedComponent, {
      userId: e._id,
      fullName: e.fullName,
      defaultRange: this.range
    });
  }

  protected onPraiseSentClick(e: { _id: string; fullName: string }): void {
    this.showRange = false;
    this._ionNav.push(PraiseSentComponent, {
      userId: e._id,
      fullName: e.fullName,
      defaultRange: this.range
    });
  }
}
