import { Component } from '@angular/core';
import { AnnouncementModel } from '@app/core/model/announcement.model';
import { MongoStoredObject } from '@app/types/mongo-stored-object';
import { UserModel } from '@app/core/model/user.model';
import { AuthenticationService } from '@app/core';
import { LedgerApiService } from '@app/core/service/api/ledger.api.service';
import { LoadingController, ModalController } from '@ionic/angular';
import { ToastService } from '@app/core/service/toast.service';
import { WhatsNewService } from '@app/core/service/whats-new.service';
import { IPsychologicalSurvey } from '@backend/models/types/psychological-survey';
import { RecognitionApiService } from '@app/core/service/api/recognition.api.service';
import { IPsychologicalSurveyQuestion } from '@backend/models/types/psychological-survey-question';
import { LedgerModel } from '@app/core/model/ledger.model';
import { RecognitionModalService } from '@app/core/service/recognition-modal.service';
import { TenantService } from '@app/core/service/tenant.service';
import { take } from 'rxjs';
import { UserRoles } from '@backend/models/types/user';

@Component({
  selector: 'app-whats-new-modal',
  templateUrl: './whats-new-modal.component.html',
  styleUrls: ['./whats-new-modal.component.scss']
})
export class WhatsNewModalComponent {
  protected announcementsIsLoaded = false;
  protected recognitionsIsLoaded = false;
  protected surveysIsLoaded = false;
  protected recognitionRemindersIsLoaded = false;

  protected announcements: {
    items: AnnouncementModel[];
    count: number;
    totalCount: number;
  } = { items: [], count: 0, totalCount: 0 };
  protected selectedAnnouncement: AnnouncementModel | null = null;
  protected selectedAnnouncementIndex = -1;

  protected recognitions: {
    items: LedgerModel[];
    count: number;
    totalCount: number;
  } = { items: [], count: 0, totalCount: 0 };

  protected surveys: {
    items: {
      survey: MongoStoredObject<IPsychologicalSurvey>;
      questions: MongoStoredObject<IPsychologicalSurveyQuestion>[];
    }[];
    count: number;
    totalCount: number;
  } = { items: [], count: 0, totalCount: 0 };
  protected selectedSurvey: {
    survey: MongoStoredObject<IPsychologicalSurvey>;
    questions: MongoStoredObject<IPsychologicalSurveyQuestion>[];
  } = null;

  protected recognitionReminders: {
    items: { title: string }[];
    count: number;
    totalCount: number;
  } = {
    items: [],
    count: 0,
    totalCount: 0
  };
  protected recognitionReminderButtons = [
    {
      id: 'send-recognition',
      text: 'Send Recognition'
    },
    {
      id: 'dismiss',
      text: 'Dismiss'
    }
  ];

  protected isAdmin: boolean;
  protected authenticatedUser: UserModel;

  public constructor(
    private readonly _authenticationService: AuthenticationService,
    private readonly _ledgerApiService: LedgerApiService,
    private readonly _loadingCtrl: LoadingController,
    private readonly _toastService: ToastService,
    private readonly _whatsNewService: WhatsNewService,
    private readonly _modalCtrl: ModalController,
    private readonly _recognitionApiService: RecognitionApiService,
    private readonly _recognitionModalService: RecognitionModalService,
    private readonly _tenantService: TenantService
  ) {
    this.authenticatedUser = this._authenticationService.user;
    this.isAdmin = this.authenticatedUser
      ? this.authenticatedUser.roles.includes(UserRoles.Admin)
      : false;

    this._whatsNewService.announcements$.subscribe((announcements) => {
      if (
        announcements.count === 0 &&
        this.recognitions.count === 0 &&
        this.recognitionsIsLoaded &&
        this.surveys.count === 0 &&
        this.surveysIsLoaded &&
        this.recognitionReminders.count === 0 &&
        this.recognitionRemindersIsLoaded
      ) {
        const whatsNewModal = document.getElementById('whats-new-modal');
        if (whatsNewModal) {
          this._modalCtrl.dismiss(null, 'confirm', 'whats-new-modal');
        }
      }

      this.announcements = announcements;
      this.announcementsIsLoaded = true;
    });
    this._whatsNewService.recognitions$.subscribe((recognitions) => {
      if (
        recognitions.count === 0 &&
        this.announcements.count === 0 &&
        this.announcementsIsLoaded &&
        this.surveys.count === 0 &&
        this.surveysIsLoaded &&
        this.recognitionReminders.count === 0 &&
        this.recognitionRemindersIsLoaded
      ) {
        const whatsNewModal = document.getElementById('whats-new-modal');
        if (whatsNewModal) {
          this._modalCtrl.dismiss(null, 'confirm', 'whats-new-modal');
        }
      }

      this.recognitions = recognitions;
      this.recognitionsIsLoaded = true;
    });
    this._whatsNewService.surveys$.subscribe((surveys) => {
      if (
        surveys.count === 0 &&
        this.recognitions.count === 0 &&
        this.recognitionsIsLoaded &&
        this.announcements.count === 0 &&
        this.announcementsIsLoaded &&
        this.recognitionReminders.count === 0 &&
        this.recognitionRemindersIsLoaded
      ) {
        const whatsNewModal = document.getElementById('whats-new-modal');
        if (whatsNewModal) {
          this._modalCtrl.dismiss(null, 'confirm', 'whats-new-modal');
        }
      }

      this.surveys = surveys;
      this.surveysIsLoaded = true;
    });
    this._whatsNewService.recognitionReminders$.subscribe(
      (recognitionReminders) => {
        if (
          recognitionReminders.count === 0 &&
          this.recognitions.count === 0 &&
          this.recognitionsIsLoaded &&
          this.announcements.count === 0 &&
          this.announcementsIsLoaded &&
          this.surveys.count === 0 &&
          this.surveysIsLoaded
        ) {
          const whatsNewModal = document.getElementById('whats-new-modal');
          if (whatsNewModal) {
            this._modalCtrl.dismiss(null, 'confirm', 'whats-new-modal');
          }
        }

        this.recognitionReminders = recognitionReminders;
        this.recognitionRemindersIsLoaded = true;
      }
    );
  }

  protected async readRecognition(recognition: LedgerModel, index: number) {
    const loading = await this._loadingCtrl.create({
      message: 'Loading...'
    });
    loading.present();

    this._tenantService.tenant$.pipe(take(1)).subscribe((tenant) => {
      this._ledgerApiService
        .readLedgerById({ path: { id: recognition._id, tenantId: tenant._id } })
        .subscribe(() => {
          loading.dismiss();
          this._toastService.presentToast(
            'The recognition was successfully marked as read!'
          );
          this.removeItemFromList(index, 'recognition', recognition._id);
        });
    });
  }

  protected onAnnouncementClick(
    announcement: AnnouncementModel,
    index: number
  ) {
    if (!this.selectedAnnouncement) {
      this.selectedAnnouncement = announcement;
      this.selectedAnnouncementIndex = index;
    }
  }

  protected onSurveyClick(survey: {
    survey: MongoStoredObject<IPsychologicalSurvey>;
    questions: MongoStoredObject<IPsychologicalSurveyQuestion>[];
  }) {
    this.selectedSurvey = survey;
  }

  protected onAnnouncementAcknowledged(event: { index: number; id: string }) {
    this.removeItemFromList(event.index, 'announcement', event.id);
  }

  protected onAnnouncementResponded(event: { index: number; id: string }) {
    this.removeItemFromList(event.index, 'announcement', event.id);
  }

  protected onSurveyCompleted() {
    this.removeItemFromList(0, 'survey', this.surveys.items[0].survey._id);
  }

  protected removeItemFromList(
    index: number,
    type: 'announcement' | 'recognition' | 'survey' | 'recognitionReminder',
    itemId: string
  ) {
    const remove = (event: any) => {
      event.target.remove();
      this._whatsNewService.removeItemFromTheList(itemId, type);
    };

    const article = document.querySelector(
      `.whats-new-item--${type}[data-index="${index}"]`
    );

    article.classList.add('whats-new-item--removed');

    article.addEventListener('animationend', remove);
    article.addEventListener('webkitAnimationEnd', remove);
  }

  protected onAnnouncementPreviewClose() {
    this.selectedAnnouncementIndex = -1;

    setTimeout(() => {
      this.selectedAnnouncement = null;
    }, 500);
  }

  protected onSurveyPreviewClose() {
    this.selectedSurvey = null;
  }

  protected trackRecognitionsBy(_index: number, recognition: LedgerModel) {
    return recognition._id.toString();
  }

  protected trackAnnouncementsBy(
    _index: number,
    announcement: AnnouncementModel
  ) {
    return announcement._id;
  }

  protected trackRemindersBy(_index: number, reminder: { title: string }) {
    return reminder.title;
  }

  protected trackSurveysBy(
    _index: number,
    survey: {
      survey: MongoStoredObject<IPsychologicalSurvey>;
      questions: MongoStoredObject<IPsychologicalSurveyQuestion>[];
    }
  ) {
    return survey.survey._id;
  }

  protected async whatsNewItemButtonClick(buttonId: string) {
    switch (buttonId) {
      case 'send-recognition':
        this.showRecognitionModal();
        break;
      case 'dismiss':
        this.dismissRecognitionReminder();
        break;
    }
  }

  protected async dismissRecognitionReminder() {
    const loading = await this._loadingCtrl.create({
      message: 'Dismissing...'
    });
    loading.present();
    this._recognitionApiService.dismissRecognitionReminder().subscribe(() => {
      loading.dismiss();
      this.removeItemFromList(0, 'recognitionReminder', '');
    });
  }

  protected async showRecognitionModal() {
    const { recognition, role } =
      await this._recognitionModalService.showEditRecognitionModal({
        createMode: true
      });

    if (role === 'confirm') {
      const loading = await this._loadingCtrl.create({
        message: 'Posting...'
      });
      loading.present();

      this._recognitionApiService
        .postRecognition({
          body: {
            recipientId: recognition.userId,
            behaviorId: recognition.behaviorId,
            description: recognition.description,
            isPrivate: recognition.isPrivate
          }
        })
        .subscribe({
          next: () => {
            loading.dismiss();
            this.removeItemFromList(0, 'recognitionReminder', '');
          },
          error: (e: { error: { message: string } }) => {
            loading.dismiss();
            this._toastService.presentToast(
              e.error?.message || 'There was an error creating your post'
            );
          }
        });
    }
  }
}
