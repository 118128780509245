import { Component, ViewChild } from '@angular/core';
import { HapticsService } from '@app/core/service/haptics.service';
import { TenantService } from '@app/core/service/tenant.service';
import {
  DailySurveyDefinition,
  EDailySurveyFrequency,
  EDailySurveyMode
} from '@backend/types/daily-survey-definition';
import { TSpecBodyPatchTenant } from '@backend/types/generated/Tenants/patchTenant';
import { IonLoading, IonNav, PickerButton, PickerColumn } from '@ionic/angular';
import { createDefaultDailySurveyFrequency } from '@app/utils/create-default-daily-survey-frequency';
import { ITenantEmailFlags } from '@backend/models/types/tenant';
import { addDays, addMonths, addWeeks, format } from 'date-fns';

@Component({
  selector: 'app-pulse-settings',
  templateUrl: './pulse-settings.component.html',
  styleUrls: ['./pulse-settings.component.scss']
})
export class PulseSettingsComponent {
  @ViewChild('loading', { static: true })
  public loading!: IonLoading;

  protected readonly dailySurveyMode = EDailySurveyMode;
  protected readonly dailySurveyFrequency = EDailySurveyFrequency;

  protected emailFlags: ITenantEmailFlags = {
    isSendingSupervisorEmailsRegardingLedger: false,
    isSendingLedgerEmailsEnabled: false,
    isSendingDailySurveyEmailsEnabled: false
  };
  protected dailySurveySettings: DailySurveyDefinition;
  protected surveyTimePickerColumns: PickerColumn[] = [];
  protected surveyTimePickerButtons: PickerButton[] = [
    {
      text: 'Cancel',
      role: 'cancel'
    },
    {
      text: 'Confirm',
      handler: (value: { surveyTime: { text: string; value: number } }) => {
        this.dailySurveySettings.dailySurveyPNTime = value.surveyTime.value;
      }
    }
  ];
  protected surveyTimePickerOptions = [
    { text: '15 minutes', value: 15 },
    { text: '20 minutes', value: 20 },
    { text: '25 minutes', value: 25 },
    { text: '30 minutes', value: 30 },
    { text: '45 minutes', value: 45 },
    { text: '1 hour', value: 60 }
  ];

  public constructor(
    private readonly _ionNav: IonNav,
    private readonly _tenantService: TenantService,
    private readonly _hapticsService: HapticsService
  ) {
    this._tenantService.tenant$.subscribe((tenant) => {
      if (this.loading) {
        this.loading.dismiss();
      }

      this.emailFlags = tenant.emailFlags;
      this.dailySurveySettings = tenant.settings.dailySurvey;
      this.surveyTimePickerColumns = [
        {
          name: 'surveyTime',
          options: this.surveyTimePickerOptions,
          selectedIndex: this.surveyTimePickerOptions.reduce<number>(
            (prev, option, index) =>
              tenant.settings.dailySurvey.dailySurveyPNTime === option.value
                ? index
                : prev,
            0
          )
        }
      ];
    });
  }

  protected onBackButtonClick() {
    this._ionNav.pop();
  }

  private _patch(patch: TSpecBodyPatchTenant): void {
    this.loading.present();
    this._tenantService.patch(patch);
  }

  protected onDailySurveyModeChange(event: any) {
    if (event && event.detail) {
      this.dailySurveySettings.dailySurveyMode = event.detail.value;
      this._patch({
        settings: {
          dailySurvey: {
            ...this.dailySurveySettings,
            dailySurveyMode: event.detail.value
          }
        }
      });
    }
  }

  protected onDailySurveyPNTimeChange(event: any): void {
    this._hapticsService.hapticsImpact();

    if (event.detail.data) {
      this.dailySurveySettings.dailySurveyPNTime =
        event.detail.data.surveyTime.value;
      this._patch({
        settings: {
          dailySurvey: {
            ...this.dailySurveySettings,
            dailySurveyPNTime: event.detail.data.surveyTime.value
          }
        }
      });
    }
  }

  protected get currentSurveyTimeText() {
    return this.surveyTimePickerOptions.find(
      (option) => this.dailySurveySettings.dailySurveyPNTime === option.value,
      0
    ).text;
  }

  protected onDailySurveyFrequencyChange(event: {
    detail: { value: EDailySurveyFrequency };
  }): void {
    const dates = createDefaultDailySurveyFrequency(event.detail.value);

    this._patch({
      settings: {
        dailySurvey: {
          ...this.dailySurveySettings,
          ...dates
        }
      }
    });
  }

  protected saveDailySurveyDates() {
    let date1 = this.dailySurveySettings.date1;
    let date2 = null;

    this._patch({
      settings: {
        dailySurvey: {
          ...this.dailySurveySettings,
          date1,
          date2
        }
      }
    });
  }

  protected onEmailFlagChange(event: any, emailFlag: keyof ITenantEmailFlags) {
    if (event && event.detail) {
      this.emailFlags[emailFlag] = event.detail.checked;
      this._patch({
        emailFlags: {
          ...this.emailFlags,
          [emailFlag]: event.detail.checked
        }
      });
    }
  }

  protected get postText() {
    switch (this.dailySurveySettings.dailySurveyMode) {
      case EDailySurveyMode.REQUIRED:
        return 'Prompt users to complete the survey about their most recent shift the next time they open the app';
      case EDailySurveyMode.PUSH_NOTIFICATION:
        return `Prompt the users to complete the survey
            ${this.currentSurveyTimeText} prior to the end of their scheduled
            shift end time`;
      case EDailySurveyMode.OFF:
        return 'Do not show the survey';
    }
  }

  protected get dailySurveyFrequencyMin(): string {
    return format(addDays(new Date(), 1), 'yyyy-MM-dd');
  }

  protected get dailySurveyFrequecnyMax(): string {
    let date = new Date();
    switch (this.dailySurveySettings.frequency) {
      case EDailySurveyFrequency.EVERY_WEEK:
        date = addWeeks(date, 1);
        break;
      case EDailySurveyFrequency.EVERY_TWO_WEEKS:
        date = addWeeks(date, 2);
        break;
      case EDailySurveyFrequency.ONCE_PER_MONTH:
        date = addMonths(date, 1);
        break;
    }
    return format(date, 'yyyy-MM-dd');
  }
}
