import { Injectable } from '@angular/core';
import { Observable, Subject, repeat, shareReplay, tap } from 'rxjs';
import { TenantApiService } from './api/tenant.api.service';
import { MongoStoredObject } from '@app/types/mongo-stored-object';
import { ITenant, ITenantFeatures } from '@backend/models/types/tenant';
import { ObjectId } from '@app/types/object-id';
import { IIntegration } from '@backend/models/types/integration';
import { TSpecBodyCreateTenant } from '@backend/types/generated/Tenants/createTenant';
import { TSpecBodyPatchTenantById } from '@backend/types/generated/Tenants/patchTenantById';

@Injectable({ providedIn: 'root' })
export class TenantListService {
  private readonly _update$ = new Subject<void>();

  public tenants$: Observable<
    MongoStoredObject<ITenant & { integrations: IIntegration }>[]
  > = this._tenantApi
    .getAllTenants()
    .pipe(repeat({ delay: () => this._update$ }), shareReplay(1));

  public constructor(private readonly _tenantApi: TenantApiService) {}

  public update(): void {
    this._update$.next();
  }

  public createTenant(body: TSpecBodyCreateTenant): Observable<unknown> {
    return this._tenantApi.createTenant({ body }).pipe(
      tap(() => {
        this._update$.next();
      })
    );
  }

  public patchTenantById(
    id: ObjectId,
    body: TSpecBodyPatchTenantById
  ): Observable<unknown> {
    return this._tenantApi.patchTenantById({ path: { id }, body }).pipe(
      tap(() => {
        this._update$.next();
      })
    );
  }

  public patchTenantFeatures(
    tenantId: ObjectId,
    features: Partial<ITenantFeatures>
  ): Observable<unknown> {
    return this._tenantApi
      .patchTenantFeatures({ path: { tenantId }, body: features })
      .pipe(
        tap(() => {
          this._update$.next();
        })
      );
  }
}
