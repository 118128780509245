import { Component, ViewChild } from '@angular/core';
import { DailyReportApiService } from '@app/core/service/api/dailyReport.api.service';
import { MongoStoredObject } from '@app/types/mongo-stored-object';
import { ITaskPerformance } from '@backend/models/types/task-performance';
import { ITask } from '@backend/models/types/task';
import { add, endOfDay, startOfDay, sub } from 'date-fns';
import { IonPopover, ModalController, IonNav } from '@ionic/angular';
import { AuthenticationService } from '@app/core';
import { ChecklistService } from '@app/core/service/checklist.service';
import { TaskListService } from '@app/core/service/task-list.service';
import { combineLatest, of, take } from 'rxjs';
import { getZonedTime } from '@app/utils/get-zoned-time';
import { ChecklistItem } from '@app/types/checklist-item';
import { IAttachment } from '@backend/models/types/attachment';
import { TaskHistoryModalComponent } from '@app/modules/task-history-modal/task-history-modal.component';
import { TasksCompletedComponent } from '@app/dashboard/dashboard/insights/tasks-completed/tasks-completed.component';
import { TaskFolderListService } from '@app/core/service/task-folder-list.service';
import { UserRoles } from '@backend/models/types/user';

const groupByTask = (
  array: MongoStoredObject<
    ITaskPerformance & {
      task: MongoStoredObject<ITask>;
      note?: {
        attachments: MongoStoredObject<IAttachment>[];
      };
    }
  >[]
) => {
  return array.reduce(
    (
      hash: {
        [key: string]: MongoStoredObject<
          ITaskPerformance & {
            task: MongoStoredObject<ITask>;
            note?: {
              attachments: MongoStoredObject<IAttachment>[];
            };
          }
        >[];
      },
      obj
    ) => {
      return Object.assign(hash, {
        [obj.task._id]: (hash[obj.task._id] || []).concat(obj)
      });
    },
    {}
  );
};

@Component({
  selector: 'app-daily-report',
  templateUrl: './daily-report.component.html',
  styleUrls: ['./daily-report.component.scss']
})
export class DailyReportComponent {
  @ViewChild('selectedDatePopover')
  protected selectedDatePopover: IonPopover;

  protected isAdmin = false;
  protected isLoading = false;
  protected selectedDate: Date = new Date();
  protected dailyReport: {
    completed: {
      userId: string;
      fullName: string;
      points: number;
      performances: MongoStoredObject<
        ITaskPerformance & {
          task: MongoStoredObject<ITask>;
          note?: {
            attachments: MongoStoredObject<IAttachment>[];
          };
        }
      >[];
    }[];
  } = { completed: [] };
  protected completedGrouped: {
    userId: string;
    fullName: string;
    points: number;
    performances: MongoStoredObject<
      ITaskPerformance & {
        task: MongoStoredObject<ITask>;
        note?: {
          attachments: MongoStoredObject<IAttachment>[];
        };
      }
    >[][];
  }[] = [];
  protected incompleteCriticalTasksGrouped: {
    taskFolderId: string;
    taskFolderTitle: string;
    items: ChecklistItem[];
  }[] = [];

  public constructor(
    private readonly _dailyReportApiService: DailyReportApiService,
    private readonly _authenticationService: AuthenticationService,
    private readonly _checklistService: ChecklistService,
    private readonly _taskListService: TaskListService,
    private readonly _modalCtrl: ModalController,
    private readonly _ionNav: IonNav,
    private readonly _taskFolderListService: TaskFolderListService
  ) {
    this.isAdmin = this._authenticationService.user
      ? this._authenticationService.user.roles.includes(UserRoles.Admin)
      : false;

    this._fetchDailyReport();
    this._getIncompleteCriticalTasks();
  }

  private _fetchDailyReport() {
    this.isLoading = true;
    this._dailyReportApiService
      .getDailyReport({
        query: {
          from: startOfDay(this.selectedDate).toISOString(),
          to: endOfDay(this.selectedDate).toISOString()
        }
      })
      .subscribe((dailyReport) => {
        this.dailyReport = dailyReport;
        this.completedGrouped = dailyReport.completed.map((user) => ({
          ...user,
          performances: Object.values(groupByTask(user.performances))
        }));
        this.isLoading = false;
      });
  }

  private _getIncompleteCriticalTasks() {
    const start = startOfDay(getZonedTime(this.selectedDate)); // beginning of today
    const end = endOfDay(start); // end of today

    combineLatest([
      this._taskFolderListService.taskFolders$,
      this._checklistService.getMyChecklist(
        this._taskListService.list$,
        of({ start, end })
      )
    ])
      .pipe(take(1))
      .subscribe(([taskFolders, tasksForDate]) => {
        const tasks = tasksForDate.filter(
          (task) => !task.isCompleted() && task.task.isCritical
        );

        const sorted = tasks.sort(
          (a, b) => a.task.orderIndex - b.task.orderIndex
        );

        const grouped = taskFolders
          .map((tf) => ({
            taskFolderId: tf._id.toString(),
            taskFolderTitle: tf.title,
            items: sorted.filter((item) => item.task.taskFolder === tf._id)
          }))
          .filter((tf) => tf.items.length);

        const withoutTaskFolder = sorted.filter(
          (item) => !item.task.taskFolder
        );

        if (withoutTaskFolder.length) {
          grouped.push({
            taskFolderId: 'WITHOUT_TASK_FOLDER',
            taskFolderTitle: 'Ungrouped',
            items: withoutTaskFolder
          });
        }

        this.incompleteCriticalTasksGrouped = grouped;
      });
  }

  protected onCalendarDateClick(event: any) {
    if (event.detail.value && typeof event.detail.value === 'string') {
      this.selectedDate = new Date(event.detail.value);
      this._fetchDailyReport();
      this._getIncompleteCriticalTasks();

      this.selectedDatePopover.dismiss();
    }
  }

  protected onPrevDayClick() {
    this.selectedDate = sub(this.selectedDate, { days: 1 });
    this._fetchDailyReport();
    this._getIncompleteCriticalTasks();
  }

  protected onNextDayClick() {
    this.selectedDate = add(this.selectedDate, { days: 1 });
    this._fetchDailyReport();
    this._getIncompleteCriticalTasks();
  }

  protected async onPerformanceClick(
    performance: MongoStoredObject<
      ITaskPerformance & {
        task: MongoStoredObject<ITask>;
        note?: {
          attachments: MongoStoredObject<IAttachment>[];
        };
      }
    >
  ) {
    const taskHistoryModal = await this._modalCtrl.create({
      id: 'task-history-modal',
      component: TaskHistoryModalComponent,
      componentProps: {
        taskId: performance.task._id
      },
      breakpoints: [0, 1],
      initialBreakpoint: 1,
      handle: false
    });
    taskHistoryModal.present();
  }

  protected showTasksCompletedOfUser(userId: string, fullName: string) {
    this._ionNav.push(TasksCompletedComponent, {
      userId,
      fullName,
      defaultRange: {
        start: startOfDay(this.selectedDate),
        end: endOfDay(this.selectedDate)
      }
    });
  }

  protected async onBackButtonClick() {
    this._ionNav.pop();
  }
}
