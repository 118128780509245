import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { ToastService } from '@app/core/service/toast.service';
import { IonLoading } from '@ionic/angular';
import { UploadFileApiService } from '@app/core/service/api/uploadFile.api.service';
import { SuggestionApiService } from '@app/core/service/api/suggestion.api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TenantApiService } from '@app/core/service/api/tenant.api.service';
import { TenantPublicModel } from '@app/core/model/tenant.model';
import { SuggestionCategoryApiService } from '@app/core/service/api/suggestionCategory.api.service';
import { combineLatest, take } from 'rxjs';
import { ObjectId } from '@app/types/object-id';
import { IonModal } from '@ionic/angular/common';
import { SuggestionCategoryModel } from '@app/core/model/suggestion-category.model';

@Component({
  selector: 'app-suggestion-form',
  templateUrl: './suggestion-form.component.html',
  styleUrls: ['./suggestion-form.component.scss']
})
export class SuggestionFormComponent implements AfterViewInit {
  @ViewChild('userSelectorModal', { static: true })
  public supervisorSelectorModal!: IonModal;

  @ViewChild('loading', { static: true })
  public loading!: IonLoading;

  protected body = '';
  protected tenant: TenantPublicModel = null;
  protected selectedUser: ObjectId | null = null;
  protected categories: SuggestionCategoryModel[] = [];
  protected selectedCategory: string | null = null;

  public constructor(
    private readonly _suggestionApiService: SuggestionApiService,
    private readonly _suggestionCategoryApiService: SuggestionCategoryApiService,
    private readonly _toastService: ToastService,
    private readonly _uploadFileApiService: UploadFileApiService,
    private readonly _route: ActivatedRoute,
    private readonly _router: Router,
    private readonly _tenantApiService: TenantApiService
  ) {}

  public ngAfterViewInit(): void {
    this.loading.present();
    this._route.params.subscribe((params) => {
      if (params.tenant && params.tenant.length) {
        combineLatest([
          this._tenantApiService.getPublicTenantDataWithUsers({
            path: { tenantId: params.tenant }
          }),
          this._suggestionCategoryApiService.getSuggestionCategories({
            query: { tenantId: params.tenant }
          })
        ])
          .pipe(take(1))
          .subscribe({
            next: ([tenant, categories]) => {
              this.loading.dismiss();
              if (!tenant.suggestionsFeatureEnabled) {
                this._toastService.presentToast(
                  'Suggestions feature is disabled'
                );
                this._router.navigate(['/']);
              } else {
                this.tenant = tenant;
                this.categories = categories;
              }
            },
            error: () => {
              this.loading.dismiss();
              this._toastService.presentToast('Tenant not found');
              this._router.navigate(['/']);
            }
          });
      } else {
        this.loading.dismiss();
        this._router.navigate(['/']);
      }
    });
  }

  protected async onFormSubmit() {
    this.loading.present();

    this._uploadFileApiService
      .extractAttachmentsFromBody(this.body || '', [])
      .subscribe({
        next: ({ body, newAttachments, keptAttachments }) => {
          this._suggestionApiService
            .postOpportunity({
              body: {
                body,
                attachments: [
                  ...keptAttachments,
                  ...newAttachments.map((a) => a._id.toString())
                ],
                tenant: this.tenant._id,
                author: this.selectedUser || undefined,
                category: this.selectedCategory
              }
            })
            .subscribe({
              next: () => {
                this.loading.dismiss();
                this._toastService.presentToast('Suggestion Submitted!', {
                  okText:
                    this.selectedUser && this.tenant.suggestionSubmitPoints
                      ? `+${this.tenant.suggestionSubmitPoints} points`
                      : ''
                });
                this._router.navigate(['/']);
              },
              error: (e) => {
                this.loading.dismiss();
                this._toastService.presentToast(
                  e.error?.message || 'Unable to submit suggestion'
                );
              }
            });
        },
        error: (e) => {
          console.log(e);
          this.loading.dismiss();
          this._toastService.presentToast('Unable to upload attachments');
        }
      });
  }

  protected get canSave() {
    return !!this.body;
  }

  protected get selectedUserIds(): ObjectId[] {
    return this.selectedUser ? [this.selectedUser] : [];
  }

  protected onUserChange(event: ObjectId[]): void {
    this.selectedUser = event[0];
    this.supervisorSelectorModal.dismiss();
  }

  protected get formattedUser(): string {
    const user = this.tenant?.users.find(
      (user) => user._id === this.selectedUser
    );
    return user ? `${user.firstName} ${user.lastName}` : '';
  }

  protected handleUserClearClick(): void {
    this.selectedUser = null;
  }

  protected onCloseClick() {
    this._router.navigate(['/']);
  }
}
