// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_getUnansweredPsychologicalSurveys = () => "/psychological-survey/unanswered";

export type TSpecHandler_getUnansweredPsychologicalSurveys = RequestHandler<TSpecPathGetUnansweredPsychologicalSurveys, any, TSpecBodyGetUnansweredPsychologicalSurveys, TSpecQueryGetUnansweredPsychologicalSurveys, {}>;

export interface TSpecPathGetUnansweredPsychologicalSurveys {  }

export interface TSpecBodyGetUnansweredPsychologicalSurveys {}

export interface TSpecQueryGetUnansweredPsychologicalSurveys {
  skipSurveyMode?: boolean;
}
