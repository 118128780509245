import { Component, ViewChild } from '@angular/core';
import { LibraryApiService } from '@app/core/service/api/library.api.service';
import {
  LoadingController,
  ViewDidEnter,
  IonInfiniteScroll,
  IonNav,
  ViewWillEnter
} from '@ionic/angular';
import { AuthenticationService } from '@app/core';
import { LibraryDocAddComponent } from './components/library-doc-add/library-doc-add.component';
import { CultureComponent } from './components/culture/culture.component';
import { LibraryDocModel } from '@app/core/model/library-doc.model';
import { PullToSearchComponent } from '@app/modules/pull-to-search/pull-to-search.component';
import { UserRoles } from '@backend/models/types/user';

@Component({
  selector: 'app-library',
  templateUrl: './library.component.html',
  styleUrls: ['./library.component.scss']
})
export class LibraryComponent implements ViewWillEnter, ViewDidEnter {
  @ViewChild('infiniteScroll')
  public infiniteScroll: IonInfiniteScroll;

  @ViewChild('pullToSearch', { static: true })
  public pullToSearch!: PullToSearchComponent;

  protected isAdmin = false;
  protected isLoading = false;
  protected libraryDocs: {
    originalDoc: LibraryDocModel;
    versions: LibraryDocModel[];
    latestVersion: LibraryDocModel | null;
  }[] = [];
  protected hasNextPage = true;
  protected searchTerm = '';
  protected page = 1;
  protected pageSize = 20;
  protected selectedLibraryDoc = -1;

  public constructor(
    private readonly _libraryApiService: LibraryApiService,
    private readonly _loadingCtrl: LoadingController,
    private readonly _ionNav: IonNav,
    private readonly _authenticationService: AuthenticationService
  ) {
    this.isAdmin = this._authenticationService.user.roles.includes(
      UserRoles.Admin
    );
  }

  public ionViewWillEnter(): void {
    this._fetchLibraryDocs(true);
  }

  public async ionViewDidEnter() {
    const topLoading = await this._loadingCtrl.getTop();
    if (topLoading) {
      topLoading.dismiss();
    }
  }

  protected _fetchLibraryDocs(reset = false) {
    if (reset) {
      this.isLoading = true;
      this.page = 1;
      this.hasNextPage = true;
      this.libraryDocs = [];
    }

    this._libraryApiService
      .getAllLibraryDocs({
        query: {
          page: this.page.toString(),
          limit: this.pageSize.toString(),
          searchTerm: this.searchTerm
        }
      })
      .subscribe((fetchedDocs) => {
        this.hasNextPage = fetchedDocs.length === this.pageSize;
        this.libraryDocs = this.libraryDocs.concat(fetchedDocs);
        this.isLoading = false;
        this.pullToSearch.initSearch();
        this.infiniteScroll?.complete();
      });
  }

  protected onIonInfinite() {
    this.page = this.page + 1;
    this._fetchLibraryDocs();
  }

  protected handleSearchInput(value: string) {
    this.searchTerm = value;
    this._fetchLibraryDocs(true);
  }

  protected trackDocs(_: number, item: { originalDoc: LibraryDocModel }) {
    return item.originalDoc._id;
  }

  protected onAddClick(): void {
    this._ionNav.push(LibraryDocAddComponent);
  }

  protected openCulturePage() {
    this._ionNav.push(CultureComponent);
  }

  protected expandLibraryDoc(i: number) {
    this.selectedLibraryDoc = this.selectedLibraryDoc === i ? -1 : i;
  }
}
