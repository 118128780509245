import { NgModule } from '@angular/core';
import { EditRecognitionModalComponent } from './edit-recognition-modal.component';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { WideButtonComponent } from '../wide-button/wide-button.component';
import { DirectivesModule } from '@app/modules/directives.module';
import { WidthRestrictedContainerModule } from '../width-restricted-container/width-restricted-container.module';
import { UserSelectorModalModule } from '@app/modals/user-selector-modal/user-selector-modal.module';
import { BehaviorSelectorModalModule } from '@app/modals/behavior-selector-modal/behavior-selector-modal.module';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    ReactiveFormsModule,
    WideButtonComponent,
    DirectivesModule,
    WidthRestrictedContainerModule,
    UserSelectorModalModule,
    BehaviorSelectorModalModule
  ],
  exports: [EditRecognitionModalComponent],
  declarations: [EditRecognitionModalComponent]
})
export class EditRecognitionModalModule {}
