import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { UserModel } from '@app/core/model/user.model';
import { TenantService } from '@app/core/service/tenant.service';
import { UserListService } from '@app/core/service/user-list.service';
import { ObjectId } from '@app/types/object-id';
import { TSpecBodyPatchTenant } from '@backend/types/generated/Tenants/patchTenant';
import { SuggestionsDefinition } from '@backend/types/suggestions-definition';
import { IonLoading, IonNav, ModalController } from '@ionic/angular';
import { IonModal } from '@ionic/angular/common';
import { map, take } from 'rxjs';
import { Clipboard } from '@angular/cdk/clipboard';
import { ToastService } from '@app/core/service/toast.service';
import { EnvironmentService } from '@app/core/service/environment.service';
import { SuggestionCategoryApiService } from '@app/core/service/api/suggestionCategory.api.service';
import { SuggestionCategoryModalComponent } from '@app/modals/suggestion-category-modal/suggestion-category-modal.component';
import { SuggestionCategoryModel } from '@app/core/model/suggestion-category.model';
import { showModal, showUserSelectorModal } from '@app/utils/modal';

@Component({
  selector: 'app-suggestions-settings',
  templateUrl: './suggestions-settings.component.html',
  styleUrls: ['./suggestions-settings.component.scss']
})
export class SuggestionsSettingsComponent implements AfterViewInit {
  @ViewChild('loading', { static: true })
  public loading!: IonLoading;

  @ViewChild('notifyUsersOnNewSolutionsModal', { static: true })
  public notifyUsersOnNewSolutionsModal!: IonModal;

  @ViewChild('notifyUsersOnOpportunityStatusChangeModal', { static: true })
  public notifyUsersOnOpportunityStatusChangeModal!: IonModal;

  protected allUsers: UserModel[] = [];
  protected suggestionsSettings: Omit<
    SuggestionsDefinition,
    | 'notifyUsersOnNewOpportunities'
    | 'notifyUsersOnNewSolutions'
    | 'notifyUsersOnOpportunityStatusChange'
  > & {
    notifyUsersOnNewOpportunities: ObjectId[];
    notifyUsersOnNewSolutions: ObjectId[];
    notifyUsersOnOpportunityStatusChange: ObjectId[];
  };
  protected webAppUrl = '';
  protected tenantId = '';
  protected categories: SuggestionCategoryModel[] = [];

  public constructor(
    private readonly _ionNav: IonNav,
    private readonly _tenantService: TenantService,
    private readonly _userListService: UserListService,
    private readonly _clipboard: Clipboard,
    private readonly _toastService: ToastService,
    private readonly _environmentService: EnvironmentService,
    private readonly _suggestionCategoryApiService: SuggestionCategoryApiService,
    private readonly _modalCtrl: ModalController
  ) {
    this.webAppUrl = this._environmentService.webAppUrl;

    this._userListService.users$
      .pipe(map((list) => list.filter(({ isDeleted }) => !isDeleted)))
      .subscribe((allUsers) => {
        this.allUsers = allUsers;
      });

    this._tenantService.tenant$.subscribe((tenant) => {
      if (this.loading) {
        this.loading.dismiss();
      }

      this.tenantId = tenant._id;
      this.suggestionsSettings = {
        ...tenant.settings.suggestions,
        notifyUsersOnNewOpportunities:
          (tenant.settings.suggestions
            .notifyUsersOnNewOpportunities as unknown as ObjectId[]) || [],
        notifyUsersOnNewSolutions:
          (tenant.settings.suggestions
            .notifyUsersOnNewSolutions as unknown as ObjectId[]) || [],
        notifyUsersOnOpportunityStatusChange:
          (tenant.settings.suggestions
            .notifyUsersOnOpportunityStatusChange as unknown as ObjectId[]) ||
          []
      };
    });
  }

  public ngAfterViewInit(): void {
    this._loadCategories();
  }

  private _loadCategories() {
    this._tenantService.tenant$.pipe(take(1)).subscribe((tenant) => {
      this.loading.present();

      this._suggestionCategoryApiService
        .getSuggestionCategories({
          query: { tenantId: tenant._id }
        })
        .subscribe({
          next: (categories) => {
            this.loading.dismiss();
            this.categories = categories;
          },
          error: (e) => {
            this.loading.dismiss();
            this._toastService.presentToast(
              e.error?.message || 'Unable to fetch suggestion categories'
            );
          }
        });
    });
  }

  protected onBackButtonClick() {
    this._ionNav.pop();
  }

  private _patch(patch: TSpecBodyPatchTenant): void {
    this.loading.present();
    this._tenantService.patch(patch);
  }

  protected onCopySuggestionLinkClick() {
    this._clipboard.copy(`${this.webAppUrl}/#/suggestion/${this.tenantId}`);
    this._toastService.presentToast('Link copied');
  }

  protected trackCategories(_: number, item: SuggestionCategoryModel) {
    return item._id;
  }

  protected async addCategory() {
    const { role, data } = await showModal<{
      name: string;
      notifyUsersOnNewOpportunities: ObjectId[];
      notifyUsersOnNewSolutions: ObjectId[];
      notifyUsersOnOpportunityStatusChange: ObjectId[];
    }>(
      {
        component: SuggestionCategoryModalComponent
      },
      this._modalCtrl
    );

    if (role === 'confirm') {
      this.loading.present();
      this._suggestionCategoryApiService
        .addSuggestionCategory({ body: data })
        .subscribe({
          next: (category) => {
            this.loading.dismiss();
            this.categories.push(category);
          },
          error: (e) => {
            this.loading.dismiss();
            this._toastService.presentToast(
              e.error?.message || 'Unable to create suggestion category'
            );
          }
        });
    }
  }

  protected async updateCategory(suggestionCategory: SuggestionCategoryModel) {
    const { role, data } = await showModal<{
      name: string;
      notifyUsersOnNewOpportunities: ObjectId[];
      notifyUsersOnNewSolutions: ObjectId[];
      notifyUsersOnOpportunityStatusChange: ObjectId[];
    }>(
      {
        component: SuggestionCategoryModalComponent,
        props: { suggestionCategory }
      },
      this._modalCtrl
    );

    if (role === 'confirm') {
      this.loading.present();
      this._suggestionCategoryApiService
        .updateSuggestionCategory({
          path: { suggestionCategoryId: suggestionCategory._id },
          body: data
        })
        .subscribe({
          next: (category) => {
            this.loading.dismiss();
            this.categories = this.categories.map((p) =>
              category._id === p._id ? category : p
            );
          },
          error: (e) => {
            this.loading.dismiss();
            this._toastService.presentToast(
              e.error?.message || 'Unable to update suggestion category'
            );
          }
        });
    } else if (role === 'delete') {
      this.loading.present();
      this._suggestionCategoryApiService
        .deleteSuggestionCategory({
          path: { suggestionCategoryId: suggestionCategory._id }
        })
        .subscribe({
          next: () => {
            this.loading.dismiss();
            this.categories = this.categories.filter(
              (category) => category._id !== suggestionCategory._id
            );
          },
          error: (e) => {
            this.loading.dismiss();
            this._toastService.presentToast(
              e.error?.message || 'Unable to delete suggestion category'
            );
          }
        });
    }
  }

  protected async onNotifyUsersOnNewOpportunitiesClick() {
    const { success, selectedUsers } = await showUserSelectorModal(
      {
        users: this.allUsers,
        recipients: this.suggestionsSettings.notifyUsersOnNewOpportunities,
        multiple: true
      },
      this._modalCtrl
    );

    if (success) {
      this.suggestionsSettings.notifyUsersOnNewOpportunities = selectedUsers;
      this._patch({ settings: { suggestions: this.suggestionsSettings } });
    }
  }

  protected async onNotifyUsersOnNewSolutionsClick() {
    const { success, selectedUsers } = await showUserSelectorModal(
      {
        users: this.allUsers,
        recipients: this.suggestionsSettings.notifyUsersOnNewSolutions,
        multiple: true
      },
      this._modalCtrl
    );

    if (success) {
      this.suggestionsSettings.notifyUsersOnNewSolutions = selectedUsers;
      this._patch({ settings: { suggestions: this.suggestionsSettings } });
    }
  }

  protected async onNotifyUsersOnOpportunityStatusChangeClick() {
    const { success, selectedUsers } = await showUserSelectorModal(
      {
        users: this.allUsers,
        recipients:
          this.suggestionsSettings.notifyUsersOnOpportunityStatusChange,
        multiple: true
      },
      this._modalCtrl
    );

    if (success) {
      this.suggestionsSettings.notifyUsersOnOpportunityStatusChange =
        selectedUsers;
      this._patch({ settings: { suggestions: this.suggestionsSettings } });
    }
  }
}
