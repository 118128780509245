import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { SecureStoragePlugin } from 'capacitor-secure-storage-plugin';
import { LocalStorageService } from '../local-storage.service';
import { Router } from '@angular/router';
import { NgZone } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import {
  ActionPerformed,
  PushNotificationSchema,
  PushNotifications,
  Token
} from '@capacitor/push-notifications';
import { AuthToken, LoginPayload } from '../model/authentication.model';
import { ToastService } from '../service/toast.service';
import { UserModel } from '../model/user.model';
import { ConnectedAccountsApiService } from '../service/api/connected-accounts.api.service';

const CUR_USER_KEY = 'cur_user_engine';

/**
 * Provides a base for authentication workflow.
 * The User interface as well as login/logout methods should be replaced with proper implementation.
 */
@Injectable()
export class AuthenticationService {
  private _user: UserModel | null;
  private _authToken: AuthToken | null;
  private _fcmTokenMeta: { token: string; userId: string };

  public user$ = new ReplaySubject<UserModel>(1);

  public lastPushNotification$ = new ReplaySubject<{
    title: string;
    message: string;
    target: string;
  } | null>(null);

  public constructor(
    private readonly _httpClient: HttpClient,
    private readonly _localStorageService: LocalStorageService,
    private readonly _toastService: ToastService,
    private readonly _router: Router,
    private readonly _ngZone: NgZone,
    private readonly _connectedAccountsApiService: ConnectedAccountsApiService
  ) {
    const savedUser = this._localStorageService.getItem(CUR_USER_KEY);
    if (savedUser) {
      this.setUser(JSON.parse(savedUser));
    }

    if (Capacitor.getPlatform() === 'ios') {
      SecureStoragePlugin.get({ key: 'authToken' }).then((res) => {
        if (res?.value) {
          this._authToken = JSON.parse(res.value);
        }
      });
    }

    if (Capacitor.getPlatform() !== 'web') {
      this._fcmTokenMeta = { token: '', userId: '' };
      this.initFCM();
    }
  }

  public login(payload: LoginPayload) {
    return this._httpClient
      .post('/user/login', {
        email: payload.email,
        password: payload.password,
        addConnectedAccount: payload.addConnectedAccount
      })
      .pipe(
        map((body: { authToken: AuthToken; user: UserModel }) => {
          this.setUser(body.user);
          this.setAuth(body.authToken);
          this.refreshNotificationToken();

          return { body, payload };
        })
      );
  }

  public acceptUserInvitation(
    signupToken: string,
    payload: {
      firstName: string;
      lastName: string;
      password: string;
    }
  ) {
    return this._httpClient.post(
      `/users/invite/accept?signupToken=${signupToken}`,
      payload
    );
  }

  public loginWithPinCode(pin: string) {
    const multiUserModeAuthToken = this._localStorageService.getItem(
      'multiUserModeAuthToken'
    );

    return this._httpClient
      .post(
        '/user/pincode-login',
        { pin },
        { headers: { Authorization: `Bearer ${multiUserModeAuthToken || ''}` } }
      )
      .pipe(
        map((body: { authToken: AuthToken; user: UserModel }) => {
          this.setUser(body.user);
          this.setAuth(body.authToken);
          this.refreshNotificationToken();

          if (multiUserModeAuthToken) {
            this._localStorageService.setItem(
              'multiUserModeAuthToken',
              this.getAuthToken().token
            );
          }

          return { body };
        })
      );
  }

  public requestPasswordReset(email: string) {
    return this._httpClient.post(`/user/requestPasswordReset`, {
      email
    });
  }

  public resetPassword(
    id: string,
    data: { password: string; token: string; email: string }
  ): Observable<unknown> {
    return this._httpClient.patch(`/users/${id}/resetPassword`, data);
  }

  public requestEmailChange(newEmail: string, force?: boolean) {
    return this._httpClient.post(`/user/requestEmailChange`, {
      newEmail,
      force
    });
  }

  public verifyEmailChange(changeEmailToken: string) {
    return this._httpClient.post(`/user/verifyEmailChange`, {
      changeEmailToken
    });
  }

  /**
   * Logs out the user and clear local data.
   * @return {Observable<boolean>} True if the user was logged out successfully.
   */
  public logout(params?: {
    navigateTo?: string;
    loginToConnectedAccount?: string;
    addConnectedAccount?: boolean;
  }) {
    if (!params) {
      params = {};
    }
    if (!params.navigateTo) {
      params.navigateTo = 'login';
    }
    if (!params.addConnectedAccount) {
      params.addConnectedAccount = false;
    }

    if (Capacitor.getPlatform() !== 'web') {
      this.removeNotificationToken(`${this._user._id}`).subscribe();
      this._fcmTokenMeta.userId = '';
    }

    if (params.loginToConnectedAccount) {
      this._connectedAccountsApiService
        .loginToConnectedAccount({
          body: { userId: params.loginToConnectedAccount }
        })
        .subscribe((body) => {
          this.setUser(body.user);
          this.setAuth(body.authToken);
          this.refreshNotificationToken();

          this._router.navigate(['/logout'], {
            queryParams: {
              navigateTo: params.navigateTo
            }
          });
        });
    } else {
      this._user = null;
      this._authToken = null;

      localStorage.removeItem(CUR_USER_KEY);
      SecureStoragePlugin.clear();

      this._router.navigate(['/logout'], {
        queryParams: {
          navigateTo: params.navigateTo,
          addConnectedAccount: params.addConnectedAccount.toString()
        }
      });
    }
  }

  /**
   * Refresh user access token
   */
  public refreshToken() {
    return this._httpClient
      .post('/user/refresh', {
        authToken: this._authToken
      })
      .pipe(
        map((body: { authToken: AuthToken }) => {
          const auth: AuthToken = {
            token: body.authToken.token,
            expiresIn: body.authToken.expiresIn,
            userId: body.authToken.userId,
            refreshToken: body.authToken.refreshToken
          };

          this.setAuth(auth);
          return body;
        })
      );
  }

  /**
   * Remove user notification token
   */
  public removeNotificationToken(userId: string) {
    return this._httpClient.post(`/user/notification`, {
      userId,
      fcmToken: ''
    });
  }

  /**
   * Refresh user notification token
   */
  public refreshNotificationToken() {
    const userId = this.user?._id;

    if (
      Capacitor.getPlatform() !== 'web' &&
      this._fcmTokenMeta.token &&
      !this._fcmTokenMeta.userId &&
      userId &&
      this.user?.fcmToken !== this._fcmTokenMeta.token
    ) {
      this._httpClient
        .post(`/user/notification`, {
          userId,
          fcmToken: this._fcmTokenMeta.token
        })
        .subscribe(() => (this._fcmTokenMeta.userId = userId));
    }
  }

  public getAuthToken() {
    return this._authToken;
  }

  /**
   * Checks is the user is authenticated.
   * @return {boolean} True if the user is authenticated.
   */
  public isAuthenticated(): boolean {
    return !!this.user;
  }

  /**
   * Gets the user body.
   * @return {User} The user body or null if the user is not authenticated.
   */
  public get user(): UserModel | null {
    return this._user;
  }

  /**
   * Sets the user body.
   * @param {User=} Authentication.User The user body.
   */
  public setUser(user?: UserModel) {
    this._user = user || null;
    if (user) {
      this._localStorageService.setItem(CUR_USER_KEY, JSON.stringify(user));
      this.user$.next(this._user);
    } else {
      this._localStorageService.clearItem(CUR_USER_KEY);
    }
  }

  /**
   * Sets the user authToken.
   * @param {AuthToken=} Authentication.AuthToken The user authToken.
   */
  public setAuth(authToken?: AuthToken) {
    this._authToken = authToken || null;

    if (authToken) {
      if (Capacitor.getPlatform() === 'ios') {
        SecureStoragePlugin.set({
          key: 'authToken',
          value: JSON.stringify(this._authToken)
        });
      }
    } else {
      SecureStoragePlugin.remove({ key: 'authToken' });
    }
  }

  public initFCM() {
    PushNotifications.addListener('registration', (token: Token) => {
      this._fcmTokenMeta.token = token.value;
      this.refreshNotificationToken();
    });

    // Show us the notification payload if the app is open on our device
    PushNotifications.addListener(
      'pushNotificationReceived',
      (notification: PushNotificationSchema) => {
        if (!notification.data.hideToast) {
          this._toastService.presentToast(
            `${notification.data.title}\n${notification.data.message}`,
            {
              okText: 'Open',
              okCallback: () => {
                this._ngZone.run(() => {
                  this.lastPushNotification$.next(notification.data);
                  this._router.navigateByUrl(notification.data.target);
                });
              }
            }
          );
        }
      }
    );

    // Method called when tapping on a notification
    PushNotifications.addListener(
      'pushNotificationActionPerformed',
      (notification: ActionPerformed) => {
        this._ngZone.run(() => {
          this.lastPushNotification$.next(notification.notification.data);
          this._router.navigateByUrl(notification.notification.data.target);
        });
      }
    );

    this.user$
      .pipe(switchMap(() => PushNotifications.requestPermissions()))
      .subscribe({
        next: (result) => {
          if (result.receive === 'granted') {
            // Register with Apple / Google to receive push via APNS/FCM
            PushNotifications.register();
          }
        },
        error: (error) => {
          //TODO:
        }
      });
  }
}
