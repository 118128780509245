<div class="tag-item-status" *ngIf="status">
  <ion-chip class="ion-no-margin" [color]="status.color">
    {{ status.name }}
    <div *ngIf="tag.customerName" class="tag-item-name ion-text-capitalize">
      {{ tag.customerName }}
    </div>
  </ion-chip>
</div>

<div class="tag-item-body">
  <div class="tag-item-number">#{{ tag.tagNumber }}</div>

  <div class="tag-item-info">
    <div *ngIf="tag.customerCell">
      {{ tag.customerCell }}
    </div>
    <div class="ion-text-capitalize">
      {{ tag.pickupDate }}
    </div>
  </div>
</div>
