import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MongoStoredObject } from '@app/types/mongo-stored-object';
import { ITaskFolder } from '@backend/models/types/task-folder';
import {
  TSpecBodyCreateTaskFolder,
  TSpecRoute_createTaskFolder
} from '@backend/types/generated/TaskFolders/createTaskFolder';
import {
  TSpecPathDeleteTaskFolder,
  TSpecRoute_deleteTaskFolder
} from '@backend/types/generated/TaskFolders/deleteTaskFolder';
import { TSpecRoute_getTaskFolders } from '@backend/types/generated/TaskFolders/getTaskFolders';
import {
  TSpecBodyReorderTaskFoldersMultiple,
  TSpecRoute_reorderTaskFoldersMultiple
} from '@backend/types/generated/TaskFolders/reorderTaskFoldersMultiple';
import {
  TSpecBodyUpdateTaskFolder,
  TSpecPathUpdateTaskFolder,
  TSpecRoute_updateTaskFolder
} from '@backend/types/generated/TaskFolders/updateTaskFolder';
import { Observable } from 'rxjs';

@Injectable()
export class TaskFolderApiService {
  public constructor(private readonly _httpClient: HttpClient) {}

  public getTaskFolders(): Observable<MongoStoredObject<ITaskFolder>[]> {
    return this._httpClient.get<MongoStoredObject<ITaskFolder>[]>(
      TSpecRoute_getTaskFolders()
    );
  }

  public createTaskFolder(params: {
    body: TSpecBodyCreateTaskFolder;
  }): Observable<MongoStoredObject<ITaskFolder>> {
    return this._httpClient.post<MongoStoredObject<ITaskFolder>>(
      TSpecRoute_createTaskFolder(),
      params.body
    );
  }

  public updateTaskFolder(params: {
    path: TSpecPathUpdateTaskFolder;
    body: TSpecBodyUpdateTaskFolder;
  }): Observable<MongoStoredObject<ITaskFolder>> {
    return this._httpClient.put<MongoStoredObject<ITaskFolder>>(
      TSpecRoute_updateTaskFolder(params.path),
      params.body
    );
  }

  public reorderTaskFoldersMultiple(params: {
    body: TSpecBodyReorderTaskFoldersMultiple;
  }): Observable<null> {
    return this._httpClient.post<null>(
      TSpecRoute_reorderTaskFoldersMultiple(),
      params.body
    );
  }

  public deleteTaskFolder(params: {
    path: TSpecPathDeleteTaskFolder;
  }): Observable<null> {
    return this._httpClient.delete<null>(
      TSpecRoute_deleteTaskFolder(params.path)
    );
  }
}
