// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_postOpportunity = () => "/suggestions/opportunities";

export type TSpecHandler_postOpportunity = RequestHandler<TSpecPathPostOpportunity, any, TSpecBodyPostOpportunity, TSpecQueryPostOpportunity, {}>;

export interface TSpecPathPostOpportunity {  }

export interface TSpecBodyPostOpportunity {
  category?: string;
  isPublic?: boolean;
  body: string;
  attachments: string[];
  status?: ESuggestionStatus;
  author?: string;
  tenant: string;
}

export enum ESuggestionStatus {
  AWAITING_REVIEW = "AWAITING_REVIEW",
  IN_QUEUE = "IN_QUEUE",
  IN_PROGRESS = "IN_PROGRESS",
  RESOLVED = "RESOLVED",
  ARCHIVED = "ARCHIVED"
}

export interface TSpecQueryPostOpportunity {}
