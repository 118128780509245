<ion-header class="ion-no-border">
  <ion-toolbar class="app-toolbar">
    <ion-title class="ion-no-padding"> Schedule </ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="onCloseClick()" color="primary" appHaptics>
        Done
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content color="light">
  <app-user-schedule-editor
    [userId]="userId"
    [scheduleFormGroup]="scheduleFormGroup"
    [weekTimeFormGroup]="weekTimeFormGroup"
  />
</ion-content>
