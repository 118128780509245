<ion-split-pane contentId="main">
  <ion-menu contentId="main" type="push" #mobileMenu class="menu">
    <ion-content>
      <div class="user-card">
        <div class="pts-info" *ngIf="currentUser$ | async as currentUser">
          <button
            appHaptics
            class="select-account-button"
            (click)="showUserSelector = !showUserSelector"
            [attr.disabled]="
              !tenantFeatures.connectedAccounts || isMultiUserModeActive
                ? true
                : null
            "
            [disableHaptics]="
              !tenantFeatures.connectedAccounts || isMultiUserModeActive
            "
          >
            <div class="user-info">
              <div class="user-name">
                {{ currentUser.firstName }} {{ currentUser.lastName }}
              </div>
              <div class="user-info-row">{{ currentUser.email }}</div>
              <div class="user-info-row">{{ tenantName }}</div>
            </div>
            <ion-icon
              *ngIf="tenantFeatures.connectedAccounts && !isMultiUserModeActive"
              name="chevron-down"
              class="select-account-arrow"
              [ngClass]="{ 'select-account-arrow--opened': showUserSelector }"
            ></ion-icon>
          </button>

          <div
            *ngIf="tenantFeatures.connectedAccounts && !isMultiUserModeActive"
            class="select-account-menu"
            [ngClass]="{ 'select-account-menu--opened': showUserSelector }"
            [@submenuInOut]="showUserSelector ? 'in' : 'out'"
          >
            <button
              *ngFor="let account of myConnectedAccounts"
              appHaptics
              class="select-account-button"
              [ngClass]="{
                'select-account-button--active':
                  account._id === currentUser._id,
                'select-account-button--parent': !account.parent
              }"
              (click)="changeAccount(account._id)"
              [attr.disabled]="account._id === currentUser._id ? true : null"
              [disableHaptics]="account._id === currentUser._id"
            >
              <div class="user-info">
                <div class="user-name">
                  {{ account.firstName }} {{ account.lastName }}
                </div>
                <div *ngIf="!account.parent" class="user-info-row">
                  {{ account.email }}
                </div>
                <div class="user-info-row">
                  {{ account.tenant.name }}
                </div>
              </div>
              <ng-container
                *ngIf="
                  account.parent || account._id === currentUser._id;
                  else removeAccountButton
                "
              >
                <ion-icon
                  *ngIf="account._id === currentUser._id"
                  name="checkmark-circle"
                  class="selected-account-checkmark"
                  color="primary"
                ></ion-icon>
              </ng-container>

              <ng-template #removeAccountButton>
                <ion-icon
                  *ngIf="!currentUser.parent"
                  (click)="removeAccount(account._id, $event)"
                  name="close-circle"
                  class="remove-account-button"
                  color="danger"
                  title="Unlink"
                ></ion-icon>
              </ng-template>
            </button>
            <button class="add-account-button" (click)="addAccount()">
              Add Account
            </button>
          </div>

          <!-- <div class="balances">
            <div class="balance-row">
              Last 30 Days
              <span class="redeem-balance">
                {{ (receivedInPeriod$ | async)?.points }} pts
              </span>
            </div>
            <div class="balance-row">
              Team Average
              <span class="redeem-balance">
                {{
                  (teamAverageInPeriod$ | async)?.teamAveragePoints.toFixed(0)
                }}
                pts
              </span>
            </div>
          </div> -->
        </div>
      </div>

      <ul class="side-menu level-0">
        <li>
          <a
            routerLink="/i/recognition"
            routerLinkActive="active"
            class="menu-item"
            [appMenuClose]="mobileMenu"
            appHaptics
          >
            <ion-icon name="happy-outline"></ion-icon>
            <ion-icon name="happy" class="active-item-icon"></ion-icon>
            Recognition
          </a>
        </li>
        <li *ngIf="tenantFeatures.checklist">
          <a
            routerLink="/i/checklist"
            routerLinkActive="active"
            class="menu-item"
            [appMenuClose]="mobileMenu"
            appHaptics
          >
            <ion-icon name="checkmark-circle-outline"></ion-icon>
            <ion-icon
              name="checkmark-circle"
              class="active-item-icon"
            ></ion-icon>
            Checklist
          </a>
        </li>
        <li *ngIf="waitingForApprovalsTaskPerformances.length">
          <a
            routerLink="/i/approvals"
            routerLinkActive="active"
            class="menu-item"
            [appMenuClose]="mobileMenu"
            appHaptics
          >
            <ion-icon name="checkmark-done-outline"></ion-icon>
            <ion-icon name="checkmark-done" class="active-item-icon"></ion-icon>
            Task Approvals
            <div class="unread-count">
              {{ waitingForApprovalsTaskPerformances.length }}
            </div>
          </a>
        </li>
        <li *ngIf="tenantFeatures.tags">
          <a
            routerLink="/i/tags"
            routerLinkActive="active"
            class="menu-item"
            [appMenuClose]="mobileMenu"
            appHaptics
          >
            <ion-icon name="pricetags-outline"></ion-icon>
            <ion-icon name="pricetags" class="active-item-icon"></ion-icon>
            Tags
          </a>
        </li>

        <li
          *ngIf="
            tenantFeatures.insights && !(currentUser$ | async)?.isInsightsHidden
          "
        >
          <a
            routerLink="/i/insights"
            routerLinkActive="active"
            class="menu-item"
            [appMenuClose]="mobileMenu"
            appHaptics
          >
            <ion-icon name="pie-chart-outline"></ion-icon>
            <ion-icon name="pie-chart" class="active-item-icon"></ion-icon>
            Performance
          </a>
        </li>
        <li
          *ngIf="
            tenantFeatures.surveyFeedback &&
            !(currentUser$ | async)?.isInsightsHidden
          "
        >
          <a
            routerLink="/i/psychological-survey-feedback"
            routerLinkActive="active"
            class="menu-item"
            [appMenuClose]="mobileMenu"
            appHaptics
          >
            <ion-icon name="star-half-outline"></ion-icon>
            <ion-icon name="star-half" class="active-item-icon"></ion-icon>
            Feedback
          </a>
        </li>

        <li *ngIf="tenantFeatures.announcements">
          <a
            routerLink="/i/announcements"
            routerLinkActive="active"
            class="menu-item"
            [appMenuClose]="mobileMenu"
            appHaptics
          >
            <ion-icon name="mail-outline"></ion-icon>
            <ion-icon name="mail" class="active-item-icon"></ion-icon>
            Announcements
            <ng-container
              *ngIf="
                unreadAnnouncementsCount$ | async as unreadAnnouncementsCount
              "
            >
              <div *ngIf="unreadAnnouncementsCount > 0" class="unread-count">
                {{ unreadAnnouncementsCount }}
              </div>
            </ng-container>
          </a>
        </li>
        <li *ngIf="showPulse">
          <a
            routerLink="/i/psychological-survey"
            routerLinkActive="active"
            class="menu-item"
            [appMenuClose]="mobileMenu"
            appHaptics
          >
            <ion-icon name="leaf-outline"></ion-icon>
            <ion-icon name="leaf" class="active-item-icon"></ion-icon>
            Pulse
          </a>
        </li>
        <li *ngIf="tenantFeatures.suggestions">
          <a
            routerLink="/i/suggestions"
            routerLinkActive="active"
            class="menu-item"
            [appMenuClose]="mobileMenu"
            appHaptics
          >
            <ion-icon name="chatbubbles-outline"></ion-icon>
            <ion-icon name="chatbubbles" class="active-item-icon"></ion-icon>
            Suggestions
          </a>
        </li>
        <li *ngIf="tenantFeatures.library">
          <a
            routerLink="/i/library"
            routerLinkActive="active"
            class="menu-item"
            [appMenuClose]="mobileMenu"
            appHaptics
          >
            <ion-icon name="library-outline"></ion-icon>
            <ion-icon name="library" class="active-item-icon"></ion-icon>
            Library
          </a>
        </li>

        <li *ngIf="isSuperAdmin">
          <div
            class="menu-group-header"
            (click)="toggleSubmenu(openedSubmenuType.DEVELOPER)"
            appHaptics
            [ngClass]="{
              'menu-group-header--opened':
                openedMenu === openedSubmenuType.DEVELOPER
            }"
          >
            <div class="menu-item">
              <ion-icon name="code-outline"></ion-icon>
              Developer
              <ion-icon name="chevron-down" class="open-icon"></ion-icon>
            </div>
          </div>
        </li>

        <ul
          class="side-menu level-next"
          [@submenuInOut]="
            openedMenu === openedSubmenuType.DEVELOPER ? 'in' : 'out'
          "
          [ngClass]="{
            'level-next--opened': openedMenu === openedSubmenuType.DEVELOPER,
            'level-next--closed': closedMenu === openedSubmenuType.DEVELOPER
          }"
        >
          <li>
            <a
              routerLink="/i/tenants"
              routerLinkActive="active"
              class="menu-item"
              [appMenuClose]="mobileMenu"
              appHaptics
            >
              <ion-icon name="home-outline"></ion-icon>
              <ion-icon name="home" class="active-item-icon"></ion-icon>
              Tenants
            </a>
          </li>
          <li>
            <a
              routerLink="/i/backdoor"
              routerLinkActive="active"
              class="menu-item"
              [appMenuClose]="mobileMenu"
              appHaptics
            >
              <ion-icon name="warning-outline"></ion-icon>
              <ion-icon name="warning" class="active-item-icon"></ion-icon>
              Backdoor Messaging
            </a>
          </li>
          <li>
            <a
              routerLink="/i/connected-accounts"
              routerLinkActive="active"
              class="menu-item"
              [appMenuClose]="mobileMenu"
              appHaptics
            >
              <ion-icon name="people-circle-outline"></ion-icon>
              <ion-icon
                name="people-circle"
                class="active-item-icon"
              ></ion-icon>
              Connected Accounts
            </a>
          </li>
          <li>
            <a
              routerLink="/i/instant-feedback"
              routerLinkActive="active"
              class="menu-item"
              [appMenuClose]="mobileMenu"
              appHaptics
            >
              <ion-icon name="flash-outline"></ion-icon>
              <ion-icon name="flash" class="active-item-icon"></ion-icon>
              App Feedback
              <ng-container
                *ngIf="
                  unreadInstantFeedbacksCount$
                    | async as unreadInstantFeedbacksCount
                "
              >
                <div
                  *ngIf="unreadInstantFeedbacksCount > 0"
                  class="unread-count"
                >
                  {{ unreadInstantFeedbacksCount }}
                </div>
              </ng-container>
            </a>
          </li>
          <li>
            <a
              routerLink="/i/global-settings"
              routerLinkActive="active"
              class="menu-item"
              [appMenuClose]="mobileMenu"
              appHaptics
            >
              <ion-icon name="settings-outline"></ion-icon>
              <ion-icon name="settings" class="active-item-icon"></ion-icon>
              Global Settings
            </a>
          </li>
        </ul>

        <li>
          <div
            class="menu-group-header"
            (click)="toggleSubmenu(openedSubmenuType.SETTINGS)"
            appHaptics
            [ngClass]="{
              'menu-group-header--opened':
                openedMenu === openedSubmenuType.SETTINGS
            }"
          >
            <div class="menu-item">
              <ion-icon name="settings-outline"></ion-icon>
              Settings
              <ion-icon name="chevron-down" class="open-icon"></ion-icon>
            </div>
          </div>
        </li>

        <ul
          class="side-menu level-next"
          [@submenuInOut]="
            openedMenu === openedSubmenuType.SETTINGS ? 'in' : 'out'
          "
          [ngClass]="{
            'level-next--opened': openedMenu === openedSubmenuType.SETTINGS,
            'level-next--closed': closedMenu === openedSubmenuType.SETTINGS
          }"
        >
          <li>
            <a
              routerLink="/i/profile"
              routerLinkActive="active"
              class="menu-item"
              [appMenuClose]="mobileMenu"
              appHaptics
            >
              <ion-icon name="person-circle-outline"></ion-icon>
              <ion-icon
                name="person-circle"
                class="active-item-icon"
              ></ion-icon>
              Account Settings
            </a>
          </li>
          <li [attr.data-hidden]="!isAdmin">
            <a
              routerLink="/i/team-settings"
              routerLinkActive="active"
              class="menu-item"
              [appMenuClose]="mobileMenu"
              appHaptics
            >
              <ion-icon name="hammer-outline"></ion-icon>
              <ion-icon name="hammer" class="active-item-icon"></ion-icon>
              Team Settings
            </a>
          </li>
          <li>
            <a
              routerLink="/i/users"
              routerLinkActive="active"
              class="menu-item"
              [appMenuClose]="mobileMenu"
              appHaptics
            >
              <ion-icon name="people-outline"></ion-icon>
              <ion-icon name="people" class="active-item-icon"></ion-icon>
              Users
            </a>
          </li>
          <!-- <li [attr.data-hidden]="!isAdmin || hideSubscription" >
            <a
              routerLink="/i/subscription-management"
              routerLinkActive="active"
              class="menu-item"
              [appMenuClose]="mobileMenu"
              
              appHaptics
            >
              <ion-icon name="card-outline"></ion-icon>
              <ion-icon name="card" class="active-item-icon"></ion-icon>
              Subscription
            </a>
          </li> -->
          <li>
            <a
              href="#"
              (click)="logoutUser($event)"
              class="menu-item"
              [appMenuClose]="mobileMenu"
              appHaptics
            >
              <ion-icon name="log-out-outline"></ion-icon>
              Logout
            </a>
          </li>
          <li>
            <div class="menu-item menu-item--no-icon menu-item--disabled">
              v{{ appVersion }}
            </div>
          </li>
        </ul>
      </ul>
    </ion-content>
  </ion-menu>

  <div id="main" class="ion-page">
    <router-outlet></router-outlet>
  </div>
</ion-split-pane>
