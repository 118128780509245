// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_getAllAnnouncements = () => "/announcements";

export type TSpecHandler_getAllAnnouncements = RequestHandler<TSpecPathGetAllAnnouncements, any, TSpecBodyGetAllAnnouncements, TSpecQueryGetAllAnnouncements, {}>;

export interface TSpecPathGetAllAnnouncements {  }

export interface TSpecBodyGetAllAnnouncements {}

export interface TSpecQueryGetAllAnnouncements {
  page?: string;
  limit?: string;
  searchTerm?: string;
  showRewardReports?: boolean;
}
