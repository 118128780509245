// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_getLibraryDoc = (path: TSpecPathGetLibraryDoc) => {
  let res = "/library/docs/:libraryDocId";
  Object.entries(path).map(([key, value]) => {
    res = res.replace(':' + key, value);
  });
  return res;
};

export type TSpecHandler_getLibraryDoc = RequestHandler<TSpecPathGetLibraryDoc, any, TSpecBodyGetLibraryDoc, TSpecQueryGetLibraryDoc, {}>;

export interface TSpecPathGetLibraryDoc { libraryDocId: string; }

export interface TSpecBodyGetLibraryDoc {}

export interface TSpecQueryGetLibraryDoc {}
