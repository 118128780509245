import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IonNav, LoadingController, ModalController } from '@ionic/angular';
import { ToastService } from '@app/core/service/toast.service';
import { BackdoorApiService } from '@app/core/service/api/backdoor.api.service';
import {
  EBackdoorInfoPlatform,
  IBackdoorInfo,
  IBackdoorInfoLink
} from '@backend/models/types/backdoor-info';
import { Observable } from 'rxjs';
import { ITenant } from '@backend/models/types/tenant';
import { TenantListService } from '@app/core/service/tenant-list.service';
import { MongoStoredObject } from '@app/types/mongo-stored-object';
import { Browser } from '@capacitor/browser';
import { ObjectId } from '@app/types/object-id';
import { BackdoorInfoModalComponent } from '@app/modules/backdoor-info-modal/backdoor-info-modal.component';
import { AddBackdoorInfoLinkModalComponent } from '../add-backdoor-info-link-modal/add-backdoor-info-link-modal.component';

@Component({
  selector: 'app-backdoor-info-add',
  templateUrl: './backdoor-info-add.component.html',
  styleUrls: ['./backdoor-info-add.component.scss']
})
export class BackdoorInfoAddComponent implements OnInit {
  @Input()
  public readonly backdoorInfo: MongoStoredObject<IBackdoorInfo> = null;

  protected platforms: EBackdoorInfoPlatform[] = [
    EBackdoorInfoPlatform.web,
    EBackdoorInfoPlatform.ios,
    EBackdoorInfoPlatform.android
  ];
  protected mainFormGroup: FormGroup = new FormGroup({
    title: new FormControl<string>('', Validators.required),
    message: new FormControl<string>('', Validators.required),
    versionRange: new FormControl<string>('', Validators.required),
    canDismiss: new FormControl<boolean>(true),
    showToAllTenants: new FormControl<boolean>(true),
    tenantsToShow: new FormControl<ObjectId[]>([]),
    platformsToShow: new FormControl<EBackdoorInfoPlatform[]>(this.platforms)
  });
  protected links: IBackdoorInfoLink[] = [];
  protected tenantList$: Observable<MongoStoredObject<ITenant>[]> =
    this._tenantService.tenants$;

  public constructor(
    private readonly _ionNav: IonNav,
    private readonly _toastService: ToastService,
    private readonly _backdoorApiService: BackdoorApiService,
    private readonly _tenantService: TenantListService,
    private readonly _modalCtrl: ModalController,
    private readonly _loadingCtrl: LoadingController
  ) {}

  public ngOnInit(): void {
    if (this.backdoorInfo) {
      this.mainFormGroup.reset(this.backdoorInfo);
      this.links = this.backdoorInfo.links;
    }
  }

  protected get showToAllTenants() {
    return this.mainFormGroup.controls.showToAllTenants.value;
  }

  protected async onFormSubmit() {
    const loading = await this._loadingCtrl.create({
      message: 'Loading...'
    });
    loading.present();

    if (this.backdoorInfo) {
      this._backdoorApiService
        .updateBackdoorInfo({
          path: { id: this.backdoorInfo._id },
          body: {
            ...this.mainFormGroup.value,
            links: this.links
          }
        })
        .subscribe(() => {
          this._toastService.presentToast('Backdoor Message Updated');
          this._ionNav.pop();
        });
    } else {
      this._backdoorApiService
        .addBackdoorInfo({
          body: {
            ...this.mainFormGroup.value,
            links: this.links
          }
        })
        .subscribe(() => {
          this._toastService.presentToast('Backdoor Message Added');
          this._ionNav.pop();
        });
    }
  }

  protected async preview() {
    const modal = await this._modalCtrl.create({
      id: 'backdoor-info-modal',
      component: BackdoorInfoModalComponent,
      cssClass: 'modal-auto-height',
      breakpoints: [0, 1],
      initialBreakpoint: 1,
      componentProps: {
        backdoorInfo: {
          ...this.mainFormGroup.value,
          links: this.links
        },
        preview: true
      },
      backdropDismiss: this.mainFormGroup.value.canDismiss,
      handle: false
    });
    modal.present();
  }

  protected async openVersionsHelp() {
    await Browser.open({ url: 'https://github.com/npm/node-semver#versions' });
  }

  protected async addLink() {
    const modal = await this._modalCtrl.create({
      id: 'add-backdoor-info-link-modal',
      component: AddBackdoorInfoLinkModalComponent,
      cssClass: 'modal-auto-height',
      breakpoints: [0, 1],
      initialBreakpoint: 1,
      handle: false
    });
    modal.present();

    const { data, role } = await modal.onWillDismiss();

    if (role === 'confirm') {
      this.links.push(data);
    }
  }

  protected removeLink(index: number) {
    this.links.splice(index, 1);
  }
}
