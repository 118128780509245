import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MongoStoredObject } from '@app/types/mongo-stored-object';
import { ITag } from '@backend/models/types/tag';
import { Observable } from 'rxjs';
import { ObjectId } from '@app/types/object-id';
import {
  TSpecBodyPrepareTag,
  TSpecRoute_prepareTag
} from '@backend/types/generated/Tags/prepareTag';
import {
  TSpecBodyReserveTag,
  TSpecRoute_reserveTag
} from '@backend/types/generated/Tags/reserveTag';
import {
  TSpecPathDeleteMyReservedTag,
  TSpecRoute_deleteMyReservedTag
} from '@backend/types/generated/Tags/deleteMyReservedTag';
import {
  TSpecBodyUploadTag,
  TSpecPathUploadTag,
  TSpecRoute_uploadTag
} from '@backend/types/generated/Tags/uploadTag';
import {
  TSpecBodyUpdateTag,
  TSpecPathUpdateTag,
  TSpecRoute_updateTag
} from '@backend/types/generated/Tags/updateTag';
import {
  TSpecQueryGetStatusForAllTags,
  TSpecRoute_getStatusForAllTags
} from '@backend/types/generated/Tags/getStatusForAllTags';
import {
  TSpecQueryGetAllTags,
  TSpecRoute_getAllTags
} from '@backend/types/generated/Tags/getAllTags';
import {
  TSpecPathGetTag,
  TSpecRoute_getTag
} from '@backend/types/generated/Tags/getTag';
import { UserModel } from '@app/core/model/user.model';
import {
  TSpecBodyUpdateTagStatus,
  TSpecPathUpdateTagStatus,
  TSpecRoute_updateTagStatus
} from '@backend/types/generated/Tags/updateTagStatus';
import {
  TSpecBodyBatchUploadTagsToGhl,
  TSpecRoute_batchUploadTagsToGhl
} from '@backend/types/generated/Tags/batchUploadTagsToGhl';
import {
  TSpecBodyBatchUploadTagsToGhlConfirm,
  TSpecRoute_batchUploadTagsToGhlConfirm
} from '@backend/types/generated/Tags/batchUploadTagsToGhlConfirm';

@Injectable()
export class TagApiService {
  public constructor(private readonly _httpClient: HttpClient) {}

  public prepareTag(params: { body: TSpecBodyPrepareTag }): Observable<{
    serverId: string;
    pickupDate: string;
  }> {
    return this._httpClient.post<{
      serverId: string;
      pickupDate: string;
    }>(TSpecRoute_prepareTag(), params.body);
  }

  public reserveTag(params: { body: TSpecBodyReserveTag }): Observable<{
    tag: MongoStoredObject<ITag>;
    pickupDate: string;
  }> {
    return this._httpClient.post<{
      tag: MongoStoredObject<ITag>;
      pickupDate: string;
    }>(TSpecRoute_reserveTag(), params.body);
  }

  public deleteMyReservedTag(params: {
    path: TSpecPathDeleteMyReservedTag;
  }): Observable<{
    deletedCount: number;
  }> {
    return this._httpClient.delete<{
      deletedCount: number;
    }>(TSpecRoute_deleteMyReservedTag(params.path));
  }

  public uploadTag(params: {
    path: TSpecPathUploadTag;
    body: TSpecBodyUploadTag;
  }): Observable<{
    success: boolean;
    message: string;
    tag: MongoStoredObject<ITag>;
  }> {
    return this._httpClient.post<{
      success: boolean;
      message: string;
      tag: MongoStoredObject<ITag>;
    }>(TSpecRoute_uploadTag(params.path), params.body);
  }

  public updateTag(params: {
    path: TSpecPathUpdateTag;
    body: TSpecBodyUpdateTag;
  }): Observable<{
    success: boolean;
    message: string;
    tag: MongoStoredObject<ITag>;
  }> {
    return this._httpClient.put<{
      success: boolean;
      message: string;
      tag: MongoStoredObject<ITag>;
    }>(TSpecRoute_updateTag(params.path), params.body);
  }

  public getAllTags(params: {
    query: TSpecQueryGetAllTags;
  }): Observable<MongoStoredObject<ITag>[]> {
    return this._httpClient.get<MongoStoredObject<ITag>[]>(
      TSpecRoute_getAllTags(),
      { params: { ...params.query } }
    );
  }

  public getStatusForAllTags(params: {
    query: TSpecQueryGetStatusForAllTags;
  }): Observable<
    { _id: ObjectId; pickupStatus: string; possiblePickupStatuses: string[] }[]
  > {
    return this._httpClient.get<
      {
        _id: ObjectId;
        pickupStatus: string;
        possiblePickupStatuses: string[];
      }[]
    >(TSpecRoute_getStatusForAllTags(), {
      params: { ...params.query }
    });
  }

  public getTag(params: { path: TSpecPathGetTag }): Observable<{
    success: boolean;
    message: string;
    tag: MongoStoredObject<
      ITag & {
        user: Pick<UserModel, 'firstName' | 'lastName'>;
        possiblePickupStatuses: string[];
      }
    >;
    server: Pick<UserModel, 'firstName' | 'lastName'>;
  }> {
    return this._httpClient.get<{
      success: boolean;
      message: string;
      tag: MongoStoredObject<
        ITag & {
          user: Pick<UserModel, 'firstName' | 'lastName'>;
          possiblePickupStatuses: string[];
        }
      >;
      server: Pick<UserModel, 'firstName' | 'lastName'>;
    }>(TSpecRoute_getTag(params.path));
  }

  public updateTagStatus(params: {
    path: TSpecPathUpdateTagStatus;
    body: TSpecBodyUpdateTagStatus;
  }): Observable<{
    success: boolean;
    message: string;
    tag: MongoStoredObject<
      ITag & { user: Pick<UserModel, 'firstName' | 'lastName'> }
    >;
  }> {
    return this._httpClient.put<{
      success: boolean;
      message: string;
      tag: MongoStoredObject<
        ITag & { user: Pick<UserModel, 'firstName' | 'lastName'> }
      >;
    }>(TSpecRoute_updateTagStatus(params.path), params.body);
  }

  public batchUploadTagsToGhl(params: {
    body: TSpecBodyBatchUploadTagsToGhl;
  }): Observable<{ tags: MongoStoredObject<ITag>[] }> {
    return this._httpClient.post<{ tags: MongoStoredObject<ITag>[] }>(
      TSpecRoute_batchUploadTagsToGhl(),
      params.body
    );
  }

  public batchUploadTagsToGhlConfirm(params: {
    body: TSpecBodyBatchUploadTagsToGhlConfirm;
  }): Observable<null> {
    return this._httpClient.post<null>(
      TSpecRoute_batchUploadTagsToGhlConfirm(),
      params.body
    );
  }
}
