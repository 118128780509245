import { Component, ViewChild } from '@angular/core';
import { TenantService } from '@app/core/service/tenant.service';
import { ITenantFeatures } from '@backend/models/types/tenant';
import { ChecklistDefinition } from '@backend/types/checklist-definition';
import { TSpecBodyPatchTenant } from '@backend/types/generated/Tenants/patchTenant';
import { IonLoading, IonNav } from '@ionic/angular';

@Component({
  selector: 'app-checklist-settings',
  templateUrl: './checklist-settings.component.html',
  styleUrls: ['./checklist-settings.component.scss']
})
export class ChecklistSettingsComponent {
  @ViewChild('loading', { static: true })
  public loading!: IonLoading;

  protected tenantFeatures: Pick<ITenantFeatures, 'timeAttack' | 'auditTasks'> =
    {
      timeAttack: false,
      auditTasks: false
    };
  protected checklistSettings: ChecklistDefinition;

  public constructor(
    private readonly _ionNav: IonNav,
    private readonly _tenantService: TenantService
  ) {
    this._tenantService.tenant$.subscribe((tenant) => {
      if (this.loading) {
        this.loading.dismiss();
      }

      this.tenantFeatures = tenant.features;
      this.checklistSettings = tenant.settings.checklist;
    });
  }

  protected onBackButtonClick() {
    this._ionNav.pop();
  }

  private _patch(patch: TSpecBodyPatchTenant): void {
    this.loading.present();
    this._tenantService.patch(patch);
  }

  protected onChecklistSettingsFieldChange(
    field: keyof ChecklistDefinition,
    event: any
  ) {
    if (event && event.detail) {
      this.checklistSettings[field] = event.detail.checked;
      this._patch({
        settings: {
          checklist: {
            ...this.checklistSettings,
            [field]: event.detail.checked
          }
        }
      });
    }
  }
}
