import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input
} from '@angular/core';
import {
  IonNav,
  LoadingController,
  ViewDidEnter,
  ViewWillEnter
} from '@ionic/angular';
import { AuthenticationService } from '@app/core';
import { formatUploadedBody } from '@app/utils/wysiwyg-editor';
import { EnvironmentService } from '@app/core/service/environment.service';
import { SuggestionAddComponent } from '../suggestion-add/suggestion-add.component';
import {
  OpportunityModel,
  SolutionModel
} from '@app/core/model/suggestion.model';
import { SuggestionApiService } from '@app/core/service/api/suggestion.api.service';
import { ObjectId } from '@app/types/object-id';
import { combineLatest } from 'rxjs';
import { UserRoles } from '@backend/models/types/user';

@Component({
  selector: 'app-suggestion-view',
  templateUrl: './suggestion-view.component.html',
  styleUrls: ['./suggestion-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SuggestionViewComponent implements ViewWillEnter, ViewDidEnter {
  @Input()
  public id: string;

  protected isAdmin = false;
  protected body = '';
  protected serverUrl = '';
  protected isLoading = true;
  protected suggestion: OpportunityModel = null;
  protected solutions: SolutionModel[] = [];

  public constructor(
    private readonly _ionNav: IonNav,
    private readonly _authenticationService: AuthenticationService,
    private readonly _environmentService: EnvironmentService,
    private readonly _loadingCtrl: LoadingController,
    private readonly _suggestionApiService: SuggestionApiService,
    private readonly _cdr: ChangeDetectorRef
  ) {
    this.serverUrl = this._environmentService.serverUrl;
    this.isAdmin = this._authenticationService.user.roles.includes(
      UserRoles.Admin
    );
  }

  public async ionViewWillEnter() {
    this._fetchSuggestion();
  }

  public async ionViewDidEnter() {
    const topLoading = await this._loadingCtrl.getTop();
    if (topLoading) {
      topLoading.dismiss();
    }
  }

  private _fetchSuggestion() {
    this.isLoading = true;
    combineLatest([
      this._suggestionApiService.getOpportunityById({
        path: { opportunityId: this.id }
      }),
      this.isAdmin
        ? this._suggestionApiService.getAllSolutionsOfOpportunity({
            path: { opportunityId: this.id }
          })
        : this._suggestionApiService.getPublicSolutionsOfOpportunity({
            path: { opportunityId: this.id }
          }),
      this._suggestionApiService.readOpportunity({
        path: { opportunityId: this.id }
      })
    ]).subscribe(([opportunity, solutions]) => {
      this.suggestion = opportunity;
      this.body = formatUploadedBody(
        opportunity.body,
        opportunity.attachments,
        this.serverUrl
      );
      this.solutions = solutions;
      this.isLoading = false;
      this._cdr.markForCheck();
    });
  }

  protected onBackButtonClick() {
    this._ionNav.pop();
  }

  protected editDoc() {
    this._ionNav.push(SuggestionAddComponent, {
      suggestion: this.suggestion
    });
  }

  protected get isMine() {
    return (
      !!this.suggestion &&
      (this.suggestion.author
        ? this.suggestion.author._id.toString() ===
          this._authenticationService.user._id.toString()
        : false)
    );
  }

  protected trackItems(_: number, item: SolutionModel) {
    return item._id.toString();
  }

  protected addSolution() {
    this._ionNav.push(SuggestionAddComponent, {
      parentId: this.suggestion._id.toString()
    });
  }

  protected solutionDeleted(solutionId: ObjectId) {
    this.solutions = this.solutions.filter(
      (s) => s._id.toString() !== solutionId.toString()
    );
  }

  protected get authorName() {
    if (!this.suggestion) return '';

    return this.suggestion.author
      ? `${this.suggestion.author.firstName} ${this.suggestion.author.lastName}`
      : 'Anonymous';
  }
}
