// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_addCompensationPool = () => "/compensation/pools";

export type TSpecHandler_addCompensationPool = RequestHandler<TSpecPathAddCompensationPool, any, TSpecBodyAddCompensationPool, TSpecQueryAddCompensationPool, {}>;

export interface TSpecPathAddCompensationPool {  }

export interface TSpecBodyAddCompensationPool {
  name: string;
  type: ECompensationPoolType;
  users: string[];
}

export enum ECompensationPoolType {
  CURRENCY = "CURRENCY",
  HOURS = "HOURS",
  OTHER = "OTHER"
}

export interface TSpecQueryAddCompensationPool {}
