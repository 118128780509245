import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MongoStoredObject } from '@app/types/mongo-stored-object';
import {
  IMoodCheckParams,
  IPsychologicalSurveyQuestion
} from '@backend/models/types/psychological-survey-question';
import { IMoodCheckResult } from '@backend/models/types/psychological-survey-answer';
import { IPsychologicalSurvey } from '@backend/models/types/psychological-survey';

@Component({
  selector: 'app-mood-check-question',
  templateUrl: './mood-check-question.component.html',
  styleUrls: ['./mood-check-question.component.scss']
})
export class MoodCheckQuestionComponent implements OnInit {
  @Input()
  public readonly cancelButton: 'menu' | 'cancel' = 'menu';

  @Input()
  public readonly survey: MongoStoredObject<IPsychologicalSurvey>;

  @Input()
  public readonly question: MongoStoredObject<
    IPsychologicalSurveyQuestion & { params: IMoodCheckParams }
  >;

  @Input()
  public readonly previousAnswer?: IMoodCheckResult = null;

  @Output()
  public completed = new EventEmitter<{
    questionId: string;
    result: IMoodCheckResult;
  }>();

  @Output()
  public goBack = new EventEmitter<void>();

  @Output()
  public cancelClicked = new EventEmitter<void>();

  protected result: IMoodCheckResult = {
    moodValue: 0
  };

  public constructor() {}

  public ngOnInit(): void {
    if (this.previousAnswer) {
      this.result = this.previousAnswer;
    }
  }

  protected completeQuestion() {
    this.completed.emit({
      questionId: this.question._id,
      result: this.result
    });
  }

  protected onBackButtonClick() {
    this.goBack.emit();
  }

  protected onCancelClick() {
    this.cancelClicked.emit();
  }

  protected selectMood(mood: number) {
    this.result.moodValue = mood;

    this.completeQuestion();
  }
}
