import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { MongoStoredObject } from '../../../types/mongo-stored-object';
import { ITenant } from '@backend/models/types/tenant';
import { TaskDto } from '@app/types/task-dto';
import { ITask } from '@backend/models/types/task';
import { IIntegration } from '@backend/models/types/integration';
import { ITaskFolder } from '@backend/models/types/task-folder';
import { BehaviorModel } from '../../model/behavior.model';
import {
  TSpecPathGetTenantById,
  TSpecRoute_getTenantById
} from '@backend/types/generated/Tenants/getTenantById';
import {
  TSpecBodyPatchTenant,
  TSpecRoute_patchTenant
} from '@backend/types/generated/Tenants/patchTenant';
import {
  TSpecBodyPatchUsers,
  TSpecPathPatchUsers,
  TSpecRoute_patchUsers
} from '@backend/types/generated/Tenants/patchUsers';
import { TSpecRoute_getAllTenants } from '@backend/types/generated/Tenants/getAllTenants';
import {
  TSpecBodyCreateTenant,
  TSpecRoute_createTenant
} from '@backend/types/generated/Tenants/createTenant';
import {
  TSpecBodyPatchTenantById,
  TSpecPathPatchTenantById,
  TSpecRoute_patchTenantById
} from '@backend/types/generated/Tenants/patchTenantById';
import {
  TSpecPathGetTenantTasks,
  TSpecRoute_getTenantTasks
} from '@backend/types/generated/Tenants/getTenantTasks';
import { TSpecRoute_getSysGenBehaviorsAndTasks } from '@backend/types/generated/Tenants/getSysGenBehaviorsAndTasks';
import {
  TSpecBodyCopyTasksFrom,
  TSpecPathCopyTasksFrom,
  TSpecRoute_copyTasksFrom
} from '@backend/types/generated/Tenants/copyTasksFrom';
import {
  TSpecPathGetPublicTenantDataWithUsers,
  TSpecRoute_getPublicTenantDataWithUsers
} from '@backend/types/generated/Users/getPublicTenantDataWithUsers';
import { TenantPublicModel } from '@app/core/model/tenant.model';
import {
  TSpecBodyPatchTenantFeatures,
  TSpecPathPatchTenantFeatures,
  TSpecRoute_patchTenantFeatures
} from '@backend/types/generated/Tenants/patchTenantFeatures';

@Injectable({ providedIn: 'root' })
export class TenantApiService {
  public constructor(private readonly _httpClient: HttpClient) {}

  public getPublicTenantDataWithUsers(params: {
    path: TSpecPathGetPublicTenantDataWithUsers;
  }): Observable<TenantPublicModel> {
    return this._httpClient.get<TenantPublicModel>(
      TSpecRoute_getPublicTenantDataWithUsers(params.path)
    );
  }

  public getTenantById(params: {
    path: TSpecPathGetTenantById;
  }): Observable<MongoStoredObject<ITenant>> {
    return this._httpClient.get<MongoStoredObject<ITenant>>(
      TSpecRoute_getTenantById(params.path)
    );
  }

  public patchTenant(params: {
    body: TSpecBodyPatchTenant;
  }): Observable<unknown> {
    return this._httpClient.patch(TSpecRoute_patchTenant(), params.body);
  }

  public patchUsers(params: {
    path: TSpecPathPatchUsers;
    body: TSpecBodyPatchUsers;
  }): Observable<unknown> {
    return this._httpClient.patch(
      TSpecRoute_patchUsers(params.path),
      params.body
    );
  }

  public getAllTenants(): Observable<
    MongoStoredObject<ITenant & { integrations: IIntegration }>[]
  > {
    return this._httpClient.get<
      MongoStoredObject<ITenant & { integrations: IIntegration }>[]
    >(TSpecRoute_getAllTenants());
  }

  public createTenant(params: {
    body: TSpecBodyCreateTenant;
  }): Observable<unknown> {
    return this._httpClient.post(TSpecRoute_createTenant(), params.body);
  }

  public patchTenantById(params: {
    path: TSpecPathPatchTenantById;
    body: TSpecBodyPatchTenantById;
  }): Observable<MongoStoredObject<ITenant>> {
    return this._httpClient.patch<MongoStoredObject<ITenant>>(
      TSpecRoute_patchTenantById(params.path),
      params.body
    );
  }

  public patchTenantFeatures(params: {
    path: TSpecPathPatchTenantFeatures;
    body: TSpecBodyPatchTenantFeatures;
  }): Observable<MongoStoredObject<ITenant>> {
    return this._httpClient.patch<MongoStoredObject<ITenant>>(
      TSpecRoute_patchTenantFeatures(params.path),
      params.body
    );
  }

  public getTenantTasks(params: { path: TSpecPathGetTenantTasks }) {
    return this._httpClient.get<
      MongoStoredObject<
        TaskDto & { taskFolder: MongoStoredObject<ITaskFolder> }
      >[]
    >(TSpecRoute_getTenantTasks(params.path));
  }

  public copyTasksFrom(params: {
    path: TSpecPathCopyTasksFrom;
    body: TSpecBodyCopyTasksFrom;
  }): Observable<MongoStoredObject<ITask>[]> {
    return this._httpClient.post<MongoStoredObject<ITask>[]>(
      TSpecRoute_copyTasksFrom(params.path),
      params.body
    );
  }

  public getSysGenBehaviorsAndTasks(): Observable<{
    behaviors: BehaviorModel[];
    tasks: MongoStoredObject<TaskDto>[];
  }> {
    return this._httpClient.get<{
      behaviors: BehaviorModel[];
      tasks: MongoStoredObject<TaskDto>[];
    }>(TSpecRoute_getSysGenBehaviorsAndTasks());
  }
}
