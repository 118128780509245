import { NgModule } from '@angular/core';

// directives
import { AppMenuCloseDirective } from '@app/directives/menu-close.directive';
import { HapticsDirective } from '@app/directives/haptics.directive';
import { AutofillDirective } from '@app/directives/autofill.directive';
import { SwiperDirective } from '@app/directives/swiper.directive';
import { ClickOutsideDirective } from '@app/directives/click-outside.directive';

@NgModule({
  declarations: [
    AppMenuCloseDirective,
    HapticsDirective,
    AutofillDirective,
    SwiperDirective,
    ClickOutsideDirective
  ],
  exports: [
    AppMenuCloseDirective,
    HapticsDirective,
    AutofillDirective,
    SwiperDirective,
    ClickOutsideDirective
  ]
})
export class DirectivesModule {}
