import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { AnnouncementApiService } from './api/announcement.api.service';
import { AnnouncementModel } from '../model/announcement.model';
import { ModalController } from '@ionic/angular';
import { WhatsNewModalComponent } from '@app/modules/whats-new-modal/whats-new-modal.component';
import { LedgerApiService } from './api/ledger.api.service';
import { MongoStoredObject } from '@app/types/mongo-stored-object';
import { Router } from '@angular/router';
import { PsychologicalSurveyApiService } from './api/psychologicalSurvey.api.service';
import { IPsychologicalSurvey } from '@backend/models/types/psychological-survey';
import { RecognitionApiService } from '@app/core/service/api/recognition.api.service';
import { IPsychologicalSurveyQuestion } from '@backend/models/types/psychological-survey-question';
import { LedgerModel } from '../model/ledger.model';

@Injectable()
export class WhatsNewService {
  private _announcements$ = new BehaviorSubject<{
    items: AnnouncementModel[];
    count: number;
    totalCount: number;
  }>({ items: [], count: 0, totalCount: 0 });

  private _recognitions$ = new BehaviorSubject<{
    items: LedgerModel[];
    count: number;
    totalCount: number;
  }>({ items: [], count: 0, totalCount: 0 });

  private _surveys$ = new BehaviorSubject<{
    items: {
      survey: MongoStoredObject<IPsychologicalSurvey>;
      questions: MongoStoredObject<IPsychologicalSurveyQuestion>[];
    }[];
    count: number;
    totalCount: number;
  }>({ items: [], count: 0, totalCount: 0 });

  private _recognitionReminders$ = new BehaviorSubject<{
    items: { title: string }[];
    count: number;
    totalCount: number;
  }>({ items: [], count: 0, totalCount: 0 });

  public announcements$ = this._announcements$.asObservable();
  public recognitions$ = this._recognitions$.asObservable();
  public surveys$ = this._surveys$.asObservable();
  public recognitionReminders$ = this._recognitionReminders$.asObservable();

  public constructor(
    private readonly _ledgerApiService: LedgerApiService,
    private readonly _announcementApiService: AnnouncementApiService,
    private readonly _psychologicalSurveyApiService: PsychologicalSurveyApiService,
    private readonly _recognitionApiService: RecognitionApiService,
    private readonly _modalCtrl: ModalController,
    private readonly _router: Router
  ) {}

  public checkWhatsNew(showWhatsNewModal = true) {
    combineLatest([
      this._ledgerApiService.getUnreadLedgers(),
      this._announcementApiService.getCriticalUnacknowledgedAnnouncements(),
      this._psychologicalSurveyApiService.getUnansweredPsychologicalSurveys({
        query: {}
      }),
      this._recognitionApiService.getRecognitionReminders()
    ]).subscribe(
      async ([
        unreadRecognitions,
        criticalAnnouncements,
        unansweredSurveys,
        recognitionReminders
      ]) => {
        this._recognitions$.next(unreadRecognitions);
        this._announcements$.next(criticalAnnouncements);
        this._surveys$.next(unansweredSurveys);
        this._recognitionReminders$.next(recognitionReminders);

        if (showWhatsNewModal) {
          const showViewRecognitionPage =
            unreadRecognitions.count === 1 &&
            criticalAnnouncements.count === 0 &&
            unansweredSurveys.count === 0 &&
            recognitionReminders.count === 0;

          if (showViewRecognitionPage) {
            this._router.navigate(['/i/view-recognition'], {
              queryParams: {
                post: unreadRecognitions.items[0]._id.toString()
              }
            });
          } else if (
            unreadRecognitions.count ||
            criticalAnnouncements.count ||
            unansweredSurveys.count ||
            recognitionReminders.count
          ) {
            const whatsNewModal = document.getElementById('whats-new-modal');
            if (!whatsNewModal) {
              const modal = await this._modalCtrl.create({
                id: 'whats-new-modal',
                breakpoints: [0, 1],
                initialBreakpoint: 1,
                component: WhatsNewModalComponent,
                backdropDismiss: false,
                handle: false,
                cssClass: 'whats-new-modal',
                canDismiss: async (_, role) => role === 'confirm'
              });
              modal.present();
            }
          }
        }
      }
    );
  }

  public removeItemFromTheList(
    itemId: string,
    type: 'announcement' | 'recognition' | 'survey' | 'recognitionReminder'
  ) {
    switch (type) {
      case 'announcement':
        this._announcements$.next({
          items: this._announcements$.value.items.filter(
            (a) => a._id !== itemId
          ),
          count: this._announcements$.value.count - 1,
          totalCount: this._announcements$.value.totalCount - 1
        });
        break;
      case 'recognition':
        this._recognitions$.next({
          items: this._recognitions$.value.items.filter(
            (a) => a._id.toString() !== itemId
          ),
          count: this._recognitions$.value.count - 1,
          totalCount: this._recognitions$.value.totalCount - 1
        });
        break;
      case 'survey':
        this._surveys$.next({
          items: this._surveys$.value.items.filter(
            (a) => a.survey._id.toString() !== itemId
          ),
          count: this._surveys$.value.count - 1,
          totalCount: this._surveys$.value.totalCount - 1
        });
        break;
      case 'recognitionReminder':
        this._recognitionReminders$.next({
          items: [],
          count: 0,
          totalCount: 0
        });
        break;
    }
    this.checkWhatsNew(false);
  }
}
