// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_setShiftTime = () => "/user/shift-time";

export type TSpecHandler_setShiftTime = RequestHandler<TSpecPathSetShiftTime, any, TSpecBodySetShiftTime, TSpecQuerySetShiftTime, {}>;

export interface TSpecPathSetShiftTime {  }

export interface TSpecBodySetShiftTime {
  start?: string;
  end?: string;
}

export interface TSpecQuerySetShiftTime {}
