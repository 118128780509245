// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_publishAnnouncement = () => "/announcements";

export type TSpecHandler_publishAnnouncement = RequestHandler<TSpecPathPublishAnnouncement, any, TSpecBodyPublishAnnouncement, TSpecQueryPublishAnnouncement, {}>;

export interface TSpecPathPublishAnnouncement {  }

export interface TSpecBodyPublishAnnouncement {
  title: string;
  body: string;
  recipients: {
    id: string;
  }[];
  isCritical: boolean;
  attachments: string[];
}

export interface TSpecQueryPublishAnnouncement {}
