import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  Input,
  ViewChild
} from '@angular/core';
import { ObjectId } from '@app/types/object-id';
import { ModalController, IonModal, IonInput } from '@ionic/angular';
import { UserModel } from '@app/core/model/user.model';
import { UserListService } from '@app/core/service/user-list.service';
import fastDeepEqual from 'fast-deep-equal';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { TaskListService } from '@app/core/service/task-list.service';
import { TaskDto } from '@app/types/task-dto';

export interface IEditTaskPerformanceModalResult {
  userId: string;
  startDate: Date;
  startTime: string;
  completeDate: Date;
  completeTime: string;
  quantity: number;
  timeAttackBonusPoints: number;
  isDeleted: boolean;
  isTimeAttack: boolean;
}

@Component({
  selector: 'app-edit-task-performance-modal',
  templateUrl: './edit-task-performance-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditTaskPerformanceModalComponent implements OnInit {
  @Input()
  public readonly taskId: ObjectId;

  @Input()
  public readonly initialData: {
    userId: ObjectId;
    startDate: Date;
    startTime: string;
    completeDate: Date;
    completeTime: string;
    quantity: number;
    isDeleted: boolean;
    isTimeAttack: boolean;
    timeAttackBonusPoints: number;
  };

  @Input()
  public readonly createMode?: boolean = false;

  @Input()
  public readonly showTimeAttackBonusPoints?: boolean = false;

  @ViewChild('userSelectorModal', { static: true })
  public userSelectorModal!: IonModal;

  @ViewChild('quantityInput', { static: true })
  public quantityInput!: IonInput;

  @ViewChild('timeAttackBonusPointsInput', { static: true })
  public timeAttackBonusPointsInput!: IonInput;

  protected form = new FormGroup({
    userId: new FormControl<ObjectId>(null, Validators.required),
    startDate: new FormControl<Date>(new Date()),
    startTime: new FormControl<string>('00:00'),
    completeDate: new FormControl<Date>(new Date(), Validators.required),
    completeTime: new FormControl<string>('00:00', Validators.required),
    quantity: new FormControl<string>('1', Validators.required),
    timeAttackBonusPoints: new FormControl<string>('0', Validators.required),
    isTimeAttack: new FormControl<boolean>(false)
  });
  protected allUsers: UserModel[] = [];
  protected task?: TaskDto = null;

  public constructor(
    private readonly _modalCtrl: ModalController,
    private readonly _userListService: UserListService,
    private readonly _taskListService: TaskListService
  ) {}

  public ngOnInit(): void {
    this.form.reset({
      userId: this.initialData.userId,
      startDate: this.initialData.startDate,
      startTime: this.initialData.startTime,
      completeDate: this.initialData.completeDate,
      completeTime: this.initialData.completeTime,
      quantity: this.initialData.quantity.toString(),
      timeAttackBonusPoints: this.initialData.timeAttackBonusPoints.toString(),
      isTimeAttack: this.initialData.isTimeAttack
    });
    this._userListService.users$.subscribe((users) => {
      this.allUsers = users;
    });
    this._taskListService.list$.subscribe((tasks) => {
      this.task = tasks.find((t) => t._id === this.taskId);
    });
  }

  protected get startDate() {
    return this.form.get('startDate').value.toISOString();
  }

  protected get completeDate() {
    return this.form.get('completeDate').value.toISOString();
  }

  protected get taskTitle() {
    return this.task?.title || '';
  }

  protected get selectedUserIds(): ObjectId[] {
    return [this.form.get('userId').value];
  }

  protected get currentUser(): UserModel {
    return this.allUsers.find(
      (user) => user._id === this.form.get('userId').value
    );
  }

  protected get isTimeAttack() {
    return this.form.get('isTimeAttack').value;
  }

  protected get canSave() {
    const res =
      this.form.get('userId').value &&
      this.form.get('completeDate').value &&
      this.form.get('completeTime').value &&
      Number(this.form.get('quantity').value) > 0 &&
      (this.isTimeAttack
        ? this.form.get('startDate').value && this.form.get('startTime').value
        : true);

    if (this.createMode) {
      return res;
    } else {
      return (
        res &&
        !fastDeepEqual(this.initialData, {
          userId: this.form.get('userId').value,
          startDate: this.form.get('startDate').value,
          startTime: this.form.get('startTime').value,
          completeDate: this.form.get('completeDate').value,
          completeTime: this.form.get('completeTime').value,
          quantity: Number(this.form.get('quantity').value),
          timeAttackBonusPoints: Number(
            this.form.get('timeAttackBonusPoints').value
          ),
          isTimeAttack: this.form.get('isTimeAttack').value,
          isDeleted: this.initialData.isDeleted
        })
      );
    }
  }

  protected onUserChange(event: ObjectId[]): void {
    this.form.controls.userId.setValue(event[0]);
    this.userSelectorModal.dismiss();
  }

  protected onCloseClick(): void {
    this._modalCtrl.dismiss(null, 'cancel', 'edit-task-performance-modal');
  }

  protected onDeleteClick(): void {
    const result: IEditTaskPerformanceModalResult = {
      userId: this.initialData.userId,
      startDate: this.initialData.startDate,
      startTime: this.initialData.startTime,
      completeDate: this.initialData.completeDate,
      completeTime: this.initialData.completeTime,
      quantity: this.initialData.quantity,
      timeAttackBonusPoints: this.initialData.timeAttackBonusPoints,
      isTimeAttack: this.initialData.isTimeAttack,
      isDeleted: true
    };

    this._modalCtrl.dismiss(result, 'confirm', 'edit-task-performance-modal');
  }

  protected onRestoreClick(): void {
    const result: IEditTaskPerformanceModalResult = {
      userId: this.initialData.userId,
      startDate: this.initialData.startDate,
      startTime: this.initialData.startTime,
      completeDate: this.initialData.completeDate,
      completeTime: this.initialData.completeTime,
      quantity: this.initialData.quantity,
      timeAttackBonusPoints: this.initialData.timeAttackBonusPoints,
      isTimeAttack: this.initialData.isTimeAttack,
      isDeleted: false
    };

    this._modalCtrl.dismiss(result, 'confirm', 'edit-task-performance-modal');
  }

  protected onSaveClick(): void {
    const result: IEditTaskPerformanceModalResult = {
      userId: this.form.get('userId').value,
      startDate: this.form.get('startDate').value,
      startTime: this.form.get('startTime').value,
      completeDate: this.form.get('completeDate').value,
      completeTime: this.form.get('completeTime').value,
      quantity: Number(this.form.get('quantity').value) || 1,
      timeAttackBonusPoints:
        Number(this.form.get('timeAttackBonusPoints').value) || 0,
      isTimeAttack: this.form.get('isTimeAttack').value,
      isDeleted: this.initialData.isDeleted
    };

    this._modalCtrl.dismiss(result, 'confirm', 'edit-task-performance-modal');
  }
}
