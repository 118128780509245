import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { ListPreLineComponent } from './list-pre-line.component';

@NgModule({
  imports: [CommonModule, IonicModule],
  exports: [ListPreLineComponent],
  declarations: [ListPreLineComponent]
})
export class ListPreLineModule {}
