// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_prepareTag = () => "/tags/prepare";

export type TSpecHandler_prepareTag = RequestHandler<TSpecPathPrepareTag, any, TSpecBodyPrepareTag, TSpecQueryPrepareTag, {}>;

export interface TSpecPathPrepareTag {  }

export interface TSpecBodyPrepareTag {
  clientDate: string;
}

export interface TSpecQueryPrepareTag {}
