import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ModalClass } from '@app/core/class/modal.class';

@Component({
  selector: 'app-values-modal',
  templateUrl: './values-modal.component.html'
})
export class ValuesModalComponent extends ModalClass {
  public constructor(private readonly _modalCtrl: ModalController) {
    super();
  }

  protected close() {
    this._modalCtrl.dismiss(null, 'cancel', this._modalName);
  }
}
