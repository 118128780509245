// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_getAllUsers = (path: TSpecPathGetAllUsers) => {
  let res = "/tenants/:tenantId/users";
  Object.entries(path).map(([key, value]) => {
    res = res.replace(':' + key, value);
  });
  return res;
};

export type TSpecHandler_getAllUsers = RequestHandler<TSpecPathGetAllUsers, any, TSpecBodyGetAllUsers, TSpecQueryGetAllUsers, {}>;

export interface TSpecPathGetAllUsers { tenantId: string; }

export interface TSpecBodyGetAllUsers {}

export interface TSpecQueryGetAllUsers {}
