<app-login-page-wrapper [backBtn]="true">
  <form [formGroup]="mainFormGroup" (ngSubmit)="submit()" novalidate>
    <ion-row>
      <ion-col size="12">
        <h1>Sign Up</h1>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col size="12">
        <app-form-error-wrapper
          [control]="mainFormGroup.controls['firstName']"
          [controlName]="'First Name'"
        >
          <ion-input
            appAutofill
            type="text"
            class="login-form-control"
            formControlName="firstName"
            placeholder="First Name"
            name="firstName"
          />
        </app-form-error-wrapper>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col size="12">
        <app-form-error-wrapper
          [control]="mainFormGroup.controls['lastName']"
          [controlName]="'Last Name'"
        >
          <ion-input
            appAutofill
            type="text"
            class="login-form-control"
            formControlName="lastName"
            placeholder="Last Name"
            name="lastName"
          />
        </app-form-error-wrapper>
      </ion-col>
    </ion-row>
    <ion-row [attr.data-hidden]="noPassword">
      <ion-col size="12">
        <app-form-error-wrapper
          [control]="mainFormGroup.controls['password']"
          [controlName]="'Password'"
        >
          <ion-input
            appAutofill
            type="password"
            class="login-form-control"
            formControlName="password"
            placeholder="Password"
            name="password"
          />
        </app-form-error-wrapper>
      </ion-col>
    </ion-row>
    <ion-row [attr.data-hidden]="noPassword">
      <ion-col size="12">
        <app-form-error-wrapper
          [control]="mainFormGroup.controls['confirmPassword']"
          [controlName]="'Password Confirmation'"
        >
          <ion-input
            appAutofill
            type="password"
            class="login-form-control"
            formControlName="confirmPassword"
            placeholder="Confirm Password"
            name="confirmPassword"
          />
        </app-form-error-wrapper>
        <div
          class="input-error"
          *ngIf="mainFormGroup.errors?.['PasswordNoMatch']"
        >
          Passwords do not match
        </div>
      </ion-col>
    </ion-row>

    <div class="controls">
      <ion-button
        type="submit"
        color="light"
        fill="clear"
        [disabled]="mainFormGroup.invalid"
        appHaptics
        [disableHaptics]="mainFormGroup.invalid"
      >
        Submit
      </ion-button>

      <!-- this input is the hack to force submit event to fire on the enter press -->
      <input type="submit" class="hidden-control" />
    </div>
  </form>
</app-login-page-wrapper>
