// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_patchUsers = (path: TSpecPathPatchUsers) => {
  let res = "/tenants/:id/users";
  Object.entries(path).map(([key, value]) => {
    res = res.replace(':' + key, value);
  });
  return res;
};

export type TSpecHandler_patchUsers = RequestHandler<TSpecPathPatchUsers, any, TSpecBodyPatchUsers, TSpecQueryPatchUsers, {}>;

export interface TSpecPathPatchUsers { id: string; }

export interface TSpecBodyPatchUsers {
  isInsightsHidden?: boolean;
  receiveDailyReports?: boolean;
  userId: string | string[];
}

export interface TSpecQueryPatchUsers {}
