import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MongoStoredObject } from '@app/types/mongo-stored-object';
import { IAttachment } from '@backend/models/types/attachment';
import { ITask } from '@backend/models/types/task';
import { ITaskPerformance } from '@backend/models/types/task-performance';
import {
  TSpecQueryGetDailyReport,
  TSpecRoute_getDailyReport
} from '@backend/types/generated/DailyReport/getDailyReport';
import { Observable } from 'rxjs';

@Injectable()
export class DailyReportApiService {
  public constructor(private readonly _httpClient: HttpClient) {}

  public getDailyReport(params: {
    query: TSpecQueryGetDailyReport;
  }): Observable<{
    completed: {
      userId: string;
      fullName: string;
      points: number;
      performances: MongoStoredObject<
        ITaskPerformance & {
          task: MongoStoredObject<ITask>;
          note?: {
            attachments: MongoStoredObject<IAttachment>[];
          };
        }
      >[];
    }[];
  }> {
    return this._httpClient.get<{
      completed: {
        userId: string;
        fullName: string;
        points: number;
        performances: MongoStoredObject<
          ITaskPerformance & {
            task: MongoStoredObject<ITask>;
            note?: {
              attachments: MongoStoredObject<IAttachment>[];
            };
          }
        >[];
      }[];
    }>(TSpecRoute_getDailyReport(), {
      params: { ...params.query }
    });
  }
}
