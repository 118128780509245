// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_deleteAnnouncement = (path: TSpecPathDeleteAnnouncement) => {
  let res = "/announcements/:id";
  Object.entries(path).map(([key, value]) => {
    res = res.replace(':' + key, value);
  });
  return res;
};

export type TSpecHandler_deleteAnnouncement = RequestHandler<TSpecPathDeleteAnnouncement, any, TSpecBodyDeleteAnnouncement, TSpecQueryDeleteAnnouncement, {}>;

export interface TSpecPathDeleteAnnouncement { id: string; }

export interface TSpecBodyDeleteAnnouncement {}

export interface TSpecQueryDeleteAnnouncement {}
