// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_setIntegration = () => "/integrations";

export type TSpecHandler_setIntegration = RequestHandler<TSpecPathSetIntegration, any, TSpecBodySetIntegration, TSpecQuerySetIntegration, {}>;

export interface TSpecPathSetIntegration {  }

export interface TSpecBodySetIntegration {
  provider: EIntegrationProvider;
  tenantId: string;
  settings?: unknown;
  publicSettingsFields?: string[];
}

export enum EIntegrationProvider {
  AIRTABLE = "AIRTABLE",
  GOHIGHLEVEL = "GOHIGHLEVEL"
}

export interface TSpecQuerySetIntegration {}
