// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_getMyConnectedAccounts = () => "/connected-accounts";

export type TSpecHandler_getMyConnectedAccounts = RequestHandler<TSpecPathGetMyConnectedAccounts, any, TSpecBodyGetMyConnectedAccounts, TSpecQueryGetMyConnectedAccounts, {}>;

export interface TSpecPathGetMyConnectedAccounts {  }

export interface TSpecBodyGetMyConnectedAccounts {}

export interface TSpecQueryGetMyConnectedAccounts {}
