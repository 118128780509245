import { TaskScheduleNever } from '@backend/models/types/task';
import { DateInterval } from '../../../../backend/src/types/date-interval';
import { MongoStoredObject } from '../../types/mongo-stored-object';
import { TaskRendererClass } from './task-renderer.class';
import { createTaskInterval } from '../../utils/create-task-interval';
import { TaskDto } from '@app/types/task-dto';

export class TaskNeverRendererClass extends TaskRendererClass {
  public constructor(
    task: MongoStoredObject<TaskDto>,
    allowTasksToBeDisplayedOnlyDuringCertainHours: boolean,
    private readonly _schedule: TaskScheduleNever
  ) {
    super(task, allowTasksToBeDisplayedOnlyDuringCertainHours);
  }

  protected createMatchingIntervals(interval: DateInterval): DateInterval[] {
    if (this._schedule.date === null) {
      return [];
    }
    const date = new Date(this._schedule.date);
    if (
      interval.start.getTime() <= date.getTime() &&
      date.getTime() <= interval.end.getTime()
    ) {
      const taskInterval = createTaskInterval(
        date,
        this.task.appearsAt,
        this.task.disappearsAt,
        this.allowTasksToBeDisplayedOnlyDuringCertainHours
      );
      return [taskInterval];
    }
    return [];
  }
}
