import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import {
  EActionType,
  EShowMoreRecentNote,
  ESkillMatrixStatus,
  TaskPerformanceEvaluationType,
  TaskPointsEarns,
  TaskScheduleType,
  TaskTimeAttackType
} from '@backend/models/types/task';
import { createScheduleFormGroup } from './utils/create-schedule-form-group';
import { TaskScheduleForm } from './types/task-schedule-form';
import {
  IonDatetime,
  IonModal,
  ItemReorderCustomEvent,
  LoadingController,
  ModalController
} from '@ionic/angular';
import { TaskListService } from '@app/core/service/task-list.service';
import { MongoStoredObject } from '@app/types/mongo-stored-object';
import { minArrayLength } from './utils/min-array-length-validator';
import { UserListService } from '@app/core/service/user-list.service';
import { BehaviorSubject, map, take } from 'rxjs';
import { ObjectId } from '@app/types/object-id';
import {
  PromptUserToLeaveNoteDto,
  RequestProcessFeedbackDto,
  TaskDto
} from '@app/types/task-dto';
import { UserModel } from '@app/core/model/user.model';
import { isSameSet } from '@app/utils/is-same-set';
import { UserEntityService } from '@app/core/service/user-entity.service';
import { ToastService } from '@app/core/service/toast.service';
import {
  formatUploadedBody,
  prepareBodyForUpload
} from '@app/utils/wysiwyg-editor';
import { EnvironmentService } from '@app/core/service/environment.service';
import { TenantService } from '@app/core/service/tenant.service';
import { UploadFileApiService } from '@app/core/service/api/uploadFile.api.service';
import { WysiwygModalComponent } from '../wysiwyg-modal/wysiwyg-modal.component';
import { ITaskFolder } from '@backend/models/types/task-folder';
import { TaskFolderListService } from '@app/core/service/task-folder-list.service';
import { ITenantFeatures } from '@backend/models/types/tenant';
import { SkillMatrixForTaskModalComponent } from '../skill-matrix-for-task-modal/skill-matrix-for-task-modal.component';
import fastDeepEqual from 'fast-deep-equal';

interface MainForm {
  title: FormControl<string>;
  points: FormControl<number>;
  pointsEarns: FormControl<TaskPointsEarns>;

  // once and multiple
  pointsUnit: FormControl<string>; // nounSingular
  verbBase: FormControl<string>;
  verbPastSimple: FormControl<string>;

  // only multiple
  verbPastParticiple?: FormControl<string>;
  nounPlural?: FormControl<string>;

  schedule: FormArray<FormGroup<TaskScheduleForm>>;
  shouldReappear: FormControl<boolean>;
  appearsAt: FormControl<string>;
  disappearsAt: FormControl<string>;
  performanceEvaluation: FormControl<TaskPerformanceEvaluationType>;
  performanceDuration: FormControl<string>;
  isCritical: FormControl<boolean>;
  skillMatrix: FormControl<{ status: ESkillMatrixStatus; user: ObjectId }[]>;
  notifyEveryoneUponCompletion: FormControl<boolean>;
  notifyUsersUponCompletion: FormControl<ObjectId[]>;
  timeAttack: FormControl<TaskTimeAttackType>;
  timeAttackRewardPoints: FormControl<number>;
  timeAttackAttemptPoints: FormControl<number>;
  actionsUponTaskCompletion: FormControl<TaskDto['actionsUponTaskCompletion']>;

  // audit
  isAuditTask: FormControl<boolean>;
  auditSuccessCriteria: FormControl<string[]>;
  tasksForAudit: FormControl<ObjectId[]>;
  auditReviewers: FormControl<ObjectId[]>;
}

@Component({
  selector: 'app-task-form',
  templateUrl: './task-form.component.html',
  styleUrls: ['./task-form.component.scss']
})
export class TaskFormComponent implements OnInit {
  @Input()
  public task: MongoStoredObject<TaskDto> | null = null;

  @Output()
  public taskSaved = new EventEmitter<MongoStoredObject<TaskDto>>();

  @Output()
  public delete = new EventEmitter<void>();

  @Output()
  public restore = new EventEmitter<void>();

  @ViewChild('timeAppear')
  public readonly timeAppear: IonDatetime;

  @ViewChild('taskFolderSelectorModal', { static: true })
  public taskFolderSelectorModal!: IonModal;

  @ViewChild('selectUsersModal', { static: true })
  public selectUsersModal!: IonModal;

  @ViewChild('notifyUsersUponCompletionModal', { static: true })
  public notifyUsersUponCompletionModal!: IonModal;

  @ViewChild('selectUsersWhoCanSeeNotesModal', { static: true })
  public selectUsersWhoCanSeeNotesModal!: IonModal;

  @ViewChild('tasksForAuditSelectorModal', { static: true })
  public tasksForAuditSelectorModal!: IonModal;

  @ViewChild('selectAuditReviewersModals', { static: true })
  public selectAuditReviewersModals!: IonModal;

  protected readonly taskPointsEarns = TaskPointsEarns;
  protected readonly taskScheduleType = TaskScheduleType;
  protected readonly taskPerformanceEvaluationType =
    TaskPerformanceEvaluationType;
  protected readonly taskTimeAttackType = TaskTimeAttackType;
  protected readonly actionType = EActionType;
  protected readonly showMoreRecentNote = EShowMoreRecentNote;

  protected allUsers$ = this._userListService.users$.pipe(
    map((list) => list.filter(({ isDeleted }) => !isDeleted))
  );
  protected mainFormGroup: FormGroup<MainForm>;
  protected schedules: FormArray<FormGroup<TaskScheduleForm>>;
  protected userFirstName = 'Hawk';
  protected serverUrl = '';
  protected body = '';
  protected allTaskFolders: MongoStoredObject<ITaskFolder>[] = [];
  protected taskFolder: MongoStoredObject<ITaskFolder> | null = null;
  protected allowTasksToBeDisplayedOnlyDuringCertainHours = false;
  protected tenantFeatures: Pick<
    ITenantFeatures,
    | 'actionsUponTaskCompletion'
    | 'taskAttachments'
    | 'timeAttack'
    | 'auditTasks'
  > = {
    actionsUponTaskCompletion: false,
    taskAttachments: false,
    timeAttack: false,
    auditTasks: false
  };
  protected tasksWithCriteria: MongoStoredObject<TaskDto>[] = [];
  protected hasChanges = false;

  protected get scheduleType(): TaskScheduleType | null {
    if (this.schedules.controls.length === 0) {
      return null;
    }
    return this.schedules.controls[0].value.scheduleType;
  }

  protected get notifyUsersUponCompletion(): ObjectId[] {
    return this.mainFormGroup.controls.notifyUsersUponCompletion.value;
  }

  protected get requestProcessFeedback() {
    return this.mainFormGroup
      .get('actionsUponTaskCompletion')
      .value.find(
        (action) => action.actionType === EActionType.REQUEST_PROCESS_FEEDBACK
      ) as RequestProcessFeedbackDto;
  }

  protected get promptUserToLeaveNote() {
    return this.mainFormGroup
      .get('actionsUponTaskCompletion')
      .value.find(
        (action) => action.actionType === EActionType.PROMPT_USER_TO_LEAVE_NOTE
      ) as PromptUserToLeaveNoteDto;
  }

  protected get timeAttack() {
    return this.mainFormGroup.get('timeAttack').value;
  }

  protected get pointsEarns() {
    return this.mainFormGroup.get('pointsEarns').value;
  }

  protected get pointsUnit() {
    return this.mainFormGroup.get('pointsUnit').value;
  }

  protected get nounPlural() {
    return this.mainFormGroup.get('nounPlural').value;
  }

  protected get points() {
    return this.mainFormGroup.get('points').value;
  }

  protected get performanceEvaluation() {
    return this.mainFormGroup.get('performanceEvaluation').value;
  }

  public constructor(
    private readonly _taskListService: TaskListService,
    private readonly _userListService: UserListService,
    private readonly _userEntityService: UserEntityService,
    private readonly _loadingCtrl: LoadingController,
    private readonly _toastService: ToastService,
    private readonly _environmentService: EnvironmentService,
    private readonly _tenantService: TenantService,
    private readonly _uploadFileApiService: UploadFileApiService,
    private readonly _modalCtrl: ModalController,
    private readonly _taskFolderListService: TaskFolderListService,
    private readonly _cdr: ChangeDetectorRef
  ) {
    this.serverUrl = this._environmentService.serverUrl;

    this._tenantService.tenant$.subscribe((tenant) => {
      this.tenantFeatures = tenant.features;
      this.allowTasksToBeDisplayedOnlyDuringCertainHours =
        tenant.settings.checklist.allowTasksToBeDisplayedOnlyDuringCertainHours;
    });

    this._userEntityService.user$.subscribe((user) => {
      if (user && user.firstName) {
        this.userFirstName = user.firstName;
      }
    });

    this.mainFormGroup = new FormGroup<MainForm>({
      title: new FormControl<string>(null, Validators.required),
      points: new FormControl<number>(null, [
        Validators.required,
        Validators.min(0),
        Validators.pattern(/\d+/)
      ]),
      pointsEarns: new FormControl<TaskPointsEarns>(
        TaskPointsEarns.PER_COMPLETION,
        Validators.required
      ),
      pointsUnit: new FormControl<string>('', Validators.required),
      verbBase: new FormControl<string>('', Validators.required),
      verbPastSimple: new FormControl<string>('', Validators.required),
      schedule: new FormArray<FormGroup<TaskScheduleForm>>(
        [],
        minArrayLength(1)
      ),
      shouldReappear: new FormControl<boolean>(false, { nonNullable: true }),
      appearsAt: new FormControl<string>('00:00', Validators.required),
      disappearsAt: new FormControl<string>('23:45', Validators.required),
      performanceEvaluation: new FormControl<TaskPerformanceEvaluationType>(
        TaskPerformanceEvaluationType.STANDARD,
        {
          nonNullable: true
        }
      ),
      performanceDuration: new FormControl<string>(null),
      isCritical: new FormControl<boolean>(false, { nonNullable: true }),
      skillMatrix: new FormControl([]),
      notifyEveryoneUponCompletion: new FormControl<boolean>(false, {
        nonNullable: true
      }),
      notifyUsersUponCompletion: new FormControl([]),
      timeAttack: new FormControl<TaskTimeAttackType>(TaskTimeAttackType.OFF, {
        nonNullable: true
      }),
      timeAttackRewardPoints: new FormControl<number>(0, [
        Validators.required,
        Validators.min(0),
        Validators.pattern(/\d+/)
      ]),
      timeAttackAttemptPoints: new FormControl<number>(0, [
        Validators.required,
        Validators.min(0),
        Validators.pattern(/\d+/)
      ]),
      actionsUponTaskCompletion: new FormControl<
        TaskDto['actionsUponTaskCompletion']
      >([]),
      isAuditTask: new FormControl<boolean>(false, { nonNullable: true }),
      auditSuccessCriteria: new FormControl<string[]>([]),
      tasksForAudit: new FormControl<ObjectId[]>([]),
      auditReviewers: new FormControl<ObjectId[]>([])
    });
    this.schedules = this.mainFormGroup.controls.schedule;
  }

  public ngOnInit(): void {
    this._taskListService.list$.subscribe((tasks) => {
      this.tasksWithCriteria = tasks
        .filter((task) => !task.isDeleted)
        .filter((task) =>
          !task.isAuditTask && task.auditSuccessCriteria.length && this.task
            ? this.task._id.toString() !== task._id.toString()
            : true
        );
    });

    this._taskFolderListService.taskFolders$.subscribe((taskFolders) => {
      this.allTaskFolders = taskFolders;

      if (this.task) {
        this.taskFolder = taskFolders.find(
          (folder) => folder._id.toString() === this.task.taskFolder?.toString()
        );
      }
    });

    if (this.task === null) {
      this.mainFormGroup.controls.schedule.push(
        createScheduleFormGroup(TaskScheduleType.DAILY)
      );
      return;
    }
    this._setPointEarnsSubcontrols(this.task.pointsEarns);
    for (const schedule of this.task.schedule) {
      this.mainFormGroup.controls.schedule.push(
        createScheduleFormGroup(schedule.scheduleType, schedule as any)
      );
    }

    const initValue = this.task
      ? {
          description: this.task.details?.description,
          taskFolder: this.task.taskFolder,
          title: this.task.title,
          points: this.task.points,
          pointsEarns: this.task.pointsEarns,
          pointsUnit: this.task.pointsUnit,
          verbBase: this.task.verbBase,
          verbPastSimple: this.task.verbPastSimple,
          verbPastParticiple: this.task.verbPastParticiple,
          nounPlural: this.task.nounPlural,
          schedule: this.task.schedule,
          shouldReappear: this.task.shouldReappear,
          appearsAt: this.task.appearsAt,
          disappearsAt: this.task.disappearsAt,
          performanceEvaluation: this.task.performanceEvaluation,
          performanceDuration: this.task.performanceDuration,
          isCritical: this.task.isCritical,
          skillMatrix: this.task.skillMatrix,
          notifyEveryoneUponCompletion: this.task.notifyEveryoneUponCompletion,
          notifyUsersUponCompletion: this.task.notifyUsersUponCompletion,
          timeAttack: this.task.timeAttack,
          timeAttackRewardPoints: this.task.timeAttackRewardPoints,
          timeAttackAttemptPoints: this.task.timeAttackAttemptPoints,
          actionsUponTaskCompletion: this.task.actionsUponTaskCompletion,
          isAuditTask: this.task.isAuditTask,
          auditSuccessCriteria: this.task.auditSuccessCriteria,
          tasksForAudit: this.task.tasksForAudit,
          auditReviewers: this.task.auditReviewers
        }
      : null;

    this.mainFormGroup.reset(JSON.parse(JSON.stringify(initValue)));
    this.body = formatUploadedBody(
      this.task?.details?.description || '',
      this.task?.details?.attachments || [],
      this.serverUrl
    );

    this.mainFormGroup.valueChanges.subscribe((value) => {
      if (!initValue) return true;

      const newValue = {
        description: prepareBodyForUpload(
          this.body,
          this.task.details?.attachments || [],
          this.serverUrl
        ).body,
        taskFolder: this.taskFolder?._id,

        title: value.title,
        points: value.points,
        pointsEarns: value.pointsEarns,
        pointsUnit: value.pointsUnit,
        verbBase: value.verbBase,
        verbPastSimple: value.verbPastSimple,
        verbPastParticiple: value.verbPastParticiple,
        nounPlural: value.nounPlural,
        schedule: value.schedule,
        shouldReappear: value.shouldReappear,
        appearsAt: value.appearsAt,
        disappearsAt: value.disappearsAt,
        performanceEvaluation: value.performanceEvaluation,
        performanceDuration: value.performanceDuration,
        isCritical: value.isCritical,
        skillMatrix: value.skillMatrix,
        notifyEveryoneUponCompletion: value.notifyEveryoneUponCompletion,
        notifyUsersUponCompletion: value.notifyUsersUponCompletion,
        timeAttack: value.timeAttack,
        timeAttackRewardPoints: value.timeAttackRewardPoints,
        timeAttackAttemptPoints: value.timeAttackAttemptPoints,
        actionsUponTaskCompletion: value.actionsUponTaskCompletion,
        isAuditTask: value.isAuditTask,
        auditSuccessCriteria: value.auditSuccessCriteria,
        tasksForAudit: value.tasksForAudit,
        auditReviewers: value.auditReviewers
      };

      this.hasChanges = !fastDeepEqual(initValue, newValue);
      this._cdr.markForCheck();
    });
  }

  protected async showDescriptionEditor() {
    const modal = await this._modalCtrl.create({
      id: 'wysiwyg-modal',
      component: WysiwygModalComponent,
      componentProps: {
        body: this.body,
        disableAttachments: !this.tenantFeatures.taskAttachments,
        title: 'Description',
        bodyPlaceholder: 'Describe the process...',
        allowEmptyValue: true
      },
      handle: false,
      breakpoints: [0, 1],
      initialBreakpoint: 1
    });
    modal.present();

    const { data, role } = await modal.onWillDismiss();

    if (role === 'confirm') {
      this.body = data;
    }
  }

  public onPointEarnsChange(event: any): void {
    this.mainFormGroup.controls.pointsEarns.setValue(event.detail.value);
    this._setPointEarnsSubcontrols(event.detail.value);
  }

  private _isITask(_formValue: unknown): _formValue is TaskDto {
    return true;
  }

  public async onFormSubmit() {
    if (!this._isITask(this.mainFormGroup.value)) {
      return;
    }

    const loading = await this._loadingCtrl.create({
      message: 'Loading...'
    });
    loading.present();

    this._uploadFileApiService
      .extractAttachmentsFromBody(
        this.body || '',
        this.task?.details?.attachments || []
      )
      .subscribe({
        next: ({ body, newAttachments, keptAttachments }) => {
          const newValue: any = {
            ...this.mainFormGroup.value,
            taskFolder: this.taskFolder ? this.taskFolder._id : null,
            details: {
              description: body,
              attachments: [
                ...keptAttachments,
                ...newAttachments.map((a) => a._id.toString())
              ]
            },
            actionsUponTaskCompletion:
              this.mainFormGroup.value.actionsUponTaskCompletion.map(
                (action) => {
                  switch (action.actionType) {
                    case EActionType.REQUEST_PROCESS_FEEDBACK:
                      return {
                        ...action,
                        completeThreshold: action.completeThreshold || '1',
                        delayInDays: action.delayInDays || '1'
                      };
                    case EActionType.PROMPT_USER_TO_LEAVE_NOTE:
                      return action;
                  }
                }
              ),
            auditSuccessCriteria:
              this.mainFormGroup.value.auditSuccessCriteria.filter(Boolean)
          };

          const saveOperation = this.task
            ? this._taskListService.patchTask(this.task._id, newValue)
            : this._taskListService.createTask(newValue);

          saveOperation.subscribe({
            next: (result) => {
              this.taskSaved.emit(result);
            },
            error: () => {
              loading.dismiss();
              this._toastService.presentToast(
                this.task
                  ? 'Unable to update the task'
                  : 'Unable to create the task'
              );
            }
          });
        },
        error: (e) => {
          console.log(e);
          loading.dismiss();
          this._toastService.presentToast('Unable to upload attachments');
        }
      });
  }

  private _setPointEarnsSubcontrols(pointsEarns: TaskPointsEarns): void {
    if (
      pointsEarns === TaskPointsEarns.PER_COMPLETION &&
      this.mainFormGroup.contains('verbPastParticiple') &&
      this.mainFormGroup.contains('nounPlural')
    ) {
      this.mainFormGroup.removeControl('verbPastParticiple');
      this.mainFormGroup.removeControl('nounPlural');
    }

    if (
      pointsEarns === TaskPointsEarns.PER_UNIT &&
      !this.mainFormGroup.contains('verbPastParticiple') &&
      !this.mainFormGroup.contains('nounPlural')
    ) {
      this.mainFormGroup.addControl(
        'verbPastParticiple',
        new FormControl('', Validators.required)
      );
      this.mainFormGroup.addControl(
        'nounPlural',
        new FormControl('', Validators.required)
      );
    }
  }

  protected stopEventPropagation(event: Event): void {
    // this is the workarauond of the content resize issue
    event.stopPropagation();
  }

  protected onDeleteClick(): void {
    this.delete.emit();
  }

  protected onRestoreClick(): void {
    this.restore.emit();
  }

  protected notifyUsersUponCompletionChanged(
    selection: ObjectId[],
    allUsers: UserModel[]
  ): void {
    const allUserIds = allUsers.map(({ _id }) => _id);
    this.notifyUsersUponCompletionModal.dismiss();
    if (isSameSet(new Set(selection), allUserIds)) {
      this.mainFormGroup.patchValue({
        notifyUsersUponCompletion: [],
        notifyEveryoneUponCompletion: true
      });
    } else {
      this.mainFormGroup.patchValue({
        notifyUsersUponCompletion: selection,
        notifyEveryoneUponCompletion: false
      });
    }
  }

  protected async onTaskFolderSelect(
    taskFolder: MongoStoredObject<ITaskFolder>
  ) {
    this.taskFolderSelectorModal.dismiss();
    this.taskFolder = taskFolder;
  }

  protected async handleTaskFolderClearClick() {
    this.taskFolder = null;
  }

  protected onRequestProcessFeedbackToggle() {
    const actions = this.mainFormGroup.get('actionsUponTaskCompletion').value;

    if (
      actions.find(
        (action) => action.actionType === EActionType.REQUEST_PROCESS_FEEDBACK
      )
    ) {
      this.mainFormGroup
        .get('actionsUponTaskCompletion')
        .setValue(
          actions.filter(
            (action) =>
              action.actionType !== EActionType.REQUEST_PROCESS_FEEDBACK
          )
        );
    } else {
      this.mainFormGroup.get('actionsUponTaskCompletion').setValue([
        ...actions,
        {
          actionType: EActionType.REQUEST_PROCESS_FEEDBACK,
          completeThreshold: '10',
          delayInDays: '7',
          mandatory: false
        }
      ]);
    }
  }

  protected onPromptUserToLeaveNoteToggle() {
    const actions = this.mainFormGroup.get('actionsUponTaskCompletion').value;

    if (
      actions.find(
        (action) => action.actionType === EActionType.PROMPT_USER_TO_LEAVE_NOTE
      )
    ) {
      this.mainFormGroup
        .get('actionsUponTaskCompletion')
        .setValue(
          actions.filter(
            (action) =>
              action.actionType !== EActionType.PROMPT_USER_TO_LEAVE_NOTE
          )
        );
    } else {
      this.mainFormGroup.get('actionsUponTaskCompletion').setValue([
        ...actions,
        {
          actionType: EActionType.PROMPT_USER_TO_LEAVE_NOTE,
          visibleToEveryone: true,
          visibleToUsers: [],
          mandatory: false,
          showMoreRecentNote: EShowMoreRecentNote.ONLY_ONCE_TO_EACH_USER
        }
      ]);
    }
  }

  protected onActionUponCompletingChange(
    actionType: EActionType,
    fieldName: string,
    detailField: string,
    newValue: any
  ) {
    const actions = this.mainFormGroup.get('actionsUponTaskCompletion').value;

    this.mainFormGroup.get('actionsUponTaskCompletion').setValue(
      actions.map((action) => {
        if (action.actionType === actionType) {
          return {
            ...action,
            [fieldName]: newValue.detail[detailField]
          };
        } else {
          return action;
        }
      })
    );
  }

  protected usersWhoCanSeeNotesChange(
    selection: ObjectId[],
    allUsers: UserModel[]
  ): void {
    const allUserIds = allUsers.map(({ _id }) => _id);
    this.selectUsersWhoCanSeeNotesModal.dismiss();

    const actions = this.mainFormGroup.get('actionsUponTaskCompletion').value;

    const isSame = isSameSet(new Set(selection), allUserIds);

    this.mainFormGroup.get('actionsUponTaskCompletion').setValue(
      actions.map((action) => {
        if (action.actionType === EActionType.PROMPT_USER_TO_LEAVE_NOTE) {
          return {
            ...action,
            visibleToUsers: isSame ? [] : (selection as any[]),
            visibleToEveryone: isSame
          };
        } else {
          return action;
        }
      })
    );
  }

  protected onTasksForAuditSelect(value: ObjectId[]) {
    if (value.length) {
      const oldValue = this.mainFormGroup.controls.tasksForAudit.value;

      const keptValues = value.filter((v1) => oldValue.includes(v1));
      const newValues = value.filter((v1) => !oldValue.includes(v1));

      this.mainFormGroup.controls.tasksForAudit.setValue([
        ...keptValues,
        ...newValues
      ]);
      this.tasksForAuditSelectorModal.dismiss();
    }
  }

  protected get selectedTasksForAuditIds() {
    return this.mainFormGroup.controls.tasksForAudit.value;
  }

  protected get selectedTasksForAudit() {
    return this.mainFormGroup.controls.tasksForAudit.value.map((t) =>
      this.tasksWithCriteria.find((task) => task._id === t)
    );
  }

  protected get selectedAuditSuccessCriteria() {
    return this.mainFormGroup.controls.auditSuccessCriteria.value;
  }

  protected taskForAuditTrackBy(
    _index: number,
    item: MongoStoredObject<TaskDto>
  ) {
    return item._id;
  }

  protected auditSuccessCriterionTrackBy(index: number) {
    return index;
  }

  protected handleTasksForAuditReorder({
    detail
  }: ItemReorderCustomEvent): void {
    const newValue = detail.complete(
      this.mainFormGroup.controls.tasksForAudit.value
    );
    this.mainFormGroup.controls.tasksForAudit.setValue(newValue);
  }

  protected handleAuditSuccessCriteriaReorder({
    detail
  }: ItemReorderCustomEvent): void {
    const newValue = detail.complete(
      this.mainFormGroup.controls.auditSuccessCriteria.value
    );
    this.mainFormGroup.controls.auditSuccessCriteria.setValue(newValue);
  }

  protected get isAuditTask() {
    return this.mainFormGroup.controls.isAuditTask.value;
  }

  protected onAuditSuccessCriterionChange(index: number, event: any) {
    this.mainFormGroup.controls.auditSuccessCriteria.setValue(
      this.mainFormGroup.controls.auditSuccessCriteria.value.map(
        (criterion, i) => (i === index ? event.detail.value : criterion)
      )
    );
  }

  protected addEmptyAuditSuccessCriterion() {
    this.mainFormGroup.controls.auditSuccessCriteria.setValue([
      ...this.mainFormGroup.controls.auditSuccessCriteria.value,
      ''
    ]);
  }

  protected removeAuditSuccessCriterionAtIndex(index: number) {
    const newValue = this.mainFormGroup.controls.auditSuccessCriteria.value;
    newValue.splice(index, 1);
    this.mainFormGroup.controls.auditSuccessCriteria.setValue(newValue);
  }

  protected auditReviewersChange(selection: ObjectId[]): void {
    this.selectAuditReviewersModals.dismiss();

    this.mainFormGroup.get('auditReviewers').setValue(selection as any[]);
  }

  protected async openSkillMatrix() {
    this.allUsers$.pipe(take(1)).subscribe(async (allUsers) => {
      const skillMatrixSubject = new BehaviorSubject(
        this.mainFormGroup.controls.skillMatrix.value
      );

      const skillMatrixForTaskModal = await this._modalCtrl.create({
        id: 'skill-matrix-for-task-modal',
        component: SkillMatrixForTaskModalComponent,
        componentProps: {
          allUsers,
          skillMatrix: this.mainFormGroup.controls.skillMatrix.value,
          skillMatrixSubject
        },
        breakpoints: [0, 1],
        initialBreakpoint: 1,
        handle: false
      });
      skillMatrixForTaskModal.present();

      skillMatrixSubject.subscribe(
        (
          data: {
            status: ESkillMatrixStatus;
            user: ObjectId;
          }[]
        ) => {
          this.mainFormGroup.controls.skillMatrix.setValue(data, {
            emitEvent: true
          });
        }
      );

      skillMatrixForTaskModal.onDidDismiss().then((_) => {
        skillMatrixSubject.unsubscribe();
      });
    });
  }
}
