// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_getAllTags = () => "/tags";

export type TSpecHandler_getAllTags = RequestHandler<TSpecPathGetAllTags, any, TSpecBodyGetAllTags, TSpecQueryGetAllTags, {}>;

export interface TSpecPathGetAllTags {  }

export interface TSpecBodyGetAllTags {}

export interface TSpecQueryGetAllTags {
  page?: string;
  limit?: string;
  searchTerm?: string;
}
