<div class="suggestion-item__header">
  <div class="suggestion-item__title">{{ opportunity.title }}</div>
  <ion-chip
    class="ion-text-capitalize suggestion-item__status"
    [color]="statusColor"
  >
    {{ opportunity.status.toLowerCase().replace('_', ' ') }}
  </ion-chip>
</div>
<div class="suggestion-item__footer">
  <div class="suggestion-item__footer-item">
    {{ authorName }}
  </div>
  <div class="suggestion-item__footer-item">
    {{ opportunity.dateCreated | date : 'MMM d, y' }}
  </div>
  <div *ngIf="opportunity.solutionsCount" class="suggestion-item__footer-item">
    {{ solutionsText }}
  </div>
  <div
    *ngIf="opportunity.isPublic"
    class="suggestion-item__footer-item--primary"
  >
    Published to Team
  </div>
</div>
