import { Injectable } from '@angular/core';
import { AnnouncementApiService } from './api/announcement.api.service';
import { Observable, Subject, map, repeat, shareReplay, tap } from 'rxjs';
import { TSpecBodyPublishAnnouncement } from '@backend/types/generated/Announcements/publishAnnouncement';
import { TSpecBodyEditAnnouncement } from '@backend/types/generated/Announcements/editAnnouncement';

@Injectable({ providedIn: 'root' })
export class AnnouncementListService {
  public readonly pageSize = 20;

  private readonly _update$ = new Subject<void>();

  public unacknowledgedCount$: Observable<number> = this._api
    .getUnacknowledgedCount()
    .pipe(
      map(({ unacknowledgedCount }) => unacknowledgedCount),
      repeat({ delay: () => this._update$ }),
      shareReplay({ refCount: true, bufferSize: 1 })
    );

  public constructor(private readonly _api: AnnouncementApiService) {}

  public updateUnacknowledgedCount() {
    this._update$.next();
  }

  public editAnnouncement(id: string, body: TSpecBodyEditAnnouncement) {
    return this._api
      .editAnnouncement({ path: { id }, body })
      .pipe(tap(() => this.updateUnacknowledgedCount()));
  }

  public publishAnnouncement(body: TSpecBodyPublishAnnouncement) {
    return this._api
      .publishAnnouncement({ body })
      .pipe(tap(() => this.updateUnacknowledgedCount()));
  }

  public acknowledgeAnnouncement(announcementId: string) {
    return this._api
      .acknowledgeAnnouncement({ path: { id: announcementId } })
      .pipe(tap(() => this.updateUnacknowledgedCount()));
  }

  public deleteAnnouncement(announcementId: string) {
    return this._api
      .deleteAnnouncement({ path: { id: announcementId } })
      .pipe(tap(() => this.updateUnacknowledgedCount()));
  }

  public readAnnouncement(announcementId: string) {
    return this._api
      .readAnnouncement({ path: { id: announcementId } })
      .pipe(tap(() => this.updateUnacknowledgedCount()));
  }

  public respondToAnnouncement(announcementId: string, data: { body: string }) {
    return this._api
      .respondToAnnouncement({ path: { id: announcementId }, body: data })
      .pipe(tap(() => this.updateUnacknowledgedCount()));
  }

  public getThreadOfAnnouncement(announcementId: string) {
    return this._api.getThreadOfAnnouncement({ path: { id: announcementId } });
  }
}
