import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { UserModel } from '@app/core/model/user.model';
import { Observable, take } from 'rxjs';
import { ToastService } from '@app/core/service/toast.service';
import { ITenant } from '@backend/models/types/tenant';
import { MongoStoredObject } from '@app/types/mongo-stored-object';
import { LedgerApiService } from '@app/core/service/api/ledger.api.service';
import { RecognitionApiService } from '@app/core/service/api/recognition.api.service';
import { AuthenticationService } from '@app/core';
import { ActivatedRoute } from '@angular/router';
import { UserEntityService } from '@app/core/service/user-entity.service';
import {
  ActionSheetController,
  IonInfiniteScroll,
  LoadingController
} from '@ionic/angular';
import { groupByDate } from '@app/utils/group-by-date';
import { LedgerModel } from '@app/core/model/ledger.model';
import { PullToSearchComponent } from '@app/modules/pull-to-search/pull-to-search.component';
import { RecognitionModalService } from '@app/core/service/recognition-modal.service';
import { TenantService } from '@app/core/service/tenant.service';
import { UserRoles } from '@backend/models/types/user';

@Component({
  selector: 'app-recognition',
  templateUrl: './recognition.component.html',
  styleUrls: ['./recognition.component.scss']
})
export class RecognitionComponent implements AfterViewInit {
  @ViewChild('infiniteScroll')
  public infiniteScroll: IonInfiniteScroll;

  @ViewChild('pullToSearch', { static: true })
  public pullToSearch!: PullToSearchComponent;

  protected isAdmin: boolean;
  protected authenticatedUser: UserModel;
  protected currentUser$: Observable<UserModel> = this._userEntity.user$;
  protected tenant: MongoStoredObject<ITenant>;
  protected isLoading = false;
  protected postPageSize = 10;
  protected postsLoaded = 0;
  protected posts: LedgerModel[] = [];
  protected postsGroupedByDate: {
    date: string;
    items: LedgerModel[];
  }[] = [];
  protected hasNextPage = true;
  protected searchTerm = '';

  public constructor(
    private readonly _ledgerApiService: LedgerApiService,
    private readonly _authenticationService: AuthenticationService,
    private readonly _userEntity: UserEntityService,
    private readonly _toastService: ToastService,
    private readonly _route: ActivatedRoute,
    private readonly _recognitionApi: RecognitionApiService,
    private readonly _actionSheetCtrl: ActionSheetController,
    private readonly _loadingCtrl: LoadingController,
    private readonly _recognitionModalService: RecognitionModalService,
    private readonly _tenantService: TenantService
  ) {
    this.authenticatedUser = this._authenticationService.user;
    this.isAdmin = this.authenticatedUser
      ? this.authenticatedUser.roles.includes(UserRoles.Admin)
      : false;

    this._tenantService.tenant$.pipe(take(1)).subscribe((tenant) => {
      this.tenant = tenant;
    });

    this.setDataSource();
  }

  public ngAfterViewInit(): void {
    this._route.queryParams.subscribe((params) => {
      setTimeout(() => {
        if (params && params.action) {
          switch (params.action) {
            case 'openRecognitionModal':
              this.onAddRecognition();
              break;
          }
        }
      }, 500);
    });
  }

  protected setDataSource() {
    this.isLoading = true;

    // to get the latest posts
    this.posts = [];
    this.postsGroupedByDate = [];
    this.postsLoaded = this.postPageSize;
    this._fetchLedgerPage();
  }

  protected updatePrivate(value: boolean, post: LedgerModel) {
    const updatedPost = {
      description: post.description,
      recipientId: post.recipient._id.toString(),
      behaviorId: post.behavior._id.toString(),
      isPrivate: value
    };

    this._tenantService.tenant$.pipe(take(1)).subscribe((tenant) => {
      this._ledgerApiService
        .updateLedger({
          path: { id: post._id, tenantId: tenant._id },
          body: updatedPost
        })
        .subscribe(() => {
          post.isPrivate = value;
        });
    });
  }

  private _fetchLedgerPage(): void {
    const currentPage = this.postsLoaded / this.postPageSize;

    this._tenantService.tenant$.pipe(take(1)).subscribe((tenant) => {
      this._ledgerApiService
        .getLedger({
          path: { tenantId: tenant._id },
          query: {
            page: currentPage.toString(),
            limit: this.postPageSize.toString(),
            searchTerm: this.searchTerm
          }
        })
        .subscribe((res) => {
          this.hasNextPage = res.ledgers.length === this.postPageSize;
          this.posts = this.posts.concat(res.ledgers);
          this.postsGroupedByDate = groupByDate<LedgerModel>(
            this.posts,
            'dateCreated'
          );
          this.isLoading = false;
          this.pullToSearch.initSearch();
          this.infiniteScroll?.complete();
        });
    });
  }

  protected onIonInfinite() {
    this.postsLoaded += this.postPageSize;
    this._fetchLedgerPage();
  }

  protected trackPosts(_: number, item: LedgerModel) {
    return item._id;
  }

  protected trackDates(
    _: number,
    item: {
      date: string;
      items: LedgerModel[];
    }
  ) {
    return item.date;
  }

  protected async onEditRecognition(ledger: LedgerModel) {
    const { recognition, role } =
      await this._recognitionModalService.showEditRecognitionModal(
        {
          initialData: {
            userId: ledger.recipient._id,
            behaviorId: ledger.behavior._id,
            description: ledger.description,
            isPrivate: ledger.isPrivate
          }
        },
        'ion-split-pane'
      );

    if (role === 'confirm') {
      const loading = await this._loadingCtrl.create({
        message: 'Updating...'
      });
      loading.present();

      this._tenantService.tenant$.pipe(take(1)).subscribe((tenant) => {
        this._ledgerApiService
          .updateLedger({
            path: { id: ledger._id, tenantId: tenant._id },
            body: {
              recipientId: recognition.userId,
              behaviorId: recognition.behaviorId,
              description: recognition.description,
              isPrivate: recognition.isPrivate
            }
          })
          .subscribe(() => {
            loading.dismiss();
            this.setDataSource();
            this._userEntity.update();
          });
      });
    }
  }

  protected async onDeleteRecognition(ledger: LedgerModel) {
    const actionSheet = await this._actionSheetCtrl.create({
      header: 'Are you sure?',
      subHeader: 'This message will be permanently deleted.',
      buttons: [
        {
          role: 'destructive',
          text: 'Delete'
        },
        {
          role: 'cancel',
          text: 'Cancel'
        }
      ]
    });
    actionSheet.present();

    const { role } = await actionSheet.onWillDismiss();

    if (role === 'destructive') {
      const loading = await this._loadingCtrl.create({
        message: 'Deleting...'
      });
      loading.present();

      this._ledgerApiService
        .deleteLedger({ path: { id: ledger._id } })
        .subscribe(() => {
          loading.dismiss();
          this.setDataSource();
          this._userEntity.update();
        });
    }
  }

  protected async onAddRecognition() {
    const { recognition, role, behavior } =
      await this._recognitionModalService.showEditRecognitionModal(
        { createMode: true },
        'ion-split-pane'
      );

    if (role === 'confirm') {
      const loading = await this._loadingCtrl.create({
        message: 'Posting...'
      });
      loading.present();

      this._recognitionApi
        .postRecognition({
          body: {
            recipientId: recognition.userId,
            behaviorId: recognition.behaviorId,
            description: recognition.description,
            isPrivate: recognition.isPrivate
          }
        })
        .subscribe({
          next: () => {
            loading.dismiss();
            this.setDataSource();
            this._userEntity.update();

            if (
              behavior &&
              behavior._id.toString() ===
                this.tenant.behaviors.reinforce.toString()
            ) {
              this._toastService.presentToast(
                'Message sent successfully — Thank you!',
                { okText: `+${behavior.points} points` }
              );
            }
          },
          error: (e: { error: { message: string } }) => {
            loading.dismiss();
            this._toastService.presentToast(
              e.error?.message || 'There was an error creating your post'
            );
          }
        });
    }
  }

  protected handleSearchInput(value: string) {
    this.searchTerm = value;
    this.setDataSource();
  }

  protected firstPagePostsCount(groupIndex: number) {
    return this.postsGroupedByDate
      .slice(0, groupIndex)
      .reduce((prev, cur) => prev + cur.items.length, 0);
  }
}
