import { Component, Input, OnInit } from '@angular/core';
import { IntegrationApiService } from '@app/core/service/api/integration.api.service';
import { MongoStoredObject } from '@app/types/mongo-stored-object';
import { ITenant } from '@backend/models/types/tenant';
import { IonNav, LoadingController } from '@ionic/angular';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  EIntegrationProvider,
  IIntegration,
  IIntegrationAirtableSettings,
  IIntegrationGoHighLevelSettings
} from '@backend/models/types/integration';
import { ToastService } from '@app/core/service/toast.service';
import { Browser } from '@capacitor/browser';
import { TagsDefinition } from '@backend/types/tags-definition';
import { TenantService } from '@app/core/service/tenant.service';
import { GhlTagUploaderComponent } from '../ghl-tag-uploader/ghl-tag-uploader.component';

const DEFAULT_VALUES: {
  airtable: IIntegrationAirtableSettings;
  goHighLevel: IIntegrationGoHighLevelSettings;
} = {
  airtable: {
    apiKey: '',
    baseId: '',
    tableName: 'Tags',
    fieldNames: {
      tag: 'Tag',
      phone: 'Phone',
      pickupDate: 'Date',
      photos: 'Photos',
      server: 'Server',
      notes: 'Notes',
      numberOfItems: 'Number of Items',
      status: 'Status'
    }
  },
  goHighLevel: {
    clientId: '',
    clientSecret: '',
    pipelineName: '',
    accessToken: '',
    refreshToken: '',
    companyId: '',
    locationId: ''
  }
};

@Component({
  selector: 'app-integrations-users',
  templateUrl: './tenant-integrations.component.html',
  styleUrls: ['./tenant-integrations.component.scss']
})
export class TenantIntegrationsComponent implements OnInit {
  @Input()
  public tenant: MongoStoredObject<ITenant & { integrations: IIntegration[] }>;

  protected airtableFormGroup: FormGroup = new FormGroup({
    apiKey: new FormControl<string>(
      DEFAULT_VALUES.airtable.apiKey,
      Validators.required
    ),
    baseId: new FormControl<string>(
      DEFAULT_VALUES.airtable.baseId,
      Validators.required
    ),
    tableName: new FormControl<string>(
      DEFAULT_VALUES.airtable.tableName,
      Validators.required
    ),
    fieldNames: new FormGroup({
      tag: new FormControl<string>(
        DEFAULT_VALUES.airtable.fieldNames.tag,
        Validators.required
      ),
      phone: new FormControl<string>(
        DEFAULT_VALUES.airtable.fieldNames.phone,
        Validators.required
      ),
      pickupDate: new FormControl<string>(
        DEFAULT_VALUES.airtable.fieldNames.pickupDate,
        Validators.required
      ),
      photos: new FormControl<string>(
        DEFAULT_VALUES.airtable.fieldNames.photos,
        Validators.required
      ),
      server: new FormControl<string>(
        DEFAULT_VALUES.airtable.fieldNames.server,
        Validators.required
      ),
      notes: new FormControl<string>(
        DEFAULT_VALUES.airtable.fieldNames.notes,
        Validators.required
      ),
      numberOfItems: new FormControl<string>(
        DEFAULT_VALUES.airtable.fieldNames.numberOfItems,
        Validators.required
      ),
      status: new FormControl<string>(
        DEFAULT_VALUES.airtable.fieldNames.status,
        Validators.required
      )
    })
  });
  protected isAirtableIntegrationAvailable = false;
  protected isAirtableIntegrationEdit = false;

  protected goHighLevelFormGroup: FormGroup = new FormGroup({
    clientId: new FormControl<string>(
      DEFAULT_VALUES.goHighLevel.clientId,
      Validators.required
    ),
    clientSecret: new FormControl<string>(
      DEFAULT_VALUES.goHighLevel.clientSecret,
      Validators.required
    ),
    pipelineName: new FormControl<string>(
      DEFAULT_VALUES.goHighLevel.pipelineName,
      Validators.required
    ),
    accessToken: new FormControl<string>(DEFAULT_VALUES.goHighLevel.accessToken)
  });
  protected isGoHighLevelIntegrationAvailable = false;
  protected isGoHighLevelIntegrationEdit = false;
  protected defaultTagsSettings: TagsDefinition;
  protected tagsSettingsFormGroup: FormGroup = new FormGroup({
    defaultStatusName: new FormControl<string>('', Validators.required),
    readyForPickupStatusName: new FormControl<string>('', Validators.required),
    completedStatusName: new FormControl<string>('', Validators.required),
    reminder1stStatusName: new FormControl<string>('', Validators.required),
    reminder2ndStatusName: new FormControl<string>('', Validators.required)
  });

  protected isLoading = true;

  public constructor(
    private readonly _ionNav: IonNav,
    private readonly _integrationApiService: IntegrationApiService,
    private readonly _loadingCtrl: LoadingController,
    private readonly _toastService: ToastService,
    private readonly _tenantService: TenantService
  ) {
    this._tenantService.tenant$.subscribe((tenant) => {
      this.defaultTagsSettings = tenant.settings.tags;
      this.tagsSettingsFormGroup.reset({
        defaultStatusName: tenant.settings.tags.defaultStatusName,
        readyForPickupStatusName: tenant.settings.tags.readyForPickupStatusName,
        completedStatusName: tenant.settings.tags.completedStatusName,
        reminder1stStatusName: tenant.settings.tags.reminder1stStatusName,
        reminder2ndStatusName: tenant.settings.tags.reminder2ndStatusName
      });
    });
  }

  public ngOnInit(): void {
    this._fetchIntegrations();

    Browser.addListener('browserFinished', () => {
      this._fetchIntegrations();
    });
  }

  protected _fetchIntegrations() {
    this.isLoading = true;

    this._integrationApiService
      .getIntegrations({ query: { tenantId: this.tenant._id } })
      .subscribe((integrations) => {
        const airtableIntegration = integrations.find(
          (integration) =>
            integration.provider === EIntegrationProvider.AIRTABLE
        );
        this.isAirtableIntegrationAvailable = !!airtableIntegration;

        const goHighLevelIntegration = integrations.find(
          (integration) =>
            integration.provider === EIntegrationProvider.GOHIGHLEVEL
        );
        this.isGoHighLevelIntegrationAvailable = !!goHighLevelIntegration;

        this.isLoading = false;
      });
  }

  protected onBackButtonClick() {
    this._ionNav.pop();
  }

  protected async goToUrl(url: string) {
    try {
      await Browser.open({
        url: encodeURI(url)
      });
    } catch (e) {
      this._toastService.presentToast(e.message || 'Unable to open URL');
    }
  }

  // Airtable

  protected async saveAirtableIntegration() {
    const loading = await this._loadingCtrl.create({
      message: 'Saving...'
    });
    loading.present();

    this._integrationApiService
      .setIntegration({
        body: {
          provider: EIntegrationProvider.AIRTABLE,
          settings: this.airtableFormGroup.value,
          tenantId: this.tenant._id,
          publicSettingsFields: ['fieldNames']
        }
      })
      .subscribe(() => {
        this.isAirtableIntegrationAvailable = true;
        this.isAirtableIntegrationEdit = false;
        this.airtableFormGroup.reset(DEFAULT_VALUES.airtable);
        this._integrationApiService.update();
        loading.dismiss();
        this._toastService.presentToast(
          'Airtable Integration Successfully Saved!'
        );
      });
  }

  protected async deleteAirtableIntegration() {
    const loading = await this._loadingCtrl.create({
      message: 'Deleting...'
    });
    loading.present();

    this._integrationApiService
      .deleteIntegration({
        path: { provider: EIntegrationProvider.AIRTABLE },
        query: { tenantId: this.tenant._id }
      })
      .subscribe(() => {
        this.isAirtableIntegrationAvailable = false;
        this.airtableFormGroup.reset(DEFAULT_VALUES.airtable);
        this._integrationApiService.update();
        loading.dismiss();
        this._toastService.presentToast(
          'Airtable Integration Successfully Deleted!'
        );
      });
  }

  protected async editAirtableIntegration() {
    const loading = await this._loadingCtrl.create({
      message: 'Loading...'
    });
    loading.present();

    this._integrationApiService
      .getIntegration({
        path: { provider: EIntegrationProvider.AIRTABLE },
        query: { tenantId: this.tenant._id }
      })
      .subscribe((airtableIntegration) => {
        this.isAirtableIntegrationEdit = true;
        this.airtableFormGroup.reset(airtableIntegration.settings);
        loading.dismiss();
      });
  }

  protected cancelEditingAirtableIntegration() {
    this.isAirtableIntegrationEdit = false;
    this.airtableFormGroup.reset(DEFAULT_VALUES.airtable);
  }

  // GoHighLevel

  protected async saveGoHighLevelIntegration() {
    this.isGoHighLevelIntegrationEdit = false;
    this._tenantService.patch({
      settings: {
        tags: {
          ...this.defaultTagsSettings,
          ...this.tagsSettingsFormGroup.value
        }
      }
    });

    if (!this.isGoHighLevelIntegrationAvailable) {
      const loading = await this._loadingCtrl.create({
        message: 'Saving...'
      });
      loading.present();

      this._integrationApiService
        .initIntegration({
          path: { provider: EIntegrationProvider.GOHIGHLEVEL },
          body: {
            tenantId: this.tenant._id,
            settings: this.goHighLevelFormGroup.value
          }
        })
        .subscribe((res) => {
          this.goToUrl(res.initUrl);

          this.isGoHighLevelIntegrationAvailable = true;
          this.goHighLevelFormGroup.reset(DEFAULT_VALUES.goHighLevel);
          this._integrationApiService.update();
          loading.dismiss();
          this._toastService.presentToast(
            'GoHighLevel Integration Successfully Saved!'
          );
        });
    }
  }

  protected async deleteGoHighLevelIntegration() {
    const loading = await this._loadingCtrl.create({
      message: 'Deleting...'
    });
    loading.present();

    this._integrationApiService
      .deleteIntegration({
        path: { provider: EIntegrationProvider.GOHIGHLEVEL },
        query: { tenantId: this.tenant._id }
      })
      .subscribe(() => {
        this.isGoHighLevelIntegrationAvailable = false;
        this.goHighLevelFormGroup.reset(DEFAULT_VALUES.goHighLevel);
        this._integrationApiService.update();
        loading.dismiss();
        this._toastService.presentToast(
          'GoHighLevel Integration Successfully Deleted!'
        );
      });
  }

  protected async editGoHighLevelIntegration() {
    const loading = await this._loadingCtrl.create({
      message: 'Loading...'
    });
    loading.present();

    this._integrationApiService
      .getIntegration({
        path: { provider: EIntegrationProvider.GOHIGHLEVEL },
        query: { tenantId: this.tenant._id }
      })
      .subscribe((goHighLevelIntegration) => {
        this.isGoHighLevelIntegrationEdit = true;
        this.goHighLevelFormGroup.reset(goHighLevelIntegration.settings);
        loading.dismiss();
      });
  }

  protected cancelEditingGoHighLevelIntegration() {
    this.isGoHighLevelIntegrationEdit = false;
    this.tagsSettingsFormGroup.reset(this.defaultTagsSettings);
  }

  protected goToGoHighLevelTagUploader() {
    this._ionNav.push(GhlTagUploaderComponent, { tenant: this.tenant });
  }
}
