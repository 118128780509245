<ion-header class="ion-no-border">
  <ion-toolbar class="app-toolbar">
    <ion-buttons slot="start">
      <ion-button (click)="onBackButtonClick()" appHaptics>
        <ion-icon slot="icon-only" name="chevron-back-outline"></ion-icon>
        <ion-label>Back</ion-label>
      </ion-button>
    </ion-buttons>
    <ion-title class="ion-text-capitalize"> Suggestions </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content color="light">
  <app-width-restricted-container class="container-with-fab">
    <app-list-pre-line
      title="Suggestion Categories"
      [showAddButton]="true"
      (addClicked)="addCategory()"
    />
    <ion-list [inset]="true">
      <ion-item
        *ngFor="let category of categories; trackBy: trackCategories"
        button
        (click)="updateCategory(category)"
        appHaptics
      >
        <ion-label>{{ category.name }}</ion-label>
      </ion-item>
    </ion-list>

    <app-list-pre-line title="New Suggestion" />
    <ion-list [inset]="true">
      <ion-item
        button
        appHaptics
        (click)="onNotifyUsersOnNewOpportunitiesClick()"
      >
        <ion-label>
          {{ suggestionsSettings.notifyUsersOnNewOpportunities.length }} users
          selected
        </ion-label>
      </ion-item>
    </ion-list>
    <app-list-post-line
      text="Notify selected users when new suggestion is posted (Push Notification and Email)"
    />

    <app-list-pre-line title="New Comment" />
    <ion-list [inset]="true">
      <ion-item button appHaptics (click)="onNotifyUsersOnNewSolutionsClick()">
        <ion-label>
          {{ suggestionsSettings.notifyUsersOnNewSolutions.length }} users
          selected
        </ion-label>
      </ion-item>
    </ion-list>
    <app-list-post-line
      text="Notify selected users when new comment is posted (Push Notification and Email)"
    />

    <app-list-pre-line title="Suggestion Status" />
    <ion-list [inset]="true">
      <ion-item
        button
        appHaptics
        (click)="onNotifyUsersOnOpportunityStatusChangeClick()"
      >
        <ion-label>
          {{ suggestionsSettings.notifyUsersOnOpportunityStatusChange.length }}
          users selected
        </ion-label>
      </ion-item>
    </ion-list>
    <app-list-post-line
      text="Notify selected users when suggestion status is changed (Push Notification and Email)"
    />

    <app-list-pre-line title="Suggestion Form URL" />
    <ion-list [inset]="true">
      <ion-item
        button
        appHaptics
        [detail]="false"
        (click)="onCopySuggestionLinkClick()"
      >
        <ion-icon slot="start" name="copy-outline"></ion-icon>
        <ion-label>Copy Link</ion-label>
      </ion-item>
    </ion-list>
  </app-width-restricted-container>
</ion-content>

<ion-loading #loading message="Loading..."></ion-loading>
