// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_removeCompensationPool = (path: TSpecPathRemoveCompensationPool) => {
  let res = "/compensation/pools/:compensationPoolId";
  Object.entries(path).map(([key, value]) => {
    res = res.replace(':' + key, value);
  });
  return res;
};

export type TSpecHandler_removeCompensationPool = RequestHandler<TSpecPathRemoveCompensationPool, any, TSpecBodyRemoveCompensationPool, TSpecQueryRemoveCompensationPool, {}>;

export interface TSpecPathRemoveCompensationPool { compensationPoolId: string; }

export interface TSpecBodyRemoveCompensationPool {}

export interface TSpecQueryRemoveCompensationPool {}
