import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '@app/core';
import { ToastService } from '@app/core/service/toast.service';

@Component({
  selector: 'app-change-email',
  templateUrl: './change-email.component.html',
  styleUrls: ['./change-email.component.scss']
})
export class ChangeEmailComponent implements OnInit {
  protected navigateTo = '';

  public constructor(
    private readonly _router: Router,
    private readonly _route: ActivatedRoute,
    private readonly _authenticationService: AuthenticationService,
    private readonly _toastService: ToastService
  ) {
    this._route.queryParams.subscribe((params) => {
      if (params['token']) {
        const token = params['token'];

        this._authenticationService.verifyEmailChange(token).subscribe({
          next: () => {
            this._toastService.presentToast('Email changed successfully');
            setTimeout(() => {
              this._authenticationService.logout();
            }, 2000);
          },
          error: (e) => {
            this._toastService.presentToast(
              e.error?.message || 'Unable to verify email'
            );
            setTimeout(() => {
              this._authenticationService.logout();
            }, 2000);
          }
        });
      } else {
        this._router.navigate(['/login'], { replaceUrl: true });
      }
    });
  }

  public ngOnInit(): void {}
}
