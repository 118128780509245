// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_updateSolution = (path: TSpecPathUpdateSolution) => {
  let res = "/suggestions/opportunities/:opportunityId/solutions/:solutionId";
  Object.entries(path).map(([key, value]) => {
    res = res.replace(':' + key, value);
  });
  return res;
};

export type TSpecHandler_updateSolution = RequestHandler<TSpecPathUpdateSolution, any, TSpecBodyUpdateSolution, TSpecQueryUpdateSolution, {}>;

export interface TSpecPathUpdateSolution { opportunityId: string; solutionId: string; }

export interface TSpecBodyUpdateSolution {
  isPublic: boolean;
}

export interface TSpecQueryUpdateSolution {}
