import { NgModule } from '@angular/core';
import { WhatsNewModalComponent } from './whats-new-modal.component';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { DirectivesModule } from '@app/modules/directives.module';
import { AnnouncementItemModule } from '../announcement-item/announcement-item.module';
import { RecognitionItemModule } from '../recognition-item/recognition-item.module';
import { AnnouncementPreviewComponent } from './components/announcement-preview/announcement-preview.component';
import { WysiwygEditorComponent } from '../wysiwyg-editor/wysiwyg-editor.component';
import { AnnouncementThreadModule } from '../announcement-thread/announcement-thread.module';
import { SurveyItemModule } from '../survey-item/survey-item.module';
import { SurveyPreviewComponent } from './components/survey-preview/survey-preview.component';
import { PositiveFeedbackQuestionModule } from '../positive-feedback-question/positive-feedback-question.module';
import { NegativeFeedbackQuestionModule } from '../negative-feedback-question/negative-feedback-question.module';
import { WhatsNewItemComponent } from './components/whats-new-item/whats-new-item.component';
import { MoodCheckQuestionModule } from '../mood-check-question/mood-check-question.module';
import { TeamEvaluationQuestionModule } from '../team-evaluation-question/team-evaluation-question.module';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    DirectivesModule,
    AnnouncementItemModule,
    RecognitionItemModule,
    SurveyItemModule,
    WysiwygEditorComponent,
    AnnouncementThreadModule,
    PositiveFeedbackQuestionModule,
    NegativeFeedbackQuestionModule,
    MoodCheckQuestionModule,
    TeamEvaluationQuestionModule
  ],
  exports: [WhatsNewModalComponent],
  declarations: [
    WhatsNewModalComponent,
    AnnouncementPreviewComponent,
    SurveyPreviewComponent,
    WhatsNewItemComponent
  ]
})
export class WhatsNewModalModule {}
