<ion-header class="ion-no-border">
  <ng-container *ngIf="!showSearchBar">
    <ion-toolbar class="app-toolbar">
      <ion-buttons slot="start">
        <ion-button (click)="onBackButtonClick()" appHaptics>
          <ion-icon slot="icon-only" name="chevron-back-outline"></ion-icon>
        </ion-button>
      </ion-buttons>
      <ion-title>Praise Sent</ion-title>
      <ion-buttons slot="end">
        <ion-button (click)="onSearchButtonClick()" appHaptics>
          <ion-icon slot="icon-only" name="search-outline"></ion-icon>
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
    <ion-toolbar class="app-toolbar">
      <div class="full-name">{{ fullName }}</div>
      <ion-buttons *ngIf="range">
        <ion-button
          color="primary"
          class="toolbar-range-button"
          (click)="onRangeClick()"
          appHaptics
        >
          <ng-container *ngIf="isOneDayRange; else rangeTwoDays">
            {{ range.start | date : 'MMM d, y' }}
          </ng-container>
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ng-container>
  <app-width-restricted-container [attr.data-hidden]="!showSearchBar">
    <ion-toolbar class="app-toolbar ion-no-padding">
      <ion-searchbar
        #ionSearchbar
        class="searchbar"
        showCancelButton="always"
        showClearButton="always"
        (ionCancel)="onSearchBarCancel()"
        [debounce]="700"
        (ionInput)="handleSearchInput($event)"
      />
    </ion-toolbar>
  </app-width-restricted-container>
</ion-header>

<ion-content color="light">
  <app-width-restricted-container class="container-with-fab">
    <ion-grid *ngIf="isLoading">
      <ion-row class="ion-justify-content-center">
        <ion-spinner name="lines-sharp-small"></ion-spinner>
      </ion-row>
    </ion-grid>

    <app-recognition-item
      *ngFor="let item of praiseSent; trackBy: trackItems"
      [item]="item"
    ></app-recognition-item>

    <ion-infinite-scroll
      (ionInfinite)="onIonInfinite()"
      #infiniteScroll
      *ngIf="!(praiseSent.length === 0 || isLoading || !hasNextPage)"
      class="ion-padding-top"
    >
      <ion-infinite-scroll-content></ion-infinite-scroll-content>
    </ion-infinite-scroll>

    <ng-container *ngIf="praiseSent.length === 0 && !isLoading">
      <ng-container *ngTemplateOutlet="emptyList"></ng-container>
    </ng-container>
  </app-width-restricted-container>
</ion-content>

<ng-template #emptyList>
  <app-empty-stub
    [icon]="searchTerm ? 'search-outline' : null"
    [text]="searchTerm ? 'Nothing Found' : 'List Is Empty'"
  ></app-empty-stub>
</ng-template>

<ng-template #rangeTwoDays>
  {{ range.start | date : 'MMM d, y' }} -
  {{ range.end | date : 'MMM d, y' }}
</ng-template>
