<ion-header class="ion-no-border">
  <ion-toolbar class="app-toolbar">
    <ion-title>Values</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="close()" appHaptics>
        <ion-icon slot="icon-only" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content *ngIf="_isMobile; else content" color="light">
  <ng-container *ngTemplateOutlet="content" />
</ion-content>

<ng-template #content>
  <app-full-height-container [isModal]="true">
    <div class="ion-padding">
      <div class="ion-padding-vertical">
        <app-values-accordion />
      </div>
    </div>
  </app-full-height-container>
</ng-template>
