import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { MongoStoredObject } from '../../../types/mongo-stored-object';
import { GlobalSettings } from '@app/types/global-settings';
import { TSpecRoute_getGlobalSettings } from '@backend/types/generated/GlobalSettings/getGlobalSettings';
import {
  TSpecBodyUpdateGlobalSettings,
  TSpecRoute_updateGlobalSettings
} from '@backend/types/generated/GlobalSettings/updateGlobalSettings';

@Injectable({ providedIn: 'root' })
export class GlobalSettingsApiService {
  public constructor(private _httpClient: HttpClient) {}

  public getGlobalSettings(): Observable<GlobalSettings> {
    return this._httpClient.get<MongoStoredObject<GlobalSettings>>(
      TSpecRoute_getGlobalSettings()
    );
  }

  public updateGlobalSettings(params: {
    body: TSpecBodyUpdateGlobalSettings;
  }): Observable<GlobalSettings> {
    return this._httpClient.put<MongoStoredObject<GlobalSettings>>(
      TSpecRoute_updateGlobalSettings(),
      params.body
    );
  }
}
