// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_getBackdoorInfo = () => "/backdoor/current-info";

export type TSpecHandler_getBackdoorInfo = RequestHandler<TSpecPathGetBackdoorInfo, any, TSpecBodyGetBackdoorInfo, TSpecQueryGetBackdoorInfo, {}>;

export interface TSpecPathGetBackdoorInfo {  }

export interface TSpecBodyGetBackdoorInfo {}

export interface TSpecQueryGetBackdoorInfo {
  version: string;
  platform?: string;
  manufacturer?: string;
  model?: string;
  osVersion?: string;
  operatingSystem?: string;
}
