import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BehaviorModel } from '@app/core/model/behavior.model';
import { IonNav } from '@ionic/angular';

@Component({
  selector: 'app-culture',
  templateUrl: './culture.component.html',
  styleUrls: ['./culture.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CultureComponent {
  protected valueBehaviors: BehaviorModel[] = [];

  public constructor(private readonly _ionNav: IonNav) {}

  protected onBackButtonClick() {
    this._ionNav.pop();
  }
}
