import {
  Component,
  Input,
  Output,
  EventEmitter,
  HostBinding,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { AnnouncementModel } from '@app/core/model/announcement.model';
import { formatDate } from '@angular/common';
import { ToastService } from '@app/core/service/toast.service';
import { LoadingController, ModalController } from '@ionic/angular';
import { AnnouncementListService } from '@app/core/service/announcement-list.service';
import { formatUploadedBody } from '@app/utils/wysiwyg-editor';
import { EnvironmentService } from '@app/core/service/environment.service';
import {
  EAnnouncementAcknowledgeButtonText,
  EAnnouncementActions,
  EAnnouncementRespondButtonText
} from '@backend/models/types/announcement';
import { AnnouncementRespondModalComponent } from '@app/modules/announcement-respond-modal/announcement-respond-modal.component';

@Component({
  selector: 'app-announcement-preview',
  templateUrl: './announcement-preview.component.html',
  styleUrls: ['./announcement-preview.component.scss']
})
export class AnnouncementPreviewComponent implements OnChanges {
  @Input()
  public readonly announcement: AnnouncementModel = null;

  @Input()
  public readonly announcementIndex: number = -1;

  @Input()
  public readonly show: boolean = false;

  @Output()
  public cancelClicked = new EventEmitter<void>();

  @Output()
  public announcementAcknowledged = new EventEmitter<{
    index: number;
    id: string;
  }>();

  @Output()
  public announcementResponded = new EventEmitter<{
    index: number;
    id: string;
  }>();

  @HostBinding('class.show')
  get showClass() {
    return this.show;
  }

  protected readonly announcementActions = EAnnouncementActions;
  protected readonly announcementAcknowledgeButtonText =
    EAnnouncementAcknowledgeButtonText;
  protected readonly announcementRespondButtonText =
    EAnnouncementRespondButtonText;

  protected serverUrl = '';
  protected body = '';
  protected thread: AnnouncementModel[] = [];

  public constructor(
    private readonly _toastService: ToastService,
    private readonly _loadingCtrl: LoadingController,
    private readonly _announcementListService: AnnouncementListService,
    private readonly _environmentService: EnvironmentService,
    private readonly _modalCtrl: ModalController
  ) {
    this.serverUrl = this._environmentService.serverUrl;
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (
      changes &&
      changes.show &&
      changes.show.currentValue &&
      changes.announcementIndex.currentValue !== -1 &&
      changes.announcement.currentValue &&
      changes.announcement.currentValue._id
    ) {
      this.body = formatUploadedBody(
        changes.announcement.currentValue.body,
        changes.announcement.currentValue.attachments,
        this.serverUrl
      );

      this._announcementListService
        .readAnnouncement(changes.announcement.currentValue._id)
        .subscribe();

      this._announcementListService
        .getThreadOfAnnouncement(changes.announcement.currentValue._id)
        .subscribe((thread) => {
          this.thread = thread;
        });
    }
  }

  protected get editedString() {
    return this.announcement && this.announcement.edited
      ? `Last edit: ${this.fDateAndTime(this.announcement.edited)}`
      : '';
  }

  protected onCancelClicked() {
    this.cancelClicked.emit();
  }

  protected fDateAndTime(date: Date) {
    return formatDate(date, 'MMM dd, yyyy h:mm a', 'en-US');
  }

  protected async acknowledge() {
    const loading = await this._loadingCtrl.create({
      message: 'Loading...'
    });
    loading.present();

    this._announcementListService
      .acknowledgeAnnouncement(this.announcement._id)
      .subscribe(() => {
        loading.dismiss();
        this._toastService.presentToast(
          'Announcement acknowledged successfully!'
        );
        this.announcementAcknowledged.emit({
          index: this.announcementIndex,
          id: this.announcement._id
        });
        this.onCancelClicked();
      });
  }

  protected async respond() {
    const modal = await this._modalCtrl.create({
      id: 'announcement-respond-modal',
      component: AnnouncementRespondModalComponent,
      handle: false,
      componentProps: {
        announcement: this.announcement
      },
      breakpoints: [0, 1],
      initialBreakpoint: 1
    });
    modal.present();

    const { role } = await modal.onWillDismiss();

    if (role === 'confirm') {
      this.announcementResponded.emit({
        index: this.announcementIndex,
        id: this.announcement._id
      });
      this.onCancelClicked();
    }
  }

  protected get respondButtonText() {
    switch (this.announcement.respondButtonText) {
      case EAnnouncementRespondButtonText.RESPOND:
        return 'Respond';
      case EAnnouncementRespondButtonText.REPORT_ISSUE:
        return 'Report Issue';
      default:
        return 'Share Feedback';
    }
  }
}
