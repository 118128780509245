import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MongoStoredObject } from '@app/types/mongo-stored-object';
import { IBackdoorInfo } from '@backend/models/types/backdoor-info';
import { Observable, from, switchMap } from 'rxjs';
import { EnvironmentService } from '../environment.service';
import { Device } from '@capacitor/device';
import { TSpecRoute_getBackdoorInfo } from '@backend/types/generated/Backdoor/getBackdoorInfo';
import { TSpecRoute_getAllBackdoorMessages } from '@backend/types/generated/Backdoor/getAllBackdoorMessages';
import {
  TSpecBodyAddBackdoorInfo,
  TSpecRoute_addBackdoorInfo
} from '@backend/types/generated/Backdoor/addBackdoorInfo';
import {
  TSpecBodyUpdateBackdoorInfo,
  TSpecPathUpdateBackdoorInfo,
  TSpecRoute_updateBackdoorInfo
} from '@backend/types/generated/Backdoor/updateBackdoorInfo';
import {
  TSpecPathDeleteBackdoorInfo,
  TSpecRoute_deleteBackdoorInfo
} from '@backend/types/generated/Backdoor/deleteBackdoorInfo';

@Injectable()
export class BackdoorApiService {
  private version = '';

  public constructor(
    private readonly _httpClient: HttpClient,
    private readonly _environmentService: EnvironmentService
  ) {
    this.version = this._environmentService.version;
  }

  public getBackdoorInfo(): Observable<MongoStoredObject<IBackdoorInfo> | null> {
    return from(Device.getInfo()).pipe(
      switchMap((deviceInfo) =>
        this._httpClient.get<MongoStoredObject<IBackdoorInfo> | null>(
          TSpecRoute_getBackdoorInfo(),
          {
            params: {
              version: this.version,
              platform: deviceInfo.platform,
              manufacturer: deviceInfo.manufacturer,
              model: deviceInfo.model,
              osVersion: deviceInfo.osVersion,
              operatingSystem: deviceInfo.operatingSystem
            }
          }
        )
      )
    );
  }

  public getAllBackdoorMessages(): Observable<
    MongoStoredObject<IBackdoorInfo>[]
  > {
    return this._httpClient.get<MongoStoredObject<IBackdoorInfo>[]>(
      TSpecRoute_getAllBackdoorMessages()
    );
  }

  public addBackdoorInfo(params: {
    body: TSpecBodyAddBackdoorInfo;
  }): Observable<MongoStoredObject<IBackdoorInfo>> {
    return this._httpClient.post<MongoStoredObject<IBackdoorInfo>>(
      TSpecRoute_addBackdoorInfo(),
      params.body
    );
  }

  public updateBackdoorInfo(params: {
    path: TSpecPathUpdateBackdoorInfo;
    body: TSpecBodyUpdateBackdoorInfo;
  }): Observable<MongoStoredObject<IBackdoorInfo>> {
    return this._httpClient.put<MongoStoredObject<IBackdoorInfo>>(
      TSpecRoute_updateBackdoorInfo(params.path),
      params.body
    );
  }

  public deleteBackdoorInfo(params: { path: TSpecPathDeleteBackdoorInfo }) {
    return this._httpClient.delete(TSpecRoute_deleteBackdoorInfo(params.path));
  }
}
