<ion-header class="ion-no-border">
  <ion-toolbar class="app-common-background" *ngIf="cancelButton === 'menu'">
    <ion-buttons
      slot="start"
      class="ion-hide-lg-up"
      *ngIf="question.orderIndex === 0; else goBackBtn"
    >
      <app-slim-menu-button></app-slim-menu-button>
    </ion-buttons>
  </ion-toolbar>

  <ion-toolbar
    class="app-common-background survey-preview-toolbar"
    *ngIf="cancelButton === 'cancel'"
  >
    <ion-buttons slot="start" *ngIf="question.orderIndex === 0; else goBackBtn">
      <ion-button (click)="onCancelClick()" color="primary" appHaptics>
        Cancel
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<app-width-restricted-container class="question-holder">
  <div class="top-content ion-padding-top">
    <ion-text>
      <h2
        class="ion-text-center heading"
        [innerHTML]="question.params.questionTitle"
      ></h2>
    </ion-text>
    <ion-grid class="ion-padding">
      <ion-row
        class="ion-justify-content-center"
        *ngFor="let option of question.params.options"
      >
        <ion-col size-xs="12" size-sm="6">
          <app-emoji-button
            [label]="option.text"
            [emoji]="option.emoji"
            appHaptics
            (click)="selectMood(option.value)"
          />
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
</app-width-restricted-container>

<ng-template #goBackBtn>
  <ion-buttons slot="start">
    <ion-button (click)="onBackButtonClick()" appHaptics>
      <ion-icon slot="icon-only" name="chevron-back-outline"></ion-icon>
    </ion-button>
  </ion-buttons>
</ng-template>
