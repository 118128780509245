import {
  FormArray,
  FormControl,
  FormGroup,
  Validators,
  ɵFormGroupValue
} from '@angular/forms';
import { TaskScheduleType } from '@backend/models/types/task';
import { TaskScheduleForm } from '../types/task-schedule-form';
import { minArrayLength } from './min-array-length-validator';

type TaskScheduleFormType = Omit<TaskScheduleForm, 'scheduleType'>;

export function updateScheduleFormGroup(
  form: FormGroup<TaskScheduleForm>,
  scheduleType: TaskScheduleType,
  data: Omit<ɵFormGroupValue<TaskScheduleFormType>, 'scheduleType'> = {}
): void {
  for (let key in form.controls) {
    if (key !== 'scheduleType') {
      form.removeControl(key as keyof TaskScheduleForm);
    }
  }
  switch (scheduleType) {
    case TaskScheduleType.DAILY:
      break;
    case TaskScheduleType.NEVER:
      form.addControl('date', new FormControl<string>(null));
      break;
    case TaskScheduleType.SPECIFIC_DATES:
      form.addControl(
        'dates',
        new FormArray<FormControl<string>>([], minArrayLength(1))
      );
      if (data.dates) {
        for (const date of data.dates) {
          form.controls.dates.push(new FormControl(date, Validators.required));
        }
      }
      form.addControl(
        'repeatEveryYear',
        new FormControl<boolean>(true, {
          nonNullable: true
        })
      );
      form.addControl(
        'adjustWeekdays',
        new FormControl<boolean>(false, {
          nonNullable: true
        })
      );
      break;
    case TaskScheduleType.WEEKLY:
      form.addControl(
        'weekDays',
        new FormArray<FormControl<boolean>>(
          [
            ...new Array(7)
              .fill(null)
              .map(() => new FormControl<boolean>(false, { nonNullable: true }))
          ],
          {
            validators: [minArrayLength(1)]
          }
        )
      );
      break;
    case TaskScheduleType.MONTHLY:
      form.addControl(
        'daysOfMonth',
        new FormArray<FormControl<string>>([], {
          validators: [minArrayLength(1)]
        })
      );
      if (data.daysOfMonth) {
        for (const dayOfMonth of data.daysOfMonth) {
          form.controls.daysOfMonth.push(
            new FormControl(dayOfMonth, Validators.required)
          );
        }
      }
      break;
  }
  form.reset({ ...data, scheduleType });
}
