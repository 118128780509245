<ion-content color="light">
  <app-full-height-container maxWidth="modal">
    <div class="container">
      <app-content-title
        subtitle="This page could not be found"
        titleSize="extra-large"
      >
        404
      </app-content-title>
    </div>
    <div bottom class="modal-actions">
      <ion-button
        color="dark"
        expand="block"
        (click)="backToHome()"
        appHaptics
        class="ion-no-margin"
      >
        Back to Home
      </ion-button>
    </div>
  </app-full-height-container>
</ion-content>
