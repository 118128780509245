import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FullHeightContainerComponent } from './full-height-container.component';
import { WidthRestrictedContainerModule } from '../width-restricted-container/width-restricted-container.module';

@NgModule({
  declarations: [FullHeightContainerComponent],
  imports: [CommonModule, WidthRestrictedContainerModule],
  exports: [FullHeightContainerComponent]
})
export class FullHeightContainerModule {}
