import { Injectable } from '@angular/core';
import {
  EditRecognitionModalComponent,
  IEditRecognitionModalProps,
  IEditRecognitionModalResult
} from '@app/modules/edit-recognition-modal/edit-recognition-modal.component';
import { isMobile } from '@app/utils/is-mobile';
import { ActionSheetController, ModalController } from '@ionic/angular';

@Injectable({ providedIn: 'root' })
export class RecognitionModalService {
  public constructor(
    private readonly _modalCtrl: ModalController,
    private readonly _actionSheetCtrl: ActionSheetController
  ) {}

  public async showEditRecognitionModal(
    props: IEditRecognitionModalProps,
    presentingElementSelector?: string
  ) {
    const editRecognitionModal = await this._modalCtrl.create({
      id: 'edit-recognition-modal',
      cssClass: isMobile() ? undefined : 'modal-auto-height',
      breakpoints: isMobile() ? undefined : [0, 1],
      initialBreakpoint: isMobile() ? undefined : 1,
      component: EditRecognitionModalComponent,
      componentProps: props,
      handle: false,
      presentingElement:
        presentingElementSelector && isMobile()
          ? document.querySelector(presentingElementSelector)
          : undefined,
      canDismiss: async (_, r) => {
        if (r === 'cancel' || r === 'gesture' || r === 'backdrop') {
          const actionSheet = await this._actionSheetCtrl.create({
            header: 'Unsaved content',
            subHeader:
              'Are you sure you want to discard your work and close the editor?',
            buttons: [
              {
                role: 'destructive',
                text: 'Discard and close'
              },
              {
                role: 'cancel',
                text: 'Continue editing'
              }
            ]
          });

          actionSheet.present();

          const { role } = await actionSheet.onWillDismiss();

          return role === 'destructive';
        }

        return true;
      }
    });
    editRecognitionModal.present();

    const { data, role } = await editRecognitionModal.onWillDismiss();

    const res: IEditRecognitionModalResult = data;

    return { role, ...res };
  }
}
