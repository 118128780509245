// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_getUnreadLedgers = () => "/ledger/unread";

export type TSpecHandler_getUnreadLedgers = RequestHandler<TSpecPathGetUnreadLedgers, any, TSpecBodyGetUnreadLedgers, TSpecQueryGetUnreadLedgers, {}>;

export interface TSpecPathGetUnreadLedgers {  }

export interface TSpecBodyGetUnreadLedgers {}

export interface TSpecQueryGetUnreadLedgers {}
