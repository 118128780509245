import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { FormErrorWrapperModule } from '../../modules/form-error-wrapper/form-error-wrapper.module';
import { ResetPasswordComponent } from './reset-password.component';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { LoginPageWrapperModule } from '@app/modules/login-page-wrapper/login-page-wrapper.module';
import { FormErrorPipe } from '@app/modules/form-error-pipe/form-error.pipe';
import { DirectivesModule } from '@app/modules/directives.module';
import { NavBaseComponent } from '@app/modules/nav-base/nav-base.component';

const ROUTES: Routes = [
  {
    path: '',
    component: NavBaseComponent,
    data: {
      title: 'Reset Password',
      rootComponent: ResetPasswordComponent
    }
  }
];

@NgModule({
  imports: [
    NavBaseComponent,
    RouterModule.forChild(ROUTES),
    CommonModule,
    ReactiveFormsModule,
    FormErrorWrapperModule,
    IonicModule,
    LoginPageWrapperModule,
    FormErrorPipe,
    DirectivesModule
  ],
  exports: [],
  declarations: [ResetPasswordComponent]
})
export class ResetPasswordModule {}
