import { Component, ViewChild } from '@angular/core';
import { PsychologicalSurveyApiService } from '@app/core/service/api/psychologicalSurvey.api.service';
import { MongoStoredObject } from '@app/types/mongo-stored-object';
import { IPsychologicalSurvey } from '@backend/models/types/psychological-survey';
import { AuthenticationService } from '@app/core';
import { UserListService } from '@app/core/service/user-list.service';
import { UserModel } from '@app/core/model/user.model';
import { ObjectId } from '@app/types/object-id';
import { IonModal } from '@ionic/angular';
import { EPsychologicalFeedbackView } from '@backend/models/types/psychological-survey';
import {
  EPsychologicalSurveyShowFeedbackBy,
  IFeedbackStatsItem,
  IFeedbackStatsResult
} from '@backend/types/psychological-survey-feedback-stats';
import { GlobalSettingsService } from '@app/core/service/global-settings.service';
import { UserRoles } from '@backend/models/types/user';

enum EFeedbackType {
  TEAM = 'team',
  PERSONAL = 'personal'
}

@Component({
  selector: 'app-psychological-survey-feedback',
  templateUrl: './psychological-survey-feedback.component.html',
  styleUrls: ['./psychological-survey-feedback.component.scss']
})
export class PsychologicalSurveyFeedbackComponent {
  @ViewChild('userSelectorModal', { static: true })
  public userSelectorModal!: IonModal;

  protected readonly feedbackType = EFeedbackType;
  protected readonly feedbackView = EPsychologicalFeedbackView;

  protected showFeedbackBy: EPsychologicalSurveyShowFeedbackBy =
    EPsychologicalSurveyShowFeedbackBy.feedbackByBehaviors;
  protected currentTab = EFeedbackType.TEAM;
  protected isLoading = true;
  protected survey: MongoStoredObject<IPsychologicalSurvey> = null;
  protected surveyStats: IFeedbackStatsResult = {
    personal: {
      feedbackByBehaviors: [],
      moodCheck: { avgValue: 0 },
      feedbackByEvaluationOptions: []
    },
    team: {
      feedbackByBehaviors: [],
      moodCheck: { avgValue: 0 },
      feedbackByEvaluationOptions: []
    }
  };
  protected allUsers: UserModel[] = [];
  protected selectedUserIds: ObjectId[] = [];
  protected isAdmin = false;
  protected selectedView: EPsychologicalFeedbackView =
    EPsychologicalFeedbackView.SINGLE_BAR_VIEW;
  protected showFeedbackSwitch = false;

  public constructor(
    private readonly _psychologicalSurveyApiService: PsychologicalSurveyApiService,
    private readonly _authenticationService: AuthenticationService,
    private readonly _userListService: UserListService,
    private readonly _globalSettingsService: GlobalSettingsService
  ) {
    this.selectedUserIds = [this._authenticationService.user._id];
    this.isAdmin =
      this._authenticationService.user?.roles.includes(UserRoles.Admin) ??
      false;
    this._globalSettingsService.globalSettings$.subscribe((globalSettings) => {
      this.showFeedbackBy = globalSettings.psychologicalSurvey.showFeedbackBy;
    });
    this._psychologicalSurveyApiService
      .getCurrentPsychologicalSurvey()
      .subscribe((survey) => {
        this.showFeedbackSwitch =
          this.isAdmin && !survey.survey.forceFeedbackView;
        if (survey.survey.forceFeedbackView) {
          this.selectedView = survey.survey.forceFeedbackView;
        }
      });

    this._userListService.users$.subscribe((users) => {
      this.allUsers = users;
    });

    this._fetchFeedbackStats();
  }

  private _fetchFeedbackStats() {
    this.isLoading = true;

    this._psychologicalSurveyApiService
      .getFeedbackStats({
        query: {
          userId:
            this.selectedUserIds[0] === this._authenticationService.user._id
              ? undefined
              : this.selectedUserIds[0]
        }
      })
      .subscribe((surveyStats) => {
        this.surveyStats = surveyStats;
        this.isLoading = false;
      });
  }

  protected get formattedUser(): string {
    const user = this.allUsers.find(
      (user) => user._id === this.selectedUserIds[0]
    );
    return user ? `${user.firstName} ${user.lastName}` : '';
  }

  // getters for double bar view
  protected get maxValue() {
    return this.surveyStats[this.currentTab][this.showFeedbackBy].reduce(
      (prev, behavior) => {
        const max = Math.max(behavior.positiveCount, behavior.negativeCount);
        return max > prev ? max : prev;
      },
      0
    );
  }

  // getters for single bar view
  protected get minFinalValue() {
    return Math.min(
      ...this.surveyStats[this.currentTab][this.showFeedbackBy].map(
        (b) => b.positiveCount - b.negativeCount
      )
    );
  }
  protected get maxFinalValue() {
    return Math.max(
      ...this.surveyStats[this.currentTab][this.showFeedbackBy].map(
        (b) => b.positiveCount - b.negativeCount
      )
    );
  }

  protected trackFeedbacks(_: number, item: IFeedbackStatsItem) {
    return item.behaviorId;
  }

  protected onCurrentTabChange(event: any) {
    this.currentTab = event.detail.value;
  }

  protected onUserChange(event: ObjectId[]): void {
    this.selectedUserIds = event;
    this.userSelectorModal.dismiss();
    this._fetchFeedbackStats();
  }

  protected handleViewChange(event: any): void {
    this.selectedView = event.detail.value;
  }
}
