// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_addBackdoorInfo = () => "/backdoor/info";

export type TSpecHandler_addBackdoorInfo = RequestHandler<TSpecPathAddBackdoorInfo, any, TSpecBodyAddBackdoorInfo, TSpecQueryAddBackdoorInfo, {}>;

export interface TSpecPathAddBackdoorInfo {  }

export interface TSpecBodyAddBackdoorInfo {
  versionRange: string;
  title: string;
  message: string;
  links: {
    text?: string;
    url?: string;
  }[];
  canDismiss: boolean;
  tenantsToShow: string[];
  showToAllTenants: boolean;
  platformsToShow: EBackdoorInfoPlatform[];
}

export enum EBackdoorInfoPlatform {
  web = "web",
  ios = "ios",
  android = "android"
}

export interface TSpecQueryAddBackdoorInfo {}
