import { Component } from '@angular/core';
import { BackdoorApiService } from '@app/core/service/api/backdoor.api.service';
import { MongoStoredObject } from '@app/types/mongo-stored-object';
import { IBackdoorInfo } from '@backend/models/types/backdoor-info';
import { BackdoorInfoAddComponent } from './components/backdoor-info-add/backdoor-info-add.component';
import {
  IonNav,
  LoadingController,
  ViewDidEnter,
  ViewWillEnter
} from '@ionic/angular';
import { TenantListService } from '@app/core/service/tenant-list.service';
import { Observable } from 'rxjs';
import { ITenant } from '@backend/models/types/tenant';
import { ToastService } from '@app/core/service/toast.service';

@Component({
  selector: 'app-backdoor',
  templateUrl: './backdoor.component.html',
  styleUrls: ['./backdoor.component.scss']
})
export class BackdoorComponent implements ViewWillEnter, ViewDidEnter {
  protected messages: MongoStoredObject<IBackdoorInfo>[] = [];
  protected isLoading = false;
  protected tenantList$: Observable<MongoStoredObject<ITenant>[]> =
    this._tenantService.tenants$;
  protected tenants: MongoStoredObject<ITenant>[] = [];

  public constructor(
    private readonly _backdoorApiService: BackdoorApiService,
    private readonly _ionNav: IonNav,
    private readonly _tenantService: TenantListService,
    private readonly _loadingCtrl: LoadingController,
    private readonly _toastService: ToastService
  ) {}

  public async ionViewDidEnter() {
    const topLoading = await this._loadingCtrl.getTop();
    if (topLoading) {
      topLoading.dismiss();
    }
  }

  public ionViewWillEnter() {
    this.isLoading = true;
    this.tenantList$.subscribe((tenants) => {
      this.tenants = tenants;

      this._backdoorApiService
        .getAllBackdoorMessages()
        .subscribe((messages) => {
          this.messages = messages;
          this.isLoading = false;
        });
    });
  }

  protected onFabClick() {
    this._ionNav.push(BackdoorInfoAddComponent);
  }

  protected async updateBackdoorInfo(
    message: MongoStoredObject<IBackdoorInfo>
  ) {
    this._ionNav.push(BackdoorInfoAddComponent, { backdoorInfo: message });
  }

  protected async deleteBackdoorInfo(
    message: MongoStoredObject<IBackdoorInfo>
  ) {
    const loading = await this._loadingCtrl.create({
      message: 'Deleting...'
    });
    loading.present();

    this._backdoorApiService
      .deleteBackdoorInfo({ path: { id: message._id.toString() } })
      .subscribe(() => {
        this.messages = this.messages.filter((m) => m._id !== message._id);
        loading.dismiss();
        this._toastService.presentToast('Backdoor Message Deleted');
      });
  }
}
