import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LibraryDocModel } from '../../model/library-doc.model';
import {
  TSpecQueryGetAllLibraryDocs,
  TSpecRoute_getAllLibraryDocs
} from '@backend/types/generated/Library/getAllLibraryDocs';
import {
  TSpecPathGetLibraryDoc,
  TSpecRoute_getLibraryDoc
} from '@backend/types/generated/Library/getLibraryDoc';
import {
  TSpecBodyAddLibraryDoc,
  TSpecRoute_addLibraryDoc
} from '@backend/types/generated/Library/addLibraryDoc';
import {
  TSpecBodyUpdateLibraryDoc,
  TSpecPathUpdateLibraryDoc,
  TSpecRoute_updateLibraryDoc
} from '@backend/types/generated/Library/updateLibraryDoc';
import {
  TSpecBodySaveAsNewVersion,
  TSpecPathSaveAsNewVersion,
  TSpecRoute_saveAsNewVersion
} from '@backend/types/generated/Library/saveAsNewVersion';
import {
  TSpecPathSoftDeleteLibraryDoc,
  TSpecRoute_softDeleteLibraryDoc
} from '@backend/types/generated/Library/softDeleteLibraryDoc';
import {
  TSpecPathSignLibraryDoc,
  TSpecRoute_signLibraryDoc
} from '@backend/types/generated/Library/signLibraryDoc';
import {
  TSpecBodyRequestUsersToSignLibraryDoc,
  TSpecPathRequestUsersToSignLibraryDoc,
  TSpecRoute_requestUsersToSignLibraryDoc
} from '@backend/types/generated/Library/requestUsersToSignLibraryDoc';

@Injectable()
export class LibraryApiService {
  public constructor(private readonly _httpClient: HttpClient) {}

  public getAllLibraryDocs(params: {
    query: TSpecQueryGetAllLibraryDocs;
  }): Observable<
    {
      originalDoc: LibraryDocModel;
      versions: LibraryDocModel[];
      latestVersion: LibraryDocModel | null;
    }[]
  > {
    return this._httpClient.get<
      {
        originalDoc: LibraryDocModel;
        versions: LibraryDocModel[];
        latestVersion: LibraryDocModel | null;
      }[]
    >(TSpecRoute_getAllLibraryDocs(), { params: { ...params.query } });
  }

  public getLibraryDoc(params: {
    path: TSpecPathGetLibraryDoc;
  }): Observable<LibraryDocModel> {
    return this._httpClient.get<LibraryDocModel>(
      TSpecRoute_getLibraryDoc(params.path)
    );
  }

  public addLibraryDoc(params: {
    body: TSpecBodyAddLibraryDoc;
  }): Observable<LibraryDocModel> {
    return this._httpClient.post<LibraryDocModel>(
      TSpecRoute_addLibraryDoc(),
      params.body
    );
  }

  public updateLibraryDoc(params: {
    path: TSpecPathUpdateLibraryDoc;
    body: TSpecBodyUpdateLibraryDoc;
  }): Observable<LibraryDocModel> {
    return this._httpClient.put<LibraryDocModel>(
      TSpecRoute_updateLibraryDoc(params.path),
      params.body
    );
  }

  public saveAsNewVersion(params: {
    path: TSpecPathSaveAsNewVersion;
    body: TSpecBodySaveAsNewVersion;
  }): Observable<LibraryDocModel> {
    return this._httpClient.post<LibraryDocModel>(
      TSpecRoute_saveAsNewVersion(params.path),
      params.body
    );
  }

  public softDeleteLibraryDoc(params: { path: TSpecPathSoftDeleteLibraryDoc }) {
    return this._httpClient.delete(
      TSpecRoute_softDeleteLibraryDoc(params.path)
    );
  }

  public signLibraryDoc(params: {
    path: TSpecPathSignLibraryDoc;
  }): Observable<LibraryDocModel> {
    return this._httpClient.post<LibraryDocModel>(
      TSpecRoute_signLibraryDoc(params.path),
      {}
    );
  }

  public requestUsersToSignLibraryDoc(params: {
    path: TSpecPathRequestUsersToSignLibraryDoc;
    body: TSpecBodyRequestUsersToSignLibraryDoc;
  }) {
    return this._httpClient.post(
      TSpecRoute_requestUsersToSignLibraryDoc(params.path),
      params.body
    );
  }
}
