// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_addDepartment = () => "/departments";

export type TSpecHandler_addDepartment = RequestHandler<TSpecPathAddDepartment, any, TSpecBodyAddDepartment, TSpecQueryAddDepartment, {}>;

export interface TSpecPathAddDepartment {  }

export interface TSpecBodyAddDepartment {
  name: string;
}

export interface TSpecQueryAddDepartment {}
