<ion-header class="ion-no-border">
  <ion-toolbar class="app-toolbar">
    <ion-buttons slot="start" class="ion-hide-lg-up">
      <app-slim-menu-button></app-slim-menu-button>
    </ion-buttons>
    <ion-title> Library </ion-title>
  </ion-toolbar>
</ion-header>

<app-pull-to-search
  #pullToSearch
  (searchInput)="handleSearchInput($event)"
  [searchDebounce]="700"
>
  <app-width-restricted-container class="container-with-fab">
    <div *ngIf="!searchTerm" class="independent-item">
      <ion-item
        lines="none"
        button
        (click)="openCulturePage()"
        appHaptics
        [detail]="false"
      >
        <ion-icon class="doc-icon" name="flask-outline"></ion-icon>
        <ion-label>Values</ion-label>
      </ion-item>
    </div>

    <ion-grid *ngIf="isLoading">
      <ion-row class="ion-justify-content-center">
        <ion-spinner name="lines-sharp-small"></ion-spinner>
      </ion-row>
    </ion-grid>

    <div *ngIf="!isLoading && libraryDocs.length > 0">
      <app-library-item
        *ngFor="let doc of libraryDocs; let i = index; trackBy: trackDocs"
        [doc]="doc"
        [isSelected]="i === selectedLibraryDoc"
        [animationOrder]="page === 1 ? i : -1"
        (selected)="expandLibraryDoc(i)"
      />
    </div>

    <ion-infinite-scroll
      (ionInfinite)="onIonInfinite()"
      #infiniteScroll
      *ngIf="!(libraryDocs.length === 0 || isLoading || !hasNextPage)"
      class="ion-padding-top"
    >
      <ion-infinite-scroll-content></ion-infinite-scroll-content>
    </ion-infinite-scroll>

    <ng-container *ngIf="libraryDocs.length === 0 && !isLoading">
      <ng-container *ngTemplateOutlet="emptyList"></ng-container>
    </ng-container>
  </app-width-restricted-container>

  <ion-fab
    slot="fixed"
    vertical="bottom"
    horizontal="end"
    *ngIf="isAdmin"
    class="main-fab"
  >
    <ion-fab-button color="dark" (click)="onAddClick()" appHaptics>
      <ion-icon name="add"></ion-icon>
    </ion-fab-button>
  </ion-fab>
</app-pull-to-search>

<ng-template #emptyList>
  <app-empty-stub
    [icon]="searchTerm ? 'search-outline' : null"
    [text]="searchTerm ? 'Nothing Found' : 'List Is Empty'"
  ></app-empty-stub>
</ng-template>
