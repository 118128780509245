import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { FormErrorWrapperModule } from '../../modules/form-error-wrapper/form-error-wrapper.module';
import { SuggestionFormComponent } from './suggestion-form.component';
import { IonicModule } from '@ionic/angular';
import { FormErrorPipe } from '@app/modules/form-error-pipe/form-error.pipe';
import { DirectivesModule } from '@app/modules/directives.module';
import { WysiwygEditorComponent } from '@app/modules/wysiwyg-editor/wysiwyg-editor.component';
import { SuggestionApiService } from '@app/core/service/api/suggestion.api.service';
import { UploadFileApiService } from '@app/core/service/api/uploadFile.api.service';
import { FilterUsersPipe } from './filter-users.pipe';
import { SuggestionCategoryApiService } from '@app/core/service/api/suggestionCategory.api.service';
import { ContentTitleModule } from '@app/modules/content-title/content-title.module';
import { UserSelectorComponent } from '@app/modules/user-selector/user-selector.component';
import { FullHeightContainerModule } from '@app/modules/full-height-container/full-height-container.module';
import { NavBaseComponent } from '@app/modules/nav-base/nav-base.component';

const ROUTES: Routes = [
  {
    path: '',
    component: NavBaseComponent,
    data: {
      title: 'Share Feedback',
      rootComponent: SuggestionFormComponent
    }
  }
];

@NgModule({
  imports: [
    NavBaseComponent,
    RouterModule.forChild(ROUTES),
    CommonModule,
    ReactiveFormsModule,
    FormErrorWrapperModule,
    IonicModule,
    FormErrorPipe,
    DirectivesModule,
    WysiwygEditorComponent,
    FilterUsersPipe,
    FormsModule,
    ContentTitleModule,
    UserSelectorComponent,
    FullHeightContainerModule
  ],
  declarations: [SuggestionFormComponent],
  providers: [
    SuggestionApiService,
    SuggestionCategoryApiService,
    UploadFileApiService
  ]
})
export class SuggestionFormModule {}
