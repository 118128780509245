import { Component, ViewChild } from '@angular/core';
import { UserModel } from '@app/core/model/user.model';
import { TenantService } from '@app/core/service/tenant.service';
import { UserListService } from '@app/core/service/user-list.service';
import { ObjectId } from '@app/types/object-id';
import { isSameSet } from '@app/utils/is-same-set';
import { DailyReportDefinition } from '@backend/types/daily-report-definition';
import { TSpecBodyPatchTenant } from '@backend/types/generated/Tenants/patchTenant';
import { IonLoading, IonNav, ModalController } from '@ionic/angular';
import { map } from 'rxjs';
import { ReceiveDailyRepotsPipe } from '../../receive-daily-reports.pipe';
import { showUserSelectorModal } from '@app/utils/modal';

@Component({
  selector: 'app-daily-report-settings',
  templateUrl: './daily-report-settings.component.html',
  styleUrls: ['./daily-report-settings.component.scss']
})
export class DailyReportSettingsComponent {
  @ViewChild('loading', { static: true })
  public loading!: IonLoading;

  protected allUsers: UserModel[] = [];
  protected dailyReportSettings: DailyReportDefinition;

  public constructor(
    private readonly _ionNav: IonNav,
    private readonly _tenantService: TenantService,
    private readonly _userListService: UserListService,
    private readonly _modalCtrl: ModalController
  ) {
    this._userListService.users$
      .pipe(map((list) => list.filter(({ isDeleted }) => !isDeleted)))
      .subscribe((allUsers) => {
        this.allUsers = allUsers;
      });

    this._tenantService.tenant$.subscribe((tenant) => {
      if (this.loading) {
        this.loading.dismiss();
      }

      this.dailyReportSettings = tenant.settings.dailyReport;
    });
  }

  protected onBackButtonClick() {
    this._ionNav.pop();
  }

  private _patch(patch: TSpecBodyPatchTenant): void {
    this.loading.present();
    this._tenantService.patch(patch);
  }

  protected onDailyReportTimeChange(time: string) {
    if (event) {
      this._patch({
        settings: {
          dailyReport: {
            ...this.dailyReportSettings,
            dailyReportTime: time
          }
        }
      });
    }
  }

  protected onDailyReportEnabledChange(event: {
    detail: { checked: boolean };
  }) {
    this._patch({
      settings: {
        dailyReport: {
          ...this.dailyReportSettings,
          dailyReportEnabled: event.detail.checked
        }
      }
    });
  }

  private _patchReceiveDailyReports(userIds: ObjectId[], value: boolean) {
    this.loading.present();

    this._userListService
      .patchUsers({
        userId: userIds,
        receiveDailyReports: value
      })
      .subscribe(() => {
        this.loading.dismiss();
      });
  }

  protected async onSelectDailyReportUsersClick() {
    const recipients = new ReceiveDailyRepotsPipe().transform(this.allUsers);

    const { success, selectedUsers } = await showUserSelectorModal(
      {
        users: this.allUsers,
        recipients,
        multiple: true
      },
      this._modalCtrl
    );

    if (success) {
      const prevSelection = this.allUsers
        .filter(({ receiveDailyReports }) => receiveDailyReports)
        .map(({ _id }) => _id);
      const selectionSet = new Set(selectedUsers);
      if (isSameSet(selectionSet, prevSelection)) {
        return;
      }
      const prevSelectionSet = new Set(prevSelection);
      const newSelected = selectedUsers.filter((s) => !prevSelectionSet.has(s));
      const newRemoved = prevSelection.filter((s) => !selectionSet.has(s));
      if (newRemoved.length > 0) {
        this._patchReceiveDailyReports(newRemoved, false);
      }
      if (newSelected.length > 0) {
        this._patchReceiveDailyReports(newSelected, true);
      }
    }
  }
}
