// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_moveBehavior = (path: TSpecPathMoveBehavior) => {
  let res = "/tenants/:tenantId/behavior/move/:from/:to";
  Object.entries(path).map(([key, value]) => {
    res = res.replace(':' + key, value);
  });
  return res;
};

export type TSpecHandler_moveBehavior = RequestHandler<TSpecPathMoveBehavior, any, TSpecBodyMoveBehavior, TSpecQueryMoveBehavior, {}>;

export interface TSpecPathMoveBehavior { tenantId: string; from: string; to: string; }

export interface TSpecBodyMoveBehavior {}

export interface TSpecQueryMoveBehavior {}
