<ion-header class="ion-no-border">
  <ion-toolbar class="app-common-background">
    <ion-buttons slot="start">
      <ion-button (click)="cancelChanges()" color="primary" appHaptics>
        Cancel
      </ion-button>
    </ion-buttons>
    <ion-title>{{ title }}</ion-title>
    <ion-buttons slot="end" *ngIf="multiple">
      <ion-button
        (click)="confirmChanges()"
        color="primary"
        [disabled]="!canSave"
        appHaptics
        [disableHaptics]="!canSave"
      >
        {{ doneBtnText }}
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
  <ion-toolbar class="app-common-background">
    <ion-searchbar
      #ionSearchbar
      (ionInput)="searchbarInput($event)"
      (keyup.enter)="selectFirst()"
    />
  </ion-toolbar>
</ion-header>

<ion-content>
  <app-width-restricted-container>
    <ion-list>
      <ion-item
        button
        [detail]="false"
        *ngIf="multiple && !hideSelectAllButton"
      >
        <ion-checkbox
          [checked]="isAllChecked"
          [indeterminate]="isIndeterminate"
          [disabled]="filter.length > 0"
          (ionChange)="allUsersChange($event)"
          appHaptics
          [disableHaptics]="filter.length > 0"
        >
          <span class="user-name">All Users</span>
        </ion-checkbox>
      </ion-item>
      <ion-item
        button
        [detail]="!multiple"
        *ngFor="
          let recipient of selected | filterUsers : filter;
          trackBy: trackItems
        "
        (click)="checkboxClick($event, recipient.id)"
        appHaptics
        [attr.data-hidden]="!!hiddenUsers.length && isUserHidden(recipient.id)"
        [ngClass]="{ 'highlighted-item': getFirst()?.id === recipient.id }"
      >
        <ion-checkbox
          *ngIf="multiple; else label"
          [value]="recipient.id"
          [checked]="recipient.checked"
        >
          <span class="user-name">
            {{ recipient.name }}
          </span>
        </ion-checkbox>
        <ng-template #label>
          <ion-label class="user-name">
            {{ recipient.name }}
          </ion-label>
        </ng-template>
      </ion-item>
    </ion-list>
  </app-width-restricted-container>
</ion-content>
