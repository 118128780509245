import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { IonicModule, NavController } from '@ionic/angular';
import { AppRoutes } from './app.routing';
import { AppComponent } from './app.component';
import { CoreModule } from './core';
import { SharedModule } from './shared/shared.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { BackdoorInfoModalModule } from '@app/modules/backdoor-info-modal/backdoor-info-modal.module';
import { WhatsNewService } from './core/service/whats-new.service';
import { TodaysShiftApiService } from './core/service/api/todaysShift.api.service';
import { PasscodeTimeoutService } from './core/service/passcode-timeout.service';
import { WhatsNewModalModule } from './modules/whats-new-modal/whats-new-modal.module';
import { TodaysShiftModalModule } from './modules/todays-shift-modal/todays-shift-modal.module';
import { InstantFeedbackButtonModule } from './modules/instant-feedback-button/instant-feedback-button.module';
import { ImageViewerModalModule } from './modules/image-viewer-modal/image-viewer-modal.module';
import { ImageViewerService } from './core/service/image-viewer.service';
import { register } from 'swiper/element/bundle';
// import {
//   modalEnterAnimation,
//   modalLeaveAnimation
// } from './utils/ionic-animations';

register();

@NgModule({
  declarations: [AppComponent],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    NgbModule,
    RouterModule.forRoot(AppRoutes, { useHash: true }),
    CoreModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    IonicModule.forRoot({
      mode: 'ios',
      menuIcon: 'chevron-back-outline'
      // modalEnter: modalEnterAnimation,
      // modalLeave: modalLeaveAnimation
    }),
    BackdoorInfoModalModule,
    WhatsNewModalModule,
    TodaysShiftModalModule,
    InstantFeedbackButtonModule,
    ImageViewerModalModule
  ],
  bootstrap: [AppComponent],
  providers: [
    WhatsNewService,
    PasscodeTimeoutService,
    TodaysShiftApiService,
    ImageViewerService,
    NavController
  ]
})
export class AppModule {}
