import { Component, ViewChild } from '@angular/core';
import { TenantService } from '@app/core/service/tenant.service';
import { TSpecBodyPatchTenant } from '@backend/types/generated/Tenants/patchTenant';
import { TagsDefinition } from '@backend/types/tags-definition';
import { IonLoading, IonNav } from '@ionic/angular';

@Component({
  selector: 'app-tags-settings',
  templateUrl: './tags-settings.component.html',
  styleUrls: ['./tags-settings.component.scss']
})
export class TagsSettingsComponent {
  @ViewChild('loading', { static: true })
  public loading!: IonLoading;

  protected tagsSettings: TagsDefinition;

  public constructor(
    private readonly _ionNav: IonNav,
    private readonly _tenantService: TenantService
  ) {
    this._tenantService.tenant$.subscribe((tenant) => {
      if (this.loading) {
        this.loading.dismiss();
      }

      this.tagsSettings = tenant.settings.tags;
    });
  }

  protected onBackButtonClick() {
    this._ionNav.pop();
  }

  private _patch(patch: TSpecBodyPatchTenant): void {
    this.loading.present();
    this._tenantService.patch(patch);
  }

  protected onPickupDateFieldNameInput({
    detail: { value }
  }: CustomEvent<{ value: string }>) {
    this.tagsSettings = {
      ...this.tagsSettings,
      pickupDateFieldName: value
    };
    this._patch({ settings: { tags: this.tagsSettings } });
  }

  protected onCustomerNameEnabledChange(event: any) {
    if (event && event.detail) {
      this.tagsSettings.customerNameEnabled = event.detail.checked;
      this._patch({
        settings: {
          tags: this.tagsSettings
        }
      });
    }
  }
}
