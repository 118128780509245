// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_getSentLedger = (path: TSpecPathGetSentLedger) => {
  let res = "/users/:id/ledger-sent";
  Object.entries(path).map(([key, value]) => {
    res = res.replace(':' + key, value);
  });
  return res;
};

export type TSpecHandler_getSentLedger = RequestHandler<TSpecPathGetSentLedger, any, TSpecBodyGetSentLedger, TSpecQueryGetSentLedger, {}>;

export interface TSpecPathGetSentLedger { id: string; }

export interface TSpecBodyGetSentLedger {}

export interface TSpecQueryGetSentLedger {
  searchTerm?: string;
  limit?: string;
  page?: string;
  from?: string;
  to?: string;
}
