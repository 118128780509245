import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MongoStoredObject } from '@app/types/mongo-stored-object';
import { ITaskAudit } from '@backend/models/types/task-audit';
import {
  TSpecBodySubmitTaskAudit,
  TSpecRoute_submitTaskAudit
} from '@backend/types/generated/TaskAudit/submitTaskAudit';
import { Observable } from 'rxjs';

@Injectable()
export class TaskAuditApiService {
  public constructor(private readonly _httpClient: HttpClient) {}

  public submitTaskAudit(params: {
    body: TSpecBodySubmitTaskAudit;
  }): Observable<MongoStoredObject<ITaskAudit>> {
    return this._httpClient.post<MongoStoredObject<ITaskAudit>>(
      TSpecRoute_submitTaskAudit(),
      params.body
    );
  }
}
