<ion-content class="content">
  <div class="layout-container">
    <ng-container *ngIf="survey && currentQuestion.question">
      <app-positive-feedback-question
        *ngIf="
          currentQuestion.question.type ===
          psychologicalSurveyQuestionType.POSITIVE_FEEDBACK
        "
        class="question-animation"
        [survey]="survey"
        [question]="currentQuestion.question"
        [previousAnswer]="currentQuestion.answer"
        (completed)="onQuestionComplete($event)"
        (goBack)="onStepBack()"
        cancelButton="cancel"
        (cancelClicked)="onCancelClicked()"
        questionTexting="LAST_SHIFT"
      />
      <app-negative-feedback-question
        *ngIf="
          currentQuestion.question.type ===
          psychologicalSurveyQuestionType.NEGATIVE_FEEDBACK
        "
        class="question-animation"
        [survey]="survey"
        [question]="currentQuestion.question"
        [previousAnswer]="currentQuestion.answer"
        (completed)="onQuestionComplete($event)"
        (goBack)="onStepBack()"
        cancelButton="cancel"
        (cancelClicked)="onCancelClicked()"
        questionTexting="LAST_SHIFT"
      />
      <app-mood-check-question
        *ngIf="
          currentQuestion.question.type ===
          psychologicalSurveyQuestionType.MOOD_CHECK
        "
        class="question-animation"
        [survey]="survey"
        [question]="currentQuestion.question"
        [previousAnswer]="currentQuestion.answer"
        (completed)="onQuestionComplete($event)"
        (goBack)="onStepBack()"
        cancelButton="cancel"
        (cancelClicked)="onCancelClicked()"
      />
      <app-team-evaluation-question
        *ngIf="
          currentQuestion.question.type ===
          psychologicalSurveyQuestionType.TEAM_EVALUATION
        "
        class="question-animation"
        [survey]="survey"
        [question]="currentQuestion.question"
        [previousAnswer]="currentQuestion.answer"
        (completed)="onQuestionComplete($event)"
        (goBack)="onStepBack()"
        cancelButton="cancel"
        (cancelClicked)="onCancelClicked()"
      />
    </ng-container>
  </div>
</ion-content>
