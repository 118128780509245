<ion-header class="ion-no-border">
  <ion-toolbar class="app-toolbar">
    <ion-buttons slot="start">
      <ion-button (click)="onBackButtonClick()" appHaptics>
        <ion-icon slot="icon-only" name="chevron-back-outline"></ion-icon>
        <ion-label>Back</ion-label>
      </ion-button>
    </ion-buttons>
    <ion-title class="ion-text-capitalize"> Checklist </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content color="light">
  <app-width-restricted-container class="container-with-fab">
    <ion-list [inset]="true">
      <ion-item>
        <ion-toggle
          appHaptics
          [(ngModel)]="checklistSettings.showProgressBars"
          (ionChange)="
            onChecklistSettingsFieldChange('showProgressBars', $event)
          "
        >
          <ion-label> Show progress bars </ion-label>
        </ion-toggle>
      </ion-item>
      <ion-item>
        <ion-toggle
          appHaptics
          [(ngModel)]="
            checklistSettings.allowTasksToBeDisplayedOnlyDuringCertainHours
          "
          (ionChange)="
            onChecklistSettingsFieldChange(
              'allowTasksToBeDisplayedOnlyDuringCertainHours',
              $event
            )
          "
        >
          <ion-label>
            Allow individual tasks to be displayed only during certain hours
          </ion-label>
        </ion-toggle>
      </ion-item>
      <ion-item [attr.data-hidden]="!tenantFeatures.timeAttack">
        <ion-toggle
          appHaptics
          [(ngModel)]="checklistSettings.requireManagerToInitiateTimeAttack"
          (ionChange)="
            onChecklistSettingsFieldChange(
              'requireManagerToInitiateTimeAttack',
              $event
            )
          "
        >
          <ion-label> Require manager to initiate Time Attack </ion-label>
        </ion-toggle>
      </ion-item>
    </ion-list>

    <app-list-pre-line title="Tasks for Audit" />
    <ion-list [inset]="true">
      <ion-item [attr.data-hidden]="!tenantFeatures.auditTasks">
        <ion-toggle
          appHaptics
          [(ngModel)]="checklistSettings.showLastCompleteInTasksForAudit"
          (ionChange)="
            onChecklistSettingsFieldChange(
              'showLastCompleteInTasksForAudit',
              $event
            )
          "
        >
          <ion-label> Show last completion for each task </ion-label>
        </ion-toggle>
      </ion-item>
    </ion-list>
  </app-width-restricted-container>
</ion-content>

<ion-loading #loading message="Loading..."></ion-loading>
