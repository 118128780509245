// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_getAllBackdoorMessages = () => "/backdoor/info";

export type TSpecHandler_getAllBackdoorMessages = RequestHandler<TSpecPathGetAllBackdoorMessages, any, TSpecBodyGetAllBackdoorMessages, TSpecQueryGetAllBackdoorMessages, {}>;

export interface TSpecPathGetAllBackdoorMessages {  }

export interface TSpecBodyGetAllBackdoorMessages {}

export interface TSpecQueryGetAllBackdoorMessages {}
