// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_deleteIntegration = (path: TSpecPathDeleteIntegration) => {
  let res = "/integrations/:provider";
  Object.entries(path).map(([key, value]) => {
    res = res.replace(':' + key, value);
  });
  return res;
};

export type TSpecHandler_deleteIntegration = RequestHandler<TSpecPathDeleteIntegration, any, TSpecBodyDeleteIntegration, TSpecQueryDeleteIntegration, {}>;

export interface TSpecPathDeleteIntegration { provider: string; }

export interface TSpecBodyDeleteIntegration {}

export interface TSpecQueryDeleteIntegration {
  tenantId: string;
}
