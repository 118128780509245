<app-width-restricted-container
  [maxWidth]="maxWidth"
  class="top-content {{ isModal ? 'modal-body' : '' }} {{
    virtualScroll ? 'virtual-scroll-container' : ''
  }}"
>
  <ng-content></ng-content>
</app-width-restricted-container>

<app-width-restricted-container
  [maxWidth]="maxWidth"
  class="bottom-content {{ isModal ? 'modal-actions' : '' }}"
>
  <ng-content select="[bottom]"></ng-content>
</app-width-restricted-container>
