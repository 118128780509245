import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit
} from '@angular/core';
import { UserApiService } from '@app/core/service/api/user.api.service';
import { UserModel } from '@app/core/model/user.model';
import { MongoStoredObject } from '@app/types/mongo-stored-object';
import { IUserDeviceInfo } from '@backend/models/types/device-info';
import { ITenant } from '@backend/models/types/tenant';
import { IonNav } from '@ionic/angular';
import { Observable, Subject, repeat } from 'rxjs';

@Component({
  selector: 'app-tenant-users',
  templateUrl: 'tenant-users.component.html',
  styleUrls: ['./tenant-users.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TenantUsersComponent implements OnInit {
  @Input()
  public readonly tenant: MongoStoredObject<ITenant>;

  protected userList$: Observable<
    (UserModel & { deviceInfos: MongoStoredObject<IUserDeviceInfo>[] })[]
  >;

  private _listUpdate$ = new Subject<void>();

  public constructor(
    private readonly _api: UserApiService,
    private readonly _ionNav: IonNav
  ) {}

  public ngOnInit(): void {
    this.userList$ = this._api
      .getAllUsers({ path: { tenantId: this.tenant._id } })
      .pipe(repeat({ delay: () => this._listUpdate$ }));
  }

  protected onBackButtonClick() {
    this._ionNav.pop();
  }

  protected trackUserBy(_index: number, user: MongoStoredObject<UserModel>) {
    return user._id;
  }

  protected devicesStr(deviceInfos: MongoStoredObject<IUserDeviceInfo>[]) {
    return deviceInfos.reduce(
      (prev, cur) =>
        `${prev ? `${prev}, ` : ''}v${cur.version} (${
          cur.deviceInfo.platform
        })`,
      ''
    );
  }
}
