// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_initIntegration = (path: TSpecPathInitIntegration) => {
  let res = "/integrations/:provider/init";
  Object.entries(path).map(([key, value]) => {
    res = res.replace(':' + key, value);
  });
  return res;
};

export type TSpecHandler_initIntegration = RequestHandler<TSpecPathInitIntegration, any, TSpecBodyInitIntegration, TSpecQueryInitIntegration, {}>;

export interface TSpecPathInitIntegration { provider: string; }

export interface TSpecBodyInitIntegration {
  tenantId: string;
  settings: unknown;
  publicSettingsFields?: string[];
}

export interface TSpecQueryInitIntegration {}
