// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_deleteDepartment = (path: TSpecPathDeleteDepartment) => {
  let res = "/departments/:departmentId";
  Object.entries(path).map(([key, value]) => {
    res = res.replace(':' + key, value);
  });
  return res;
};

export type TSpecHandler_deleteDepartment = RequestHandler<TSpecPathDeleteDepartment, any, TSpecBodyDeleteDepartment, TSpecQueryDeleteDepartment, {}>;

export interface TSpecPathDeleteDepartment { departmentId: string; }

export interface TSpecBodyDeleteDepartment {}

export interface TSpecQueryDeleteDepartment {}
