import { CommonModule } from '@angular/common';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule
} from '@angular/common/http';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import {
  AuthenticationGuard,
  AuthenticationService
} from '@app/core/authentication';
import {
  ApiPrefixInterceptor,
  ErrorHandlerInterceptor,
  HttpService
} from '@app/core/http';
import { LocalStorageService } from '@app/core/local-storage.service';
import { ErrorMessageService } from '@app/core/service/error-message.service';
import { UserApiService } from '@app/core/service/api/user.api.service';
import { AnnouncementApiService } from './service/api/announcement.api.service';
import { PsychologicalSurveyApiService } from './service/api/psychologicalSurvey.api.service';
import { ManagerGuard } from './authentication/manager.guard';
import { MembershipService } from './service/membership.service';
import { TimezoneApiService } from './service/api/timezone.api.service';
import { IntegrationApiService } from '@app/core/service/api/integration.api.service';
import { ToastService } from './service/toast.service';
import { HapticsService } from './service/haptics.service';

@NgModule({
  imports: [CommonModule, HttpClientModule, RouterModule],
  declarations: [],
  providers: [
    LocalStorageService,
    AuthenticationService,
    AuthenticationGuard,
    ManagerGuard,
    ApiPrefixInterceptor,
    ErrorHandlerInterceptor,
    UserApiService,
    AnnouncementApiService,
    PsychologicalSurveyApiService,
    ToastService,
    HapticsService,
    TimezoneApiService,
    IntegrationApiService,
    ErrorMessageService,
    MembershipService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiPrefixInterceptor,
      multi: true
    },
    {
      provide: HttpClient,
      useClass: HttpService
    }
  ]
})
export class CoreModule {}
