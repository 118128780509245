import { Component, Input, OnInit, ViewChild } from '@angular/core';
import {
  IonNav,
  ModalController,
  IonInfiniteScroll,
  IonSearchbar,
  ViewWillLeave
} from '@ionic/angular';
import { InsightsRangeModalComponent } from '@app/modules/insights-range-modal/insights-range-modal.component';
import { DateInterval } from '@backend/types/date-interval';
import { LedgerApiService } from '@app/core/service/api/ledger.api.service';
import { HapticsService } from '@app/core/service/haptics.service';
import { isSameDay } from 'date-fns';
import { LedgerModel } from '@app/core/model/ledger.model';

@Component({
  selector: 'app-praise-received',
  templateUrl: './praise-received.component.html',
  styleUrls: ['./praise-received.component.scss']
})
export class PraiseReceivedComponent implements OnInit, ViewWillLeave {
  @Input()
  public userId: string;

  @Input()
  public fullName: string;

  @Input()
  public defaultRange: DateInterval;

  @ViewChild('infiniteScroll')
  public infiniteScroll: IonInfiniteScroll;

  @ViewChild('ionSearchbar')
  public ionSearchbar!: IonSearchbar;

  protected range: DateInterval;
  protected praiseReceived: LedgerModel[] = [];
  protected showSearchBar = false;
  protected searchTerm = '';
  protected page = 1;
  protected pageSize = 20;
  protected isLoading = true;
  protected hasNextPage = true;

  public constructor(
    private readonly _ionNav: IonNav,
    private readonly _modalCtrl: ModalController,
    private readonly _ledgerApiService: LedgerApiService,
    private readonly _hapticsService: HapticsService
  ) {}

  protected get isOneDayRange() {
    return isSameDay(this.range.start, this.range.end);
  }

  public async ionViewWillLeave() {
    const curPage = await this._ionNav.getActive();
    curPage.params = { range: this.range };
  }

  protected onIonInfinite() {
    this.page = this.page + 1;
    this._fetchPraiseReceived();
  }

  private _fetchPraiseReceived(reset = false): void {
    if (reset) {
      this.isLoading = true;
      this.page = 1;
      this.hasNextPage = true;
      this.praiseReceived = [];
    }

    this._ledgerApiService
      .getReceivedLedger({
        path: { id: this.userId },
        query: {
          page: this.page.toString(),
          limit: this.pageSize.toString(),
          searchTerm: this.searchTerm,
          from: this.range?.start.toISOString(),
          to: this.range?.end.toISOString()
        }
      })
      .subscribe((praiseReceived) => {
        this.hasNextPage = praiseReceived.length === this.pageSize;
        this.praiseReceived = this.praiseReceived.concat(praiseReceived);
        this.isLoading = false;
        this.infiniteScroll?.complete();
      });
  }

  public ngOnInit(): void {
    this.range = this.defaultRange;
    this._fetchPraiseReceived();
  }

  protected async onRangeClick() {
    const modal = await this._modalCtrl.create({
      id: 'insights-range-modal',
      component: InsightsRangeModalComponent,
      cssClass: 'modal-auto-height',
      breakpoints: [0, 1],
      initialBreakpoint: 1,
      componentProps: {
        range: this.range
      },
      handle: false
    });
    modal.present();

    const { data, role } = await modal.onWillDismiss();

    if (role === 'confirm') {
      this.range = data;
      this._fetchPraiseReceived(true);
    }
  }

  protected async onBackButtonClick() {
    this._ionNav.pop();
  }

  protected onSearchButtonClick(): void {
    this.showSearchBar = true;

    setTimeout(() => {
      this.ionSearchbar.setFocus();
    }, 250);
  }

  protected onSearchBarCancel(): void {
    this._hapticsService.hapticsImpact();
    this.showSearchBar = false;
  }

  protected trackItems(_: number, item: LedgerModel) {
    return item._id;
  }

  protected handleSearchInput(event: any) {
    this.searchTerm = event.detail.value;
    this._fetchPraiseReceived(true);
  }
}
