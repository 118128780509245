import { Component, ViewChild } from '@angular/core';
import { TenantService } from '@app/core/service/tenant.service';
import { ITenantFeatures } from '@backend/models/types/tenant';
import { TSpecBodyPatchTenant } from '@backend/types/generated/Tenants/patchTenant';
import { IonLoading, IonNav, ModalController } from '@ionic/angular';
import { createDefaultPayroll } from '@app/utils/create-default-payroll';
import { createDefaultPayrollForFrequency } from '@app/utils/create-default-payroll-for-frequency';
import {
  PayrollDefinition,
  PayFrequency
} from '@backend/types/payroll-definition';
import { MongoStoredObject } from '@app/types/mongo-stored-object';
import {
  ECompensationFieldType,
  ICompensationField
} from '@backend/models/types/compensation-field';
import {
  CompensationDefinition,
  EIncludePointsFrom
} from '@backend/types/compensation-definition';
import { CompensationApiService } from '@app/core/service/api/compensation.api.service';
import { CompensationPoolModalComponent } from '@app/modals/compensation-pool-modal/compensation-pool-modal.component';
import { CompensationFieldModalComponent } from '@app/modals/compensation-field-modal/compensation-field-modal.component';
import { ToastService } from '@app/core/service/toast.service';
import {
  ECompensationPoolType,
  ICompensationPool
} from '@backend/models/types/compensation-pool';
import { addDays, addMonths, addWeeks, format } from 'date-fns';
import { showModal } from '@app/utils/modal';
import { ObjectId } from '@app/types/object-id';

@Component({
  selector: 'app-compensation-settings',
  templateUrl: './compensation-settings.component.html',
  styleUrls: ['./compensation-settings.component.scss']
})
export class CompensationSettingsComponent {
  @ViewChild('loading', { static: true })
  public loading!: IonLoading;

  protected payFrequency = PayFrequency;

  protected tenantFeatures: Pick<ITenantFeatures, 'calculateRewards'> = {
    calculateRewards: false
  };
  protected frequency: PayFrequency;
  protected date1: string;
  protected date2: string | undefined;
  private _payroll: PayrollDefinition | undefined;
  protected compensationPools: MongoStoredObject<ICompensationPool>[] = [];
  protected compensationFields: MongoStoredObject<ICompensationField>[] = [];
  protected compensationSettings: CompensationDefinition;

  public constructor(
    private readonly _ionNav: IonNav,
    private readonly _tenantService: TenantService,
    private readonly _compensationApiService: CompensationApiService,
    private readonly _modalCtrl: ModalController,
    private readonly _toastService: ToastService
  ) {
    this._tenantService.tenant$.subscribe((tenant) => {
      if (this.loading) {
        this.loading.dismiss();
      }

      this.tenantFeatures = tenant.features;
      this.compensationSettings = tenant.settings.compensation;
      this._payroll = tenant.settings?.payroll;
      this._setPayrollModel(this._payroll);
    });

    this._compensationApiService
      .getCompensationPools()
      .subscribe((compensationPools) => {
        this.compensationPools = compensationPools;
      });
    this._compensationApiService
      .getCompensationFields()
      .subscribe((compensationFields) => {
        this.compensationFields = compensationFields;
      });
  }

  protected onBackButtonClick() {
    this._ionNav.pop();
  }

  private _patch(patch: TSpecBodyPatchTenant): void {
    this.loading.present();
    this._tenantService.patch(patch);
  }

  protected savePayroll() {
    const payroll: PayrollDefinition =
      this.frequency !== PayFrequency.TWICE_PER_MONTH
        ? {
            frequency: this.frequency,
            date1: this.date1
          }
        : {
            frequency: this.frequency,
            date1: this.date1,
            date2: this.date2
          };
    this._patch({ settings: { payroll } });
  }

  private _setPayrollModel(payroll: PayrollDefinition | undefined) {
    if (!payroll) {
      payroll = createDefaultPayroll();
    }
    this.frequency = payroll.frequency;
    this._setDatesFromPayroll(payroll);
  }

  private _setDatesFromPayroll(payroll: PayrollDefinition) {
    this.date1 = payroll.date1;
    if (payroll.frequency === PayFrequency.TWICE_PER_MONTH) {
      this.date2 = payroll.date2;
    } else {
      this.date2 = undefined;
    }
  }

  protected onFrequencyChange({
    detail: { value }
  }: CustomEvent<{ value: PayFrequency }>) {
    if (this.frequency === value) {
      return;
    }
    if (value === this._payroll?.frequency) {
      // returning initial dates from the settings
      this._setPayrollModel(this._payroll);
    } else {
      //create defaults for selected frequency
      this.frequency = value;
      const payroll = createDefaultPayrollForFrequency(value);
      this._setDatesFromPayroll(payroll);
    }
    this.savePayroll();
  }

  protected get onlyHoursCompensationFields() {
    return this.compensationFields.filter(
      (field) => field.type === ECompensationFieldType.HOURS
    );
  }

  protected get compensationEmployeeIdColumnName() {
    const employeeIdColumn = this.compensationFields.find(
      (field) => field.type === ECompensationFieldType.EMPLOYEE_ID
    );

    return employeeIdColumn ? employeeIdColumn.name : '';
  }

  protected onIncludePointsFromChange({
    detail: { value }
  }: CustomEvent<{ value: EIncludePointsFrom[] }>) {
    const compensation = { includePointsFrom: value };

    this._patch({ settings: { compensation } });
  }

  protected onCompensationEmployeeIdColumnNameInput({
    detail: { value }
  }: CustomEvent<{ value: string }>) {
    const employeeIdColumn = this.compensationFields.find(
      (field) => field.type === ECompensationFieldType.EMPLOYEE_ID
    );

    this.loading.present();

    if (employeeIdColumn) {
      this._compensationApiService
        .updateCompensationField({
          path: { compensationFieldId: employeeIdColumn._id },
          body: { name: value }
        })
        .subscribe({
          next: () => {
            this.loading.dismiss();
          },
          error: (e) => {
            this.loading.dismiss();
            this._toastService.presentToast(
              e.error?.message || 'Unable to update employee ID column'
            );
          }
        });
    } else {
      this._compensationApiService
        .addCompensationField({
          body: {
            name: value,
            type: ECompensationFieldType.EMPLOYEE_ID
          }
        })
        .subscribe({
          next: () => {
            this.loading.dismiss();
          },
          error: (e) => {
            this.loading.dismiss();
            this._toastService.presentToast(
              e.error?.message || 'Unable to create employee ID column'
            );
          }
        });
    }
  }

  protected async addCompensationPool() {
    const { role, data } = await showModal<{
      name: string;
      type: ECompensationPoolType;
      users: ObjectId[];
    }>(
      {
        component: CompensationPoolModalComponent
      },
      this._modalCtrl
    );

    if (role === 'confirm') {
      this.loading.present();
      this._compensationApiService
        .addCompensationPool({ body: data })
        .subscribe({
          next: (pool) => {
            this.loading.dismiss();
            this.compensationPools.push(pool);
          },
          error: (e) => {
            this.loading.dismiss();
            this._toastService.presentToast(
              e.error?.message || 'Unable to create compensation pool'
            );
          }
        });
    }
  }

  protected async updateCompensationPool(
    compensationPool: MongoStoredObject<ICompensationPool>
  ) {
    const { role, data } = await showModal<{
      name: string;
      type: ECompensationPoolType;
      users: ObjectId[];
    }>(
      {
        component: CompensationPoolModalComponent,
        props: { compensationPool }
      },
      this._modalCtrl
    );

    if (role === 'confirm') {
      this.loading.present();
      this._compensationApiService
        .updateCompensationPool({
          path: { compensationPoolId: compensationPool._id },
          body: data
        })
        .subscribe({
          next: (pool) => {
            this.loading.dismiss();
            this.compensationPools = this.compensationPools.map((p) =>
              pool._id === p._id ? pool : p
            );
          },
          error: (e) => {
            this.loading.dismiss();
            this._toastService.presentToast(
              e.error?.message || 'Unable to update compensation pool'
            );
          }
        });
    } else if (role === 'delete') {
      this.loading.present();
      this._compensationApiService
        .removeCompensationPool({
          path: { compensationPoolId: compensationPool._id }
        })
        .subscribe({
          next: () => {
            this.loading.dismiss();
            this.compensationPools = this.compensationPools.filter(
              (pool) => pool._id !== compensationPool._id
            );
          },
          error: (e) => {
            this.loading.dismiss();
            this._toastService.presentToast(
              e.error?.message || 'Unable to delete compensation pool'
            );
          }
        });
    }
  }

  protected trackCompensationPools(_: number, item: { _id: string }) {
    return item._id;
  }

  protected trackCompensationFields(_: number, item: { _id: string }) {
    return item._id;
  }

  protected async addCompensationField() {
    const { role, data } = await showModal<{
      name: string;
      type: ECompensationFieldType;
    }>(
      {
        component: CompensationFieldModalComponent
      },
      this._modalCtrl
    );

    if (role === 'confirm') {
      this.loading.present();
      this._compensationApiService
        .addCompensationField({ body: data })
        .subscribe({
          next: (field) => {
            this.loading.dismiss();
            this.compensationFields.push(field);
          },
          error: (e) => {
            this.loading.dismiss();
            this._toastService.presentToast(
              e.error?.message || 'Unable to create compensation column'
            );
          }
        });
    }
  }

  protected async updateCompensationField(
    compensationField: MongoStoredObject<ICompensationField>
  ) {
    const { role, data } = await showModal<{
      name: string;
      type: ECompensationFieldType;
    }>(
      {
        component: CompensationFieldModalComponent,
        props: { compensationField }
      },
      this._modalCtrl
    );

    if (role === 'confirm') {
      this.loading.present();
      this._compensationApiService
        .updateCompensationField({
          path: { compensationFieldId: compensationField._id },
          body: data
        })
        .subscribe({
          next: (field) => {
            this.loading.dismiss();
            this.compensationFields = this.compensationFields.map((f) =>
              field._id === f._id ? field : f
            );
          },
          error: (e) => {
            this.loading.dismiss();
            this._toastService.presentToast(
              e.error?.message || 'Unable to update compensation field'
            );
          }
        });
    } else if (role === 'delete') {
      this.loading.present();
      this._compensationApiService
        .removeCompensationField({
          path: { compensationFieldId: compensationField._id }
        })
        .subscribe({
          next: () => {
            this.loading.dismiss();
            this.compensationFields = this.compensationFields.filter(
              (field) => field._id !== compensationField._id
            );
          },
          error: (e) => {
            this.loading.dismiss();
            this._toastService.presentToast(
              e.error?.message || 'Unable to delete compensation field'
            );
          }
        });
    }
  }

  protected get dateMin(): string {
    return format(addDays(new Date(), 1), 'yyyy-MM-dd');
  }

  protected get dateMax(): string {
    let date = new Date();
    switch (this.frequency) {
      case PayFrequency.EVERY_WEEK:
        date = addWeeks(date, 1);
        break;
      case PayFrequency.EVERY_TWO_WEEKS:
        date = addWeeks(date, 2);
        break;
      case PayFrequency.ONCE_PER_MONTH:
      case PayFrequency.TWICE_PER_MONTH:
        date = addMonths(date, 1);
        break;
    }
    return format(date, 'yyyy-MM-dd');
  }
}
