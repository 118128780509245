import { ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { AuthenticationService } from '@app/core';
import { SuggestionApiService } from '@app/core/service/api/suggestion.api.service';
import {
  IonNav,
  LoadingController,
  ViewWillEnter,
  IonInfiniteScroll
} from '@ionic/angular';
import { SuggestionAddComponent } from './components/suggestion-add/suggestion-add.component';
import { OpportunityModel } from '@app/core/model/suggestion.model';
import { SuggestionViewComponent } from './components/suggestion-view/suggestion-view.component';
import { PullToSearchComponent } from '@app/modules/pull-to-search/pull-to-search.component';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { UserRoles } from '@backend/models/types/user';

@Component({
  selector: 'app-suggestions',
  templateUrl: './suggestions.component.html'
})
export class SuggestionsComponent implements ViewWillEnter {
  @ViewChild('infiniteScroll')
  public infiniteScroll: IonInfiniteScroll;

  @ViewChild('pullToSearch', { static: true })
  public pullToSearch!: PullToSearchComponent;

  protected isAdmin = false;
  protected opportunities: OpportunityModel[] = [];
  protected isLoading = true;
  protected hasNextPage = true;
  protected searchTerm = '';
  protected page = 1;
  protected pageSize = 20;

  public constructor(
    private readonly _suggestionApiService: SuggestionApiService,
    private readonly _ionNav: IonNav,
    private readonly _authenticationService: AuthenticationService,
    private readonly _loadingCtrl: LoadingController,
    private readonly _route: ActivatedRoute,
    private readonly _location: Location
  ) {
    this.isAdmin = this._authenticationService.user.roles.includes(
      UserRoles.Admin
    );

    this._route.params.subscribe((params) => {
      const queryId = params['id'];

      if (queryId) {
        this._location.replaceState('/i/suggestions');
        this.viewDoc(queryId);
      }
    });
  }

  public ionViewWillEnter(): void {
    this._fetchOpportunities(true);
  }

  public async ionViewDidEnter() {
    const topLoading = await this._loadingCtrl.getTop();
    if (topLoading) {
      topLoading.dismiss();
    }
  }

  protected _fetchOpportunities(reset = false) {
    if (reset) {
      this.isLoading = true;
      this.page = 1;
      this.hasNextPage = true;
      this.opportunities = [];
    }

    (this.isAdmin
      ? this._suggestionApiService.getAllOpportunities({
          query: {
            page: this.page.toString(),
            limit: this.pageSize.toString(),
            searchTerm: this.searchTerm.toString()
          }
        })
      : this._suggestionApiService.getPublicOpportunities({
          query: {
            page: this.page.toString(),
            limit: this.pageSize.toString(),
            searchTerm: this.searchTerm.toString()
          }
        })
    ).subscribe((fetchedDocs) => {
      this.hasNextPage = fetchedDocs.length === this.pageSize;
      this.opportunities = this.opportunities.concat(fetchedDocs);
      this.isLoading = false;
      this.pullToSearch.initSearch();
      this.infiniteScroll?.complete();
    });
  }

  protected onAddClick(): void {
    this._ionNav.push(SuggestionAddComponent);
  }

  protected viewDoc(opportunityId: string) {
    this._ionNav.push(SuggestionViewComponent, {
      id: opportunityId
    });
  }

  protected trackItems(_: number, item: OpportunityModel) {
    return item._id.toString();
  }

  protected onIonInfinite() {
    this.page = this.page + 1;
    this._fetchOpportunities();
  }

  protected handleSearchInput(value: string) {
    this.searchTerm = value;
    this._fetchOpportunities(true);
  }
}
