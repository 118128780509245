import { NgModule } from '@angular/core';
import { SuggestionCategoryModalComponent } from './suggestion-category-modal.component';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DirectivesModule } from '@app/modules/directives.module';
import { ListPreLineModule } from '@app/modules/list-pre-line/list-pre-line.module';
import { ListPostLineModule } from '@app/modules/list-post-line/list-post-line.module';
import { FullHeightContainerModule } from '@app/modules/full-height-container/full-height-container.module';
import { UserSelectorModalModule } from '../user-selector-modal/user-selector-modal.module';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    ReactiveFormsModule,
    DirectivesModule,
    ListPreLineModule,
    ListPostLineModule,
    UserSelectorModalModule,
    FullHeightContainerModule
  ],
  exports: [SuggestionCategoryModalComponent],
  declarations: [SuggestionCategoryModalComponent]
})
export class SuggestionCategoryModalModule {}
