import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { map, Observable } from 'rxjs';
import { UserEntityService } from '../service/user-entity.service';
import { UserRoles } from '@backend/models/types/user';

@Injectable()
export class ManagerGuard {
  constructor(
    private _router: Router,
    private _userEntity: UserEntityService
  ) {}

  canActivate(): Observable<true | UrlTree> {
    return this._userEntity.user$.pipe(
      map((user) => {
        if (
          user.roles.includes(UserRoles.Admin) ||
          user.roles.includes(UserRoles.Manager)
        ) {
          return true;
        }
        return this._router.createUrlTree(['/']);
      })
    );
  }
}
