import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MongoStoredObject } from '@app/types/mongo-stored-object';
import { IUserShiftTime } from '@backend/models/types/user-shift-time';
import { TodaysShiftApiService } from '@app/core/service/api/todaysShift.api.service';
import { EUserScheduleType } from '@backend/models/types/user';
import { localizedWeekDayName } from '@app/utils/localized-week-day-name';

@Component({
  selector: 'app-user-schedule-editor',
  templateUrl: './user-schedule-editor.component.html',
  styleUrls: ['./user-schedule-editor.component.scss']
})
export class UserScheduleEditorComponent implements OnInit {
  @Input()
  public userId?: string;

  @Input()
  public scheduleFormGroup: FormGroup;

  @Input()
  public weekTimeFormGroup: FormGroup;

  public readonly userScheduleType = EUserScheduleType;

  protected lastVariableShiftTime: MongoStoredObject<IUserShiftTime> | null =
    null;
  protected weekDays = [0, 1, 2, 3, 4, 5, 6].map((i) =>
    localizedWeekDayName(i, 'long')
  );

  public constructor(
    private readonly _todaysShiftApiService: TodaysShiftApiService
  ) {}

  public ngOnInit(): void {
    this._todaysShiftApiService
      .getUserLatestShiftTime({ query: { userId: this.userId } })
      .subscribe((latestShiftTime) => {
        this.lastVariableShiftTime = latestShiftTime;
      });
  }

  protected get isFixedScheduleType() {
    return (
      this.scheduleFormGroup.get('scheduleType').value ===
      EUserScheduleType.FIXED
    );
  }

  protected get dayArray() {
    return this.scheduleFormGroup.controls.weekDays.value;
  }

  protected trackByDay(index: number) {
    return this.weekDays ? this.weekDays[index] : index;
  }
}
