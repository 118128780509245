import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WidthRestrictedContainerModule } from '../width-restricted-container/width-restricted-container.module';
import { IonicModule } from '@ionic/angular';
import { SlimMenuButtonComponent } from '../slim-menu-button/slim-menu-button.component';
import { DirectivesModule } from '../directives.module';
import { TeamEvaluationQuestionComponent } from './team-evaluation-question.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EmojiButtonComponent } from '../emoji-button/emoji-button.component';
import { EmptyStubModule } from '../empty-stub/empty-stub.module';

@NgModule({
  declarations: [TeamEvaluationQuestionComponent],
  imports: [
    CommonModule,
    IonicModule,
    SlimMenuButtonComponent,
    WidthRestrictedContainerModule,
    DirectivesModule,
    FormsModule,
    ReactiveFormsModule,
    EmojiButtonComponent,
    EmptyStubModule
  ],
  exports: [TeamEvaluationQuestionComponent]
})
export class TeamEvaluationQuestionModule {}
