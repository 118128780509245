import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LedgerModel } from '../../model/ledger.model';
import { TSpecRoute_getUnreadLedgers } from '@backend/types/generated/Ledger/getUnreadLedgers';
import {
  TSpecPathGetLedger,
  TSpecQueryGetLedger,
  TSpecRoute_getLedger
} from '@backend/types/generated/Ledger/getLedger';
import {
  TSpecPathGetSentLedger,
  TSpecQueryGetSentLedger,
  TSpecRoute_getSentLedger
} from '@backend/types/generated/Users/getSentLedger';
import {
  TSpecPathGetReceivedLedger,
  TSpecQueryGetReceivedLedger,
  TSpecRoute_getReceivedLedger
} from '@backend/types/generated/Users/getReceivedLedger';
import {
  TSpecPathGetLedgerById,
  TSpecRoute_getLedgerById
} from '@backend/types/generated/Ledger/getLedgerById';
import {
  TSpecPathReadLedgerById,
  TSpecRoute_readLedgerById
} from '@backend/types/generated/Ledger/readLedgerById';
import {
  TSpecPathDeleteLedger,
  TSpecRoute_deleteLedger
} from '@backend/types/generated/Ledger/deleteLedger';
import {
  TSpecBodyUpdateLedger,
  TSpecPathUpdateLedger,
  TSpecRoute_updateLedger
} from '@backend/types/generated/Ledger/updateLedger';

interface IGetAllLedgersPaginatedResult {
  ledgers: LedgerModel[];
  totalItems: number;
  message: string;
  currentPage: string;
  totalPages: number;
}

@Injectable({
  providedIn: 'root'
})
export class LedgerApiService {
  public constructor(private readonly _httpClient: HttpClient) {}

  public getUnreadLedgers(): Observable<{
    items: LedgerModel[];
    count: number;
    totalCount: number;
  }> {
    return this._httpClient.get<{
      items: LedgerModel[];
      count: number;
      totalCount: number;
    }>(TSpecRoute_getUnreadLedgers());
  }

  public getLedger(params: {
    path: TSpecPathGetLedger;
    query: TSpecQueryGetLedger;
  }): Observable<IGetAllLedgersPaginatedResult> {
    return this._httpClient.get<IGetAllLedgersPaginatedResult>(
      TSpecRoute_getLedger(params.path),
      { params: { ...params.query } }
    );
  }

  public getSentLedger(params: {
    path: TSpecPathGetSentLedger;
    query: TSpecQueryGetSentLedger;
  }): Observable<LedgerModel[]> {
    return this._httpClient.get<LedgerModel[]>(
      TSpecRoute_getSentLedger(params.path),
      { params: { ...params.query } }
    );
  }

  public getReceivedLedger(params: {
    path: TSpecPathGetReceivedLedger;
    query: TSpecQueryGetReceivedLedger;
  }): Observable<LedgerModel[]> {
    return this._httpClient.get<LedgerModel[]>(
      TSpecRoute_getReceivedLedger(params.path),
      { params: { ...params.query } }
    );
  }

  public getLedgerById(params: {
    path: TSpecPathGetLedgerById;
  }): Observable<LedgerModel> {
    return this._httpClient.get<LedgerModel>(
      TSpecRoute_getLedgerById(params.path)
    );
  }

  public readLedgerById(params: {
    path: TSpecPathReadLedgerById;
  }): Observable<any> {
    return this._httpClient.post(TSpecRoute_readLedgerById(params.path), {});
  }

  public deleteLedger(params: {
    path: TSpecPathDeleteLedger;
  }): Observable<null> {
    return this._httpClient.delete<null>(TSpecRoute_deleteLedger(params.path));
  }

  public updateLedger(params: {
    path: TSpecPathUpdateLedger;
    body: TSpecBodyUpdateLedger;
  }): Observable<unknown> {
    return this._httpClient.patch(
      TSpecRoute_updateLedger(params.path),
      params.body
    );
  }
}
