// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_postRecognition = () => "/recognition";

export type TSpecHandler_postRecognition = RequestHandler<TSpecPathPostRecognition, any, TSpecBodyPostRecognition, TSpecQueryPostRecognition, {}>;

export interface TSpecPathPostRecognition {  }

export interface TSpecBodyPostRecognition {
  description: string;
  recipientId: string;
  behaviorId: string;
  isPrivate: boolean;
}

export interface TSpecQueryPostRecognition {}
