import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MongoStoredObject } from '@app/types/mongo-stored-object';
import { IAttachment } from '@backend/models/types/attachment';
import { IUploadedFile } from '@backend/types/uploaded-file';
import { Observable, map, of } from 'rxjs';
import {
  prepareBodyForUpload,
  replaceIndexesByIds
} from '@app/utils/wysiwyg-editor';
import { dataURLtoFile } from '@app/utils/file';
import { EnvironmentService } from '../environment.service';
import { TSpecRoute_uploadAttachments } from '@backend/types/generated/FileUpload/uploadAttachments';
import { TSpecRoute_uploadFile } from '@backend/types/generated/FileUpload/uploadFile';

@Injectable()
export class UploadFileApiService {
  public constructor(
    private readonly _httpClient: HttpClient,
    private readonly _environmentService: EnvironmentService
  ) {}

  public uploadFile(file: File): Observable<IUploadedFile> {
    const formData = new FormData();
    formData.append('file', file);

    return this._httpClient.post<IUploadedFile>(
      TSpecRoute_uploadFile(),
      formData
    );
  }

  public extractAttachmentsFromBody(
    body: string,
    prevAttachments: MongoStoredObject<IAttachment>[]
  ): Observable<{
    newAttachments: MongoStoredObject<IAttachment>[];
    body: string;
    keptAttachments: string[];
  }> {
    const data = prepareBodyForUpload(
      body,
      prevAttachments,
      this._environmentService.serverUrl
    );

    if (!data.dataImages.length) {
      return of({
        newAttachments: [],
        body: data.body,
        keptAttachments: data.keptAttachments
      });
    }

    const formData = new FormData();

    data.dataImages.map((dataImage) => {
      formData.append('file', dataURLtoFile(dataImage.dataImage, 'ATTACHMENT'));
      formData.append('positionInBody', dataImage.positionToInsert.toString());
    });

    return this._httpClient
      .post<MongoStoredObject<IAttachment>[]>(
        TSpecRoute_uploadAttachments(),
        formData
      )
      .pipe(
        map((newAttachments) => {
          return {
            newAttachments,
            body: replaceIndexesByIds(
              data.body,
              newAttachments.map((a, i) => ({ index: i, id: a._id.toString() }))
            ),
            keptAttachments: data.keptAttachments
          };
        })
      );
  }
}
