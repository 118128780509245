import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';

@Component({
  selector: 'app-survey-item',
  templateUrl: './survey-item.component.html',
  styleUrls: ['./survey-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SurveyItemComponent {
  @Input()
  public readonly titleHtml: string;

  @Input()
  public animationOrder?: number = -1;

  @Output()
  public buttonClicked = new EventEmitter<void>();

  @HostBinding('style')
  get myStyle(): SafeStyle {
    return this._sanitizer.bypassSecurityTrustStyle(
      `--animation-order: ${this.animationOrder};`
    );
  }

  @HostBinding('class.animated')
  public get isAnimated() {
    return this.animationOrder !== -1;
  }

  public constructor(private readonly _sanitizer: DomSanitizer) {}
}
