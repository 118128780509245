import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component
} from '@angular/core';
import { GlobalSettingsService } from '@app/core/service/global-settings.service';
import { InstantFeedbackApiService } from '@app/core/service/api/instantFeedback.api.service';
import { LoadingController } from '@ionic/angular';
import { IfVisible } from '@root/src/client/static_modules/@rosskevin/ifvisible';
import { Router } from '@angular/router';

const IFVISIBLE_KEY = 'ifvisible_instantFeedbackButton';

@Component({
  selector: 'app-instant-feedback-button',
  templateUrl: './instant-feedback-button.component.html',
  styleUrls: ['./instant-feedback-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InstantFeedbackButtonComponent {
  protected isOpen = false;
  protected alertButtons = [
    {
      text: 'Submit',
      role: 'submit',
      handler: (data: { message: string }) => {
        return !!data.message;
      }
    }
  ];
  protected alertInputs = [
    {
      name: 'message',
      type: 'textarea',
      placeholder: 'Leave comment'
    }
  ];
  protected inactiveTime = 0;
  protected timeout: any = null;
  protected showButton = false;

  public constructor(
    private readonly _loadingCtrl: LoadingController,
    private readonly _instantFeedbackApiService: InstantFeedbackApiService,
    private readonly _globalSettingsService: GlobalSettingsService,
    private readonly _cdr: ChangeDetectorRef,
    private readonly _router: Router
  ) {
    (window as any)[IFVISIBLE_KEY] = new IfVisible(window, document);

    this._globalSettingsService.globalSettings$.subscribe((globalSettings) => {
      this.inactiveTime = globalSettings.instantFeedback.inactivityTime;

      (window as any)[IFVISIBLE_KEY].setIdleDuration(this.inactiveTime);
    });

    (window as any)[IFVISIBLE_KEY].on('idle', () => {
      this.showButton = true;
      this._cdr.markForCheck();
    });

    (window as any)[IFVISIBLE_KEY].on('wakeup', () => {
      this.showButton = false;
      this._cdr.markForCheck();
    });
  }

  protected onButtonClick() {
    this.isOpen = !this.isOpen;
  }

  protected async willDismiss(event: any) {
    this.isOpen = false;

    if (event.detail.role === 'submit' && event.detail.data.values.message) {
      const loading = await this._loadingCtrl.create({
        message: 'Loading...'
      });
      loading.present();

      this._instantFeedbackApiService
        .sendInstantFeedback({
          body: {
            message: event.detail.data.values.message,
            appUrl: this._router.url
          }
        })
        .subscribe(() => {
          loading.dismiss();
        });
    }
  }
}
