import { Component, ViewChild } from '@angular/core';
import { TenantService } from '@app/core/service/tenant.service';
import { ITenantPushNotificationsFlags } from '@backend/models/types/tenant';
import { AnnouncementsDefinition } from '@backend/types/announcements-definition';
import { TSpecBodyPatchTenant } from '@backend/types/generated/Tenants/patchTenant';
import { IonLoading, IonNav } from '@ionic/angular';

@Component({
  selector: 'app-announcements-settings',
  templateUrl: './announcements-settings.component.html',
  styleUrls: ['./announcements-settings.component.scss']
})
export class AnnouncementsSettingsComponent {
  @ViewChild('loading', { static: true })
  public loading!: IonLoading;

  protected announcementsSettings: AnnouncementsDefinition;
  protected pnFlags: ITenantPushNotificationsFlags = {
    isAdminsReceiveNotificationsWhenAnyUserIsRecognized: false,
    isManagersReceiveNotificationsWhenAnyUserIsRecognized: false,
    isUsersReceiveNotificationsForAnnouncements: false
  };

  public constructor(
    private readonly _ionNav: IonNav,
    private readonly _tenantService: TenantService
  ) {
    this._tenantService.tenant$.subscribe((tenant) => {
      if (this.loading) {
        this.loading.dismiss();
      }

      this.announcementsSettings = tenant.settings.announcements;
      this.pnFlags = tenant.pnFlags;
    });
  }

  protected onBackButtonClick() {
    this._ionNav.pop();
  }

  private _patch(patch: TSpecBodyPatchTenant): void {
    this.loading.present();
    this._tenantService.patch(patch);
  }

  protected get whoCanSendAnnouncements() {
    return this.announcementsSettings.whoCanSendAnnouncements.sort().join('+');
  }

  protected onWhoCanSendAnnouncementsChange(event: any) {
    if (event && event.detail) {
      const newValue = event.detail.value.split('+');
      this.announcementsSettings.whoCanSendAnnouncements = newValue;
      this._patch({
        settings: {
          announcements: {
            ...this.announcementsSettings,
            whoCanSendAnnouncements: newValue
          }
        }
      });
    }
  }

  protected onPnFlagChange(
    event: any,
    pnFlag: keyof ITenantPushNotificationsFlags
  ) {
    if (event && event.detail) {
      this.pnFlags[pnFlag] = event.detail.checked;
      this._patch({
        pnFlags: {
          ...this.pnFlags,
          [pnFlag]: event.detail.checked
        }
      });
    }
  }
}
