// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_deleteOpportunity = (path: TSpecPathDeleteOpportunity) => {
  let res = "/suggestions/opportunities/:opportunityId";
  Object.entries(path).map(([key, value]) => {
    res = res.replace(':' + key, value);
  });
  return res;
};

export type TSpecHandler_deleteOpportunity = RequestHandler<TSpecPathDeleteOpportunity, any, TSpecBodyDeleteOpportunity, TSpecQueryDeleteOpportunity, {}>;

export interface TSpecPathDeleteOpportunity { opportunityId: string; }

export interface TSpecBodyDeleteOpportunity {}

export interface TSpecQueryDeleteOpportunity {}
