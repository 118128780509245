import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { ObjectId } from '@app/types/object-id';
import { UserModel } from '@app/core/model/user.model';
import { UserListService } from '@app/core/service/user-list.service';
import { SuggestionCategoryModel } from '@app/core/model/suggestion-category.model';
import { ModalClass } from '@app/core/class/modal.class';
import { showUserSelectorModal } from '@app/utils/modal';
import { map } from 'rxjs';

@Component({
  selector: 'app-suggestion-category-modal',
  templateUrl: './suggestion-category-modal.component.html'
})
export class SuggestionCategoryModalComponent
  extends ModalClass
  implements OnInit
{
  @Input()
  public readonly suggestionCategory: SuggestionCategoryModel = null;

  protected form = new FormGroup({
    name: new FormControl<string>('', Validators.required),
    notifyUsersOnNewOpportunities: new FormControl<ObjectId[]>([]),
    notifyUsersOnNewSolutions: new FormControl<ObjectId[]>([]),
    notifyUsersOnOpportunityStatusChange: new FormControl<ObjectId[]>([])
  });
  protected allUsers: UserModel[] = [];

  public constructor(
    private readonly _modalCtrl: ModalController,
    private readonly _userListService: UserListService
  ) {
    super();

    this._userListService.users$
      .pipe(map((list) => list.filter(({ isDeleted }) => !isDeleted)))
      .subscribe((allUsers) => {
        this.allUsers = allUsers;
      });
  }

  public ngOnInit(): void {
    if (this.suggestionCategory) {
      this.form.reset({
        name: this.suggestionCategory.name,
        notifyUsersOnNewOpportunities:
          this.suggestionCategory.notifyUsersOnNewOpportunities || [],
        notifyUsersOnNewSolutions:
          this.suggestionCategory.notifyUsersOnNewSolutions || [],
        notifyUsersOnOpportunityStatusChange:
          this.suggestionCategory.notifyUsersOnOpportunityStatusChange || []
      });
    }
  }

  protected onCloseClick(): void {
    this._modalCtrl.dismiss(null, 'cancel', this._modalName);
  }

  protected save(): void {
    this._modalCtrl.dismiss(this.form.value, 'confirm', this._modalName);
  }

  protected delete(): void {
    this._modalCtrl.dismiss(this.form.value, 'delete', this._modalName);
  }

  protected async onNotifyUsersOnNewOpportunitiesClick() {
    const { success, selectedUsers } = await showUserSelectorModal(
      {
        users: this.allUsers,
        recipients: this.form.controls.notifyUsersOnNewOpportunities.value,
        multiple: true
      },
      this._modalCtrl,
      this._modalName
    );

    if (success) {
      this.form.controls.notifyUsersOnNewOpportunities.setValue(selectedUsers);
    }
  }

  protected async onNotifyUsersOnNewSolutionsClick() {
    const { success, selectedUsers } = await showUserSelectorModal(
      {
        users: this.allUsers,
        recipients: this.form.controls.notifyUsersOnNewSolutions.value,
        multiple: true
      },
      this._modalCtrl,
      this._modalName
    );

    if (success) {
      this.form.controls.notifyUsersOnNewSolutions.setValue(selectedUsers);
    }
  }

  protected async onNotifyUsersOnOpportunityStatusChangeClick() {
    const { success, selectedUsers } = await showUserSelectorModal(
      {
        users: this.allUsers,
        recipients:
          this.form.controls.notifyUsersOnOpportunityStatusChange.value,
        multiple: true
      },
      this._modalCtrl,
      this._modalName
    );

    if (success) {
      this.form.controls.notifyUsersOnOpportunityStatusChange.setValue(
        selectedUsers
      );
    }
  }
}
