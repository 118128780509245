import { Injectable } from '@angular/core';
import { Subject, repeat, shareReplay, tap } from 'rxjs';
import { GlobalSettingsApiService } from './api/globalSettings.api.service';
import { TSpecBodyUpdateGlobalSettings } from '@backend/types/generated/GlobalSettings/updateGlobalSettings';

@Injectable({ providedIn: 'root' })
export class GlobalSettingsService {
  private readonly _update$ = new Subject<void>();

  public readonly globalSettings$ = this._api
    .getGlobalSettings()
    .pipe(repeat({ delay: () => this._update$ }), shareReplay(1));

  public constructor(private readonly _api: GlobalSettingsApiService) {}

  public updateGlobalSettings(body: TSpecBodyUpdateGlobalSettings) {
    return this._api
      .updateGlobalSettings({ body })
      .pipe(tap(() => this.update()));
  }

  private update() {
    this._update$.next();
  }
}
