<ion-header class="ion-no-border">
  <ion-toolbar class="app-toolbar">
    <ion-title class="ion-no-padding">Updates Since Last Login</ion-title>
  </ion-toolbar>
  <ion-toolbar class="app-toolbar">
    <div class="toolbar-subtitle">Review all items to proceed</div>
  </ion-toolbar>
</ion-header>

<app-announcement-preview
  [show]="selectedAnnouncementIndex !== -1"
  [announcement]="selectedAnnouncement"
  [announcementIndex]="selectedAnnouncementIndex"
  (cancelClicked)="onAnnouncementPreviewClose()"
  (announcementAcknowledged)="onAnnouncementAcknowledged($event)"
  (announcementResponded)="onAnnouncementResponded($event)"
/>

<app-survey-preview
  (cancelClicked)="onSurveyPreviewClose()"
  (surveyCompleted)="onSurveyCompleted()"
  [survey]="selectedSurvey?.survey"
  [questions]="selectedSurvey?.questions"
/>

<ion-content color="light" class="content">
  <div class="whats-new-container">
    <div
      class="whats-new-section"
      [ngClass]="{
        'whats-new-section--hidden':
          !recognitions.count && !recognitionReminders.count
      }"
    >
      Recognition
    </div>
    <div
      class="whats-new-item whats-new-item--recognitionReminder"
      *ngFor="
        let reminder of recognitionReminders.items;
        let i = index;
        trackBy: trackRemindersBy
      "
      [attr.data-index]="i"
    >
      <app-whats-new-item
        [title]="reminder.title"
        [buttons]="recognitionReminderButtons"
        (buttonClicked)="whatsNewItemButtonClick($event)"
      />
    </div>
    <div
      class="whats-new-item whats-new-item--recognition"
      *ngFor="
        let post of recognitions.items;
        let i = index;
        trackBy: trackRecognitionsBy
      "
      [attr.data-index]="i"
    >
      <app-recognition-item
        [item]="post"
        [showOptions]="authenticatedUser._id === post.sender?._id || isAdmin"
        [showEditButton]="false"
        [showPrivateButton]="false"
        [showMarkAsReadButton]="true"
        (markAsReadClicked)="readRecognition(post, i)"
        appHaptics
        [disableHaptics]="
          !(authenticatedUser._id === post.sender?._id || isAdmin)
        "
      />
    </div>
    <div
      class="whats-new-section whats-new-section--bottom"
      [ngClass]="{
        'whats-new-section--hidden':
          recognitions.totalCount <= recognitions.count
      }"
    >
      + {{ recognitions.totalCount - recognitions.count }} more
    </div>

    <div
      class="whats-new-section"
      [ngClass]="{ 'whats-new-section--hidden': !announcements.count }"
    >
      Critical Announcements
    </div>
    <div
      *ngFor="
        let announcement of announcements.items;
        let i = index;
        trackBy: trackAnnouncementsBy
      "
      class="whats-new-item whats-new-item--announcement"
      [attr.data-index]="i"
    >
      <app-announcement-item
        [announcement]="announcement"
        (click)="onAnnouncementClick(announcement, i)"
        appHaptics
      />
    </div>
    <div
      class="whats-new-section whats-new-section--bottom"
      [ngClass]="{
        'whats-new-section--hidden':
          announcements.totalCount <= announcements.count
      }"
    >
      + {{ announcements.totalCount - announcements.count }} more
    </div>

    <div
      class="whats-new-section"
      [ngClass]="{ 'whats-new-section--hidden': !surveys.count }"
    >
      Team Health
    </div>
    <div
      class="whats-new-item whats-new-item--survey"
      *ngFor="
        let survey of surveys.items;
        let i = index;
        trackBy: trackSurveysBy
      "
      [attr.data-index]="i"
    >
      <app-survey-item
        [titleHtml]="'Team Feedback'"
        (buttonClicked)="onSurveyClick(survey)"
      />
    </div>
    <div
      class="whats-new-section whats-new-section--bottom"
      [ngClass]="{
        'whats-new-section--hidden': surveys.totalCount <= surveys.count
      }"
    >
      + {{ surveys.totalCount - surveys.count }} more
    </div>
  </div>
</ion-content>
