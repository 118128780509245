// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_sendInstantFeedback = () => "/instant-feedback";

export type TSpecHandler_sendInstantFeedback = RequestHandler<TSpecPathSendInstantFeedback, any, TSpecBodySendInstantFeedback, TSpecQuerySendInstantFeedback, {}>;

export interface TSpecPathSendInstantFeedback {  }

export interface TSpecBodySendInstantFeedback {
  message: string;
  appUrl: string;
}

export interface TSpecQuerySendInstantFeedback {}
