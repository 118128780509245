import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit
} from '@angular/core';
import { TagApiService } from '@app/core/service/api/tag.api.service';
import { ToastService } from '@app/core/service/toast.service';
import { MongoStoredObject } from '@app/types/mongo-stored-object';
import { ITag } from '@backend/models/types/tag';
import {
  ActionSheetController,
  IonNav,
  LoadingController
} from '@ionic/angular';
import { UploadTagComponent } from '../../upload-tag.component';
import { UserModel } from '@app/core/model/user.model';
import { UserEntityService } from '@app/core/service/user-entity.service';
import { TenantService } from '@app/core/service/tenant.service';
import { combineLatest } from 'rxjs';
import { TagsDefinition } from '@backend/types/tags-definition';
import { ImageViewerService } from '@app/core/service/image-viewer.service';

@Component({
  selector: 'app-tag-view',
  templateUrl: './tag-view.component.html',
  styleUrls: ['./tag-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TagViewComponent implements OnInit {
  @Input()
  public tagId: string;

  protected server: Pick<UserModel, 'firstName' | 'lastName'>;
  protected tag: MongoStoredObject<
    ITag & {
      user: Pick<UserModel, 'firstName' | 'lastName'>;
      possiblePickupStatuses: string[];
    }
  > = null;
  protected isLoading = true;
  protected tagsSettings: TagsDefinition;

  public constructor(
    private readonly _tagApiService: TagApiService,
    private readonly _ionNav: IonNav,
    private readonly _loadingCtrl: LoadingController,
    private readonly _cdr: ChangeDetectorRef,
    private readonly _toastService: ToastService,
    private readonly _actionSheetCtrl: ActionSheetController,
    private readonly _userEntity: UserEntityService,
    private readonly _tenantService: TenantService,
    private readonly _imageViewerService: ImageViewerService
  ) {}

  public ngOnInit(): void {
    combineLatest([
      this._tenantService.tenant$,
      this._tagApiService.getTag({ path: { tagId: this.tagId } })
    ]).subscribe({
      next: ([tenant, res]) => {
        this.tagsSettings = tenant.settings.tags;

        this.tag = res.tag;
        this.server = res.server;

        this.isLoading = false;
        this._cdr.markForCheck();
      },
      error: (e) => {
        if (e.error?.message) {
          this._toastService.presentToast(e.error.message);
        }
        this._ionNav.pop();
      }
    });
  }

  protected onBackButtonClick() {
    this._ionNav.pop();
  }

  protected get images() {
    return this.tag.images || [];
  }

  protected viewPhoto(index: number) {
    this._imageViewerService.viewImage(
      this.images.map((image) => image.url),
      index
    );
  }

  protected async onStatusChange(event: any) {
    event.target.value = this.tag.pickupStatus;

    if (event.detail.value !== this.tag.pickupStatus) {
      let confirmQuestion = 'Are you sure you want to change the status?';

      switch (event.detail.value) {
        case this.tagsSettings.readyForPickupStatusName:
          confirmQuestion =
            'Are you sure you want to change the status and notify the customer?';
          break;
        case this.tagsSettings.completedStatusName:
          confirmQuestion =
            'Has the customer successfully collected their items?';
          break;
      }

      const actionSheet = await this._actionSheetCtrl.create({
        header: confirmQuestion,
        buttons: [
          {
            role: 'confirm',
            text: 'Yes'
          },
          {
            role: 'cancel',
            text: 'Cancel'
          }
        ]
      });
      actionSheet.present();

      const { role } = await actionSheet.onWillDismiss();

      if (role === 'confirm') {
        const loading = await this._loadingCtrl.create({
          message: 'Loading...'
        });
        loading.present();

        this._tagApiService
          .updateTagStatus({
            path: { tagId: this.tagId },
            body: { status: event.detail.value }
          })
          .subscribe((res) => {
            this.tag = {
              ...res.tag,
              possiblePickupStatuses: this.tag.possiblePickupStatuses
            };
            this._userEntity.update();
            loading.dismiss();

            this._toastService.presentToast('Status Changed Successfully!');
            this._cdr.markForCheck();
          });
      }
    }
  }

  protected editTag() {
    this._ionNav.push(UploadTagComponent, {
      input: {
        tag: this.tag
      }
    });
  }
}
