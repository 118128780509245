<ion-header class="ion-no-border">
  <ion-toolbar class="app-toolbar">
    <ion-buttons slot="start">
      <ion-button (click)="onBackButtonClick()" appHaptics>
        <ion-icon slot="icon-only" name="chevron-back-outline"></ion-icon>
        <ion-label>Back</ion-label>
      </ion-button>
    </ion-buttons>
    <ion-title *ngIf="libraryDoc" class="ion-text-capitalize">
      {{ libraryDoc.title.toLowerCase() }}
    </ion-title>
    <ion-buttons slot="end" *ngIf="isAdmin">
      <ion-button
        color="primary"
        (click)="onEditButtonClick($event)"
        appHaptics
      >
        Edit
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content color="light" [fullscreen]="true">
  <app-width-restricted-container class="layout-container">
    <app-content-title>
      {{ libraryDoc.title.toLowerCase() }}
    </app-content-title>

    <ion-grid *ngIf="isLoading">
      <ion-row class="ion-justify-content-center">
        <ion-spinner name="lines-sharp-small"></ion-spinner>
      </ion-row>
    </ion-grid>

    <div class="top-content" *ngIf="!isLoading">
      <ion-grid *ngIf="libraryDoc">
        <ion-row>
          <ion-col size="12">
            <app-wysiwyg-editor [(value)]="body" mode="view" />
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>
    <div class="bottom-content" *ngIf="!isLoading">
      <ion-grid>
        <ion-row class="ion-justify-content-center ion-padding">
          <ion-col size="8" *ngIf="!mySign; else signedText">
            <ion-button
              type="button"
              color="dark"
              expand="block"
              (click)="signLibraryDoc()"
              appHaptics
            >
              I Understand and Accept
            </ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>
  </app-width-restricted-container>
</ion-content>

<ng-template #signedText>
  <ion-col size="12">
    <div class="signed-text">
      Acknowledged and Accepted by
      <div *ngIf="isAdmin; else mySignedText">
        <div *ngFor="let user of signedUsers">
          {{ user.user.firstName }} {{ user.user.lastName }} on
          {{ user.date | date : 'M/d/yy h:mm a' }}
        </div>
      </div>
    </div>
  </ion-col>
</ng-template>

<ng-template #mySignedText>
  <div>
    {{ mySign.user.firstName }} {{ mySign.user.lastName }} on
    {{ mySign.date | date : 'M/d/yy h:mm a' }}
  </div>
</ng-template>

<ion-popover
  [isOpen]="isPopoverOpen"
  [dismissOnSelect]="true"
  (didDismiss)="onPopoverDismiss()"
  #popover
>
  <ng-template>
    <ion-list lines="none">
      <ion-item button (click)="editDoc()" appHaptics>
        <ion-label>Edit Document</ion-label>
      </ion-item>
      <ion-item button (click)="requestDocToSign()" appHaptics>
        <ion-label>Request Users to Read And Sign</ion-label>
      </ion-item>
    </ion-list>
  </ng-template>
</ion-popover>

<ion-modal
  #userSelectorModal
  [handle]="false"
  [breakpoints]="[0, 1]"
  [initialBreakpoint]="1"
>
  <ng-template>
    <app-user-selector
      class="ion-page"
      [users]="allUsers"
      [recipients]="[]"
      (selectionChange)="onUsersSelect($event)"
      (selectionCancel)="userSelectorModal.dismiss()"
      [multiple]="true"
      [hiddenUsers]="signedUsersIds"
      doneBtnText="Send"
    ></app-user-selector>
  </ng-template>
</ion-modal>
