// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_addSuggestionCategory = () => "/suggestion-categories";

export type TSpecHandler_addSuggestionCategory = RequestHandler<TSpecPathAddSuggestionCategory, any, TSpecBodyAddSuggestionCategory, TSpecQueryAddSuggestionCategory, {}>;

export interface TSpecPathAddSuggestionCategory {  }

export interface TSpecBodyAddSuggestionCategory {
  name: string;
  notifyUsersOnNewOpportunities?: string[];
  notifyUsersOnNewSolutions?: string[];
  notifyUsersOnOpportunityStatusChange?: string[];
}

export interface TSpecQueryAddSuggestionCategory {}
