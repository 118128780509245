import { Component, HostBinding, Input } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { MongoStoredObject } from '@app/types/mongo-stored-object';
import { ITag } from '@backend/models/types/tag';

@Component({
  selector: 'app-tag-item',
  templateUrl: './tag-item.component.html',
  styleUrls: ['./tag-item.component.scss']
})
export class TagItemComponent {
  @Input()
  public readonly tag: MongoStoredObject<ITag> & {
    goHighLevelPipelineStage?: string;
  };

  @Input()
  public readonly status: { name: string; color: string };

  @Input()
  public animationOrder?: number = -1;

  @Input()
  public disabled? = false;

  @HostBinding('style')
  get myStyle(): SafeStyle {
    return this._sanitizer.bypassSecurityTrustStyle(
      `--animation-order: ${this.animationOrder};`
    );
  }

  @HostBinding('class.animated')
  public get isAnimated() {
    return this.animationOrder !== -1;
  }

  @HostBinding('class.disabled')
  public get isDisabled() {
    return this.disabled;
  }

  public constructor(private readonly _sanitizer: DomSanitizer) {}
}
