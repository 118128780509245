import { Routes } from '@angular/router';
import { AuthenticationGuard } from '@app/core';
import { adminGuard } from '@app/core/authentication/admin.guard';
import { SubscriptionManagementComponent } from './dashboard/subscription-management/subscription-management.component';
import { AnnouncementsComponent } from './dashboard/announcements/announcements.component';
import { inject } from '@angular/core';
import { ChecklistComponent } from './dashboard/checklist/checklist.component';
import { NavBaseComponent } from '../modules/nav-base/nav-base.component';
import { InsightsComponent } from './dashboard/insights/insights.component';
import { superAdminGuard } from '@app/core/authentication/super-admin.guard';
import { AnnouncementViewComponent } from './dashboard/announcements/announcement-view/announcement-view.component';

export const DashboardRoutes: Routes = [
  {
    path: '',
    canActivate: [() => inject(AuthenticationGuard).canActivate()],
    children: [
      {
        path: 'default',
        loadChildren: () =>
          import('../pages/default/default.module').then((m) => m.DefaultModule)
      },
      {
        path: 'recognition',
        loadChildren: () =>
          import('../pages/recognition/recognition.module').then(
            (m) => m.RecognitionModule
          )
      },
      {
        path: 'view-recognition',
        loadChildren: () =>
          import('../pages/view-recognition/view-recognition.module').then(
            (m) => m.ViewRecognitionModule
          )
      },
      {
        path: 'users',
        loadChildren: () =>
          import('../pages/users/users.module').then((m) => m.UsersModule)
      },
      {
        path: 'insights',
        component: NavBaseComponent,
        data: {
          rootComponent: InsightsComponent,
          title: 'Insights'
        }
      },
      {
        path: 'psychological-survey',
        loadChildren: () =>
          import(
            '../pages/psychological-survey/psychological-survey.module'
          ).then((m) => m.PsychologicalSurveyModule)
      },
      {
        path: 'psychological-survey-feedback',
        loadChildren: () =>
          import(
            '../pages/psychological-survey-feedback/psychological-survey-feedback.module'
          ).then((m) => m.PsychologicalSurveyFeedbackModule)
      },
      {
        path: 'profile',
        loadChildren: () =>
          import('../pages/profile/profile.module').then((m) => m.ProfileModule)
      },
      {
        path: 'announcements',
        component: NavBaseComponent,
        data: {
          title: 'Announcements',
          rootComponent: AnnouncementsComponent
        }
      },
      {
        path: 'announcements/view/:id',
        component: NavBaseComponent,
        data: {
          title: 'Announcement',
          rootComponent: AnnouncementViewComponent
        }
      },
      {
        path: 'subscription-management',
        canActivate: [adminGuard],
        component: SubscriptionManagementComponent
      },
      {
        path: 'checklist',
        component: NavBaseComponent,
        data: {
          title: 'Checklist',
          rootComponent: ChecklistComponent
        }
      },
      {
        path: 'approvals',
        canMatch: [adminGuard],
        loadChildren: () =>
          import('../pages/approvals/approvals.module').then(
            (m) => m.ApprovalsModule
          )
      },
      {
        path: 'tenants',
        canMatch: [superAdminGuard],
        loadChildren: () =>
          import('../pages/tenants/tenants.module').then((m) => m.TenantsModule)
      },
      {
        path: 'global-settings',
        canMatch: [superAdminGuard],
        loadChildren: () =>
          import('../pages/global-settings/global-settings.module').then(
            (m) => m.GlobalSettingsModule
          )
      },
      {
        path: 'backdoor',
        canMatch: [superAdminGuard],
        loadChildren: () =>
          import('../pages/backdoor/backdoor.module').then(
            (m) => m.BackdoorModule
          )
      },
      {
        path: 'connected-accounts',
        canMatch: [superAdminGuard],
        loadChildren: () =>
          import('../pages/connected-accounts/connected-accounts.module').then(
            (m) => m.ConnectedAccountsModule
          )
      },
      {
        path: 'instant-feedback',
        canMatch: [superAdminGuard],
        loadChildren: () =>
          import('../pages/instant-feedback/instant-feedback.module').then(
            (m) => m.InstantFeedbackModule
          )
      },
      {
        path: 'team-settings',
        canMatch: [adminGuard],
        loadChildren: () =>
          import('../pages/team-settings/team-settings.module').then(
            (m) => m.TeamSettingsModule
          )
      },
      {
        path: 'tags',
        loadChildren: () =>
          import('../pages/upload-tag/upload-tag.module').then(
            (m) => m.UploadTagModule
          )
      },
      {
        path: 'library',
        loadChildren: () =>
          import('../pages/library/library.module').then((m) => m.LibraryModule)
      },
      {
        path: 'suggestions',
        loadChildren: () =>
          import('../pages/suggestions/suggestions.module').then(
            (m) => m.SuggestionsModule
          )
      },
      {
        path: 'suggestions/view/:id',
        loadChildren: () =>
          import('../pages/suggestions/suggestions.module').then(
            (m) => m.SuggestionsModule
          )
      },
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'default'
      }
    ]
  }
];
