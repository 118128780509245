import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { OpportunityModel, SolutionModel } from '../../model/suggestion.model';
import {
  TSpecBodyPostOpportunity,
  TSpecRoute_postOpportunity
} from '@backend/types/generated/Suggestions/postOpportunity';
import {
  TSpecQueryGetAllOpportunities,
  TSpecRoute_getAllOpportunities
} from '@backend/types/generated/Suggestions/getAllOpportunities';
import {
  TSpecQueryGetPublicOpportunities,
  TSpecRoute_getPublicOpportunities
} from '@backend/types/generated/Suggestions/getPublicOpportunities';
import {
  TSpecBodyUpdateOpportunity,
  TSpecPathUpdateOpportunity,
  TSpecRoute_updateOpportunity
} from '@backend/types/generated/Suggestions/updateOpportunity';
import {
  TSpecBodyPostSolution,
  TSpecPathPostSolution,
  TSpecRoute_postSolution
} from '@backend/types/generated/Suggestions/postSolution';
import {
  TSpecBodyUpdateSolution,
  TSpecPathUpdateSolution,
  TSpecRoute_updateSolution
} from '@backend/types/generated/Suggestions/updateSolution';
import {
  TSpecPathGetPublicSolutionsOfOpportunity,
  TSpecRoute_getPublicSolutionsOfOpportunity
} from '@backend/types/generated/Suggestions/getPublicSolutionsOfOpportunity';
import {
  TSpecPathGetAllSolutionsOfOpportunity,
  TSpecRoute_getAllSolutionsOfOpportunity
} from '@backend/types/generated/Suggestions/getAllSolutionsOfOpportunity';
import {
  TSpecPathDeleteOpportunity,
  TSpecRoute_deleteOpportunity
} from '@backend/types/generated/Suggestions/deleteOpportunity';
import {
  TSpecPathDeleteSolution,
  TSpecRoute_deleteSolution
} from '@backend/types/generated/Suggestions/deleteSolution';
import {
  TSpecPathGetOpportunityById,
  TSpecRoute_getOpportunityById
} from '@backend/types/generated/Suggestions/getOpportunityById';
import {
  TSpecPathReadOpportunity,
  TSpecRoute_readOpportunity
} from '@backend/types/generated/Suggestions/readOpportunity';

@Injectable()
export class SuggestionApiService {
  public constructor(private readonly _httpClient: HttpClient) {}

  public getPublicOpportunities(params: {
    query: TSpecQueryGetPublicOpportunities;
  }): Observable<OpportunityModel[]> {
    return this._httpClient.get<OpportunityModel[]>(
      TSpecRoute_getPublicOpportunities(),
      { params: { ...params.query } }
    );
  }

  public getAllOpportunities(params: {
    query: TSpecQueryGetAllOpportunities;
  }): Observable<OpportunityModel[]> {
    return this._httpClient.get<OpportunityModel[]>(
      TSpecRoute_getAllOpportunities(),
      { params: { ...params.query } }
    );
  }

  public postOpportunity(params: {
    body: TSpecBodyPostOpportunity;
  }): Observable<OpportunityModel> {
    return this._httpClient.post<OpportunityModel>(
      TSpecRoute_postOpportunity(),
      params.body
    );
  }

  public readOpportunity(params: {
    path: TSpecPathReadOpportunity;
  }): Observable<null> {
    return this._httpClient.post<null>(
      TSpecRoute_readOpportunity(params.path),
      {}
    );
  }

  public getOpportunityById(params: {
    path: TSpecPathGetOpportunityById;
  }): Observable<OpportunityModel> {
    return this._httpClient.get<OpportunityModel>(
      TSpecRoute_getOpportunityById(params.path)
    );
  }

  public updateOpportunity(params: {
    path: TSpecPathUpdateOpportunity;
    body: TSpecBodyUpdateOpportunity;
  }): Observable<OpportunityModel> {
    return this._httpClient.put<OpportunityModel>(
      TSpecRoute_updateOpportunity(params.path),
      params.body
    );
  }

  public postSolution(params: {
    path: TSpecPathPostSolution;
    body: TSpecBodyPostSolution;
  }): Observable<OpportunityModel> {
    return this._httpClient.post<OpportunityModel>(
      TSpecRoute_postSolution(params.path),
      params.body
    );
  }

  public updateSolution(params: {
    path: TSpecPathUpdateSolution;
    body: TSpecBodyUpdateSolution;
  }): Observable<OpportunityModel> {
    return this._httpClient.put<OpportunityModel>(
      TSpecRoute_updateSolution(params.path),
      params.body
    );
  }

  public getPublicSolutionsOfOpportunity(params: {
    path: TSpecPathGetPublicSolutionsOfOpportunity;
  }): Observable<SolutionModel[]> {
    return this._httpClient.get<SolutionModel[]>(
      TSpecRoute_getPublicSolutionsOfOpportunity(params.path)
    );
  }

  public getAllSolutionsOfOpportunity(params: {
    path: TSpecPathGetAllSolutionsOfOpportunity;
  }): Observable<SolutionModel[]> {
    return this._httpClient.get<SolutionModel[]>(
      TSpecRoute_getAllSolutionsOfOpportunity(params.path)
    );
  }

  public deleteOpportunity(params: {
    path: TSpecPathDeleteOpportunity;
  }): Observable<null> {
    return this._httpClient.delete<null>(
      TSpecRoute_deleteOpportunity(params.path)
    );
  }

  public deleteSolution(params: {
    path: TSpecPathDeleteSolution;
  }): Observable<null> {
    return this._httpClient.delete<null>(
      TSpecRoute_deleteSolution(params.path)
    );
  }
}
