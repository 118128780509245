import { Injectable } from '@angular/core';
import { map, merge, ReplaySubject, switchMap, take, tap, timer } from 'rxjs';
import { repeat, share, Subject } from 'rxjs';
import { MongoStoredObject } from '../../types/mongo-stored-object';
import { ObjectId } from '../../types/object-id';
import { TasksApiService } from './api/tasks.api.service';
import { TaskDto } from '@app/types/task-dto';
import { ESkillMatrixStatus } from '@backend/models/types/task';
import { TSpecBodyCreateTask } from '@backend/types/generated/Tasks/createTask';
import { TSpecBodyPatchTask } from '@backend/types/generated/Tasks/patchTask';

@Injectable({ providedIn: 'root' })
export class TaskListService {
  private _listRefresher$ = new Subject<void>();
  private _listSource$ = new Subject<MongoStoredObject<TaskDto>[]>();

  public list$ = merge(
    this._tasksApiService.getTasks().pipe(
      repeat({
        delay: () => this._listRefresher$
      })
    ),
    this._listSource$
  ).pipe(
    share({
      connector: () => new ReplaySubject(1),
      resetOnRefCountZero: () => timer(1000)
    })
  );

  public constructor(private readonly _tasksApiService: TasksApiService) {}

  public update(): void {
    this._listRefresher$.next();
  }

  public getTaskById(taskId: string) {
    return this._tasksApiService.getTaskById({ path: { id: taskId } }).pipe(
      switchMap((taskSaved) =>
        this.list$.pipe(
          take(1),
          map((list) => list.map((t) => (t._id !== taskId ? t : taskSaved))),
          tap((list) => {
            this._listSource$.next(list);
          }),
          map(() => taskSaved)
        )
      )
    );
  }

  public createTask(body: TSpecBodyCreateTask) {
    return this._tasksApiService.createTask({ body }).pipe(
      switchMap((task) =>
        this.list$.pipe(
          take(1),
          map((list) => [...list, task]),
          tap((list) => {
            this._listSource$.next(list);
          }),
          map(() => task)
        )
      )
    );
  }

  public patchTask(taskId: ObjectId, body: TSpecBodyPatchTask) {
    return this._tasksApiService.patchTask({ path: { id: taskId }, body }).pipe(
      switchMap((taskSaved) =>
        this.list$.pipe(
          take(1),
          map((list) => list.map((t) => (t._id !== taskId ? t : taskSaved))),
          tap((list) => {
            this._listSource$.next(list);
          }),
          map(() => taskSaved)
        )
      )
    );
  }

  public deleteTask(taskId: ObjectId) {
    this._tasksApiService.deleteTask({ path: { id: taskId } }).subscribe({
      next: () => {
        this.update();
      }
    });
  }

  public restoreTask(taskId: ObjectId) {
    this._tasksApiService.restoreTask({ path: { id: taskId } }).subscribe({
      next: () => {
        this.update();
      }
    });
  }

  public reorderTasksMultiple(
    taskFolders: {
      taskFolder: string | null;
      tasks: { taskId: string; orderIndex: number }[];
    }[]
  ): void {
    this._tasksApiService
      .reorderTasksMultiple({ body: taskFolders })
      .subscribe({
        next: (list) => {
          this._listSource$.next(list);
        }
      });
  }

  public refoldTasksMultiple(
    taskFolders: { taskFolder: string | null; tasks: string[] }[]
  ): void {
    this._tasksApiService.refoldTasksMultiple({ body: taskFolders }).subscribe({
      next: (list) => {
        this._listSource$.next(list);
      }
    });
  }

  public patchTasks(
    userId: ObjectId,
    tasks: {
      _id: ObjectId;
      isNotificationUponCompletion?: boolean;
      skillMatrixStatus?: ESkillMatrixStatus;
    }[]
  ): void {
    this._tasksApiService.patchTasks({ body: { userId, tasks } }).subscribe({
      next: (list) => {
        this._listSource$.next(list);
      }
    });
  }
}
