import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NotFoundComponent } from './not-found.component';
import { IonicModule } from '@ionic/angular';
import { DirectivesModule } from '@app/modules/directives.module';
import { NavBaseComponent } from '@app/modules/nav-base/nav-base.component';
import { FullHeightContainerModule } from '../../modules/full-height-container/full-height-container.module';
import { ContentTitleModule } from '../../modules/content-title/content-title.module';

const ROUTES: Routes = [
  {
    path: '',
    component: NavBaseComponent,
    data: {
      title: 'Not Found',
      rootComponent: NotFoundComponent
    }
  }
];

@NgModule({
  imports: [
    NavBaseComponent,
    RouterModule.forChild(ROUTES),
    IonicModule,
    DirectivesModule,
    FullHeightContainerModule,
    ContentTitleModule
  ],
  declarations: [NotFoundComponent]
})
export class NotFoundModule {}
