import { Component, ViewChild } from '@angular/core';
import { UserModel } from '@app/core/model/user.model';
import { TenantService } from '@app/core/service/tenant.service';
import { UserListService } from '@app/core/service/user-list.service';
import { ObjectId } from '@app/types/object-id';
import { showUserSelectorModal } from '@app/utils/modal';
import {
  ITenantEmailFlags,
  ITenantPushNotificationsFlags
} from '@backend/models/types/tenant';
import { UserRoles } from '@backend/models/types/user';
import { TSpecBodyPatchTenant } from '@backend/types/generated/Tenants/patchTenant';
import { RecognitionDefinition } from '@backend/types/recognition-definition';
import {
  IonLoading,
  IonNav,
  ModalController,
  PickerButton,
  PickerColumn
} from '@ionic/angular';
import { map, take } from 'rxjs';

@Component({
  selector: 'app-recognition-settings',
  templateUrl: './recognition-settings.component.html',
  styleUrls: ['./recognition-settings.component.scss']
})
export class RecognitionSettingsComponent {
  @ViewChild('loading', { static: true })
  public loading!: IonLoading;

  protected allUsers: UserModel[] = [];
  protected pnFlags: ITenantPushNotificationsFlags = {
    isAdminsReceiveNotificationsWhenAnyUserIsRecognized: false,
    isManagersReceiveNotificationsWhenAnyUserIsRecognized: false,
    isUsersReceiveNotificationsForAnnouncements: false
  };
  protected emailFlags: ITenantEmailFlags = {
    isSendingSupervisorEmailsRegardingLedger: false,
    isSendingLedgerEmailsEnabled: false,
    isSendingDailySurveyEmailsEnabled: false
  };
  protected recognitionSettings: Omit<
    RecognitionDefinition,
    'encourageManagersAndAdmins' | 'encourageNormalUsers'
  > & {
    encourageManagersAndAdmins: ObjectId[];
    encourageNormalUsers: ObjectId[];
  };
  protected encourageManagersAndAdminsNumberOfDaysPickerColumns: PickerColumn[] =
    [];
  protected encourageNormalUsersNumberOfDaysPickerColumns: PickerColumn[] = [];
  protected encourageManagersAndAdminsNumberOfDaysPickerButtons: PickerButton[] =
    [
      {
        text: 'Cancel',
        role: 'cancel'
      },
      {
        text: 'Confirm',
        handler: (value: { numberOfDays: { text: string; value: number } }) => {
          this.recognitionSettings.encourageManagersAndAdminsNumberOfDays =
            value.numberOfDays.value;
        }
      }
    ];
  protected encourageNormalUsersNumberOfDaysPickerButtons: PickerButton[] = [
    {
      text: 'Cancel',
      role: 'cancel'
    },
    {
      text: 'Confirm',
      handler: (value: { numberOfDays: { text: string; value: number } }) => {
        this.recognitionSettings.encourageNormalUsersNumberOfDays =
          value.numberOfDays.value;
      }
    }
  ];
  protected numberOfDaysPickerOptions: {
    text: string;
    value: number;
  }[] = [];

  public constructor(
    private readonly _ionNav: IonNav,
    private readonly _tenantService: TenantService,
    private readonly _userListService: UserListService,
    private readonly _modalCtrl: ModalController
  ) {
    this._userListService.users$
      .pipe(map((list) => list.filter(({ isDeleted }) => !isDeleted)))
      .subscribe((allUsers) => {
        this.allUsers = allUsers;
      });

    this.numberOfDaysPickerOptions = new Array(60).fill(0).map((_, index) => ({
      value: index + 1,
      text: `${index + 1} ${index === 0 ? 'day' : 'days'}`
    }));

    this._tenantService.tenant$.subscribe((tenant) => {
      if (this.loading) {
        this.loading.dismiss();
      }

      this.pnFlags = tenant.pnFlags;
      this.emailFlags = tenant.emailFlags;
      this.recognitionSettings = {
        ...tenant.settings.recognition,
        encourageManagersAndAdmins: tenant.settings.recognition
          .encourageManagersAndAdmins as unknown as ObjectId[],
        encourageNormalUsers: tenant.settings.recognition
          .encourageNormalUsers as unknown as ObjectId[]
      };
      this.encourageManagersAndAdminsNumberOfDaysPickerColumns = [
        {
          name: 'numberOfDays',
          options: this.numberOfDaysPickerOptions,
          selectedIndex: this.numberOfDaysPickerOptions.reduce<number>(
            (prev, option, index) =>
              tenant.settings.recognition
                .encourageManagersAndAdminsNumberOfDays === option.value
                ? index
                : prev,
            7
          )
        }
      ];
      this.encourageNormalUsersNumberOfDaysPickerColumns = [
        {
          name: 'numberOfDays',
          options: this.numberOfDaysPickerOptions,
          selectedIndex: this.numberOfDaysPickerOptions.reduce<number>(
            (prev, option, index) =>
              tenant.settings.recognition.encourageNormalUsersNumberOfDays ===
              option.value
                ? index
                : prev,
            7
          )
        }
      ];
    });
  }

  protected onBackButtonClick() {
    this._ionNav.pop();
  }

  private _patch(patch: TSpecBodyPatchTenant): void {
    this.loading.present();
    this._tenantService.patch(patch);
  }

  protected onEncourageManagersAndAdminsToRecognizeSomeoneChange(event: any) {
    if (event && event.detail) {
      this.recognitionSettings.encourageManagersAndAdminsToRecognizeSomeone =
        event.detail.checked;
      this._patch({
        settings: {
          recognition: {
            ...this.recognitionSettings,
            encourageManagersAndAdminsToRecognizeSomeone: event.detail.checked
          }
        }
      });
    }
  }

  protected onEncourageManagersAndAdminsPNChange(event: any) {
    if (event && event.detail) {
      this.recognitionSettings.encourageManagersAndAdminsPN =
        event.detail.checked;
      this._patch({
        settings: {
          recognition: {
            ...this.recognitionSettings,
            encourageManagersAndAdminsPN: event.detail.checked
          }
        }
      });
    }
  }

  protected onEncourageManagersAndAdminsTimeChange(event: any) {
    if (event && event.detail) {
      this.recognitionSettings.encourageManagersAndAdminsTime =
        event.detail.checked;
    }
  }

  protected onEncourageNormalUsersToRecognizeSomeoneChange(event: any) {
    if (event && event.detail) {
      this.recognitionSettings.encourageNormalUsersToRecognizeSomeone =
        event.detail.checked;
      this._patch({
        settings: {
          recognition: {
            ...this.recognitionSettings,
            encourageNormalUsersToRecognizeSomeone: event.detail.checked
          }
        }
      });
    }
  }

  protected onEncourageNormalUsersPNChange(event: any) {
    if (event && event.detail) {
      this.recognitionSettings.encourageNormalUsersPN = event.detail.checked;
      this._patch({
        settings: {
          recognition: {
            ...this.recognitionSettings,
            encourageNormalUsersPN: event.detail.checked
          }
        }
      });
    }
  }

  protected onEncourageNormalUsersTimeChange(event: any) {
    if (event && event.detail) {
      this.recognitionSettings.encourageNormalUsersTime = event.detail.checked;
    }
  }

  protected onPnFlagChange(
    event: any,
    pnFlag: keyof ITenantPushNotificationsFlags
  ) {
    if (event && event.detail) {
      this.pnFlags[pnFlag] = event.detail.checked;
      this._patch({
        pnFlags: {
          ...this.pnFlags,
          [pnFlag]: event.detail.checked
        }
      });
    }
  }

  protected onEmailFlagChange(event: any, emailFlag: keyof ITenantEmailFlags) {
    if (event && event.detail) {
      this.emailFlags[emailFlag] = event.detail.checked;
      this._patch({
        emailFlags: {
          ...this.emailFlags,
          [emailFlag]: event.detail.checked
        }
      });
    }
  }

  protected async onSelectEncourageManagersAndAdminsClick() {
    const onlyManagersAndAdmins = this.allUsers.filter(
      ({ roles }) =>
        roles.includes(UserRoles.Manager) || roles.includes(UserRoles.Admin)
    );

    const { success, selectedUsers } = await showUserSelectorModal(
      {
        users: onlyManagersAndAdmins,
        recipients: this.recognitionSettings.encourageManagersAndAdmins,
        multiple: true,
        title: 'Managers And Admins'
      },
      this._modalCtrl
    );

    if (success) {
      this.recognitionSettings.encourageManagersAndAdmins = selectedUsers;
    }
  }

  protected async onSelectNormalUsersClick() {
    const onlyNormalUsers = this.allUsers.filter(
      ({ roles }) =>
        !roles.includes(UserRoles.Manager) && !roles.includes(UserRoles.Admin)
    );

    const { success, selectedUsers } = await showUserSelectorModal(
      {
        users: onlyNormalUsers,
        recipients: this.recognitionSettings.encourageNormalUsers,
        multiple: true,
        title: 'Normal Users'
      },
      this._modalCtrl
    );

    if (success) {
      this.recognitionSettings.encourageNormalUsers = selectedUsers;
    }
  }
}
