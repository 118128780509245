<ion-item
  *ngIf="isItem; else button"
  button
  (click)="onTriggerClick($event)"
  [disabled]="disabled"
  appHaptics
  [disableHaptics]="disabled"
  [lines]="itemLines ? undefined : 'none'"
>
  <ion-label>{{ label }}</ion-label>
  <ion-note slot="end">
    {{
      value | transformerApplicator : dateTransformer | date : 'EEEE, MMM d, y'
    }}
  </ion-note>
</ion-item>

<ng-template #button>
  <ion-button
    color="light"
    expand="block"
    (click)="onTriggerClick($event)"
    [disabled]="disabled"
    appHaptics
    [disableHaptics]="disabled"
    class="ion-no-margin"
  >
    <div class="button-content-wrapper">
      <div class="label">{{ label }}</div>
      <div class="time">
        {{
          value
            | transformerApplicator : dateTransformer
            | date : 'EEEE, MMM d, y'
        }}
      </div>
    </div>
  </ion-button>
</ng-template>

<ion-popover
  [keepContentsMounted]="true"
  [isOpen]="isOpen"
  (didDismiss)="isOpen = false"
  cssClass="popover-datetime"
  #popover
>
  <ng-template>
    <ion-datetime
      (ionChange)="onDateChange($any($event))"
      presentation="date"
      [min]="min"
      [max]="max"
      [isDateEnabled]="isDateEnabled"
    />
  </ng-template>
</ion-popover>
