import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MongoStoredObject } from '@app/types/mongo-stored-object';
import { ModalController, IonModal } from '@ionic/angular';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import {
  ECompensationFieldType,
  ICompensationField
} from '@backend/models/types/compensation-field';
import fastDeepEqual from 'fast-deep-equal';
import { ModalClass } from '@app/core/class/modal.class';

@Component({
  selector: 'app-compensation-field-modal',
  templateUrl: './compensation-field-modal.component.html'
})
export class CompensationFieldModalComponent
  extends ModalClass
  implements OnInit
{
  @Input()
  public readonly compensationField: MongoStoredObject<ICompensationField> =
    null;

  @ViewChild('userSelectorModal', { static: true })
  public userSelectorModal!: IonModal;

  protected form = new FormGroup({
    name: new FormControl<string>('', Validators.required),
    type: new FormControl<ECompensationFieldType>(
      ECompensationFieldType.HOURS,
      Validators.required
    )
  });

  public constructor(private readonly _modalCtrl: ModalController) {
    super();
  }

  public ngOnInit(): void {
    if (this.compensationField) {
      this.form.reset({
        name: this.compensationField.name,
        type: this.compensationField.type
      });
    }
  }

  protected get canSave() {
    if (this.form.invalid) return false;

    if (this.compensationField) {
      return !fastDeepEqual(
        {
          name: this.compensationField.name,
          type: this.compensationField.type
        },
        this.form.value
      );
    }

    return true;
  }

  protected onCloseClick(): void {
    this._modalCtrl.dismiss(null, 'cancel', this._modalName);
  }

  protected save(): void {
    this._modalCtrl.dismiss(this.form.value, 'confirm', this._modalName);
  }

  protected delete(): void {
    this._modalCtrl.dismiss(this.form.value, 'delete', this._modalName);
  }
}
