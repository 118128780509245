import { NgModule } from '@angular/core';
import { InstantFeedbackButtonComponent } from './instant-feedback-button.component';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { InstantFeedbackApiService } from '@app/core/service/api/instantFeedback.api.service';
import { DirectivesModule } from '../directives.module';

@NgModule({
  imports: [CommonModule, IonicModule, DirectivesModule],
  exports: [InstantFeedbackButtonComponent],
  declarations: [InstantFeedbackButtonComponent],
  providers: [InstantFeedbackApiService]
})
export class InstantFeedbackButtonModule {}
