// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_updateCompensationField = (path: TSpecPathUpdateCompensationField) => {
  let res = "/compensation/fields/:compensationFieldId";
  Object.entries(path).map(([key, value]) => {
    res = res.replace(':' + key, value);
  });
  return res;
};

export type TSpecHandler_updateCompensationField = RequestHandler<TSpecPathUpdateCompensationField, any, TSpecBodyUpdateCompensationField, TSpecQueryUpdateCompensationField, {}>;

export interface TSpecPathUpdateCompensationField { compensationFieldId: string; }

export interface TSpecBodyUpdateCompensationField {
  name: string;
}

export interface TSpecQueryUpdateCompensationField {}
