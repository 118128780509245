import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthToken } from '../../model/authentication.model';
import { UserModel } from '../../model/user.model';
import { MongoStoredObject } from '@app/types/mongo-stored-object';
import { ConnectedAccountModel } from '@app/core/model/connected-account-model';
import {
  TSpecBodyLoginToConnectedAccount,
  TSpecRoute_loginToConnectedAccount
} from '@backend/types/generated/ConnectedAccounts/loginToConnectedAccount';
import {
  TSpecQueryRemoveMyConnectedAccount,
  TSpecRoute_removeMyConnectedAccount
} from '@backend/types/generated/ConnectedAccounts/removeMyConnectedAccount';
import { TSpecRoute_getConnectedAccountsGroups } from '@backend/types/generated/ConnectedAccounts/getConnectedAccountsGroups';
import { TSpecRoute_getMyConnectedAccounts } from '@backend/types/generated/ConnectedAccounts/getMyConnectedAccounts';
import {
  TSpecQueryRemoveConnectedAccountFromGroup,
  TSpecRoute_removeConnectedAccountFromGroup
} from '@backend/types/generated/ConnectedAccounts/removeConnectedAccountFromGroup';

@Injectable({ providedIn: 'root' })
export class ConnectedAccountsApiService {
  public constructor(private readonly _httpClient: HttpClient) {}

  public getMyConnectedAccounts() {
    return this._httpClient.get<ConnectedAccountModel[]>(
      TSpecRoute_getMyConnectedAccounts()
    );
  }

  public loginToConnectedAccount(params: {
    body: TSpecBodyLoginToConnectedAccount;
  }) {
    return this._httpClient.post<{
      authToken: AuthToken;
      user: UserModel;
    }>(TSpecRoute_loginToConnectedAccount(), params.body);
  }

  public removeMyConnectedAccount(params: {
    query: TSpecQueryRemoveMyConnectedAccount;
  }) {
    return this._httpClient.delete<ConnectedAccountModel[]>(
      TSpecRoute_removeMyConnectedAccount(),
      { params: { ...params.query } }
    );
  }

  public getConnectedAccountsGroups() {
    return this._httpClient.get<
      MongoStoredObject<{ accounts: ConnectedAccountModel[] }>[]
    >(TSpecRoute_getConnectedAccountsGroups());
  }

  public removeConnectedAccountFromGroup(params: {
    query: TSpecQueryRemoveConnectedAccountFromGroup;
  }) {
    return this._httpClient.delete<
      MongoStoredObject<{ accounts: ConnectedAccountModel[] }>[]
    >(TSpecRoute_removeConnectedAccountFromGroup(), {
      params: { ...params.query }
    });
  }
}
