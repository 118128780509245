<ion-header class="ion-no-border">
  <ion-toolbar class="app-common-background">
    <ion-title class="ion-text-capitalize"> Change Email </ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="onCloseClick()" appHaptics>
        <ion-icon slot="icon-only" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<div>
  <ion-grid [formGroup]="form">
    <ion-row>
      <ion-col size="12">
        <ion-input
          label="New Email"
          class="app-w100 input-with-background"
          labelPlacement="floating"
          fill="solid"
          formControlName="email"
          type="email"
          email
        />
        <ion-note color="danger" *ngIf="emailError" class="error-note">
          {{ emailError }}
        </ion-note>
      </ion-col>
    </ion-row>

    <ion-row class="ion-justify-content-center ion-padding">
      <ion-col size="4">
        <ion-button
          type="submit"
          color="dark"
          expand="block"
          (click)="onSaveClick()"
          appHaptics
          [disabled]="!canSave"
          [disableHaptics]="!canSave"
        >
          Save
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</div>
