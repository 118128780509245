import { NgModule } from '@angular/core';
import { TimezoneSelectorModalComponent } from './timezone-selector-modal.component';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DirectivesModule } from '@app/modules/directives.module';
import { FullHeightContainerModule } from '@app/modules/full-height-container/full-height-container.module';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { FilterTimezonesPipe } from './filter-timezones.pipe';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    ReactiveFormsModule,
    DirectivesModule,
    FullHeightContainerModule,
    ScrollingModule,
    FilterTimezonesPipe
  ],
  exports: [TimezoneSelectorModalComponent],
  declarations: [TimezoneSelectorModalComponent]
})
export class TimezoneSelectorModalModule {}
