// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_removeConnectedAccountFromGroup = () => "/connected-accounts/groups";

export type TSpecHandler_removeConnectedAccountFromGroup = RequestHandler<TSpecPathRemoveConnectedAccountFromGroup, any, TSpecBodyRemoveConnectedAccountFromGroup, TSpecQueryRemoveConnectedAccountFromGroup, {}>;

export interface TSpecPathRemoveConnectedAccountFromGroup {  }

export interface TSpecBodyRemoveConnectedAccountFromGroup {}

export interface TSpecQueryRemoveConnectedAccountFromGroup {
  groupId: string;
  userId: string;
}
