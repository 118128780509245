// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_getIntegrations = () => "/integrations";

export type TSpecHandler_getIntegrations = RequestHandler<TSpecPathGetIntegrations, any, TSpecBodyGetIntegrations, TSpecQueryGetIntegrations, {}>;

export interface TSpecPathGetIntegrations {  }

export interface TSpecBodyGetIntegrations {}

export interface TSpecQueryGetIntegrations {
  tenantId?: string;
}
