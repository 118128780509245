import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-list-post-line',
  templateUrl: './list-post-line.component.html',
  styleUrls: ['./list-post-line.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ListPostLineComponent {
  @Input()
  public readonly text: string = '';

  @Input()
  public readonly color?: string;
}
