<ion-header class="ion-no-border">
  <ion-toolbar class="app-toolbar">
    <ion-buttons slot="start">
      <ion-button (click)="onBackButtonClick()" appHaptics>
        <ion-icon slot="icon-only" name="chevron-back-outline" />
        <ion-label>Back</ion-label>
      </ion-button>
    </ion-buttons>
    <ion-title class="ion-text-capitalize"> Recognition </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content color="light">
  <app-width-restricted-container class="container-with-fab">
    <app-list-pre-line title="Push Notifications" />
    <ion-list [inset]="true">
      <ion-item>
        <ion-toggle
          appHaptics
          [(ngModel)]="
            pnFlags.isAdminsReceiveNotificationsWhenAnyUserIsRecognized
          "
          (ionChange)="
            onPnFlagChange(
              $event,
              'isAdminsReceiveNotificationsWhenAnyUserIsRecognized'
            )
          "
        >
          <ion-label>
            Admins receive notifications when any user is recognized
          </ion-label>
        </ion-toggle>
      </ion-item>
      <ion-item>
        <ion-toggle
          appHaptics
          [(ngModel)]="
            pnFlags.isManagersReceiveNotificationsWhenAnyUserIsRecognized
          "
          (ionChange)="
            onPnFlagChange(
              $event,
              'isManagersReceiveNotificationsWhenAnyUserIsRecognized'
            )
          "
        >
          <ion-label>
            Managers receive notifications when any user is recognized
          </ion-label>
        </ion-toggle>
      </ion-item>
    </ion-list>

    <app-list-pre-line title="Emails" />
    <ion-list [inset]="true">
      <ion-item>
        <ion-toggle
          appHaptics
          [(ngModel)]="emailFlags.isSendingLedgerEmailsEnabled"
          (ionChange)="
            onEmailFlagChange($event, 'isSendingLedgerEmailsEnabled')
          "
        >
          <ion-label> Users receive emails when they are recognized </ion-label>
        </ion-toggle>
      </ion-item>
      <ion-item>
        <ion-toggle
          appHaptics
          [(ngModel)]="emailFlags.isSendingSupervisorEmailsRegardingLedger"
          (ionChange)="
            onEmailFlagChange(
              $event,
              'isSendingSupervisorEmailsRegardingLedger'
            )
          "
        >
          <ion-label>
            Supervisors receive emails when their supervisee is recognized or
            their supervisee recognizes someone
          </ion-label>
        </ion-toggle>
      </ion-item>
    </ion-list>

    <app-list-pre-line title="Managers and Administrators" />
    <ion-list [inset]="true">
      <ion-item>
        <ion-toggle
          appHaptics
          [(ngModel)]="
            recognitionSettings.encourageManagersAndAdminsToRecognizeSomeone
          "
          (ionChange)="
            onEncourageManagersAndAdminsToRecognizeSomeoneChange($event)
          "
        >
          <ion-label>
            Encourage Managers and Administrators at next login to recognize
            someone
          </ion-label>
        </ion-toggle>
      </ion-item>
      <ion-item
        button
        id="encourageManagersAndAdminsNumberOfDays"
        appHaptics
        [disabled]="
          recognitionSettings.encourageManagersAndAdminsToRecognizeSomeone
        "
        [disableHaptics]="
          recognitionSettings.encourageManagersAndAdminsToRecognizeSomeone
        "
      >
        <ion-label>If they haven't in</ion-label>
        <ion-note slot="end">
          {{ recognitionSettings.encourageManagersAndAdminsNumberOfDays }}
          {{
            recognitionSettings.encourageManagersAndAdminsNumberOfDays === 1
              ? 'day'
              : 'days'
          }}
        </ion-note>
      </ion-item>
    </ion-list>

    <ion-list [inset]="true">
      <ion-item>
        <ion-toggle
          appHaptics
          [(ngModel)]="recognitionSettings.encourageManagersAndAdminsPN"
          (ionChange)="onEncourageManagersAndAdminsPNChange($event)"
        >
          <ion-label>Also send Push Notification</ion-label>
        </ion-toggle>
      </ion-item>
      <app-time-form-control
        [isItem]="true"
        [itemLines]="true"
        [disabled]="recognitionSettings.encourageManagersAndAdminsPN"
        [(ngModel)]="recognitionSettings.encourageManagersAndAdminsTime"
        (ngModelChange)="onEncourageManagersAndAdminsTimeChange($event)"
        label="At"
      />
      <ion-item
        button
        appHaptics
        [disabled]="recognitionSettings.encourageManagersAndAdminsPN"
        [disableHaptics]="recognitionSettings.encourageManagersAndAdminsPN"
        (click)="onSelectEncourageManagersAndAdminsClick()"
      >
        <ion-label> To </ion-label>
        <ion-note slot="end">
          {{ recognitionSettings.encourageManagersAndAdmins.length }}
          managers and admins selected
        </ion-note>
      </ion-item>
    </ion-list>

    <app-list-pre-line title="Normal Users" />
    <ion-list [inset]="true">
      <ion-item>
        <ion-toggle
          appHaptics
          [(ngModel)]="
            recognitionSettings.encourageNormalUsersToRecognizeSomeone
          "
          (ionChange)="onEncourageNormalUsersToRecognizeSomeoneChange($event)"
        >
          <ion-label>
            Encourage Normal Users at next login to recognize someone
          </ion-label>
        </ion-toggle>
      </ion-item>
      <ion-item
        button
        id="encourageNormalUsersNumberOfDays"
        appHaptics
        [disabled]="recognitionSettings.encourageNormalUsersToRecognizeSomeone"
        [disableHaptics]="
          recognitionSettings.encourageNormalUsersToRecognizeSomeone
        "
      >
        <ion-label>If they haven't in</ion-label>
        <ion-note slot="end">
          {{ recognitionSettings.encourageNormalUsersNumberOfDays }}
          {{
            recognitionSettings.encourageNormalUsersNumberOfDays === 1
              ? 'day'
              : 'days'
          }}
        </ion-note>
      </ion-item>
    </ion-list>

    <ion-list [inset]="true">
      <ion-item>
        <ion-toggle
          appHaptics
          [(ngModel)]="recognitionSettings.encourageNormalUsersPN"
          (ionChange)="onEncourageNormalUsersPNChange($event)"
        >
          <ion-label>Also send Push Notification</ion-label>
        </ion-toggle>
      </ion-item>
      <app-time-form-control
        [isItem]="true"
        [itemLines]="true"
        [disabled]="recognitionSettings.encourageNormalUsersPN"
        [(ngModel)]="recognitionSettings.encourageNormalUsersTime"
        (ngModelChange)="onEncourageNormalUsersTimeChange($event)"
        label="At"
      />
      <ion-item
        button
        appHaptics
        [disabled]="recognitionSettings.encourageNormalUsersPN"
        [disableHaptics]="recognitionSettings.encourageNormalUsersPN"
        (click)="onSelectNormalUsersClick()"
      >
        <ion-label> To </ion-label>
        <ion-note slot="end">
          {{ recognitionSettings.encourageNormalUsers.length }} normal users
          selected
        </ion-note>
      </ion-item>
    </ion-list>
  </app-width-restricted-container>
</ion-content>

<ion-loading #loading message="Loading..." />

<ion-picker
  trigger="encourageManagersAndAdminsNumberOfDays"
  [columns]="encourageManagersAndAdminsNumberOfDaysPickerColumns"
  [buttons]="encourageManagersAndAdminsNumberOfDaysPickerButtons"
/>

<ion-picker
  trigger="encourageNormalUsersNumberOfDays"
  [columns]="encourageNormalUsersNumberOfDaysPickerColumns"
  [buttons]="encourageNormalUsersNumberOfDaysPickerButtons"
/>
