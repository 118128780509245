import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ITimezone } from '@backend/types/timezone';
import { TSpecRoute_getTimezones } from '@backend/types/generated/Timezones/getTimezones';

@Injectable()
export class TimezoneApiService {
  constructor(private _httpClient: HttpClient) {}

  public getTimezones(): Observable<ITimezone[]> {
    return this._httpClient.get<ITimezone[]>(TSpecRoute_getTimezones());
  }
}
