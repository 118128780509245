// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_patchTask = (path: TSpecPathPatchTask) => {
  let res = "/tasks/:id";
  Object.entries(path).map(([key, value]) => {
    res = res.replace(':' + key, value);
  });
  return res;
};

export type TSpecHandler_patchTask = RequestHandler<TSpecPathPatchTask, any, TSpecBodyPatchTask, TSpecQueryPatchTask, {}>;

export interface TSpecPathPatchTask { id: string; }

/**
 * This interface was referenced by `TSpecBodyPatchTask`'s JSON-Schema
 * via the `definition` "ITaskSchedule".
 */
export type ITaskSchedule =
  | TaskScheduleNever
  | TaskScheduleDaily
  | TaskScheduleSpecificDates
  | TaskScheduleWeekly
  | TaskScheduleMonthly;

export interface TSpecBodyPatchTask {
  title: string;
  details: {
    description: string;
    attachments: string[];
  };
  points: number;
  pointsUnit: string;
  verbBase: string;
  verbPastSimple: string;
  schedule: ITaskSchedule[];
  shouldReappear: boolean;
  appearsAt: string;
  disappearsAt: string;
  performanceEvaluation: TaskPerformanceEvaluationType;
  performanceDuration: string | null;
  isCritical: boolean;
  skillMatrix: {
    status: ESkillMatrixStatus;
    user: string;
  }[];
  notifyEveryoneUponCompletion: boolean;
  notifyUsersUponCompletion: string[];
  timeAttack: TaskTimeAttackType;
  timeAttackRewardPoints: number;
  timeAttackAttemptPoints: number;
  taskFolder?: string;
  actionsUponTaskCompletion: (
    | {
        actionType: EActionType.REQUEST_PROCESS_FEEDBACK;
        completeThreshold: string;
        delayInDays: string;
        mandatory: boolean;
      }
    | {
        actionType: EActionType.PROMPT_USER_TO_LEAVE_NOTE;
        visibleToEveryone: boolean;
        visibleToUsers: string[];
        mandatory: boolean;
        showMoreRecentNote: EShowMoreRecentNote;
      }
  )[];
  isAuditTask: boolean;
  auditSuccessCriteria: string[];
  tasksForAudit: string[];
  auditReviewers: string[];
  pointsEarns: TaskPointsEarns;
  verbPastParticiple?: string;
  nounPlural?: string;
  extraAppearDate: string | null;
}
/**
 * This interface was referenced by `TSpecBodyPatchTask`'s JSON-Schema
 * via the `definition` "TaskScheduleNever".
 */
export interface TaskScheduleNever {
  scheduleType: TaskScheduleType.NEVER;
  date: string | null;
}
/**
 * This interface was referenced by `TSpecBodyPatchTask`'s JSON-Schema
 * via the `definition` "TaskScheduleDaily".
 */
export interface TaskScheduleDaily {
  scheduleType: TaskScheduleType.DAILY;
}
/**
 * This interface was referenced by `TSpecBodyPatchTask`'s JSON-Schema
 * via the `definition` "TaskScheduleSpecificDates".
 */
export interface TaskScheduleSpecificDates {
  scheduleType: TaskScheduleType.SPECIFIC_DATES;
  dates: string[];
  repeatEveryYear: boolean;
  adjustWeekdays: boolean;
}
/**
 * This interface was referenced by `TSpecBodyPatchTask`'s JSON-Schema
 * via the `definition` "TaskScheduleWeekly".
 */
export interface TaskScheduleWeekly {
  scheduleType: TaskScheduleType.WEEKLY;
  weekDays: [boolean, boolean, boolean, boolean, boolean, boolean, boolean];
}
/**
 * This interface was referenced by `TSpecBodyPatchTask`'s JSON-Schema
 * via the `definition` "TaskScheduleMonthly".
 */
export interface TaskScheduleMonthly {
  scheduleType: TaskScheduleType.MONTHLY;
  daysOfMonth: string[];
}

export enum TaskPerformanceEvaluationType {
  STANDARD = "STANDARD",
  AVERAGE = "AVERAGE"
}
export enum ESkillMatrixStatus {
  CANNOT_PERFORM = "CANNOT_PERFORM",
  CAN_PERFORM_WITH_SUPERVISON = "CAN_PERFORM_WITH_SUPERVISON",
  CAN_PERFORM_WITH_REVIEW = "CAN_PERFORM_WITH_REVIEW",
  CAN_PERFORM_WITHOUT_REVIEW = "CAN_PERFORM_WITHOUT_REVIEW",
  CAN_PERFORM_AND_TRAIN = "CAN_PERFORM_AND_TRAIN",
  MUST_REVIEW_NEW_VERSION = "MUST_REVIEW_NEW_VERSION",
  REVOKED = "REVOKED"
}
export enum TaskTimeAttackType {
  OFF = "OFF",
  VOLUNTARY = "VOLUNTARY",
  REQUIRED = "REQUIRED"
}
export enum EShowMoreRecentNote {
  ONLY_ONCE_TO_EACH_USER = "ONLY_ONCE_TO_EACH_USER",
  UNTIL_TASK_IS_COMPLETED_AGAIN_BY_ANYONE = "UNTIL_TASK_IS_COMPLETED_AGAIN_BY_ANYONE",
  EVERY_TIME_TASK_IS_OPENED = "EVERY_TIME_TASK_IS_OPENED"
}
export enum TaskPointsEarns {
  PER_COMPLETION = "PER_COMPLETION",
  PER_UNIT = "PER_UNIT"
}
/**
 * This interface was referenced by `TSpecBodyPatchTask`'s JSON-Schema
 * via the `definition` "EActionType".
 */
export enum EActionType {
  REQUEST_PROCESS_FEEDBACK = "REQUEST_PROCESS_FEEDBACK",
  PROMPT_USER_TO_LEAVE_NOTE = "PROMPT_USER_TO_LEAVE_NOTE"
}
/**
 * This interface was referenced by `TSpecBodyPatchTask`'s JSON-Schema
 * via the `definition` "TaskScheduleType".
 */
export enum TaskScheduleType {
  NEVER = "NEVER",
  DAILY = "DAILY",
  SPECIFIC_DATES = "SPECIFIC_DATES",
  WEEKLY = "WEEKLY",
  MONTHLY = "MONTHLY"
}

export interface TSpecQueryPatchTask {}
