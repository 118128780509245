// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_reorderTasksMultiple = () => "/tasks/reorder-multiple";

export type TSpecHandler_reorderTasksMultiple = RequestHandler<TSpecPathReorderTasksMultiple, any, TSpecBodyReorderTasksMultiple, TSpecQueryReorderTasksMultiple, {}>;

export interface TSpecPathReorderTasksMultiple {  }

export type TSpecBodyReorderTasksMultiple = {
  tasks: {
    taskId: string;
    orderIndex: number;
  }[];
  taskFolder: string | null;
}[];

export interface TSpecQueryReorderTasksMultiple {}
