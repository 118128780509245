// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_batchUploadTagsToGhlConfirm = () => "/tags/batch-upload-tags-to-ghl/confirm";

export type TSpecHandler_batchUploadTagsToGhlConfirm = RequestHandler<TSpecPathBatchUploadTagsToGhlConfirm, any, TSpecBodyBatchUploadTagsToGhlConfirm, TSpecQueryBatchUploadTagsToGhlConfirm, {}>;

export interface TSpecPathBatchUploadTagsToGhlConfirm {  }

export interface TSpecBodyBatchUploadTagsToGhlConfirm {
  tenantId: string;
  tagId: string;
  skipUpdateTokens: boolean;
}

export interface TSpecQueryBatchUploadTagsToGhlConfirm {}
