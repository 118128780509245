import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MongoStoredObject } from '@app/types/mongo-stored-object';
import { IIntegration } from '@backend/models/types/integration';
import {
  TSpecPathDeleteIntegration,
  TSpecQueryDeleteIntegration,
  TSpecRoute_deleteIntegration
} from '@backend/types/generated/Integrations/deleteIntegration';
import {
  TSpecPathGetIntegration,
  TSpecQueryGetIntegration,
  TSpecRoute_getIntegration
} from '@backend/types/generated/Integrations/getIntegration';
import {
  TSpecQueryGetIntegrations,
  TSpecRoute_getIntegrations
} from '@backend/types/generated/Integrations/getIntegrations';
import {
  TSpecQueryGetPublicSettings,
  TSpecRoute_getPublicSettings
} from '@backend/types/generated/Integrations/getPublicSettings';
import {
  TSpecBodyInitIntegration,
  TSpecPathInitIntegration,
  TSpecRoute_initIntegration
} from '@backend/types/generated/Integrations/initIntegration';
import {
  TSpecBodySetIntegration,
  TSpecRoute_setIntegration
} from '@backend/types/generated/Integrations/setIntegration';
import { Observable, Subject, repeat, shareReplay } from 'rxjs';

@Injectable()
export class IntegrationApiService {
  private readonly _update$ = new Subject<void>();
  public tenantIntegrations$: Observable<MongoStoredObject<IIntegration[]>> =
    this.getIntegrations({ query: {} }).pipe(
      repeat({ delay: () => this._update$ }),
      shareReplay(1)
    );

  public constructor(private readonly _httpClient: HttpClient) {}

  public getIntegrations(params: {
    query: TSpecQueryGetIntegrations;
  }): Observable<MongoStoredObject<IIntegration[]>> {
    return this._httpClient.get<MongoStoredObject<IIntegration[]>>(
      TSpecRoute_getIntegrations(),
      { params: { ...params.query } }
    );
  }

  public getPublicSettings(params: {
    query: TSpecQueryGetPublicSettings;
  }): Observable<any> {
    return this._httpClient.get<any>(TSpecRoute_getPublicSettings(), {
      params: { ...params.query }
    });
  }

  public initIntegration(params: {
    path: TSpecPathInitIntegration;
    body: TSpecBodyInitIntegration;
  }): Observable<MongoStoredObject<IIntegration> & { initUrl: string }> {
    return this._httpClient.post<
      MongoStoredObject<IIntegration> & { initUrl: string }
    >(TSpecRoute_initIntegration(params.path), params.body);
  }

  public setIntegration(params: {
    body: TSpecBodySetIntegration;
  }): Observable<MongoStoredObject<IIntegration>> {
    return this._httpClient.put<MongoStoredObject<IIntegration>>(
      TSpecRoute_setIntegration(),
      params.body
    );
  }

  public getIntegration(params: {
    path: TSpecPathGetIntegration;
    query: TSpecQueryGetIntegration;
  }): Observable<MongoStoredObject<IIntegration>> {
    return this._httpClient.get<MongoStoredObject<IIntegration>>(
      TSpecRoute_getIntegration(params.path),
      { params: { ...params.query } }
    );
  }

  public deleteIntegration(params: {
    path: TSpecPathDeleteIntegration;
    query: TSpecQueryDeleteIntegration;
  }): Observable<null> {
    return this._httpClient.delete<null>(
      TSpecRoute_deleteIntegration(params.path),
      { params: { ...params.query } }
    );
  }

  public update(): void {
    this._update$.next();
  }
}
