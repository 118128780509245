import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MongoStoredObject } from '@app/types/mongo-stored-object';
import { ICompensation } from '@backend/models/types/compensation';
import { ICompensationField } from '@backend/models/types/compensation-field';
import { ICompensationPool } from '@backend/models/types/compensation-pool';
import {
  TSpecBodyAddCompensationField,
  TSpecRoute_addCompensationField
} from '@backend/types/generated/Compensation/addCompensationField';
import {
  TSpecBodyAddCompensationPool,
  TSpecRoute_addCompensationPool
} from '@backend/types/generated/Compensation/addCompensationPool';
import {
  TSpecBodyGetCompensationAnnouncementPreview,
  TSpecRoute_getCompensationAnnouncementPreview
} from '@backend/types/generated/Compensation/getCompensationAnnouncementPreview';
import { TSpecRoute_getCompensationFields } from '@backend/types/generated/Compensation/getCompensationFields';
import { TSpecRoute_getCompensationPools } from '@backend/types/generated/Compensation/getCompensationPools';
import { TSpecRoute_getCompensations } from '@backend/types/generated/Compensation/getCompensations';
import { TSpecRoute_parseFile } from '@backend/types/generated/Compensation/parseFile';
import {
  TSpecPathRemoveCompensationField,
  TSpecRoute_removeCompensationField
} from '@backend/types/generated/Compensation/removeCompensationField';
import {
  TSpecPathRemoveCompensationPool,
  TSpecRoute_removeCompensationPool
} from '@backend/types/generated/Compensation/removeCompensationPool';
import {
  TSpecBodySubmitCompensation,
  TSpecRoute_submitCompensation
} from '@backend/types/generated/Compensation/submitCompensation';
import {
  TSpecBodyUpdateCompensationField,
  TSpecPathUpdateCompensationField,
  TSpecRoute_updateCompensationField
} from '@backend/types/generated/Compensation/updateCompensationField';
import {
  TSpecBodyUpdateCompensationPool,
  TSpecPathUpdateCompensationPool,
  TSpecRoute_updateCompensationPool
} from '@backend/types/generated/Compensation/updateCompensationPool';
import { Observable } from 'rxjs';

@Injectable()
export class CompensationApiService {
  public constructor(private readonly _httpClient: HttpClient) {}

  public getCompensations(): Observable<
    MongoStoredObject<
      Omit<ICompensation, 'pools' | 'fields'> & {
        pools: { pool: MongoStoredObject<ICompensationPool>; value: number }[];
        fields: MongoStoredObject<ICompensationField>[];
      }
    >[]
  > {
    return this._httpClient.get<
      MongoStoredObject<
        Omit<ICompensation, 'pools' | 'fields'> & {
          pools: {
            pool: MongoStoredObject<ICompensationPool>;
            value: number;
          }[];
          fields: MongoStoredObject<ICompensationField>[];
        }
      >[]
    >(TSpecRoute_getCompensations());
  }

  public getCompensationPools(): Observable<
    MongoStoredObject<ICompensationPool>[]
  > {
    return this._httpClient.get<MongoStoredObject<ICompensationPool>[]>(
      TSpecRoute_getCompensationPools()
    );
  }

  public addCompensationPool(params: {
    body: TSpecBodyAddCompensationPool;
  }): Observable<MongoStoredObject<ICompensationPool>> {
    return this._httpClient.post<MongoStoredObject<ICompensationPool>>(
      TSpecRoute_addCompensationPool(),
      params.body
    );
  }

  public updateCompensationPool(params: {
    path: TSpecPathUpdateCompensationPool;
    body: TSpecBodyUpdateCompensationPool;
  }): Observable<MongoStoredObject<ICompensationPool>> {
    return this._httpClient.put<MongoStoredObject<ICompensationPool>>(
      TSpecRoute_updateCompensationPool(params.path),
      params.body
    );
  }

  public removeCompensationPool(params: {
    path: TSpecPathRemoveCompensationPool;
  }): Observable<MongoStoredObject<ICompensationPool>> {
    return this._httpClient.delete<MongoStoredObject<ICompensationPool>>(
      TSpecRoute_removeCompensationPool(params.path)
    );
  }

  public parseCsv(file: File): Observable<any[]> {
    const formData = new FormData();
    formData.append('file', file);

    return this._httpClient.post<any[]>(TSpecRoute_parseFile(), formData);
  }

  public getCompensationFields(): Observable<
    MongoStoredObject<ICompensationField>[]
  > {
    return this._httpClient.get<MongoStoredObject<ICompensationField>[]>(
      TSpecRoute_getCompensationFields()
    );
  }

  public addCompensationField(params: {
    body: TSpecBodyAddCompensationField;
  }): Observable<MongoStoredObject<ICompensationField>> {
    return this._httpClient.post<MongoStoredObject<ICompensationField>>(
      TSpecRoute_addCompensationField(),
      params.body
    );
  }

  public updateCompensationField(params: {
    path: TSpecPathUpdateCompensationField;
    body: TSpecBodyUpdateCompensationField;
  }): Observable<MongoStoredObject<ICompensationField>> {
    return this._httpClient.put<MongoStoredObject<ICompensationField>>(
      TSpecRoute_updateCompensationField(params.path),
      params.body
    );
  }

  public removeCompensationField(params: {
    path: TSpecPathRemoveCompensationField;
  }): Observable<MongoStoredObject<ICompensationField>> {
    return this._httpClient.delete<MongoStoredObject<ICompensationField>>(
      TSpecRoute_removeCompensationField(params.path)
    );
  }

  public submitCompensation(params: {
    body: TSpecBodySubmitCompensation;
  }): Observable<MongoStoredObject<ICompensation>> {
    return this._httpClient.post<MongoStoredObject<ICompensation>>(
      TSpecRoute_submitCompensation(),
      params.body
    );
  }

  public getCompensationAnnouncementPreview(params: {
    body: TSpecBodyGetCompensationAnnouncementPreview;
  }): Observable<{ title: string; body: string }> {
    return this._httpClient.post<{ title: string; body: string }>(
      TSpecRoute_getCompensationAnnouncementPreview(),
      params.body
    );
  }
}
