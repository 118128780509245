<ion-header class="ion-no-border">
  <ion-toolbar class="app-common-background">
    <ion-buttons slot="start" *ngIf="cancelBtnText && dismissable">
      <ion-button (click)="onCloseClick()" appHaptics color="primary">
        {{ cancelBtnText }}
      </ion-button>
    </ion-buttons>
    <ion-title>{{ title }}</ion-title>
    <ion-buttons slot="end" *ngIf="saveBtnText">
      <ion-button
        (click)="onSaveClick()"
        appHaptics
        color="primary"
        [disabled]="!allowEmptyValue && !value"
        [disableHaptics]="!allowEmptyValue && !value"
      >
        {{ saveBtnText }}
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
  <ion-toolbar
    class="app-common-background wysiwyg-modal__info"
    *ngIf="info && (info.start || info.end)"
  >
    <div slot="start" *ngIf="info.start">{{ info.start }}</div>
    <div slot="end" *ngIf="info.end">{{ info.end }}</div>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div class="wysiwyg-modal">
    <app-wysiwyg-editor
      [(value)]="value"
      [disableAttachments]="disableAttachments"
      [bodyPlaceholder]="bodyPlaceholder"
      [mode]="mode"
      [toolbarMode]="toolbarMode"
    />
  </div>
</ion-content>
