// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_getAnnouncement = (path: TSpecPathGetAnnouncement) => {
  let res = "/announcements/:id";
  Object.entries(path).map(([key, value]) => {
    res = res.replace(':' + key, value);
  });
  return res;
};

export type TSpecHandler_getAnnouncement = RequestHandler<TSpecPathGetAnnouncement, any, TSpecBodyGetAnnouncement, TSpecQueryGetAnnouncement, {}>;

export interface TSpecPathGetAnnouncement { id: string; }

export interface TSpecBodyGetAnnouncement {}

export interface TSpecQueryGetAnnouncement {}
