// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_batchUploadTagsToGhl = () => "/tags/batch-upload-tags-to-ghl";

export type TSpecHandler_batchUploadTagsToGhl = RequestHandler<TSpecPathBatchUploadTagsToGhl, any, TSpecBodyBatchUploadTagsToGhl, TSpecQueryBatchUploadTagsToGhl, {}>;

export interface TSpecPathBatchUploadTagsToGhl {  }

export interface TSpecBodyBatchUploadTagsToGhl {
  tenantId: string;
  tagNumberFrom: string;
  tagNumberTo: string;
}

export interface TSpecQueryBatchUploadTagsToGhl {}
