import { Injectable } from '@angular/core';
import { combineLatest, map, Observable, switchMap } from 'rxjs';
import { ChecklistItem } from '@app/types/checklist-item';
import { DateInterval } from '@backend/types/date-interval';
import { MongoStoredObject } from '@app/types/mongo-stored-object';
import { TaskRendererFabricService } from './task-renderer-fabric.service';
import { TaskPerformanceService } from './task-performance.service';
import { TaskDto } from '@app/types/task-dto';
import { UserEntityService } from './user-entity.service';
import { makeChecklistItemsUnique } from '@app/utils/checklist-items-unique';
import { isSameZonedDay } from '@app/utils/is-same-zoned-day';
import { TenantService } from './tenant.service';
import { ESkillMatrixStatus } from '@backend/models/types/task';
import { UserRoles } from '@backend/models/types/user';

@Injectable({ providedIn: 'root' })
export class ChecklistService {
  public constructor(
    private readonly _taskRendererFabric: TaskRendererFabricService,
    private readonly _taskPerformance: TaskPerformanceService,
    private readonly _userService: UserEntityService,
    private readonly _tenantService: TenantService
  ) {}

  public getMyChecklist(
    taskList$: Observable<MongoStoredObject<TaskDto>[]>,
    interval$: Observable<DateInterval>
  ): Observable<ChecklistItem[]> {
    return interval$.pipe(
      switchMap((interval) =>
        combineLatest([
          this._renderChecklistToInterval(
            interval,
            this._userService.user$.pipe(
              switchMap(({ _id, roles }) =>
                taskList$.pipe(
                  map((taskList) =>
                    taskList.filter((task) => {
                      if (task.isDeleted) return false;

                      const status = task?.skillMatrix?.find(
                        (u) => u.user.toString() === _id.toString()
                      )?.status;

                      let isEligible = false;
                      switch (status) {
                        case ESkillMatrixStatus.CAN_PERFORM_WITH_REVIEW:
                        case ESkillMatrixStatus.CAN_PERFORM_WITHOUT_REVIEW:
                        case ESkillMatrixStatus.MUST_REVIEW_NEW_VERSION:
                          isEligible = true;
                      }

                      return roles.includes(UserRoles.Admin) || isEligible;
                    })
                  )
                )
              )
            )
          ),
          this._taskPerformance.getPerformanceForInterval(interval),
          this._tenantService.tenant$
        ])
      ),
      map(([checklistItems, performances, tenant]) => {
        const newChecklistItems = checklistItems.map((item) => {
          const clone = item.item.cloneWithoutPerformances();
          clone.performances = performances.filter(
            (p) =>
              p.task.toString() === clone.task._id.toString() &&
              isSameZonedDay(
                clone.interval.start,
                p.taskAppearTime,
                tenant.timezone
              )
          );
          return clone;
        });
        return newChecklistItems;
      })
    );
  }

  private _renderChecklistToInterval(
    interval: DateInterval,
    taskList$: Observable<MongoStoredObject<TaskDto>[]>
  ) {
    return combineLatest([taskList$, this._tenantService.tenant$]).pipe(
      map(([taskList, tenant]) => {
        const checklists = taskList.map((task) =>
          this._taskRendererFabric
            .createTaskRenderer(
              task,
              tenant.settings.checklist
                .allowTasksToBeDisplayedOnlyDuringCertainHours
            )
            .render(interval, task.extraAppearDate)
        );

        return makeChecklistItemsUnique(checklists.flat());
      })
    );
  }
}
