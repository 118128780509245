import { NgModule } from '@angular/core';
import { NavBaseComponent } from '@app/modules/nav-base/nav-base.component';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { WidthRestrictedContainerModule } from '@app/modules/width-restricted-container/width-restricted-container.module';
import { SlimMenuButtonComponent } from '@app/modules/slim-menu-button/slim-menu-button.component';
import { RouterModule, Routes } from '@angular/router';
import { EmptyStubModule } from '@app/modules/empty-stub/empty-stub.module';
import { DirectivesModule } from '@app/modules/directives.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { WysiwygEditorComponent } from '@app/modules/wysiwyg-editor/wysiwyg-editor.component';
import { SuggestionsComponent } from './suggestions.component';
import { SuggestionApiService } from '@app/core/service/api/suggestion.api.service';
import { SuggestionAddComponent } from './components/suggestion-add/suggestion-add.component';
import { SuggestionViewComponent } from './components/suggestion-view/suggestion-view.component';
import { SolutionItemComponent } from './components/solution-item/solution-item.component';
import { UserSelectorComponent } from '@app/modules/user-selector/user-selector.component';
import { PullToSearchModule } from '@app/modules/pull-to-search/pull-to-search.module';
import { OpportunityItemComponent } from './components/opportunity-item/opportunity-item.component';
import { SuggestionCategoryApiService } from '@app/core/service/api/suggestionCategory.api.service';
import { ListPostLineModule } from '@app/modules/list-post-line/list-post-line.module';
import { ListPreLineModule } from '../../modules/list-pre-line/list-pre-line.module';

const ROUTES: Routes = [
  {
    path: '',
    component: NavBaseComponent,
    data: {
      title: 'Suggestions',
      rootComponent: SuggestionsComponent
    }
  }
];

@NgModule({
  imports: [
    NavBaseComponent,
    CommonModule,
    RouterModule.forChild(ROUTES),
    IonicModule,
    WidthRestrictedContainerModule,
    SlimMenuButtonComponent,
    EmptyStubModule,
    DirectivesModule,
    FormsModule,
    ReactiveFormsModule,
    WysiwygEditorComponent,
    UserSelectorComponent,
    PullToSearchModule,
    ListPostLineModule,
    ListPreLineModule
  ],
  declarations: [
    SuggestionsComponent,
    SuggestionAddComponent,
    SuggestionViewComponent,
    SolutionItemComponent,
    OpportunityItemComponent
  ],
  providers: [SuggestionApiService, SuggestionCategoryApiService]
})
export class SuggestionsModule {}
