<ion-header class="ion-no-border">
  <ion-toolbar class="app-toolbar">
    <ion-title> Share Feedback </ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="onCloseClick()" appHaptics>
        <ion-icon slot="icon-only" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content color="light" [fullscreen]="true">
  <app-full-height-container maxWidth="modal">
    <app-content-title [subtitle]="tenant?.name">
      Share Feedback
    </app-content-title>

    <ion-list [inset]="true">
      <ion-item>
        <ion-input
          label="Name (optional)"
          labelPlacement="stacked"
          [value]="formattedUser"
          [readonly]="true"
          id="selectUser"
          appHaptics
          [disableHaptics]="!!formattedUser"
        />
        <ion-button
          *ngIf="formattedUser"
          fill="clear"
          (click)="handleUserClearClick()"
          appHaptics
          class="ion-no-margin"
        >
          <ion-icon slot="icon-only" name="close-circle" class="input-icon" />
        </ion-button>
      </ion-item>
      <ion-item>
        <ion-select
          label="Category"
          [(ngModel)]="selectedCategory"
          appHaptics
          labelPlacement="stacked"
        >
          <ion-select-option [value]="null">No Category</ion-select-option>
          <ion-select-option
            *ngFor="let category of categories"
            [value]="category._id"
          >
            {{ category.name }}
          </ion-select-option>
        </ion-select>
      </ion-item>
    </ion-list>

    <app-wysiwyg-editor
      [(value)]="body"
      [disableAttachments]="!tenant?.suggestionAttachmentsFeatureEnabled"
      [isItem]="true"
      toolbarMode="image-only"
      bodyPlaceholder="Please describe your question, concern, or idea."
    />

    <div bottom class="modal-actions">
      <ion-button
        (click)="onFormSubmit()"
        color="dark"
        [disabled]="!canSave"
        appHaptics
        [disableHaptics]="!canSave"
        expand="block"
        class="ion-no-margin"
      >
        {{ selectedUser ? 'Submit' : 'Submit Anonymously' }}
      </ion-button>
    </div>
  </app-full-height-container>
</ion-content>

<ion-loading #loading message="Loading..." />

<ion-modal
  trigger="selectUser"
  #userSelectorModal
  [handle]="false"
  [breakpoints]="[0, 1]"
  [initialBreakpoint]="1"
>
  <ng-template>
    <app-user-selector
      title="Select User"
      class="ion-page"
      [users]="tenant?.users || []"
      [recipients]="selectedUserIds"
      (selectionChange)="onUserChange($event)"
      (selectionCancel)="supervisorSelectorModal.dismiss()"
    />
  </ng-template>
</ion-modal>
