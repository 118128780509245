import { NgModule } from '@angular/core';
import { NavBaseComponent } from '@app/modules/nav-base/nav-base.component';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { WidthRestrictedContainerModule } from '@app/modules/width-restricted-container/width-restricted-container.module';
import { SlimMenuButtonComponent } from '@app/modules/slim-menu-button/slim-menu-button.component';
import { RouterModule, Routes } from '@angular/router';
import { LibraryComponent } from './library.component';
import { EmptyStubModule } from '@app/modules/empty-stub/empty-stub.module';
import { DirectivesModule } from '@app/modules/directives.module';
import { LibraryApiService } from '@app/core/service/api/library.api.service';
import { LibraryDocViewComponent } from './components/library-doc-view/library-doc-view.component';
import { LibraryDocAddComponent } from './components/library-doc-add/library-doc-add.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { WysiwygEditorComponent } from '@app/modules/wysiwyg-editor/wysiwyg-editor.component';
import { CultureComponent } from './components/culture/culture.component';
import { ValuesAccordionModule } from '@app/modules/culture-accordion/values-accordion.module';
import { UserSelectorComponent } from '@app/modules/user-selector/user-selector.component';
import { PullToSearchModule } from '@app/modules/pull-to-search/pull-to-search.module';
import { LibraryItemComponent } from './components/library-item/library-item.component';
import { ContentTitleModule } from '@app/modules/content-title/content-title.module';

const ROUTES: Routes = [
  {
    path: '',
    component: NavBaseComponent,
    data: {
      title: 'Library',
      rootComponent: LibraryComponent
    }
  }
];

@NgModule({
  imports: [
    NavBaseComponent,
    CommonModule,
    RouterModule.forChild(ROUTES),
    IonicModule,
    WidthRestrictedContainerModule,
    SlimMenuButtonComponent,
    EmptyStubModule,
    DirectivesModule,
    FormsModule,
    ReactiveFormsModule,
    WysiwygEditorComponent,
    ValuesAccordionModule,
    UserSelectorComponent,
    PullToSearchModule,
    ContentTitleModule
  ],
  declarations: [
    LibraryComponent,
    LibraryDocViewComponent,
    LibraryDocAddComponent,
    CultureComponent,
    LibraryItemComponent
  ],
  providers: [LibraryApiService]
})
export class LibraryModule {}
