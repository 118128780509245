import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit
} from '@angular/core';
import { CompensationApiService } from '@app/core/service/api/compensation.api.service';
import { MongoStoredObject } from '@app/types/mongo-stored-object';
import { IonNav } from '@ionic/angular';
import { DateInterval } from '@backend/types/date-interval';
import { ICompensation } from '@backend/models/types/compensation';
import { CompensationHoursComponent } from '../compensation-hours/compensation-hours.component';
import { CompensationPreviewComponent } from '../compensation-preview/compensation-preview.component';
import { ICompensationUserValue } from '../compensation-hours/compensation-hours.component';
import { ICompensationPool } from '@backend/models/types/compensation-pool';
import { ICompensationField } from '@backend/models/types/compensation-field';
import { ICompensationPoolValue } from '../compensation-pools/compensation-pools.component';

@Component({
  selector: 'app-compensations',
  templateUrl: './compensations.component.html',
  styleUrls: ['./compensations.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CompensationsComponent implements OnInit {
  @Input()
  public defaultRange: DateInterval;

  protected isLoading = true;
  protected compensations: MongoStoredObject<
    Omit<ICompensation, 'pools' | 'fields'> & {
      pools: { pool: MongoStoredObject<ICompensationPool>; value: number }[];
      fields: MongoStoredObject<ICompensationField>[];
    }
  >[] = [];

  public constructor(
    private readonly _cdr: ChangeDetectorRef,
    private readonly _ionNav: IonNav,
    private readonly _compensationApiService: CompensationApiService
  ) {}

  public ngOnInit(): void {
    this._compensationApiService
      .getCompensations()
      .subscribe((compensations) => {
        this.compensations = compensations;

        this.isLoading = false;
        this._cdr.markForCheck();
      });
  }

  protected onBackButtonClick(): void {
    this._ionNav.pop();
  }

  protected onNewClick() {
    this._ionNav.push(CompensationHoursComponent, {
      defaultRange: this.defaultRange
    });
  }

  protected openCompensation(
    compensation: MongoStoredObject<
      Omit<ICompensation, 'pools' | 'fields'> & {
        pools: { pool: MongoStoredObject<ICompensationPool>; value: number }[];
        fields: MongoStoredObject<ICompensationField>[];
      }
    >
  ) {
    const pools: ICompensationPoolValue[] = compensation.pools.map((p) => ({
      ...p.pool,
      value: p.value.toString(),
      users: compensation.usersData
        .filter(
          (u) =>
            u.pools.find(
              (pool) => pool.pool.toString() === p.pool._id.toString()
            )?.isEligible
        )
        .map((u) => u.user)
    }));

    const users = compensation.usersData.map<
      ICompensationUserValue & { notify: boolean; points: number }
    >((user) => {
      return {
        notify: user.notify,
        userId: user.user.toString(),
        firstName: user.firstName,
        lastName: user.lastName,
        compensationEmployeeId: user.compensationEmployeeId,
        values: [
          {
            field: 'hours',
            value: user.totalHoursWorked.toString()
          }
        ],
        points: user.totalPointsEarned,
        pools: user.pools
      };
    });

    this._ionNav.push(CompensationPreviewComponent, {
      users,
      pools,
      fields: compensation.fields,
      defaultRange: {
        start: new Date(compensation.range.start),
        end: new Date(compensation.range.end)
      },
      disableSubmit: true,
      includePointsFrom: compensation.includePointsFrom
    });
  }
}
