// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_getAllLibraryDocs = () => "/library/docs";

export type TSpecHandler_getAllLibraryDocs = RequestHandler<TSpecPathGetAllLibraryDocs, any, TSpecBodyGetAllLibraryDocs, TSpecQueryGetAllLibraryDocs, {}>;

export interface TSpecPathGetAllLibraryDocs {  }

export interface TSpecBodyGetAllLibraryDocs {}

export interface TSpecQueryGetAllLibraryDocs {
  page?: string;
  limit?: string;
  searchTerm?: string;
}
