// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_getDailyReport = () => "/daily-report";

export type TSpecHandler_getDailyReport = RequestHandler<TSpecPathGetDailyReport, any, TSpecBodyGetDailyReport, TSpecQueryGetDailyReport, {}>;

export interface TSpecPathGetDailyReport {  }

export interface TSpecBodyGetDailyReport {}

export interface TSpecQueryGetDailyReport {
  from: string;
  to: string;
}
