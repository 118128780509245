import { Component, Input } from '@angular/core';
import { MongoStoredObject } from '@app/types/mongo-stored-object';
import { ITenant } from '@backend/models/types/tenant';
import { IonNav, LoadingController } from '@ionic/angular';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IIntegration } from '@backend/models/types/integration';
import { ToastService } from '@app/core/service/toast.service';
import { TagApiService } from '@app/core/service/api/tag.api.service';
import { ETagStatus, ITag } from '@backend/models/types/tag';

@Component({
  selector: 'app-ghl-tag-uploader',
  templateUrl: './ghl-tag-uploader.component.html',
  styleUrls: ['./ghl-tag-uploader.component.scss']
})
export class GhlTagUploaderComponent {
  @Input()
  public tenant: MongoStoredObject<ITenant & { integrations: IIntegration[] }>;

  protected tagStatus = ETagStatus;

  protected mainFormGroup: FormGroup = new FormGroup({
    tagNumberFrom: new FormControl<string>('', [Validators.required]),
    tagNumberTo: new FormControl<string>('', [Validators.required])
  });
  protected tags: (MongoStoredObject<ITag> & {
    isSuccess?: boolean;
    isError?: boolean;
  })[] = [];
  protected tagCurrent = 0;
  protected tagTotal = 0;
  protected isDone = false;

  public constructor(
    private readonly _ionNav: IonNav,
    private readonly _tagApiService: TagApiService,
    private readonly _loadingCtrl: LoadingController,
    private readonly _toastService: ToastService
  ) {}

  protected onBackButtonClick() {
    this._ionNav.pop();
  }

  protected async syncTags() {
    const loading = await this._loadingCtrl.create({
      message: 'Preparing...'
    });
    loading.present();

    const tagNumberFrom = this.mainFormGroup.controls.tagNumberFrom.value;
    const tagNumberTo = this.mainFormGroup.controls.tagNumberTo.value;

    this._tagApiService
      .batchUploadTagsToGhl({
        body: { tenantId: this.tenant._id, tagNumberFrom, tagNumberTo }
      })
      .subscribe({
        next: ({ tags }) => {
          this.tags = tags;
          loading.dismiss();

          if (!tags.length) {
            this._toastService.presentToast(
              'There are no tags within provided range'
            );
          } else {
            this._toastService.presentToast(`${tags.length} tags found`);
          }
        },
        error: (e) => {
          loading.dismiss();
          this._toastService.presentToast(
            e.error?.message || 'Something went wrong'
          );
        }
      });
  }

  protected async confirmSyncTags() {
    const tags = this.tags.filter(
      (tag) =>
        tag.status === ETagStatus.UPLOADED &&
        tag.customerCell &&
        tag.pickupStatus
    );
    this.tagTotal = tags.length;

    tags.map((tag, index) => {
      this.tagCurrent = index;
      this._tagApiService
        .batchUploadTagsToGhlConfirm({
          body: {
            tenantId: this.tenant._id,
            tagId: tag._id,
            skipUpdateTokens: index !== 0
          }
        })
        .subscribe({
          next: () => {
            this.tags = this.tags.map((t) => ({
              ...t,
              isSuccess: t.isSuccess || t._id.toString() === tag._id.toString()
            }));

            if (tags.length - 1 === index) {
              this.isDone = true;
            }
          },
          error: () => {
            this.tags = this.tags.map((t) => ({
              ...t,
              isError: t.isError || t._id.toString() === tag._id.toString()
            }));

            if (tags.length - 1 === index) {
              this.isDone = true;
            }
          }
        });
    });
  }

  protected trackTags(_: number, item: MongoStoredObject<ITag>) {
    return item._id;
  }
}
