// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_getBehavior = (path: TSpecPathGetBehavior) => {
  let res = "/tenants/:tenantId/behavior/:id";
  Object.entries(path).map(([key, value]) => {
    res = res.replace(':' + key, value);
  });
  return res;
};

export type TSpecHandler_getBehavior = RequestHandler<TSpecPathGetBehavior, any, TSpecBodyGetBehavior, TSpecQueryGetBehavior, {}>;

export interface TSpecPathGetBehavior { tenantId: string; id: string; }

export interface TSpecBodyGetBehavior {}

export interface TSpecQueryGetBehavior {}
