<ion-header class="ion-no-border">
  <ion-toolbar class="app-toolbar">
    <ion-buttons slot="end">
      <ion-button (click)="onCloseClick()" appHaptics>
        <ion-icon slot="icon-only" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>
      {{ department ? 'Update Department' : 'Create Department' }}
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content *ngIf="_isMobile; else content" color="light">
  <ng-container *ngTemplateOutlet="content" />
</ion-content>

<ng-template #content>
  <app-full-height-container [formGroup]="form" [isModal]="true">
    <ion-list [inset]="true">
      <ion-item>
        <ion-input
          label="Name"
          formControlName="name"
          labelPlacement="stacked"
          autocapitalize="on"
          [spellcheck]="true"
        />
      </ion-item>
    </ion-list>

    <ion-button
      bottom
      *ngIf="department"
      color="danger"
      expand="block"
      fill="outline"
      appHaptics
      (click)="delete()"
      class="ion-no-margin"
    >
      Delete
    </ion-button>
    <ion-button
      bottom
      color="dark"
      expand="block"
      [disabled]="!canSave"
      appHaptics
      [disableHaptics]="!canSave"
      (click)="save()"
      class="ion-no-margin"
    >
      {{ department ? 'Update' : 'Create' }}
    </ion-button>
  </app-full-height-container>
</ng-template>
