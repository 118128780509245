<div
  [class]="'wysiwyg-editor wysiwyg-editor--toolbar-' + toolbarMode"
  [ngClass]="{
    'wysiwyg-editor--view-mode': mode === 'view',
    'wysiwyg-editor--item': isItem,
    'wysiwyg-editor--dark': dark
  }"
  (click)="setFocus()"
  [attr.data-currentFontSize]="currentFontSize"
>
  <angular-editor
    #wysiwygEditor
    id="wysiwyg-editor"
    [(ngModel)]="body"
    (ngModelChange)="onChange($event)"
    [config]="editorConfig"
  ></angular-editor>
</div>
