import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { UserModel } from '@app/core/model/user.model';
import { TasksApiService } from '@app/core/service/api/tasks.api.service';
import { MongoStoredObject } from '@app/types/mongo-stored-object';
import { ObjectId } from '@app/types/object-id';
import { IAttachment } from '@backend/models/types/attachment';
import { ITaskPerformance } from '@backend/models/types/task-performance';
import { ModalController, IonInfiniteScroll } from '@ionic/angular';
import { EnvironmentService } from '@app/core/service/environment.service';

@Component({
  selector: 'app-task-history-modal',
  templateUrl: './task-history-modal.component.html',
  styleUrls: ['./task-history-modal.component.scss']
})
export class TaskHistoryModalComponent implements OnInit {
  @Input()
  public readonly taskId: ObjectId;

  @ViewChild('infiniteScroll')
  public infiniteScroll: IonInfiniteScroll;

  protected taskPerformances: (MongoStoredObject<ITaskPerformance> & {
    user: Pick<UserModel, 'firstName' | 'lastName'>;
    note?: {
      attachments: MongoStoredObject<IAttachment>[];
    };
  })[] = [];
  protected page = 1;
  protected pageSize = 20;
  protected isLoading = true;
  protected hasNextPage = true;
  protected serverUrl = '';

  public constructor(
    private readonly _modalCtrl: ModalController,
    private readonly _tasksApiService: TasksApiService,
    private readonly _environmentService: EnvironmentService
  ) {
    this.serverUrl = this._environmentService.serverUrl;
  }

  public ngOnInit(): void {
    this._fetchTaskHistory();
  }

  protected onIonInfinite() {
    this.page = this.page + 1;
    this._fetchTaskHistory();
  }

  private _fetchTaskHistory(reset = false): void {
    if (reset) {
      this.isLoading = true;
      this.page = 1;
      this.hasNextPage = true;
      this.taskPerformances = [];
    }

    this._tasksApiService
      .getTaskPerformances({
        path: { id: this.taskId },
        query: {
          page: this.page.toString(),
          limit: this.pageSize.toString()
        }
      })
      .subscribe((taskPerformances) => {
        this.hasNextPage = taskPerformances.length === this.pageSize;
        this.taskPerformances = this.taskPerformances.concat(taskPerformances);
        this.isLoading = false;
        this.infiniteScroll?.complete();
      });
  }

  protected onCloseClick(): void {
    this._modalCtrl.dismiss(null, 'cancel', 'task-history-modal');
  }

  protected trackItems(_: number, item: MongoStoredObject<ITaskPerformance>) {
    return item._id;
  }
}
