import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit
} from '@angular/core';
import { UserModel } from '@app/core/model/user.model';
import { CompensationApiService } from '@app/core/service/api/compensation.api.service';
import { UserListService } from '@app/core/service/user-list.service';
import { MongoStoredObject } from '@app/types/mongo-stored-object';
import { IonNav, LoadingController } from '@ionic/angular';
import { CompensationPoolsComponent } from '../compensation-pools/compensation-pools.component';
import {
  ECompensationFieldType,
  ICompensationField
} from '@backend/models/types/compensation-field';
import { combineLatest } from 'rxjs';
import { ToastService } from '@app/core/service/toast.service';
import { sortUsersCompare } from '../compensation-preview/compensation-preview.component';

export type ICompensationUserValue = Pick<
  UserModel,
  'firstName' | 'lastName' | 'compensationEmployeeId'
> & {
  userId: string;
  values: { field: string; value: string }[];
};

@Component({
  selector: 'app-compensation-hours',
  templateUrl: './compensation-hours.component.html',
  styleUrls: ['./compensation-hours.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CompensationHoursComponent implements OnInit {
  protected isLoading = true;
  protected compensationFields: MongoStoredObject<ICompensationField>[] = [];
  protected users: ICompensationUserValue[] = [];

  public constructor(
    private readonly _cdr: ChangeDetectorRef,
    private readonly _ionNav: IonNav,
    private readonly _userListService: UserListService,
    private readonly _compensationApiService: CompensationApiService,
    private readonly _loadingCtrl: LoadingController,
    private readonly _toastService: ToastService
  ) {}

  public ngOnInit(): void {
    combineLatest([
      this._userListService.users$,
      this._compensationApiService.getCompensationFields()
    ]).subscribe(([users, fields]) => {
      this.compensationFields = fields;

      const targetUsers = users.map<ICompensationUserValue>((u) => {
        return {
          userId: u._id.toString(),
          firstName: u.firstName,
          lastName: u.lastName,
          compensationEmployeeId: u.compensationEmployeeId,
          values: this.onlyHoursFields.map((f) => ({
            field: f.name,
            value: ''
          }))
        };
      });

      targetUsers.sort(sortUsersCompare);

      this.users = targetUsers;

      this.isLoading = false;
      this._cdr.markForCheck();
    });
  }

  protected get onlyHoursFields() {
    return this.compensationFields.filter(
      (field) => field.type === ECompensationFieldType.HOURS
    );
  }

  protected onBackButtonClick(): void {
    this._ionNav.pop();
  }

  protected onPasteButtonClick(): void {
    const employeeIdColumnName = this.compensationFields.find(
      (field) => field.type === ECompensationFieldType.EMPLOYEE_ID
    )?.name;

    if (!employeeIdColumnName) {
      this._toastService.presentToast(
        'Title of Employee ID Column is not provided. You can set the column title in the Team Settings'
      );
      return;
    }

    const input = document.createElement('input');
    input.type = 'file';
    input.accept =
      '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel';

    input.onchange = async (e) => {
      const files = (e.target as any).files;

      if (files.length) {
        const file = files[0];

        const loading = await this._loadingCtrl.create({
          message: 'Loading...'
        });
        loading.present();

        this._compensationApiService.parseCsv(file).subscribe({
          next: (objects) => {
            this.onlyHoursFields.map((field) => {
              this.users = this.users.map((user) => {
                if (user.compensationEmployeeId) {
                  const row = objects.find(
                    (row) =>
                      row[employeeIdColumnName] &&
                      row[employeeIdColumnName] === user.compensationEmployeeId
                  );

                  if (row) {
                    const userCol = user.values.find(
                      (col) => col.field === field.name
                    );

                    if (userCol) {
                      userCol.value = row[field.name] || '';
                    }
                  }
                }

                return user;
              });
            });

            loading.dismiss();
            this._cdr.markForCheck();
          },
          error: (e: { error: { message: string } }) => {
            loading.dismiss();
            this._toastService.presentToast(
              e.error.message || 'Unable to parse CSV file'
            );
            this._cdr.markForCheck();
          }
        });
      }
    };

    input.click();
  }

  protected onInputChange(
    user: ICompensationUserValue,
    field: string,
    event: any
  ) {
    event.preventDefault();

    const userCol = user.values.find((col) => col.field === field);

    if (!userCol) return;

    userCol.value = event.detail.value;
  }

  protected next() {
    this._ionNav.push(CompensationPoolsComponent, {
      fields: this.compensationFields,
      users: this.users
    });
  }
}
