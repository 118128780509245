import { Injectable } from '@angular/core';
import { Haptics, ImpactStyle } from '@capacitor/haptics';
import { Capacitor } from '@capacitor/core';

@Injectable({ providedIn: 'root' })
export class HapticsService {
  private isMobile = Capacitor.getPlatform() !== 'web';

  public async hapticsImpact(impactStyle: ImpactStyle = ImpactStyle.Light) {
    if (this.isMobile) {
      await Haptics.impact({ style: impactStyle });
    }
  }
}
