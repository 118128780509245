// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_updateLibraryDoc = (path: TSpecPathUpdateLibraryDoc) => {
  let res = "/library/docs/:libraryDocId";
  Object.entries(path).map(([key, value]) => {
    res = res.replace(':' + key, value);
  });
  return res;
};

export type TSpecHandler_updateLibraryDoc = RequestHandler<TSpecPathUpdateLibraryDoc, any, TSpecBodyUpdateLibraryDoc, TSpecQueryUpdateLibraryDoc, {}>;

export interface TSpecPathUpdateLibraryDoc { libraryDocId: string; }

export interface TSpecBodyUpdateLibraryDoc {
  originalDoc: string;
  version: number;
  tenant: string;
  userCreated: string | null;
  dateCreated: string;
  isDeleted: boolean;
  signed: {
    user: string;
    date: string;
  }[];
}

export interface TSpecQueryUpdateLibraryDoc {}
