// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_getTenantTasks = (path: TSpecPathGetTenantTasks) => {
  let res = "/tenants/:id/tasks";
  Object.entries(path).map(([key, value]) => {
    res = res.replace(':' + key, value);
  });
  return res;
};

export type TSpecHandler_getTenantTasks = RequestHandler<TSpecPathGetTenantTasks, any, TSpecBodyGetTenantTasks, TSpecQueryGetTenantTasks, {}>;

export interface TSpecPathGetTenantTasks { id: string; }

export interface TSpecBodyGetTenantTasks {}

export interface TSpecQueryGetTenantTasks {}
