// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_getNonSysGenBehaviors = (path: TSpecPathGetNonSysGenBehaviors) => {
  let res = "/tenants/:tenantId/non-sys-gen-behaviors";
  Object.entries(path).map(([key, value]) => {
    res = res.replace(':' + key, value);
  });
  return res;
};

export type TSpecHandler_getNonSysGenBehaviors = RequestHandler<TSpecPathGetNonSysGenBehaviors, any, TSpecBodyGetNonSysGenBehaviors, TSpecQueryGetNonSysGenBehaviors, {}>;

export interface TSpecPathGetNonSysGenBehaviors { tenantId: string; }

export interface TSpecBodyGetNonSysGenBehaviors {}

export interface TSpecQueryGetNonSysGenBehaviors {}
