import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MongoStoredObject } from '@app/types/mongo-stored-object';
import { IInstantFeedback } from '@backend/models/types/instant-feedback';
import {
  TSpecQueryGetInstantFeedbacks,
  TSpecRoute_getInstantFeedbacks
} from '@backend/types/generated/InstantFeedback/getInstantFeedbacks';
import { TSpecRoute_getUnreadCount } from '@backend/types/generated/InstantFeedback/getUnreadCount';
import {
  TSpecPathReadInstantFeedback,
  TSpecRoute_readInstantFeedback
} from '@backend/types/generated/InstantFeedback/readInstantFeedback';
import {
  TSpecBodySendInstantFeedback,
  TSpecRoute_sendInstantFeedback
} from '@backend/types/generated/InstantFeedback/sendInstantFeedback';
import { Observable } from 'rxjs';

@Injectable()
export class InstantFeedbackApiService {
  public constructor(private readonly _httpClient: HttpClient) {}

  public getInstantFeedbacks(params: {
    query: TSpecQueryGetInstantFeedbacks;
  }): Observable<MongoStoredObject<IInstantFeedback>[]> {
    return this._httpClient.get<MongoStoredObject<IInstantFeedback>[]>(
      TSpecRoute_getInstantFeedbacks(),
      { params: { ...params.query } }
    );
  }

  public sendInstantFeedback(params: { body: TSpecBodySendInstantFeedback }) {
    return this._httpClient.post(TSpecRoute_sendInstantFeedback(), params.body);
  }

  public getUnreadCount(): Observable<{
    unreadCount: number;
  }> {
    return this._httpClient.get<{ unreadCount: number }>(
      TSpecRoute_getUnreadCount()
    );
  }

  public readInstantFeedback(params: { path: TSpecPathReadInstantFeedback }) {
    return this._httpClient.post(
      TSpecRoute_readInstantFeedback(params.path),
      {}
    );
  }
}
