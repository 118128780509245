import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-user-schedule-editor-modal',
  templateUrl: './user-schedule-editor-modal.component.html'
})
export class UserScheduleEditorModalComponent {
  @Input()
  public userId?: string;

  @Input()
  public scheduleFormGroup: FormGroup;

  @Input()
  public weekTimeFormGroup: FormGroup;

  public constructor(private readonly _modalCtrl: ModalController) {}

  protected onCloseClick(): void {
    this._modalCtrl.dismiss(null, 'cancel', 'user-schedule-editor-modal');
  }
}
