import { NgModule } from '@angular/core';
import { ValuesModalComponent } from './values-modal.component';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DirectivesModule } from '@app/modules/directives.module';
import { FullHeightContainerModule } from '@app/modules/full-height-container/full-height-container.module';
import { ValuesAccordionModule } from '@app/modules/culture-accordion/values-accordion.module';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    ReactiveFormsModule,
    DirectivesModule,
    FullHeightContainerModule,
    ValuesAccordionModule
  ],
  exports: [ValuesModalComponent],
  declarations: [ValuesModalComponent]
})
export class ValuesModalModule {}
