<ion-header class="ion-no-border">
  <ion-toolbar class="app-toolbar">
    <ion-buttons slot="start">
      <ion-button (click)="onBackButtonClick()" appHaptics>
        <ion-icon slot="icon-only" name="chevron-back-outline" />
        <ion-label>Back</ion-label>
      </ion-button>
    </ion-buttons>
    <ion-title *ngIf="!parentId">
      {{ suggestion ? 'Edit Suggestion' : 'Share Feedback' }}
    </ion-title>
    <ion-title *ngIf="parentId">
      {{ suggestion ? 'Edit Comment' : 'Add Comment' }}
    </ion-title>
    <ion-buttons slot="end">
      <ion-button
        color="primary"
        [disabled]="!canSave"
        [disableHaptics]="!canSave"
        appHaptics
        (click)="onFormSubmit()"
      >
        {{ suggestion ? 'Update' : 'Submit' }}
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content color="light">
  <app-width-restricted-container
    class="container-with-fab"
    [formGroup]="mainFormGroup"
  >
    <app-wysiwyg-editor
      [isItem]="true"
      [(value)]="body"
      [disableAttachments]="disableAttachments"
      toolbarMode="image-only"
      bodyPlaceholder="Please describe your question, concern, or idea."
    />

    <ion-list *ngIf="isAdmin" [inset]="true">
      <ion-item button appHaptics (click)="userSelectorModal.present()">
        <ion-label>Author</ion-label>
        <ion-note slot="end">{{ formattedAuthor }}</ion-note>
      </ion-item>
      <ion-item *ngIf="!parentId">
        <ion-select
          label="Status"
          interface="popover"
          placeholder="Select Status"
          aria-label="Status"
          formControlName="status"
          labelPlacement="stacked"
          appHaptics
        >
          <ion-select-option
            *ngFor="let option of statuses"
            [value]="option.value"
          >
            {{ option.title }}
          </ion-select-option>
        </ion-select>
      </ion-item>
      <ion-item *ngIf="!parentId && categories.length > 0">
        <ion-select
          label="Category"
          interface="popover"
          placeholder="Select Category"
          aria-label="Category"
          formControlName="category"
          labelPlacement="stacked"
          appHaptics
        >
          <ion-select-option [value]="null">No Category</ion-select-option>
          <ion-select-option
            *ngFor="let category of categories"
            [value]="category._id"
          >
            {{ category.name }}
          </ion-select-option>
        </ion-select>
      </ion-item>
    </ion-list>

    <div *ngIf="isAdmin">
      <ion-list [inset]="true">
        <ion-item>
          <ion-toggle appHaptics formControlName="isPublic">
            <ion-label> Publish to Team </ion-label>
          </ion-toggle>
        </ion-item>
      </ion-list>
      <app-list-post-line [text]="publicInfo" />
    </div>

    <div *ngIf="suggestion">
      <app-list-pre-line title="Actions" />
      <ion-list [inset]="true">
        <ion-item
          button
          appHaptics
          (click)="onDeleteClick()"
          [detail]="false"
          color="danger"
        >
          <ion-icon slot="start" name="trash-outline" />
          <ion-label>Delete Suggestion</ion-label>
        </ion-item>
      </ion-list>
    </div>
  </app-width-restricted-container>
</ion-content>

<ion-loading #loading message="Loading..." />

<ion-modal
  #userSelectorModal
  [handle]="false"
  [breakpoints]="[0, 1]"
  [initialBreakpoint]="1"
>
  <ng-template>
    <app-user-selector
      title="Author"
      class="ion-page"
      [users]="allUsers"
      [recipients]="selectedUserIds"
      (selectionChange)="onUserChange($event)"
      (selectionCancel)="userSelectorModal.dismiss()"
    />
  </ng-template>
</ion-modal>
