export enum EDailySurveyMode {
  'OFF' = 'OFF',
  'PUSH_NOTIFICATION' = 'PUSH_NOTIFICATION',
  'REQUIRED' = 'REQUIRED'
}

export enum EDailySurveyFrequency {
  EVERY_DAY = 'EVERY_DAY',
  EVERY_WEEK = 'EVERY_WEEK',
  EVERY_TWO_WEEKS = 'EVERY_TWO_WEEKS',
  ONCE_PER_MONTH = 'ONCE_PER_MONTH'
}

export interface DailySurveyDefinition {
  dailySurveyMode: EDailySurveyMode;
  dailySurveyPNTime: number;
  frequency: EDailySurveyFrequency;
  date1: string;
  date2: string | null;
}
