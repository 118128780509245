// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_dismissRecognitionReminder = () => "/recognition/reminder/dismiss";

export type TSpecHandler_dismissRecognitionReminder = RequestHandler<TSpecPathDismissRecognitionReminder, any, TSpecBodyDismissRecognitionReminder, TSpecQueryDismissRecognitionReminder, {}>;

export interface TSpecPathDismissRecognitionReminder {  }

export interface TSpecBodyDismissRecognitionReminder {}

export interface TSpecQueryDismissRecognitionReminder {}
