// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_getFeedbackStats = () => "/psychological-survey/feedback";

export type TSpecHandler_getFeedbackStats = RequestHandler<TSpecPathGetFeedbackStats, any, TSpecBodyGetFeedbackStats, TSpecQueryGetFeedbackStats, {}>;

export interface TSpecPathGetFeedbackStats {  }

export interface TSpecBodyGetFeedbackStats {}

export interface TSpecQueryGetFeedbackStats {
  userId?: string;
}
