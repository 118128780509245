import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit
} from '@angular/core';
import { DateInterval } from '@backend/types/date-interval';
import { IonNav, LoadingController, ModalController } from '@ionic/angular';
import { ICompensationUserValue } from '../compensation-hours/compensation-hours.component';
import { ICompensationPoolValue } from '../compensation-pools/compensation-pools.component';
import { MongoStoredObject } from '@app/types/mongo-stored-object';
import {
  ECompensationFieldType,
  ICompensationField
} from '@backend/models/types/compensation-field';
import { ECompensationPoolType } from '@backend/models/types/compensation-pool';
import { CompensationApiService } from '@app/core/service/api/compensation.api.service';
import { ToastService } from '@app/core/service/toast.service';
import { InsightsRangeModalComponent } from '@app/modules/insights-range-modal/insights-range-modal.component';
import { isSameDay } from 'date-fns';
import { UserApiService } from '@app/core/service/api/user.api.service';
import { lastNDays } from '@app/core/service/insights-date-range-helper.service';
import { CompensationAnnouncementPreviewModalComponent } from '@app/modules/compensation-announcement-preview-modal/compensation-announcement-preview-modal.component';
import { EIncludePointsFrom } from '@backend/types/compensation-definition';
import { ICompensationUserPool } from '@backend/models/types/compensation';

interface ILocalSum {
  totalHours: number;
  totalPoints: number;
}

type ICalculatedUser = ICompensationUserValue &
  ILocalSum & { notify: boolean; pools?: ICompensationUserPool[] };

type ICalculatedPool = ICompensationPoolValue & {
  totalPoolPoints: number;
};

export const sortUsersCompare = (
  a: { lastName: string },
  b: { lastName: string }
) => {
  if (a.lastName < b.lastName) {
    return -1;
  }
  if (a.lastName > b.lastName) {
    return 1;
  }
  return 0;
};

@Component({
  selector: 'app-compensation-preview',
  templateUrl: './compensation-preview.component.html',
  styleUrls: ['./compensation-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CompensationPreviewComponent implements OnInit {
  @Input()
  public disableSubmit = false;

  @Input()
  public defaultRange: DateInterval = null;

  @Input()
  public pools: ICompensationPoolValue[] = [];

  @Input()
  public users: (ICompensationUserValue & {
    notify?: boolean;
    points?: number;
    pools?: ICompensationUserPool[];
  })[] = [];

  @Input()
  public fields: MongoStoredObject<ICompensationField>[] = [];

  @Input()
  public includePointsFrom: EIncludePointsFrom[] = [];

  protected compensationPoolType = ECompensationPoolType;

  protected range: DateInterval;
  protected calculatedUsers: ICalculatedUser[] = [];
  protected sums: ILocalSum = {
    totalHours: 0,
    totalPoints: 0
  };
  protected calculatedPools: ICalculatedPool[] = [];
  protected showPointsPopover = false;

  public constructor(
    private readonly _ionNav: IonNav,
    private readonly _userApiService: UserApiService,
    private readonly _cdr: ChangeDetectorRef,
    private readonly _compensationApiService: CompensationApiService,
    private readonly _loadingCtrl: LoadingController,
    private readonly _toastService: ToastService,
    private readonly _modalCtrl: ModalController
  ) {}

  public ngOnInit(): void {
    this.range = this.defaultRange;

    if (this.range) {
      this._calculateData();
    } else {
      this.onRangeClick();
    }
  }

  protected get includePointsFromText() {
    return this.includePointsFrom.map((ipf) => {
      switch (ipf) {
        case 'tasksCompleted':
          return 'Tasks';
        case 'pointsSent':
          return 'Recognition Sent';
        case 'pointsReceived':
          return 'Recognition Received';
      }
    });
  }

  protected _calculateData() {
    this._userApiService
      .getInsights({
        query: {
          start: this.range.start.toISOString(),
          end: this.range.end.toISOString()
        }
      })
      .subscribe((insights) => {
        const calculatedUsers = this.users.map((user) => {
          return {
            ...user,
            totalHours: user.values.reduce((prev, cur) => {
              const res = parseFloat(cur.value) || 0;

              this.sums.totalHours += res;

              return prev + res;
            }, 0),
            totalPoints: this.disableSubmit
              ? (() => {
                  this.sums.totalPoints += user.points;

                  return user.points;
                })()
              : (() => {
                  const stats = insights.find(
                    (i) => i._id.toString() === user.userId
                  );

                  const res = Object.entries(stats).reduce(
                    (sum, [key, value]: [EIncludePointsFrom, number]) => {
                      if (this.includePointsFrom.includes(key)) {
                        return sum + value;
                      }

                      return sum;
                    },
                    0
                  );

                  this.sums.totalPoints += res;

                  return res;
                })()
          };
        });

        calculatedUsers.sort(sortUsersCompare);

        this.calculatedUsers = calculatedUsers.map((user) => ({
          ...user,
          notify:
            typeof user.notify === 'boolean' ? user.notify : !!user.totalPoints
        }));

        this.calculatedPools = this.pools.map((pool) => {
          const stats = insights.filter((i) =>
            pool.users.map((p) => p.toString()).includes(i._id.toString())
          );

          const totalPoolPoints = stats.reduce((prev, cur) => {
            const res = Object.entries(cur).reduce(
              (sum, [key, value]: [EIncludePointsFrom, number]) => {
                if (this.includePointsFrom.includes(key)) {
                  return sum + value;
                }

                return sum;
              },
              0
            );

            return prev + res;
          }, 0);

          return {
            ...pool,
            totalPoolPoints
          };
        });

        this._cdr.markForCheck();
      });
  }

  protected async onRangeClick() {
    const dateRangeModal = await this._modalCtrl.create({
      id: 'insights-range-modal',
      component: InsightsRangeModalComponent,
      cssClass: 'modal-auto-height',
      breakpoints: [0, 1],
      initialBreakpoint: 1,
      componentProps: {
        range: this.range || lastNDays(14)
      },
      handle: false
    });
    dateRangeModal.present();

    const { data, role } = await dateRangeModal.onWillDismiss();

    if (role === 'confirm') {
      this.range = data;
      this._calculateData();
    }
  }

  protected onBackButtonClick(): void {
    this._ionNav.pop();
  }

  protected formatPointsPerHour(
    totalPoints: number,
    totalHours: number
  ): string {
    return totalHours ? `${(totalPoints / totalHours).toFixed(2)}/hr` : '-';
  }

  protected formatEarningsPerHour(user: ICalculatedUser): string {
    const total = this.calculatedPools.reduce((prevPool, curPool) => {
      if (!this.inPool(user, curPool)) {
        return prevPool;
      }

      const v = user.pools
        ? user.pools.find((p) => p.pool.toString() === curPool._id.toString())
            .value
        : (user.totalPoints / curPool.totalPoolPoints) *
          (parseFloat(curPool.value) || 0);

      return prevPool + v;
    }, 0);

    return user.totalHours
      ? `$${(total / user.totalHours).toFixed(2)}/hr`
      : '-';
  }

  protected get averagePointsPerHour() {
    let totalHours = 0;
    let totalPoints = 0;
    this.calculatedUsers
      .filter((u) => u.totalHours)
      .map((u) => {
        totalHours += u.totalHours;
        totalPoints += u.totalPoints;
      });
    return totalHours && totalPoints
      ? `${(totalPoints / totalHours).toFixed(2)}/hr`
      : '-';
  }

  protected formatRelativePoolValue(
    pool: ICalculatedPool,
    user: ICalculatedUser
  ): string {
    const prefix = pool.type === ECompensationPoolType.CURRENCY ? '$' : '';
    const postfix = pool.type === ECompensationPoolType.HOURS ? ' hours' : '';

    const v = user.pools
      ? user.pools.find((p) => p.pool.toString() === pool._id.toString()).value
      : (user.totalPoints / pool.totalPoolPoints) *
        (parseFloat(pool.value) || 0);

    return this.sums.totalPoints === 0 || !this.inPool(user, pool)
      ? '-'
      : `${prefix}${v.toFixed(2)}${postfix}`;
  }

  protected formatPoolTotal(pool: ICompensationPoolValue): string {
    return `${pool.type === ECompensationPoolType.CURRENCY ? '$' : ''}${(
      parseFloat(pool.value) || 0
    ).toFixed(2)}${pool.type === ECompensationPoolType.HOURS ? ' hours' : ''}`;
  }

  protected inPool(user: ICalculatedUser, pool: ICompensationPoolValue) {
    return pool.users.map((u) => u.toString()).includes(user.userId);
  }

  protected prepareDataToSubmit() {
    return {
      includePointsFrom: this.includePointsFrom,
      range: {
        start: this.range.start.toISOString(),
        end: this.range.end.toISOString()
      },
      pools: this.pools.map((pool) => ({
        pool: pool._id.toString(),
        value: parseFloat(pool.value) || 0
      })),
      fields: this.fields
        .filter((field) => field.type === ECompensationFieldType.HOURS)
        .map((field) => field._id.toString()),
      usersData: this.calculatedUsers.map((user) => {
        return {
          user: user.userId,
          firstName: user.firstName,
          lastName: user.lastName,
          compensationEmployeeId: user.compensationEmployeeId,
          totalPointsEarned: user.totalPoints,
          totalHoursWorked: user.totalHours,
          pools: this.calculatedPools.map((pool) => {
            return {
              pool: pool._id.toString(),
              value:
                (user.totalPoints / pool.totalPoolPoints) *
                (parseFloat(pool.value) || 0),
              isEligible: this.inPool(user, pool)
            };
          }),
          notify: user.notify
        };
      })
    };
  }

  protected async submit() {
    const loading = await this._loadingCtrl.create({
      message: 'Loading...'
    });
    loading.present();

    this._compensationApiService
      .submitCompensation({ body: this.prepareDataToSubmit() })
      .subscribe({
        next: () => {
          this._toastService.presentToast('Rewards successfully sent!');
          this._cdr.markForCheck();

          this._ionNav.popToRoot();
        },
        error: (e) => {
          loading.dismiss();
          this._toastService.presentToast(
            e.error?.message || 'Unable to submit rewards'
          );
          this._cdr.markForCheck();
        }
      });
  }

  protected get isOneDayRange() {
    return isSameDay(this.range.start, this.range.end);
  }

  protected async showAnnouncementPreview(userId: string) {
    const loading = await this._loadingCtrl.create({
      message: 'Loading...'
    });
    loading.present();

    this._compensationApiService
      .getCompensationAnnouncementPreview({
        body: { userId, compensation: this.prepareDataToSubmit() }
      })
      .subscribe({
        next: async ({ title, body }) => {
          loading.dismiss();

          const announcementPreviewModal = await this._modalCtrl.create({
            id: 'compensation-announcement-preview-modal',
            component: CompensationAnnouncementPreviewModalComponent,
            componentProps: {
              title,
              body
            },
            handle: false,
            breakpoints: [0, 1],
            initialBreakpoint: 1
          });
          announcementPreviewModal.present();
        },
        error: (e) => {
          loading.dismiss();
          this._toastService.presentToast(
            e.error?.message || 'Unable to fetch announcement preview'
          );
        }
      });
  }
}
