// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_createBehavior = () => "/behaviors";

export type TSpecHandler_createBehavior = RequestHandler<TSpecPathCreateBehavior, any, TSpecBodyCreateBehavior, TSpecQueryCreateBehavior, {}>;

export interface TSpecPathCreateBehavior {  }

export interface TSpecBodyCreateBehavior {
  tenantId: string;
  name: string;
  points: number;
  isValue: boolean;
  description: string;
  eligible: UserRoles[];
}

export enum UserRoles {
  Admin = "Admin",
  Manager = "Manager"
}

export interface TSpecQueryCreateBehavior {}
