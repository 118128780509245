import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { MongoStoredObject } from '@app/types/mongo-stored-object';
import { PerformanceWithUser } from '@app/types/performance-with-user';
import {
  ITaskPerformance,
  TaskPerformanceBonus
} from '@backend/models/types/task-performance';
import { ITask } from '@backend/models/types/task';
import { UserModel } from '../../model/user.model';
import {
  TSpecQueryGetTaskPerformance,
  TSpecRoute_getTaskPerformance
} from '@backend/types/generated/TaskPerformances/getTaskPerformance';
import {
  TSpecBodyPatchTaskPerformances,
  TSpecRoute_patchTaskPerformances
} from '@backend/types/generated/TaskPerformances/patchTaskPerformances';
import {
  TSpecPathCancelTaskPerformance,
  TSpecRoute_cancelTaskPerformance
} from '@backend/types/generated/TaskPerformances/cancelTaskPerformance';
import {
  TSpecBodyStopTaskPerformance,
  TSpecPathStopTaskPerformance,
  TSpecRoute_stopTaskPerformance
} from '@backend/types/generated/TaskPerformances/stopTaskPerformance';
import {
  TSpecBodyApproveTaskPerformance,
  TSpecPathApproveTaskPerformance,
  TSpecRoute_approveTaskPerformance
} from '@backend/types/generated/TaskPerformances/approveTaskPerformance';
import { TSpecRoute_getWaitingForApprovalTaskPerformances } from '@backend/types/generated/TaskPerformances/getWaitingForApprovalTaskPerformances';
import {
  TSpecBodyAcknowledgeTaskPerformanceNote,
  TSpecPathAcknowledgeTaskPerformanceNote,
  TSpecRoute_acknowledgeTaskPerformanceNote
} from '@backend/types/generated/TaskPerformances/acknowledgeTaskPerformanceNote';

@Injectable({ providedIn: 'root' })
export class TaskPerformanceApiService {
  public constructor(private _httpClient: HttpClient) {}

  public getTaskPerformance(params: {
    query: TSpecQueryGetTaskPerformance;
  }): Observable<MongoStoredObject<PerformanceWithUser>[]> {
    return this._httpClient.get<MongoStoredObject<PerformanceWithUser>[]>(
      TSpecRoute_getTaskPerformance(),
      { params: { ...params.query } }
    );
  }

  public patchTaskPerformances(params: {
    body: TSpecBodyPatchTaskPerformances;
  }): Observable<MongoStoredObject<ITaskPerformance>> {
    return this._httpClient.patch<MongoStoredObject<ITaskPerformance>>(
      TSpecRoute_patchTaskPerformances(),
      params.body
    );
  }

  public cancelTaskPerformance(params: {
    path: TSpecPathCancelTaskPerformance;
  }): Observable<MongoStoredObject<ITaskPerformance>> {
    return this._httpClient.post<MongoStoredObject<ITaskPerformance>>(
      TSpecRoute_cancelTaskPerformance(params.path),
      {}
    );
  }

  public stopTaskPerformance(params: {
    path: TSpecPathStopTaskPerformance;
    body: TSpecBodyStopTaskPerformance;
  }): Observable<MongoStoredObject<ITaskPerformance>> {
    return this._httpClient.post<MongoStoredObject<ITaskPerformance>>(
      TSpecRoute_stopTaskPerformance(params.path),
      params.body
    );
  }

  public approveTaskPerformance(params: {
    path: TSpecPathApproveTaskPerformance;
    body: TSpecBodyApproveTaskPerformance;
  }): Observable<MongoStoredObject<ITaskPerformance>> {
    return this._httpClient.post<MongoStoredObject<ITaskPerformance>>(
      TSpecRoute_approveTaskPerformance(params.path),
      params.body
    );
  }

  public getWaitingForApprovalTaskPerformances(): Observable<
    MongoStoredObject<
      ITaskPerformance & {
        user: UserModel;
        task: MongoStoredObject<ITask>;
      }
    >[]
  > {
    return this._httpClient.get<
      MongoStoredObject<
        ITaskPerformance & {
          user: UserModel;
          task: MongoStoredObject<ITask>;
        }
      >[]
    >(TSpecRoute_getWaitingForApprovalTaskPerformances());
  }

  public acknowledgeTaskPerformanceNote(params: {
    path: TSpecPathAcknowledgeTaskPerformanceNote;
    body: TSpecBodyAcknowledgeTaskPerformanceNote;
  }): Observable<MongoStoredObject<ITaskPerformance>> {
    return this._httpClient.post<MongoStoredObject<ITaskPerformance>>(
      TSpecRoute_acknowledgeTaskPerformanceNote(params.path),
      params.body
    );
  }
}
