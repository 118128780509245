import { Component, Input } from '@angular/core';
import { AnnouncementModel } from '@app/core/model/announcement.model';

@Component({
  selector: 'app-announcement-thread',
  templateUrl: './announcement-thread.component.html',
  styleUrls: ['./announcement-thread.component.scss']
})
export class AnnouncementThreadComponent {
  @Input()
  public readonly announcements: AnnouncementModel[];

  public constructor() {}
}
