// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_getTasks = () => "/tasks";

export type TSpecHandler_getTasks = RequestHandler<TSpecPathGetTasks, any, TSpecBodyGetTasks, TSpecQueryGetTasks, {}>;

export interface TSpecPathGetTasks {  }

export interface TSpecBodyGetTasks {}

export interface TSpecQueryGetTasks {}
