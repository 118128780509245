// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_submitTaskAudit = () => "/task-audit";

export type TSpecHandler_submitTaskAudit = RequestHandler<TSpecPathSubmitTaskAudit, any, TSpecBodySubmitTaskAudit, TSpecQuerySubmitTaskAudit, {}>;

export interface TSpecPathSubmitTaskAudit {  }

export interface TSpecBodySubmitTaskAudit {
  auditTask: string;
  tasksForAudit: {
    title: string;
    lastPerformance?: {
      taskPerformance: string;
      performer: string;
    };
    criteria: {
      title: string;
      value: boolean;
      comment: string;
      attachments: string[];
    }[];
  }[];
}

export interface TSpecQuerySubmitTaskAudit {}
