// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_answerToPsychologicalSurvey = (path: TSpecPathAnswerToPsychologicalSurvey) => {
  let res = "/psychological-survey/:surveyId/answer";
  Object.entries(path).map(([key, value]) => {
    res = res.replace(':' + key, value);
  });
  return res;
};

export type TSpecHandler_answerToPsychologicalSurvey = RequestHandler<TSpecPathAnswerToPsychologicalSurvey, any, TSpecBodyAnswerToPsychologicalSurvey, TSpecQueryAnswerToPsychologicalSurvey, {}>;

export interface TSpecPathAnswerToPsychologicalSurvey { surveyId: string; }

export interface TSpecBodyAnswerToPsychologicalSurvey {
  questionAnswers: {
    question: string;
    result: {};
  }[];
}

export interface TSpecQueryAnswerToPsychologicalSurvey {}
