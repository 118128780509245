// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_approveTaskPerformance = (path: TSpecPathApproveTaskPerformance) => {
  let res = "/task-performance/:id/approve";
  Object.entries(path).map(([key, value]) => {
    res = res.replace(':' + key, value);
  });
  return res;
};

export type TSpecHandler_approveTaskPerformance = RequestHandler<TSpecPathApproveTaskPerformance, any, TSpecBodyApproveTaskPerformance, TSpecQueryApproveTaskPerformance, {}>;

export interface TSpecPathApproveTaskPerformance { id: string; }

export interface TSpecBodyApproveTaskPerformance {
  bonus?: TaskPerformanceBonus;
  isFasterThanAverage?: boolean;
  startTime?: string;
  completeTime?: string;
}

export enum TaskPerformanceBonus {
  NEEDS_IMPROVEMENT = "NEEDS_IMPROVEMENT",
  ACCEPTABLE = "ACCEPTABLE",
  EXCELLENT = "EXCELLENT"
}

export interface TSpecQueryApproveTaskPerformance {}
