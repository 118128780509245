// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_getUser = () => "/user";

export type TSpecHandler_getUser = RequestHandler<TSpecPathGetUser, any, TSpecBodyGetUser, TSpecQueryGetUser, {}>;

export interface TSpecPathGetUser {  }

export interface TSpecBodyGetUser {}

export interface TSpecQueryGetUser {}
