// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_readLedgerById = (path: TSpecPathReadLedgerById) => {
  let res = "/tenant/:tenantId/ledger/:id/read";
  Object.entries(path).map(([key, value]) => {
    res = res.replace(':' + key, value);
  });
  return res;
};

export type TSpecHandler_readLedgerById = RequestHandler<TSpecPathReadLedgerById, any, TSpecBodyReadLedgerById, TSpecQueryReadLedgerById, {}>;

export interface TSpecPathReadLedgerById { tenantId: string; id: string; }

export interface TSpecBodyReadLedgerById {}

export interface TSpecQueryReadLedgerById {}
